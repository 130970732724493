import React, { FunctionComponent } from 'react'

import styles from './DropdownGroup.module.scss'
import { DropdownGroupProps } from './DropdownGroup.model'

export const DropdownGroup: FunctionComponent<DropdownGroupProps> = ({ children, label }) => {
  return (
    <div className={styles.container}>
      {label && <div className={styles.header}>{label}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  )
}
