import { Patient, TrustedSource } from '../../../../model/Patient'
import { SelectOption } from '../../../../model/SelectOption'
import { ImpersonatePermissions } from '../../../../model/User'

export interface IdentityStatusBlockProps {
  impersonatePermissions: ImpersonatePermissions | null
  patient: Partial<Patient> | Patient | null
}

export const SHOWN_DOCUMENT_OPTIONS: SelectOption<TrustedSource | null>[] = [
  { value: null, label: 'Aucun' },
  { value: TrustedSource.SOCIAL_SECURITY_CARD, label: 'Carte vitale' },
  { value: TrustedSource.ID_CARD, label: "Carte nationale d'identité" },
  { value: TrustedSource.DRIVER_LICENSE, label: 'Permis de conduire' },
  { value: TrustedSource.RESIDENCE_PERMIT, label: 'Titre de séjour' },
  { value: TrustedSource.E_DOCUMENT, label: "Dispositif d'identification electronique" },
  { value: TrustedSource.PASSPORT, label: 'Passeport' },
  { value: TrustedSource.MANUAL_VALIDATION, label: 'Avis du praticien' },
  { value: TrustedSource.OTHER, label: 'Autre' },
]
