import { FunctionComponent, useCallback, useEffect, useReducer } from 'react'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import { IconButton } from '../buttons'
import { Icon } from '../Icon'

import { AudioPlayerProps, AudioPlayerState } from './AudioPlayer.model'
import styles from './AudioPlayer.module.scss'

const initialState: AudioPlayerState = {
  audio: null,
  slider: 0,
  active: false,
  length: 0,
  volume: 0.5,
  time: 0,
  drag: 0,
}

const convertSecondsToMinutesSeconds = (s: number) => new Date(1000 * s).toISOString().substr(15, 4)

export const AudioPlayer: FunctionComponent<AudioPlayerProps> = ({ file }) => {
  const [state, updateState] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initialState)

  const play = useCallback(() => {
    updateState({ active: true })
    state.audio?.play()
  }, [state.audio])

  const pause = useCallback(() => {
    updateState({ active: false })
    state.audio?.pause()
  }, [state.audio])

  useEffect(() => {
    const audio = new Audio(file?.base64)

    const setAudioData = () => {
      updateState({ length: audio.duration, time: audio.currentTime })
    }

    const setAudioTime = () => {
      const curTime = audio.currentTime
      updateState({
        time: curTime,
        slider: curTime ? parseInt(((curTime * 100) / audio.duration).toFixed(1)) : 0,
      })
    }

    const setAudioVolume = () => updateState({ volume: audio.volume })

    updateState({ audio: audio })

    audio.addEventListener('loadeddata', setAudioData)
    audio.addEventListener('timeupdate', setAudioTime)
    audio.addEventListener('volumechange', setAudioVolume)
    audio.addEventListener('ended', () => {
      updateState({ active: false })
      audio.pause()
    })

    return () => {
      audio.pause()
      updateState({ audio: null, slider: 1, drag: 0 })
    }
  }, [file?.base64])

  useEffect(() => {
    if (state.audio) {
      let val = 0
      if (state.drag !== 0) {
        val = Math.round((state.drag * state.audio.duration) / 100)
      }

      state.audio.currentTime = val
    }
  }, [state.drag, state.audio])

  const increaseTime = useCallback(() => {
    let newTimeValue = 0
    if (state.audio) {
      if (state.time + 15 <= state.audio.duration) {
        newTimeValue = state.time + 15
      } else {
        newTimeValue = state.audio.duration
      }
    }

    updateState({
      time: newTimeValue,
      slider: newTimeValue ? parseInt(((newTimeValue * 100) / state.audio.duration).toFixed(1)) : 0,
      drag: parseInt(((newTimeValue * 100) / state.audio.duration).toFixed(1)),
    })
  }, [state.audio, state.time])

  const decreaseTime = useCallback(() => {
    const newTimeValue = state.audio && state.time - 15 >= 0 ? state.time - 15 : 0

    updateState({
      time: newTimeValue,
      slider: newTimeValue ? parseInt(((newTimeValue * 100) / state.audio.duration).toFixed(1)) : 0,
      drag: parseInt(((newTimeValue * 100) / state.audio.duration).toFixed(1)),
    })
  }, [state.audio, state.time])

  useEffect(() => {
    if (state.audio?.paused) {
      pause()
    } else {
      play()
    }
  }, [state.audio?.paused, pause, play])

  return (
    <div className="flex flex-col w-full bg-shades-7 rounded-t-lg">
      <div className="text-xl font-semibold text-shades-3 w-full p-4 break-words">
        <span>{file?.visibleName}</span>
      </div>
      <div className="flex items-center w-full bg-shades-3 px-2 py-2 rounded-b-lg space-x-4">
        <div
          className="flex flex-1 w-1/6 items-center justify-around"
          style={{ flexBasis: 'content' }}
        >
          <IconButton
            icon="decreaseTime"
            size="normal"
            theme="transparent-light"
            noBorder
            onClick={decreaseTime}
          />
          {state.active ? (
            <IconButton
              icon="pause"
              size="normal"
              onClick={pause}
              noBorder
              theme="transparent-light"
            />
          ) : (
            <IconButton
              icon="play"
              size="normal"
              onClick={play}
              noBorder
              theme="transparent-light"
            />
          )}
          <IconButton
            icon="addTime"
            size="normal"
            theme="transparent-light"
            noBorder
            onClick={increaseTime}
          />
        </div>
        <div className="flex w-4/6 justify-center items-center mx-2">
          <span className="text-lg text-shades-8 font-medium mr-2">
            {!state.time ? '0:00' : convertSecondsToMinutesSeconds(state.time)}
          </span>
          <input
            type="range"
            min="1"
            max="100"
            step="1"
            className={styles.progressAudio}
            value={state.slider}
            onChange={(e) => {
              updateState({ slider: parseInt(e.target.value), drag: parseInt(e.target.value) })
            }}
            style={{
              background: `linear-gradient(90deg, var(--fw-color-primary-light) ${Math.floor(
                state.slider,
              )}%, var(--fw-color-shades-shade2) ${Math.floor(state.slider)}%)`,
            }}
            onMouseUp={play}
            onTouchEnd={play}
          />
          <span className="text-lg text-shades-8 font-medium ml-2">
            {!state.length ? '0:00' : convertSecondsToMinutesSeconds(state.length)}
          </span>
        </div>
        <div className="flex w-1/6 items-center">
          <div className="mr-3">
            <Icon icon="speaker" size="micro" color={getV3ColorCssVariable('shades', 'white')} />
          </div>
          <input
            type="range"
            min="1"
            max="100"
            className={styles.progressVolume}
            defaultValue={initialState.volume * 100}
            style={{
              background: `linear-gradient(90deg, var(--fw-color-primary-light) ${
                state.volume * 100
              }%, var(--fw-color-shades-shade2) ${state.volume * 100}%)`,
            }}
            onChange={(e) => {
              const newVolumeValue = parseInt(e.target.value) / 100
              updateState({ volume: newVolumeValue })
              state.audio.volume = newVolumeValue
            }}
          />
        </div>
      </div>
    </div>
  )
}
