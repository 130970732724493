import { domainAnswerValuesSagas } from './documentAnswerValues/documentAnswerValues.sagas'
import { domainDocumentHistorySagas } from './documentHistory/documentHistory.sagas'
import { domainDocumentTemplatesSagas } from './documentTemplates/documentTemplates.sagas'
import { filesDomainSagas } from './files/files.sagas'
import { questionTemplatesSagas } from './questionTemplates/questionTemplates.sagas'
import { meSagas } from './me/me.sagas'
import { medicalEventsDomainSagas } from './medicalEvents/medicalEvents.saga'
import { domainDocumentInstancesSagas } from './documentInstances/documentInstances.sagas'
import { codesSagas } from './codes/codes.sagas'
import { externalServicesSagas } from './externalServices/externalServices.sagas'
import { domainDocumentAlertsSagas } from './documentAlerts'
import { dmpSagas } from './dmp/dmp.sagas'
import { metricsSagas } from './metrics/metrics.sagas'

export const domainSagas = [
  ...Object.values(domainAnswerValuesSagas),
  ...Object.values(domainDocumentHistorySagas),
  ...Object.values(domainDocumentTemplatesSagas),
  ...Object.values(domainDocumentAlertsSagas),
  ...Object.values(externalServicesSagas),
  ...Object.values(medicalEventsDomainSagas),
  ...Object.values(domainDocumentInstancesSagas),
  ...Object.values(filesDomainSagas),
  ...Object.values(meSagas),
  ...Object.values(questionTemplatesSagas),
  ...Object.values(codesSagas),
  ...Object.values(dmpSagas),
  ...Object.values(metricsSagas),
]
