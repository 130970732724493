import { format, isValid, parse } from 'date-fns'
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.scss'
import styles from './DatePicker.module.scss'

import { DatePickerProps } from './DatePicker.model'
import {
  applyInputDateMask,
  DATE_FORMAT_FRONT_SHORT,
  formatFr,
  FR_SHORT_DATE_REGEX,
  getFrLocale,
} from '../../../../misc/date.utilities'
import { Icon } from '../../Icon'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import classNames from 'classnames'
import { Input } from '../Input'
import { SearchInput } from '../../search'
import { useClickOutside } from '../../../../hooks/utils'

export const DatePicker: FunctionComponent<DatePickerProps> = ({
  value,
  onChange,
  label,
  placeholder,
  disabled,
  icon,
  showYearDropdown = true,
  showMonthDropdown = true,
  colorPreset = 'light',
  inputType = 'input',
  direction = 'bottom',
  inputName,
  showCalendarButton = true,
  clearable = false,
  validation = true,
  testId,
}) => {
  const [rawValue, setRawValue] = useState('')
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef(null)

  const closeDropdown = useCallback(() => setOpen(false), [])
  useClickOutside(dropdownRef, open, !open, closeDropdown)

  useEffect(() => {
    const formated = value ? formatFr(value, DATE_FORMAT_FRONT_SHORT) : ''
    setRawValue(formated)
  }, [value])

  const isRawValueValid = useMemo(
    () => (rawValue ? isValid(parse(rawValue, DATE_FORMAT_FRONT_SHORT, new Date())) : undefined),
    [rawValue],
  )

  function handleChange(date: Date | null) {
    if (date !== null || clearable) {
      onChange(date)
      setRawValue(date ? format(date, DATE_FORMAT_FRONT_SHORT) : '')
      setOpen(false)
    }
  }

  const handleRawChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const [eventValue] = applyInputDateMask(event)
      setRawValue(eventValue)

      const parsed = parse(eventValue, DATE_FORMAT_FRONT_SHORT, new Date())
      const match = eventValue.match(FR_SHORT_DATE_REGEX)?.pop()
      const isParsedValid = isValid(parsed) && !!match

      if (isParsedValid) {
        onChange(parsed)
      } else if (eventValue === '' && clearable) {
        onChange(null)
      }
    },
    [clearable, onChange],
  )

  const onClear = () => {
    onChange(null)
    setRawValue('')
  }

  const onFocus = () => !disabled && setOpen(true)

  return (
    <div
      ref={dropdownRef}
      className={styles.container}
      tabIndex={0}
      data-test-id={testId}
      onFocus={onFocus}
    >
      {inputType === 'input' && (
        <Input
          name={inputName ?? 'date_input'}
          colorPreset={colorPreset}
          valid={validation ? isRawValueValid : undefined}
          icon={icon}
          value={rawValue}
          onChange={handleRawChange}
          placeholder={placeholder}
          label={label}
          autocomplete="off"
          disabled={disabled}
        />
      )}
      {inputType === 'search' && (
        <SearchInput
          colorPreset={colorPreset}
          placeholder={label}
          value={rawValue}
          icon={icon}
          onChange={handleRawChange}
        />
      )}
      <div className={classNames(styles.icons)}>
        {clearable && (
          <div className={styles.icon} onClick={onClear}>
            <Icon
              size="micro"
              icon="cross"
              color={
                colorPreset === 'dark'
                  ? getV3ColorCssVariable('shades', 'shade4')
                  : getV3ColorCssVariable('shades', 'shade1')
              }
            />
          </div>
        )}
        {showCalendarButton && (
          <div className={styles.icon} onClick={disabled ? undefined : () => setOpen(true)}>
            <Icon
              size="micro"
              icon="calendar"
              color={
                colorPreset === 'dark'
                  ? getV3ColorCssVariable('shades', 'shade4')
                  : getV3ColorCssVariable('shades', 'shade1')
              }
            />
          </div>
        )}
      </div>
      {open && (
        <div className={classNames(styles.dropdown, styles[direction])}>
          <ReactDatePicker
            inline
            selected={value}
            todayButton="Aujourd'hui"
            onChange={handleChange}
            dateFormat={DATE_FORMAT_FRONT_SHORT}
            locale={getFrLocale()}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            dropdownMode="select"
            placeholderText={placeholder}
            className="text-base text-shades-2"
            disabled={disabled}
          />
        </div>
      )}
    </div>
  )
}
