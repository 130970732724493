import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import { Icon } from '../../../shared'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { PatientInformationsContainerProps } from './PatientInformationsContainer.model'

export const PatientInformationsContainer: FunctionComponent<PatientInformationsContainerProps> = ({
  children,
  isIndicationGroup,
  cim10,
  onClick,
  testId,
}) => (
  <div
    onClick={onClick}
    className={classNames(
      onClick && 'cursor-pointer',
      'bg-shades-white px-6 py-2.5 border-b border-gray-200 flex flex-row justify-between items-center',
    )}
    data-test-id={testId}
  >
    <div className="flex flex-row text-shades-2 text-sm">
      {children}
      {cim10 && <div className="ml-2 text-gray-500">{cim10}</div>}
    </div>
    {isIndicationGroup && (
      <Icon
        icon="caret"
        rotate={90}
        size="femto"
        color={getV3ColorCssVariable('shades', 'shade4')}
      />
    )}
  </div>
)
