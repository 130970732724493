import { connect } from 'react-redux'
import { RootState } from '../../../store'
import {
  bottomPanelDocumentTemplatesActions,
  bottomPanelDocumentTemplatesPageSelector,
} from '../../../store/ui/bottomPanelDocumentTemplates'

import {
  SearchDocumentTemplatesStoreProps,
  SearchDocumentTemplatesDispatchProps,
} from './SearchDocumentTemplates.model'
import { SearchDocumentTemplates } from './SearchDocumentTemplates.component'
import { withUnmountTransition } from '../../../misc/withUnmountTransition.hoc'

const mapStateToProps = (state: RootState): SearchDocumentTemplatesStoreProps => ({
  documentTemplatesPage: bottomPanelDocumentTemplatesPageSelector(state),
})

const mapDispatchToProps: SearchDocumentTemplatesDispatchProps = {
  getPaginatedDocumentTemplate: bottomPanelDocumentTemplatesActions.requestPage,
  clearDocumentTemplateList: bottomPanelDocumentTemplatesActions.clearPagination,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(SearchDocumentTemplates)
const withUnmountHoc = withUnmountTransition(withConnect)
export { withUnmountHoc as SearchDocumentTemplates }
