import { PluginNames } from '@/model'
import { isDefined } from '@/utils/functions.utils'
import { Mark } from '@tiptap/react'

const dataAttrib = 'data-marker'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.Marker]: {
      /** Ajoute un marker au bloc actif, ou le retire si null  */
      setMarker: (marker: string | null) => ReturnType
      /** Positionne le curseur à la fin du bloc précédant le bloc marqué */
      setSelectionBeforeMarker: (marker: string) => ReturnType
    }
  }
}

export const Marker = Mark.create({
  name: PluginNames.Marker,
  group: 'inline',
  keepOnSplit: false,
  exitable: true,
  inclusive: false,

  addGlobalAttributes() {
    return [
      {
        types: ['paragraph'],
        attributes: {
          marker: {
            parseHTML: (element) => element.getAttribute(dataAttrib),
            renderHTML: (attributes) => {
              const marker = attributes['marker']
              if (!marker) return {}

              return {
                [dataAttrib]: marker,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setMarker:
        (marker) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { marker })
        },
      setSelectionBeforeMarker:
        (marker) =>
        ({ editor, chain }) => {
          const markedNode = editor.$node('paragraph', { marker })
          if (!isDefined(markedNode) || !isDefined(markedNode.before)) return false

          return chain()
            .setNodeSelection(markedNode.before.from)
            .setTextSelection(markedNode.before.to)
            .run()
        },
    }
  },
})
