import { useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { insiConsultSerializer } from '../../../../misc/insi.utilities'
import { InsiIdentityCheckPayload, InsiPatient } from '../../../../model/Insi'
import { insiConsultRequestAtom } from '../../../../state/insi'

interface UseInsiConsultIdentityCheckVariables {
  patient: InsiIdentityCheckPayload | null
}

export const useInsiConsultIdentityCheck = () => {
  const [, setInsiConsultRequest] = useAtom(insiConsultRequestAtom)

  const mutation = useMutation<InsiPatient | null, Error, UseInsiConsultIdentityCheckVariables>({
    mutationFn: async ({ patient }) => {
      const promise = new Promise<InsiPatient | null>((resolve, reject) => {
        setInsiConsultRequest({
          search: patient ? insiConsultSerializer(patient) : null,
          onReceivePatient: (patient: InsiPatient | null) => {
            resolve(patient)
          },
          onError: reject,
        })
      })

      const response = await promise

      return response
    },
    onSettled: () => {
      setInsiConsultRequest(RESET)
    },
  })

  return mutation
}
