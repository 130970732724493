import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getDocumentLayouts } from '../../../data/documentLayouts'
import { documentLayoutsKeys } from './documentLayouts.keys'

interface GetAllDocumentLayoutsParams {
  enabled?: boolean
}

export const useGetAllDocumentLayouts = ({ enabled = true }: GetAllDocumentLayoutsParams = {}) => {
  const queryKey = documentLayoutsKeys.listAll

  const fetchDocumentLayouts = async (page: number, signal?: AbortSignal) => {
    const { data, ok } = await getDocumentLayouts(page, undefined, signal)
    if (!data || !ok) {
      throw new UserFriendlyError(
        'error',
        'Impossible de récupérer la liste des mises en page',
        "Une erreur technique s'est produite",
      )
    }
    return data
  }

  const queryReturn = useQuery({
    queryKey,

    queryFn: async ({ signal }) => {
      const firstPaginatedList = await fetchDocumentLayouts(1, signal)
      const paginatedLists = await Promise.all(
        Array.from({ length: firstPaginatedList.pageCount - 1 }).map((_, index) => {
          const page = index + 2
          return fetchDocumentLayouts(page, signal)
        }),
      )

      return [...firstPaginatedList.items, ...paginatedLists.flatMap((p) => p.items)]
    },
    enabled,
  })

  return { query: queryReturn, documentLayoutList: queryReturn.data || [] }
}
