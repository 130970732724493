import { connect } from 'react-redux'

import { RootState } from '../../../../../store'
import { MedicalEventHeader } from './MedicalEventHeader.component'
import { MedicalEventHeaderStoreProps } from './MedicalEventHeader.model'
import { inUseMedicalEventSelector } from '../../../../../store/ui/medicalEvents/index'
import { isOwnerOfSelectedMedicalEvent } from '../../../../../store/domain/medicalEvents'
import { enabledFeaturesPreferencesSelector } from '../../../../../store/domain/me'

const mapStateToProps = (state: RootState): MedicalEventHeaderStoreProps => ({
  medicalEvent: inUseMedicalEventSelector(state),
  isOwner: isOwnerOfSelectedMedicalEvent(state),
  userEnabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const withConnect = connect(mapStateToProps)(MedicalEventHeader)
export { withConnect as MedicalEventHeader }
