import { useCallback, useEffect } from 'react'
import {
  onInitPatient,
  onOpenPatient,
  onUpdatePatient,
} from '../../misc/Doctolib/doctolib.callbacks'
import { FeaturesType } from '@follow/cdk'
import { DoctolibPatient } from '../../misc/Doctolib/doctolib.model'
import { useCreateFollowPatient, useCreatePendingFollowPatientData } from '../queries/doctolib'
import { zipperStatusAtom } from '../../state/doctolib'
import { useAtom } from 'jotai'
import { DoctolibZipperStatus } from '../../model/Doctolib'
import { useUserEnabledFeature } from './user'
import { useUpdateFollowPatientWithoutPendingChanges } from '../queries/doctolib/useUpdateFollowPatientWithoutPendingChanges.query'

export const useDoctolibStatus = () => {
  const [zipperStatus, setZipperStatus] = useAtom(zipperStatusAtom)
  const { mutate: createFollowPatient } = useCreateFollowPatient()
  const { mutate: createPendingFollowPatientData } = useCreatePendingFollowPatientData()
  const { mutate: updatePatientWithoutPendingChanges } =
    useUpdateFollowPatientWithoutPendingChanges()
  const hasDoctolibPendingChangesFeatureFlag = useUserEnabledFeature(FeaturesType.doctolibUpdates)

  const createPatient = useCallback(
    (patient: DoctolibPatient) => {
      createFollowPatient({ patient })
    },
    [createFollowPatient],
  )

  const createPendingData = useCallback(
    (patient: DoctolibPatient) => {
      createPendingFollowPatientData({ patient })
    },
    [createPendingFollowPatientData],
  )

  const updatePatientWithoutDoctolibFeatureFlag = useCallback(
    (patient: DoctolibPatient) => {
      updatePatientWithoutPendingChanges({ patient })
    },
    [updatePatientWithoutPendingChanges],
  )

  const initializeDoctolibZipper = useCallback(() => {
    if (window.zipper && zipperStatus === DoctolibZipperStatus.NOT_LOADED) {
      setZipperStatus(DoctolibZipperStatus.LOADED)
      window.zipper('onOpenPatient', onOpenPatient)
      window.zipper('onInitPatient', (patient: DoctolibPatient) =>
        onInitPatient(patient, createPatient),
      )
      window.zipper('onUpdatePatient', (patient: DoctolibPatient) => {
        // retirer la ternary condition lors de la suppression du FF doctolibUpdates
        hasDoctolibPendingChangesFeatureFlag
          ? onUpdatePatient(patient, createPendingData)
          : updatePatientWithoutDoctolibFeatureFlag(patient)
      })
    }
  }, [
    zipperStatus,
    setZipperStatus,
    createPatient,
    hasDoctolibPendingChangesFeatureFlag,
    createPendingData,
    updatePatientWithoutDoctolibFeatureFlag,
  ])

  useEffect(() => {
    initializeDoctolibZipper()
  }, [initializeDoctolibZipper])

  return { initializeDoctolibZipper }
}
