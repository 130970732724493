import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { updatePatient } from '../../../data/patients'
import { patientsKeys } from './patients.keys'
import { PatientMutationPayload } from '../../../data/patients/mapper/patients.model'
import { PatientMutationParams } from '../../../model/Patient'
import { resourceHistoryKeys } from '../resourceHistory'

export interface UpdatePatientMutateVariable {
  id: number
  payload: PatientMutationPayload
  params?: PatientMutationParams
}

export const useUpdatePatient = ({
  optimistic = false,
  triggerSubscription = true,
}: { optimistic?: boolean; triggerSubscription?: boolean } = {}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: triggerSubscription ? patientsKeys.update : [],

    mutationFn: async ({ id, payload, params }: UpdatePatientMutateVariable) => {
      if (optimistic) {
        queryClient.setQueryData(patientsKeys.detail(id), payload)
      }

      const { data, ok } = await updatePatient(id, payload, params)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La modification du patient a échoué')
      }
      return data
    },

    onSuccess: (patient) => {
      queryClient.invalidateQueries({ queryKey: patientsKeys.lists })
      queryClient.invalidateQueries({
        queryKey: resourceHistoryKeys.list({ type: 'patient', id: patient.id }),
        type: 'all',
      })
      queryClient.setQueryData(patientsKeys.detail(patient.id), patient)
    },
  })
}
