import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react'
import { INSI_CONSULT_APP_URL } from '../../../environment/insiConsult'
import { insiConsultPatientMapper } from '../../../misc/insi.utilities'
import { InsiConnectInsResponse } from '../../../model/Insi'
import { insiConsultRequestAtom } from '../../../state/insi'
import { IconButton, Modal } from '../../shared'
import { InsiConsultIframeProps } from './InsiConsultIframe.model'
import { useAtom } from 'jotai'
import { useCreateInsiLogs } from '../../../hooks/queries/insi'
import styles from './InsiConsultIframe.module.scss'

export const InsiConsultIframe: FunctionComponent<InsiConsultIframeProps> = () => {
  const [insiConsultRequest] = useAtom(insiConsultRequestAtom)

  const insiConsultRef = useRef<HTMLIFrameElement>(null)
  const { mutate: logInsi } = useCreateInsiLogs()

  const insiConsultMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== INSI_CONSULT_APP_URL.origin) return

      logInsi(event.data)
      const response: InsiConnectInsResponse = event.data

      const isSuccess = response.CR?.codeCR === '00'
      const patient = insiConsultPatientMapper(response)

      if (isSuccess && patient) {
        insiConsultRequest?.onReceivePatient(patient)
      } else {
        insiConsultRequest?.onError(response?.error)
      }
    },
    [insiConsultRequest, logInsi],
  )

  useEffect(() => {
    window.addEventListener('message', insiConsultMessageHandler)
    return () => {
      window.removeEventListener('message', insiConsultMessageHandler)
    }
  }, [insiConsultMessageHandler])

  const requestSearch = useMemo(() => {
    if (!insiConsultRequest?.search) return ''

    const params = btoa(JSON.stringify({ search: insiConsultRequest.search }))
    return `#params=${params}`
  }, [insiConsultRequest])

  return (
    <Modal
      hidden={!insiConsultRequest}
      isUnmounting={false}
      onClickOutside={() => {
        insiConsultRequest?.onReceivePatient(null)
      }}
    >
      <div className={styles.container}>
        <div className={styles.backWrapper}>
          <IconButton
            onClick={() => insiConsultRequest?.onReceivePatient(null)}
            icon="back"
            theme="light"
            size="normal"
            testId="button-exit-insi-iframe"
          />
          <span className={styles.backReturnText}>Retour sur Follow</span>
        </div>
        <iframe
          className="w-full h-full"
          ref={insiConsultRef}
          title="insi-consult-iframe"
          src={`${INSI_CONSULT_APP_URL.toString()}${requestSearch}`}
        />
      </div>
    </Modal>
  )
}
