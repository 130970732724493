import React, { FunctionComponent } from 'react'

import { CheckboxState, CheckboxProps } from './Checkbox.model'
import BaseThemes from '../../../../design-system/themes/base.theme'
import classNames from 'classnames'
import { Icon } from '../../Icon/Icon.component'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  checked,
  disabled,
  theme = 'primary',
  label,
  title,
  onChange,
  testId,
  size = 'medium',
}) => {
  const selectedTheme = disabled ? BaseThemes[theme].disabled : BaseThemes[theme].active

  return (
    <div className="relative flex items-center justify-start w-fit" title={title}>
      <input
        type="checkbox"
        className={classNames(
          selectedTheme.colors.focusColor,
          `form-checkbox absolute ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } appearance-none`,
          { 'w-full h-full': size === 'medium' },
          { 'w-3/4 h-3/4': size === 'small' },
        )}
        checked={!!checked}
        disabled={disabled}
        onChange={onChange}
        data-test-id={addTestIdSuffix('input', testId)}
      />
      <div className={classNames('w-6 h-6', { 'flex items-center': size === 'small' })}>
        <div
          className={classNames(
            'relative rounded-md overflow-hidden flex items-center justify-center pointer-events-none',
            selectedTheme.borderColors.default,
            { 'border-2 bg-white': !checked, [selectedTheme.colors.backgroundColor]: !!checked },
            { 'w-full h-full': size === 'medium' },
            { 'w-3/4 h-3/4': size === 'small' },
          )}
        >
          {checked === CheckboxState.CHECKED && (
            <Icon icon="check" size="normal" color={getV3ColorCssVariable('shades', 'white')} />
          )}
          {checked === CheckboxState.INDETERMINATE && (
            <Icon icon="dash" size="normal" color={getV3ColorCssVariable('shades', 'white')} />
          )}
        </div>
      </div>
      {label && <span className="text-shades-2 ml-3 text-sm font-medium">{label}</span>}
    </div>
  )
}
