import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createSurvey } from '../../../data/survey'
import { UserFriendlyError } from '../../../model/Error'
import { Survey } from '../../../model/Survey'
import { surveyKeys } from './survey.keys'

interface UseCreateSurveyVariables {
  name: string
}

export const useCreateSurvey = () => {
  const queryClient = useQueryClient()

  return useMutation<Survey, Error, UseCreateSurveyVariables>({
    mutationFn: async ({ name }) => {
      const { data, ok } = await createSurvey(name)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "La création du questionnaire en salle d'attente a échoué",
        )
      }

      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: surveyKeys.list })
      queryClient.setQueryData(surveyKeys.detail(data.id), data)
    },
  })
}
