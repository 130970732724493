import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getSurveys } from '../../../data/survey'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { surveyKeys } from './survey.keys'

export const useGetInfiniteSurveys = () => {
  const queryKey = useMemo(() => surveyKeys.list, [])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getSurveys(pageParam, signal)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "Impossible de récupérer la liste des questionnaires en salle d'attente",
          `Une erreur technique s'est produite`,
        )
      }

      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
  })

  const queryClient = useQueryClient()
  const surveyList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, surveyList, paginationState, cancelPendingQuery }
}
