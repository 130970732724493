import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getPrescriptionTemplates } from '../../../data/prescriptionTemplates'
import { getNextPageParam } from '../../../misc/query.utilities'
import { Drug } from '../../../model/Drug'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { prescriptionTemplateKeys } from './prescriptionTemplates.keys'

interface UseGetInfinitePrescriptionTemplatesParams {
  drugs: Array<Drug>
  enabled?: boolean
}

export const useGetInfinitePrescriptionTemplates = ({
  drugs,
  enabled,
}: UseGetInfinitePrescriptionTemplatesParams) => {
  const drugIds = useMemo(() => drugs.map(({ id }) => id), [drugs])

  const queryKey = useMemo(() => prescriptionTemplateKeys.list(drugIds), [drugIds])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getPrescriptionTemplates(drugIds, pageParam, signal)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la récupération des modèles de prescriptions',
        )
      }

      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    enabled,
  })

  const prescriptionTemplateList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  return { query, prescriptionTemplateList, paginationState }
}
