import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { QuestionnaireTemplateMutationPayload } from '../../../data/questionnaireTemplates/mapper/questionnaireTemplate.model'
import { createQuestionnaireTemplate } from '../../../data/questionnaireTemplates'
import { questionnaireTemplatesKeys } from './questionnaireTemplates.keys'

export const useCreateQuestionnaireTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: QuestionnaireTemplateMutationPayload) => {
      const { data, ok } = await createQuestionnaireTemplate(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création du questionnaire a échoué')
      }
      return data
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: questionnaireTemplatesKeys.lists })
    },
  })
}
