import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getNextPageParam } from '../../../misc/query.utilities'
import { questionnaireTemplatesKeys } from './questionnaireTemplates.keys'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { useCallback, useMemo } from 'react'
import { singleItemPageLimit, startingPage } from '../../../constants'
import { getQuestionnaireTemplates } from '../../../data/questionnaireTemplates'

interface GetInfiniteQuestionnaireTemplatesParams {
  enabled?: boolean
  filters?: Filters
  limit?: number
}

export const useGetInfiniteQuestionnaireTemplates = ({
  enabled = true,
  filters,
  limit = singleItemPageLimit,
}: GetInfiniteQuestionnaireTemplatesParams = {}) => {
  const queryKey = useMemo(() => questionnaireTemplatesKeys.list(filters), [filters])

  const queryReturn = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getQuestionnaireTemplates(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des templates de questionnaire`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    enabled,
  })

  const queryClient = useQueryClient()

  const questionnaireTemplateList = useFlatPaginatedData(queryReturn.data)
  const paginationState = usePaginationState(queryReturn.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query: queryReturn, questionnaireTemplateList, paginationState, cancelPendingQuery }
}
