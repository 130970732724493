import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getAtcClasses } from '../../../data/drugs'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { drugsKeys } from './drugs.keys'

interface UseGetInfiniteAtcClassesParams {
  enabled?: boolean
  search?: string
}

export const useGetInfiniteAtcClasses = ({
  enabled = true,
  search,
}: UseGetInfiniteAtcClassesParams) => {
  const queryKey = useMemo(() => drugsKeys.atcClassList(search), [search])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getAtcClasses(pageParam, search, signal)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la récupération des classifications ATC',
        )
      }

      return data
    },
    initialPageParam: startingPage,
    enabled,
    getNextPageParam,
  })

  const atcClassList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  return { query, atcClassList, paginationState }
}
