import { useMemo } from 'react'
import { specialitiesKeys } from './specialities.keys'
import { useQuery } from '@tanstack/react-query'
import { Speciality } from '../../../model/Professions'
import { getSpecialities } from '../../../data/specialities'

export const useGetSpecialities = () => {
  const queryKey = useMemo(() => specialitiesKeys.list(), [])

  const query = useQuery<Array<Speciality>>({
    queryKey,
    queryFn: async () => {
      const { data, ok } = await getSpecialities()
      if (!data || !ok) {
        throw new Error('Impossible de récupérer la liste des spécialités')
      }
      return data
    },
    gcTime: Infinity,
    staleTime: Infinity,
  })

  return { query }
}
