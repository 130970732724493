import getApi from '../../api'
import { SendMailPayload } from '../../model/Mail'

export const sendMail = ({
  recipientEmails,
  subject,
  content,
  files,
  replyToEmail,
  askReadAcknowledgement,
  documentIds,
  patientId,
}: SendMailPayload) => {
  const data = new FormData()
  files?.forEach((file) => {
    data.append('files[]', file)
  })

  recipientEmails.forEach((recipient) => {
    data.append('recipientEmails[]', recipient)
  })

  if (patientId) {
    data.append('patientId', patientId.toString())
  }
  data.append('content', content)
  data.append('subject', subject)
  if (replyToEmail) {
    data.append('replyToEmail', replyToEmail)
  }
  data.append('askReadAcknowledgement', `${askReadAcknowledgement}`)

  if (documentIds && documentIds.length) {
    documentIds.forEach((documentId) => {
      data.append(`documentIds[]`, `${documentId}`)
    })
  }

  return getApi().post(`/mss/messages`, data, {
    headers: {},
  })
}
