import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getPatientManualPrescriptions } from '../../../data/patientManualPrescriptions'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { patientManualPrescriptionsKeys } from './patientManualPrescriptions.keys'

interface UseGetInfinitePatientManualPrescriptionsParams {
  patientId: number
}

export const useGetInfinitePatientManualPrescriptions = ({
  patientId,
}: UseGetInfinitePatientManualPrescriptionsParams) => {
  const queryKey = useMemo(() => patientManualPrescriptionsKeys.list(patientId), [patientId])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getPatientManualPrescriptions(patientId, pageParam, signal)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la récupération des prescriptions manuelles du patient',
        )
      }

      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
  })

  const manualPrescriptionsList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  return { query, manualPrescriptionsList, paginationState }
}
