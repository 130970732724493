import React, { FunctionComponent, useContext, useMemo } from 'react'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { hasAdminRole } from '../../../misc/roles.utilities'
import { LinkGuard } from '../../navigation/LinkGuard/LinkGuard'
import styles from './MemberUserList.module.scss'
import { MemberUserListItem } from './MemberUsersListItem'

type MemberUserListProps = {
  users: Array<{
    firstName: string
    familyName: string
    professionTitle: string | undefined
    isContact: boolean
    id: string
  }>
}

export const MemberUserList: FunctionComponent<MemberUserListProps> = ({ users }) => {
  const { loggedUser } = useContext(ConnectedUserContext)
  const isAdmin = loggedUser && hasAdminRole(loggedUser.roles)

  const contactsAndMembers = useMemo(() => users.sort((a, _) => (a.isContact ? -1 : 1)), [users])

  return (
    <div className={styles.memberUsers}>
      {contactsAndMembers.map(({ firstName, familyName, professionTitle, id, isContact }) => (
        <LinkGuard key={id} condition={!!isAdmin} url={`/admin/users/${id}`}>
          <MemberUserListItem
            firstName={firstName}
            familyName={familyName}
            professionTitle={professionTitle}
            showStar={isContact}
          />
        </LinkGuard>
      ))}
    </div>
  )
}
