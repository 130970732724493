import { useFormikContext } from 'formik'
import { FC, useCallback, useContext, useMemo, useState } from 'react'
import {
  Icon,
  FormikSelectInput,
  RoundedButton,
  TooltipWrapper,
  FormikSwitch,
  FormBlock,
  IconButton,
} from '../../../shared'
import { getV3ColorCssVariable, ColorsV3StatusName } from '../../../../design-system/colors_v3'
import { IdentityCheckStatus, IdentityStateLabel, TrustedSource } from '../../../../model/Patient'
import { PatientFormFields } from '../PatientForm.model'
import { IdentityStatusBlockProps, SHOWN_DOCUMENT_OPTIONS } from './IdentityStatusBlock.model'
import { IdentityStatusCondition } from './IdentityStatusCondition'
import classNames from 'classnames'
import {
  formatDateWithDaytime,
  formatShortFrStringToApiFormat,
} from '../../../../misc/date.utilities'
import { getPatientIdentityStatusLabel } from '../../../../misc/patient.utilities'
import { InformationAlert } from '../../../user'
import { InsiPatientFirstnameChangeValidationModal } from './InsiPatientFirstnameChangeValidationModal'
import { deserializeInsiPatient } from './IdentityStatusBlock.utils'
import { useDisableInsiFields } from '../useDisableInsiFields.hook'
import { patientToInsiRestPatientPayload } from '../../../../misc/insi.utilities'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'
import { isDoctor } from '../../../../misc/user.utilities'
import { useCreateTask } from '../../../../hooks/queries/task'
import { TaskCategory } from '../../../../model/Task'
import { useUserEnabledFeature } from '../../../../hooks/utils/user'
import { FeaturesType } from '@follow/cdk'
import { useInsiIdentityCheck } from '../../../../hooks/queries/insi'
import { InsiIdentityCheckPayload } from '../../../../model/Insi'

const getIdentityCheckLabel = (status: IdentityCheckStatus, checkedAt: Date | null) => {
  const formatedDate = checkedAt ? formatDateWithDaytime(checkedAt) : ''
  switch (status) {
    case 'UNCHECKED':
      return 'Non réalisée'
    case 'VALIDATED':
      return `Succès le ${formatedDate}`
    case 'FAILED':
      return `Échec le ${formatedDate}`
  }
}

export const IdentityStatusBlock: FC<IdentityStatusBlockProps> = ({
  impersonatePermissions,
  patient,
}) => {
  const { values, errors, setFieldValue, setValues } = useFormikContext<PatientFormFields>()
  const [firstnameModalOpen, setFirstnameModalOpen] = useState(false)
  const [pendingPatientUpdate, setPendingPatientUpdate] = useState<PatientFormFields>()
  const { currentUser } = useContext(ConnectedUserContext)

  const insiService = useUserEnabledFeature(FeaturesType.insiValidation)

  const { mutate: createTask } = useCreateTask()
  const { mutate: identityCheck } = useInsiIdentityCheck()

  const disableInsiFields = useDisableInsiFields()

  const identityState: { label: string; color: ColorsV3StatusName } = useMemo(() => {
    const label = getPatientIdentityStatusLabel(
      values.identityStatus.showTrustedSource,
      values.identityStatus.suspicious || values.identityStatus.fictitious,
      values.identityStatus.insiStatus.checked === 'VALIDATED',
    )
    if (label === IdentityStateLabel.QUALIFIED) return { label, color: 'valid' }
    else if (label === IdentityStateLabel.VALIDATED) return { label, color: 'normal' }
    else if (label === IdentityStateLabel.INSI_CHECKED) return { label, color: 'info' }
    else return { label, color: 'warning' }
  }, [
    values.identityStatus.suspicious,
    values.identityStatus.fictitious,
    values.identityStatus.showTrustedSource,
    values.identityStatus.insiStatus,
  ])

  const shownDocumentOptions = useMemo(() => {
    return SHOWN_DOCUMENT_OPTIONS.map((document) => {
      if (
        isDoctor(currentUser) &&
        document.value &&
        currentUser?.preferences.trustedSourceAccepted.includes(document.value)
      ) {
        return { ...document, label: `${document.label} (Haut degré de confiance)` }
      }
      return document
    })
  }, [currentUser])

  const shownDocumentValue = useMemo(() => {
    const value = SHOWN_DOCUMENT_OPTIONS.find(
      (shownDocument) => shownDocument.value === values.identityStatus.trustedSource,
    )
    return value ?? shownDocumentOptions[0]
  }, [values.identityStatus.trustedSource, shownDocumentOptions])

  const handleResetINSIState = useCallback(
    (value: boolean, patient: PatientFormFields) => {
      if (value) {
        patient.identityStatus.insiStatus.checked = 'UNCHECKED'
        patient.identityStatus.insiStatus.checkedAt = null
        setFieldValue('inseeNumber', '')
      }
    },
    [setFieldValue],
  )

  const applyPendingPatientUpdate = useCallback(
    (pendingUpdate: PatientFormFields, overrideFirstname?: boolean) => {
      const { birthFirstName, ...patientUpdate } = pendingUpdate
      setValues(
        {
          ...patientUpdate,
          birthFirstName: overrideFirstname ? birthFirstName : values.birthFirstName,
        },
        true,
      )
      setPendingPatientUpdate(undefined)
      setFirstnameModalOpen(false)
    },
    [setValues, values],
  )

  const handleIdentityCheck = useCallback(
    (payload: InsiIdentityCheckPayload | null) => {
      identityCheck(
        { patient: payload },
        {
          onSuccess: (insiPatient) => {
            if (!insiPatient) return

            const deserializedPatient = deserializeInsiPatient(insiPatient, values)
            if (
              insiPatient.birthFirstName.toLowerCase() !== values.birthFirstName.toLowerCase() &&
              !disableInsiFields
            ) {
              setPendingPatientUpdate(deserializedPatient)
              setFirstnameModalOpen(true)
            } else {
              applyPendingPatientUpdate(deserializedPatient)
            }
          },
          onError: () => {
            setFieldValue('identityStatus.insiStatus', {
              checked: 'FAILED',
              checkedAt: new Date(),
            })

            createTask({
              title: patient?.id
                ? "Échec de la validation de l'identité via l'INSi"
                : `Échec de la validation de l'identité via l'INSi pour le patient ${values.birthFirstName} ${values.birthLastName}`,
              autoCreated: true,
              category: TaskCategory.INFORMATION,
              patientId: patient?.id ?? undefined,
              isNotify: true,
            })
            setFieldValue('inseeNumber', undefined)
          },
        },
      )
    },
    [
      patient?.id,
      values,
      disableInsiFields,
      applyPendingPatientUpdate,
      createTask,
      identityCheck,
      setFieldValue,
    ],
  )

  const handleCheckPatientIdentity = useCallback(() => {
    const serializedPatient = patientToInsiRestPatientPayload({
      ...values,
      birthDate: formatShortFrStringToApiFormat(values.birthDate),
    })

    if (serializedPatient) {
      handleIdentityCheck(serializedPatient)
    }
  }, [handleIdentityCheck, values])

  const handleCheckVitale = useCallback(() => {
    handleIdentityCheck(null)
  }, [handleIdentityCheck])

  const disableAction =
    !!errors.birthFirstName ||
    !!errors.birthLastName ||
    !!errors.sex ||
    !!errors.birthDate ||
    values.identityStatus.suspicious ||
    values.identityStatus.fictitious ||
    (impersonatePermissions !== null && !impersonatePermissions?.permissions.insiServiceAccess)

  const checkStatus = values.identityStatus.insiStatus.checked

  const isInsiInAppActive = insiService === 'insiValidationInApp'

  return (
    <FormBlock
      label={
        <span className="flex items-center space-x-2">
          <span>Statut de l'identité :</span>
          <Icon
            icon="bullet"
            color={getV3ColorCssVariable('status', identityState.color)}
            size="nano"
          />
          <span>{identityState.label}</span>
        </span>
      }
      icon="fileChecked"
    >
      <div className="space-y-4 font-medium text-base text-shades-3">
        {values.identityStatus.trustedSource !== TrustedSource.TRUSTED_CONTACT ? (
          <>
            <FormikSelectInput<TrustedSource | null>
              label="Justificatif présenté par le patient"
              fieldName="identityStatus.trustedSource"
              options={shownDocumentOptions}
              value={shownDocumentValue}
              onChange={() => {
                setFieldValue('identityStatus.showTrustedSource', false)
              }}
            />
            <FormikSwitch
              fieldName="identityStatus.showTrustedSource"
              name="Valider l’identité"
              borderBottom
              disabled={
                isDoctor(currentUser) &&
                !currentUser.preferences.trustedSourceAccepted.find(
                  (value) => value === shownDocumentValue.value,
                )
              }
            />
          </>
        ) : (
          <InformationAlert>
            L'identité du patient est certifée car elle provient d'un contact de confiance
          </InformationAlert>
        )}
        <TooltipWrapper content="À activer si vous estimez que l'identité du patient est douteuse">
          <FormikSwitch
            fieldName="identityStatus.suspicious"
            name={`Identité douteuse`}
            borderBottom
            onChange={(value) => handleResetINSIState(value, values)}
          />
        </TooltipWrapper>
        <TooltipWrapper content="À activer si vous estimez que l'identité du patient est frauduleuse">
          <FormikSwitch
            fieldName="identityStatus.fictitious"
            name={`Identité fictive`}
            borderBottom
            onChange={(value) => handleResetINSIState(value, values)}
          />
        </TooltipWrapper>
      </div>
      {insiService && (
        <div className="bg-shades-8 rounded-md">
          <div className="p-4 flex justify-between items-center border-b border-shades-6">
            <div className="flex flex-col font-medium">
              <span className="text-base text-shades-2 mb-1.5">Validation INSi</span>
              <span
                className={classNames('text-xs', {
                  'text-shades-4': checkStatus === 'UNCHECKED',
                  'text-status-valid': checkStatus === 'VALIDATED',
                  'text-status-invalid': checkStatus === 'FAILED',
                })}
              >
                {getIdentityCheckLabel(checkStatus, values.identityStatus.insiStatus.checkedAt)}
              </span>
            </div>
            <TooltipWrapper
              display={
                impersonatePermissions !== null &&
                !impersonatePermissions?.permissions.insiServiceAccess
              }
              content="Le médecin ne vous permet pas d'accéder au téléservice INSI en tant qu'assistant.e"
            >
              <div className="flex">
                <RoundedButton
                  disabled={disableAction}
                  label="Vérifier"
                  onClick={handleCheckPatientIdentity}
                  appearance={isInsiInAppActive ? 'left' : 'standalone'}
                />
                {isInsiInAppActive && (
                  <IconButton
                    disabled={disableAction}
                    icon="caret"
                    rotate={180}
                    appearance="right"
                    theme="primary"
                    showOptionsCaret={false}
                    options={[
                      {
                        label: "Vérification de l'identité patient",
                        onClick: handleCheckPatientIdentity,
                      },
                      {
                        label: 'Vérification par carte vitale',
                        onClick: handleCheckVitale,
                      },
                    ]}
                  />
                )}
              </div>
            </TooltipWrapper>
          </div>
          <div className="flex flex-col p-4 text-shades-3 font-medium text-xs">
            <span className="pb-4 pr-2 border-b border-shades-6">
              Voulez-vous faire vérifier les informations du patient auprès de l'INSi ?
            </span>
            <span className="mt-4 mb-3">Champs nécessaires pour la vérification :</span>
            <div className="space-y-2">
              <IdentityStatusCondition label="Nom de naissance" valid={!errors.birthLastName} />
              <IdentityStatusCondition label="Prénom de naissance" valid={!errors.birthFirstName} />
              <IdentityStatusCondition label="Sexe" valid={!errors.sex} />
              <IdentityStatusCondition label="Date de naissance" valid={!errors.birthDate} />
              <IdentityStatusCondition
                label="Identité non douteuse et non fictive"
                valid={!values.identityStatus.suspicious && !values.identityStatus.fictitious}
              />
            </div>
          </div>
        </div>
      )}
      <InsiPatientFirstnameChangeValidationModal
        display={firstnameModalOpen}
        userInputFirstname={values.birthFirstName}
        insiResponseFirstname={pendingPatientUpdate?.birthFirstName ?? ''}
        onSubmit={() =>
          pendingPatientUpdate && applyPendingPatientUpdate(pendingPatientUpdate, true)
        }
        onClose={() =>
          pendingPatientUpdate && applyPendingPatientUpdate(pendingPatientUpdate, false)
        }
      />
    </FormBlock>
  )
}
