import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionnaireTemplatesKeys } from '../questionnaireTemplates/questionnaireTemplates.keys'
import { questionTemplatesKeys } from './questionTemplate.keys'
import { QuestionTemplateMutationPayload } from '../../../data/questionTemplates/mapper/questionTemplates.model'
import { createQuestionTemplate } from '../../../data/questionTemplates'

export const useCreateQuestionTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: QuestionTemplateMutationPayload) => {
      const { data, ok } = await createQuestionTemplate(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création de la question a échoué')
      }
      return data
    },

    onSuccess: (questionTemplate) => {
      queryClient.invalidateQueries({ queryKey: questionTemplatesKeys.lists })
      questionTemplate.questionnaires.map(({ id }) =>
        queryClient.invalidateQueries({ queryKey: questionnaireTemplatesKeys.detail(id) }),
      )
    },
  })
}
