const all = ['messageTemplates']
const list = [...all, 'list']
const create = [...all, 'create']
const defaultMessage = [...all, 'default']
const detail = (id: number) => [...all, id]

export const messageTemplatesKeys = {
  all,
  list,
  create,
  detail,
  defaultMessage,
}
