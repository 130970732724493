import getApi from '../../api'
import { searchLimit } from '../../constants'
import { City } from '../../model/Contact'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'

export const getCities = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = searchLimit,
) =>
  getApi().get<PaginatedList<City>>(
    '/cities',
    {
      page,
      limit,
      ...filters,
    },
    { signal },
  )
