import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { questionnaireTemplatesKeys } from './questionnaireTemplates.keys'
import { getQuestionnaireTemplate } from '../../../data/questionnaireTemplates'

interface GetQuestionnaireTemplateParams {
  id: number
  enabled?: boolean
}

export const useGetQuestionnaireTemplate = ({
  id,
  enabled = true,
}: GetQuestionnaireTemplateParams) => {
  const queryKey = useMemo(() => questionnaireTemplatesKeys.detail(id), [id])

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await getQuestionnaireTemplate(id)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le questionnaire`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
