/* eslint-disable jsx-a11y/alt-text */
import React, { FC, useContext, useEffect, useState } from 'react'
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import { PARENT_NODE_ID, IMAGE_NODE_ID } from './ImageResize.model'
import { ResizeHandle } from './ResizeHandle'
import styles from './ImageResize.module.scss'
import classNames from 'classnames/bind'
import { EditorConfigContext } from '@/context'
import { PluginNames } from '@/model'

const cx = classNames.bind(styles)

const BASE64_URL_PATTERN = /data:.*;base64/

export const ImageResizeComponent: FC<NodeViewProps> = ({ editor, node }) => {
  const { float, textAlign, src, ...imgAttrs } = node.attrs

  const { authenticatedImageRequest } = useContext(EditorConfigContext)
  const disabled = !editor.isEditable

  const [base64, setBase64] = useState<string | null>(null)
  const [loaded, setLoaded] = useState(false)

  const isActive = editor.isActive(PluginNames.ImageResize)

  useEffect(() => {
    if (BASE64_URL_PATTERN.test(src)) {
      setBase64(src)
    } else {
      const fetcher = authenticatedImageRequest
      fetcher(src)
        .then(setBase64)
        .catch((error) => {
          console.error(error, JSON.stringify(error))
        })
        .finally(() => {
          setLoaded(true)
        })
    }
  }, [authenticatedImageRequest, src])

  const setImageWidth = (width: number) => {
    const selection = editor.state.selection.from
    editor
      .chain()
      .focus()
      .updateAttributes(PluginNames.ImageResize, { width })
      .setNodeSelection(selection)
      .run()
  }

  return (
    <NodeViewWrapper className={cx('image_line', `float_${float}`, `align_${textAlign}`)}>
      {loaded && (
        <>
          {base64 ? (
            <div className={styles['image_resizer']} id={PARENT_NODE_ID}>
              <img
                {...imgAttrs}
                src={base64}
                className={cx('image', {
                  focused: isActive,
                })}
                id={IMAGE_NODE_ID}
                draggable
                data-drag-handle=""
              />
              {!disabled && (
                <>
                  <ResizeHandle orientation="right" onDragEnd={setImageWidth} />
                  <ResizeHandle orientation="left" onDragEnd={setImageWidth} />
                </>
              )}
            </div>
          ) : (
            <span
              title="Erreur lors du chargement de l'image"
              className={styles['error']}
              draggable
              data-drag-handle=""
              data-src={src}
            >
              !
            </span>
          )}
        </>
      )}
    </NodeViewWrapper>
  )
}
