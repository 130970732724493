import { Formik, FormikProps } from 'formik'
import { FunctionComponent, useState } from 'react'
import { useAtomValue } from 'jotai'
import {
  AddressFields,
  RoundedButton,
  GridLayout,
  FormBlock,
  FormFooter,
  MedicalForm,
  FormikPhoneInput,
  FormikSelectInput,
  FormikTextInput,
  FieldWrapper,
  FormikSwitch,
} from '../../shared'
import { Patient } from '../../../model/Patient'
import { patientFormToPatientMapper } from './PatientForm.mapper'
import { EMPTY_DEFAULT_VALUES, PatientFormFields, PatientFormProps } from './PatientForm.model'
import { getValidationSchema } from './PatientForm.validator'
import { PatientContactsFormBlock } from './PatientContactsFormBlock'
import { getEditorEmptyJsonValue, JSONToValue, SimpleEditor, valueToJSON } from '@follow/farte'
import { NOTES_TOOLBAR_CONFIG } from '../../../model/Notes'
import { IdentityStatusBlock } from './IdentityStatusBlock'
import { isDefined } from '../../../misc/functions.utilities'
import { getInitialValues } from './PatientForm.utils'
import { PatientIdentityBlock } from './PatientIdentityBlock'
import { HealthInformationBlock } from './HealthInformationBlock'
import { useCreatePatient, useUpdatePatient } from '../../../hooks/queries/patients'
import { useNavigate } from 'react-router-dom'
import { DoctolibPatientInfoUpdateModal } from '../PatientInfos/PatientGeneralInfos/DoctolibPatientInfoUpdateModal'
import { useGetPendingFollowPatient } from '../../../hooks/queries/doctolib'
import { useDoctolibPendingChangesActions } from '../../../hooks/utils/doctolib'
import styles from './PatientForm.module.scss'
import { useGetAssigningAuthorities } from '../../../hooks/queries/assigningAuthorities'
import { isDoctolibLoaded } from '../../../state/doctolib'

export const PatientForm: FunctionComponent<PatientFormProps> = ({
  patient,
  impersonatePermissions,
  enabledFeatures,
}) => {
  const { mutate: createPatient } = useCreatePatient()
  const { mutate: updatePatient } = useUpdatePatient()
  const { handleClose, handleSubmit: handleSubmitDoctolibPendingChanges } =
    useDoctolibPendingChangesActions(patient)
  const navigate = useNavigate()
  const isZipperLoaded = useAtomValue(isDoctolibLoaded)

  const {
    query: { data: pendingDoctolibPatientData = [] },
  } = useGetPendingFollowPatient({
    patientId: patient?.id,
    enabled: !!patient?.isEditable && isZipperLoaded,
  })
  const {
    query: { data: assigningAuthorities },
  } = useGetAssigningAuthorities()

  const [localNotesValue, setLocaleNotesValue] = useState(
    JSONToValue((patient && patient.note) || getEditorEmptyJsonValue()),
  )
  const initialValues = !!patient ? getInitialValues(patient) : EMPTY_DEFAULT_VALUES

  const hasAccessToInsOid = impersonatePermissions?.permissions.insOidAccess ?? true
  const isInsiFeatureEnabled: boolean = !!enabledFeatures?.insiValidation

  const handleSubmit = (values: PatientFormFields) => {
    const updatedPatient: Partial<Patient> = patientFormToPatientMapper(values, hasAccessToInsOid)

    const defaultAuthority = assigningAuthorities?.at(0)
    if (!isDefined(updatedPatient.assigningAuthority) && isDefined(defaultAuthority)) {
      updatedPatient.assigningAuthority = {
        label: defaultAuthority.label,
        oid: defaultAuthority.oid,
      }
    }

    if (patient && patient.id) {
      updatePatient(
        {
          id: patient.id,
          payload: { ...updatedPatient, note: valueToJSON(localNotesValue) },
        },
        { onSuccess: () => navigate(-1) },
      )
    } else {
      createPatient(
        { patient: { ...updatedPatient, note: valueToJSON(localNotesValue) } },
        { onSuccess: (patient) => navigate(`/patients/${patient.id}`, { replace: true }) },
      )
    }
  }

  const displayCustomFields = initialValues.customFields.length > 0

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(hasAccessToInsOid, isInsiFeatureEnabled)}
        validateOnMount={true}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialTouched={{
          title: patient !== null,
        }}
      >
        {(props: FormikProps<PatientFormFields>) => (
          <MedicalForm onSubmit={props.handleSubmit}>
            <GridLayout
              columns={2}
              rowsTemplate="auto"
              alignColumns="start"
              gap="medium"
              padding="medium"
            >
              <PatientIdentityBlock patient={patient} />
              <FormBlock icon="infoCircle" label="Coordonnées">
                <AddressFields type="patient" colorPreset="light" />
                <div className="flex flex-row justify-between">
                  <div className={styles.telephone1}>
                    <FormikPhoneInput
                      fieldName="telephone1"
                      label="Téléphone mobile"
                      autocomplete={false}
                      colorPreset="light"
                    />
                  </div>
                  <div>
                    <FormikPhoneInput
                      fieldName="telephone2"
                      label="Téléphone fixe"
                      autocomplete={false}
                      colorPreset="light"
                    />
                  </div>
                </div>
                <FormikTextInput
                  fieldName="email"
                  label="E-mail"
                  autocomplete={false}
                  colorPreset="light"
                />
                <div className="font-medium text-base text-shades-3">
                  <FormikSwitch
                    disabled={!props.values.inseeNumber}
                    theme="primary"
                    label="Non consentement"
                    infos={
                      !props.values.inseeNumber
                        ? 'Paramètre non modifiable : matricule INS requis'
                        : undefined
                    }
                    borderBottom
                    fieldName="disallowMSSMessaging"
                    name="Le patient refuse d'être contacté via la messagerie de santé sécurisée"
                  />
                </div>
              </FormBlock>

              <HealthInformationBlock
                assigningAuthorities={assigningAuthorities ?? []}
                hasAccessToInsOid={hasAccessToInsOid}
                isInsiFeatureEnabled={isInsiFeatureEnabled}
              />

              <IdentityStatusBlock
                impersonatePermissions={impersonatePermissions}
                patient={patient}
              />

              <FormBlock icon="clipboard" label="Notes">
                <FieldWrapper>
                  <SimpleEditor
                    value={localNotesValue}
                    onValueChange={setLocaleNotesValue}
                    toolbarConfig={NOTES_TOOLBAR_CONFIG}
                  />
                </FieldWrapper>
              </FormBlock>
              <FormBlock icon="at" label="Correspondants">
                <PatientContactsFormBlock
                  patientAddress={props.values.address}
                  patientId={patient?.id}
                  colorPreset="light"
                />
              </FormBlock>
              {displayCustomFields && (
                <FormBlock icon="documentText" label="Personnalisé">
                  {initialValues.customFields.map((field, index) => {
                    if (field.type === 'TYPE_TEXT') {
                      return (
                        <FormikTextInput
                          key={index}
                          fieldName={`customFields[${index}].value`}
                          label={field.label}
                          colorPreset="light"
                        />
                      )
                    }
                    if (field.type === 'TYPE_OPTIONS') {
                      return (
                        <FormikSelectInput
                          key={index}
                          fieldName={`customFields[${index}].value`}
                          label={field.label}
                          options={[]}
                          colorPreset="light"
                          value={{ value: '', label: '' }}
                        />
                      )
                    }
                    return null
                  })}
                </FormBlock>
              )}
            </GridLayout>
            <FormFooter>
              <div className={styles.submitButtonBox}>
                <RoundedButton
                  type="submit"
                  label={patient && patient.id ? 'Modifier le patient' : 'Créer le patient'}
                  theme="primary"
                  disabled={!props.isValid}
                  testId="button-save-patient"
                />
              </div>
            </FormFooter>
          </MedicalForm>
        )}
      </Formik>
      <DoctolibPatientInfoUpdateModal
        display={!!pendingDoctolibPatientData.length}
        onSubmit={handleSubmitDoctolibPendingChanges}
        onClose={handleClose}
        pendingPatientData={pendingDoctolibPatientData}
      />
    </>
  )
}
