import { combineReducers } from 'redux'
import { adminUsersReducer as adminUsers } from './adminUsers/adminUsers.reducer'
import { bottomPanelReducer as bottomPanel } from './bottomPanel/bottomPanel.reducer'
import { bottomPanelDocumentHistoryReducer as bottomPanelDocumentHistory } from './bottomPanelDocumentHistory/bottomPanelDocumentHistory.reducer'
import { bottomPanelDocumentTemplatesReducer as bottomPanelDocumentTemplates } from './bottomPanelDocumentTemplates/bottomPanelDocumentTemplates.reducer'
import { bottomPanelQuestionsReducer as bottomPanelQuestions } from './bottomPanelQuestions/bottomPanelQuestions.reducer'
import { bottomPanelBaseVariableReducer as bottomPanelBaseVariable } from './bottomPanelBaseVariables/bottomPanelBaseVariables.reducer'
import { managerDocumentTemplatesReducer as managerDocumentTemplates } from './managerDocumentTemplates/managerDocumentTemplates.reducer'
import { medicalEventReducer as medicalEvent } from './medicalEvents/medicalEvents.reducer'
import { UiState } from './ui.model'
import { documentAlertsReducer as documentAlerts } from './documentAlerts/documentAlerts.reducer'

export default combineReducers<UiState>({
  adminUsers,
  bottomPanel,
  bottomPanelDocumentHistory,
  bottomPanelDocumentTemplates,
  bottomPanelQuestions,
  bottomPanelBaseVariable,
  documentAlerts,
  managerDocumentTemplates,
  medicalEvent,
})
