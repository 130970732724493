import { useCallback } from 'react'
import { CustomFile } from '../../../model/File'
import { UserFriendlyError } from '../../../model/Error'
import { fetchFile } from '../../../store/cache/files/api'
import { base64StringToBlob } from 'blob-util'
import { requestPrintByBlobUrl } from '../../../misc/print.utilities'
import { pdfDataToUrl } from '../../../components/file/FileViewer/PdfViewerLegacy/pdf.utils'
import { queryClient } from '../../../App'
import { fileKeys } from './files.keys'

export type DirectPrintableFileVariable = {
  fileId: number
}

export const useDirectPrintFile = () => {
  const print = useCallback((file: CustomFile) => {
    if (!file.base64 || file.extension !== 'pdf') {
      throw new Error(`Base64 content missing or extension is not "PDF" on file ${file.id}`)
    }
    const splitted = file.base64.split(',')
    const blobUrl = pdfDataToUrl(base64StringToBlob(splitted[1]))
    requestPrintByBlobUrl(blobUrl)
  }, [])

  return async ({ fileId }: DirectPrintableFileVariable) => {
    const data = await queryClient.fetchQuery<CustomFile>({
      queryKey: fileKeys.detail(fileId),
      queryFn: async () => {
        const { data, ok } = await fetchFile(fileId)

        if (!data || !ok) {
          throw new UserFriendlyError(
            'error',
            `Impossible de récupérer le fichier ${fileId}`,
            `Une erreur technique s'est produite`,
          )
        }
        return data
      },
    })

    return print(data)
  }
}
