import getApi from '../../api'
import { MessageTemplate } from '../../model/MessageTemplate'
import { MessageTemplateMutationPayload } from './mapper/messageTemplates.model'

export const updateMessageTemplate = (
  id: number,
  messageTemplate: MessageTemplateMutationPayload,
  params?: Record<string, any>,
) =>
  getApi().put<MessageTemplate>(`/message_templates/${id}`, messageTemplate, {
    params,
  })
