import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteTeam } from '../../../../data/admin'
import { checkIfPagesContainItem } from '../../../../misc/query.utilities'
import { UserFriendlyError } from '../../../../model/Error'
import { Team } from '../../../../model/Team'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { teamKeys } from './teams.keys'

export const useDeleteTeam = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation({
    mutationFn: async ({ id }: Team) => {
      const { ok } = await deleteTeam(id)
      if (!ok) {
        throw new UserFriendlyError('error', "La suppression de l'équipe a échoué")
      }
      return null
    },

    onSuccess: (_data, team) => {
      valid(`L'équipe "${team.name}" a été supprimée`)
      queryClient.refetchQueries({
        queryKey: teamKeys.all,
        predicate: (query) => checkIfPagesContainItem<Team>(query.state.data, team.id),
      })
    },
  })

  return queryReturn
}
