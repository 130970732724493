import { useQuery } from '@tanstack/react-query'
import { codedInformationsKeys } from './codedInformations.keys'
import { UserFriendlyError } from '../../../model/Error'
import { searchCodedInformationsGroup } from '../../../data/patientCodedInformations'

interface GetSearchInformationsParams {
  groupId: string
  enabled?: boolean
}

export const useSearchCodedInformationsGroup = ({
  groupId,
  enabled = true,
}: GetSearchInformationsParams) => {
  const queryKey = codedInformationsKeys.searchByGroup(groupId)

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await searchCodedInformationsGroup(groupId)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer les données de recherche sur les allergies et antécédents`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
