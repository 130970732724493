import { atomWithReset } from 'jotai/utils'
import { InsiConnectInsRequest, InsiPatient } from '../../model/Insi'

interface InsiConsultRequestState {
  search: InsiConnectInsRequest | null
  onReceivePatient: (patient: InsiPatient | null) => void
  onError: (error?: string) => void
}

export const insiConsultRequestAtom = atomWithReset<InsiConsultRequestState | null>(null)
