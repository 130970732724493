export interface HookQueryOptions {
  enabled?: boolean
  infinityStale?: boolean
}

export interface QueryOptions {
  enabled?: boolean
  staleTime?: number
}

export enum StaleTimes {
  OneHour = 60 * 60 * 1000,
  OneDay = 24 * OneHour,
}
