import React, { FunctionComponent } from 'react'

import { ColorsV2 } from '../../../design-system/colors_v2'
import { Icon } from '../../../components/shared'

import styles from './ToolbarButton.module.scss'

export const AddVariableButton: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
  <div role="button" className={styles.button} onClick={onClick}>
    <Icon icon="add" size="nano" color={ColorsV2.backgroundDark3} />
    <div className={styles.label}>Variable</div>
  </div>
)
