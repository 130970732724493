import { Formik, FormikProps } from 'formik'
import React, { FunctionComponent } from 'react'
import { Button, FormikTextInput, Radio, RadioGroup } from '../../../shared'
import { MailLoginFormProps, MailOTPMethod } from './MailLoginForm.model'
import { MailLoginValidationSchema } from './MailLoginForm.validator'
import { isDoctor } from '../../../../misc/user.utilities'
import { MailWebServiceLoginFormFields } from '../../../../model/Mail'

export const MailLoginForm: FunctionComponent<MailLoginFormProps> = ({ onSubmit, currentUser }) => {
  return (
    <Formik
      initialValues={{
        rpps: isDoctor(currentUser) && currentUser.rppsNumber ? currentUser.rppsNumber : '',
        password: '',
        otpMethod: MailOTPMethod.SMS,
      }}
      validationSchema={MailLoginValidationSchema}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
    >
      {(props: FormikProps<MailWebServiceLoginFormFields>) => (
        <div
          onKeyPress={(e) => {
            if (e.key === 'Enter' && props.isValid) {
              props.handleSubmit()
            }
          }}
        >
          <FormikTextInput
            fieldName="rpps"
            label="Identifiant RPPS"
            autocomplete={false}
            colorPreset="light"
            required
          />
          <FormikTextInput
            type="password"
            fieldName="password"
            label="Mot de passe"
            autocomplete={false}
            colorPreset="light"
            required
          />
          <div className="my-5">
            <RadioGroup
              name="otpMethod"
              label="Recevoir un code vérification par :"
              value={props.values.otpMethod}
              onChange={(e) => props.setFieldValue('otpMethod', e.currentTarget.value)}
            >
              <Radio label="SMS" value={MailOTPMethod.SMS} />
              <Radio label="Email" value={MailOTPMethod.EMAIL} />
            </RadioGroup>
          </div>
          <div className="flex w-full">
            <Button
              theme="primary"
              type="submit"
              label="Se connecter"
              disabled={!props.isValid}
              onClick={() => {
                props.handleSubmit()
              }}
            />
          </div>
          <div className="w-full text-center mt-3">
            <a
              href="https://mailiz.mssante.fr/web/mss/connexion-otp"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-shades-4 mb-3 hover:text-shades-3"
            >
              Gérer mon compte Mailiz
            </a>
          </div>
        </div>
      )}
    </Formik>
  )
}
