import getApi from '../../api'
import { PatientMutationParams } from '../../model/Patient'
import { deserializePatientResponse, serializePatient } from './mapper/patients.mapper'
import { ApiPatientResource, PatientMutationPayload } from './mapper/patients.model'

export const createPatient = (patient: PatientMutationPayload, params?: PatientMutationParams) =>
  getApi()
    .post<ApiPatientResource>('/patients', serializePatient(patient), {
      params,
    })
    .then(deserializePatientResponse)
