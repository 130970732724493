import React, { FunctionComponent } from 'react'
import styles from './InfiniteLoader.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as Logo } from '../../../../assets/images/logo.svg'

interface InfiniteLoaderProps {
  loading: boolean
  label?: string
}
const cx = classNames.bind(styles)

export const InfiniteLoader: FunctionComponent<InfiniteLoaderProps> = ({ loading, label }) => (
  <div className={styles.logoContainer}>
    <div className={cx(styles.loadingLogo, { loading })} />
    <Logo alt="follow-logo" />
    {label && (
      <div style={{ opacity: loading ? 1 : 0 }} className={styles.label}>
        {label}
      </div>
    )}
  </div>
)
