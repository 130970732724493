import React, { FunctionComponent } from 'react'
import { MessageTemplateListItemProps } from './MessageTemplateListItem.model'
import styles from './MessageTemplateListItem.module.scss'
import { getV3ColorCssVariable } from '../../../../../../design-system/colors_v3'
import { Icon } from '../../../../../shared'

export const MessageTemplateListItem: FunctionComponent<MessageTemplateListItemProps> = ({
  messageTemplate,
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.titleContainer}>
        {messageTemplate.title}
        {messageTemplate.isDefault && (
          <span className={styles.isDefaultIcon}>
            <Icon icon="star" size="nano" color={getV3ColorCssVariable('shades', 'shade2')} />
          </span>
        )}
      </div>
    </div>
  )
}
