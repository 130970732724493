import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { createPatient } from '../../../data/patients'
import { patientsKeys } from './patients.keys'
import { PatientMutationPayload } from '../../../data/patients/mapper/patients.model'
import { PatientMutationParams } from '../../../model/Patient'

interface UseCreatePatientParams {
  triggerSubscription?: boolean
}

interface UseCreatePatientVariables {
  patient: PatientMutationPayload
  params?: PatientMutationParams
}

export const useCreatePatient = ({ triggerSubscription = true }: UseCreatePatientParams = {}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: triggerSubscription ? patientsKeys.create : [],

    mutationFn: async ({ patient, params }: UseCreatePatientVariables) => {
      const { data, ok } = await createPatient(patient, params)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création du patient a échoué')
      }
      return data
    },

    onSuccess: (patient) => {
      queryClient.invalidateQueries({ queryKey: patientsKeys.lists })
      queryClient.setQueryData(patientsKeys.detail(patient.id), patient)
    },
  })
}
