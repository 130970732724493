import getApi from '../../api'
import { ManualPrescription } from '../../model/ManualPrescription'

export const updateManualPrescription = (
  manualPrescriptionUuid: string,
  manualPrescriptionUpdate: Partial<ManualPrescription>,
) =>
  getApi().put<ManualPrescription>(
    `/manual_prescriptions/${manualPrescriptionUuid}`,
    manualPrescriptionUpdate,
  )
