import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { patientHealthDataKeys } from './patientHealthData.keys'
import { getPatientAlerts } from '../../../data/patientHealthData'

interface GetAllPatientAlertsParams {
  patientId: number
  enabled?: boolean
}

export const useGetPatientAlerts = ({ patientId, enabled = true }: GetAllPatientAlertsParams) => {
  const queryKey = useMemo(() => patientHealthDataKeys.listAlerts(patientId), [patientId])

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await getPatientAlerts(patientId)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer les alertes du patient',
          "Une erreur technique s'est produite",
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn, patientAlertList: queryReturn.data || [] }
}
