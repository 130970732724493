import React, { FunctionComponent } from 'react'
import classNames from 'classnames/bind'

import { ColorsV2 } from '../../../design-system/colors_v2'
import { QuestionType } from '../../../model/Questionnaire'

import { Icon } from '../../shared'
import { Qcm, Qcu, Slider, Text, Date } from '../Placeholder'

import { QuestionnaireAddQuestionProps } from './QuestionnaireAddQuestion.model'
import styles from './QuestionnaireAddQuestion.module.scss'

const cx = classNames.bind(styles)

export const QuestionnaireAddQuestion: FunctionComponent<QuestionnaireAddQuestionProps> = ({
  onAddQuestion,
  answerTitle,
}) => {
  return (
    <div
      className={cx(styles.container)}
      data-test-id={`container-${answerTitle ? 'subquestion' : 'question'}-add`}
    >
      <div className={styles.title}>
        <Icon icon="add" size="nano" color={ColorsV2.contentDark1} />
        <div className={styles.text}>
          {answerTitle ? `Ajouter une sous-question à "${answerTitle}"` : 'Ajouter une question'}
        </div>
      </div>
      <div className={styles.placeholders} data-test-id="placeholders">
        <Qcu onClick={() => onAddQuestion(QuestionType.QCU)} />
        <Qcm onClick={() => onAddQuestion(QuestionType.QCM)} />
        <Text onClick={() => onAddQuestion(QuestionType.Text)} />
        <Slider onClick={() => onAddQuestion(QuestionType.Slider)} />
        <Date onClick={() => onAddQuestion(QuestionType.Date)} />
      </div>
    </div>
  )
}
