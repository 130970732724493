import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ActivePrinciplesSelectProps } from './ActivePrinciplesSelect.model'
import { DropdownItem, SearchInput } from '../../shared'
import { SelectOption } from '../../../model/SelectOption'
import { ActivePrinciple } from '../../../model/Drug'
import { useDebounce } from 'react-use'
import { useGetActivePrinciples } from '../../../hooks/queries/drugs'

const convertActivePrinciplesIntoOption = (item: ActivePrinciple) => ({
  label: item.name,
  value: item.externalId,
})

export const ActivePrinciplesSelect: FC<ActivePrinciplesSelectProps> = ({
  setActivePrincipleSelected,
  initialSearch = '',
  colorPreset = 'dark',
  placeHolder = '3 caractères min',
}) => {
  const [search, setSearch] = useState(initialSearch)
  const [debouncedSearch, setDebouncedSearch] = useState('')

  useEffect(() => setSearch(initialSearch), [initialSearch])

  const {
    query: { data: activePrinciples },
  } = useGetActivePrinciples({ search: debouncedSearch, enabled: debouncedSearch.length > 2 })

  const activePrinciplesOptions = useMemo(() => {
    if (activePrinciples) {
      const options = activePrinciples.map(convertActivePrinciplesIntoOption)
      return options
    }
  }, [activePrinciples])

  const handleSelect = useCallback(
    (selected: SelectOption<number>) => {
      setActivePrincipleSelected({ externalId: selected.value, name: selected.label })
      setSearch(selected.label)
    },
    [setActivePrincipleSelected],
  )

  useDebounce(
    () => {
      setDebouncedSearch(search)
      if (search.length < 3) {
        setActivePrincipleSelected(undefined)
      }
    },
    500,
    [search],
  )

  return (
    <SearchInput
      placeholder={placeHolder}
      colorPreset={colorPreset}
      value={search}
      results={activePrinciplesOptions}
      onSelect={handleSelect}
      renderResult={(option, isHovered) => (
        <DropdownItem selected={isHovered}>
          <div className="max-w-full flex items-center whitespace-normal">
            <span className="capitalize">{option.label}</span>
          </div>
        </DropdownItem>
      )}
      onChange={({ target: { value } }) => {
        setSearch(value)
      }}
    />
  )
}
