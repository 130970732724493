import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getResourceHistory } from '../../../data/resourceHistory'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { ResourceHistoryEntity } from '../../../model/ResourceHistory'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { resourceHistoryKeys } from './resourceHistory.keys'

interface UseGetInfiniteResourceHistoryParams {
  entity: ResourceHistoryEntity
  enabled?: boolean
}

export const useGetInfiniteResourceHistory = ({
  entity,
  enabled,
}: UseGetInfiniteResourceHistoryParams) => {
  const queryKey = useMemo(() => resourceHistoryKeys.list(entity), [entity])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { ok, data } = await getResourceHistory(entity, pageParam, signal)

      if (!ok || !data) {
        throw new UserFriendlyError('error', "Erreur lors de la récupération de l'historique")
      }

      return data
    },
    enabled,
    initialPageParam: startingPage,
    getNextPageParam,
  })

  const historyList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  return { query, historyList, paginationState }
}
