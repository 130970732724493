import {
  set,
  differenceInMonths,
  differenceInWeeks,
  differenceInCalendarDays,
  formatDistanceStrict,
  differenceInYears,
  parseISO,
  format,
  isSameDay,
  addDays,
  subDays,
  addMinutes,
} from 'date-fns'
import { fr as frLocale } from 'date-fns/locale'

const DAY_BREAKPOINT = 14
const WEEK_BREAKPOINT = 8
const MONTH_BREAKPOINT = 24
/** 
  Calcule la distance temporelle à la date spécifiée et renvoie la valeur en string
  @param date La date cible
  @param refDate Optionnel, la date de référence pour le calcul. Par défaut la date courante.
  @return Un string formaté comme suit :
    - "aujourd'hui", "demain" ou "hier" pour les cas éponymes
    - "X jours" quand distance ∈ [2, 13] jours
    - "X semaines" quand distance ∈ [2, 7] semaines
    - "X mois" quand distance ∈ [2, 23] mois
    - "X ans" sinon

    Préfixée par "il y a" (date < basedate) ou "dans" (date > basedate) (hors premiers cas)
*/
export function getDistanceToNowInDays(date: Date, refDate = new Date()): string {
  if (isSameDay(date, refDate)) {
    return "aujourd'hui"
  }
  if (isSameDay(date, subDays(refDate, 1))) {
    return 'hier'
  }
  if (isSameDay(date, addDays(refDate, 1))) {
    return 'demain'
  }

  const fields = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }
  const formatOptions = { locale: frLocale, addSuffix: true }
  date = set(date, fields)
  const basedate = set(refDate, fields)

  if (Math.abs(differenceInMonths(date, basedate)) < MONTH_BREAKPOINT) {
    const weeks = differenceInWeeks(date, basedate)
    if (Math.abs(weeks) < WEEK_BREAKPOINT) {
      if (Math.abs(differenceInCalendarDays(date, basedate)) >= DAY_BREAKPOINT) {
        return `${weeks > 0 ? 'dans' : 'il y a'} ${Math.abs(weeks)} semaines`
      }
    } else {
      return formatDistanceStrict(date, basedate, { ...formatOptions, unit: 'month' })
    }
  }
  return formatDistanceStrict(date, basedate, formatOptions)
}

export function getAge(birthDate: string, refDate = new Date()) {
  return differenceInYears(refDate, parseISO(birthDate))
}

export function getAgeWithUnit(birthDate: string, refDate = new Date()) {
  const age = differenceInYears(refDate, parseISO(birthDate))
  const ageInMonth = differenceInMonths(refDate, parseISO(birthDate))

  return ageInMonth < 37 ? `${ageInMonth} mois` : `${age} ans`
}

export function formatFr(date: Date, dateFormat: string) {
  return format(date, dateFormat, { locale: frLocale })
}

export function getPureDateWithoutTimezone(birthDate: string, dateFormat: string) {
  const date = new Date(birthDate)
  return formatFr(addMinutes(date, date.getTimezoneOffset()), dateFormat)
}
