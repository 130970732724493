import apisauce, { ApisauceConfig, ApisauceInstance } from 'apisauce'
import { Store } from 'redux-starter-kit'
import { AuthService } from '../misc/auth.utilities'
import { redirectToErrorPage } from '../misc/url.utilities'
import { User } from '../model/User'
import { RootState } from '../store'
import { decrementRequestCount, incrementRequestCount } from '../store/http/http.actions'
import { usurpedSelector } from '../store/ui/adminUsers/index'
import { loggedUserSelector } from '../store/domain/me'
import { isAssistant } from '../misc/user.utilities'
import { AUTH_URL } from '../environment/auth'
import { SEPHIRA_ICONNECT_URL } from '../environment/sephira'
import { selectedEmailAddressAtom } from '../state/mail/selectedEmailAddress.atom'
import { getDefaultStore } from 'jotai'
import { subCodeTokenExpired } from '../misc/mail.utilities'
import { customHistory } from '../history'
import { addError } from '../store/message'
import { ORDOCLIC_ICONNECT_URL } from '../environment/ordoclic'
import { mailWebserviceAuthToken } from '../state/mail'
import { MAIL_HEADER_NO_REDIRECT } from '../model/Mail'

const getUrlWithoutParameters = (url = '') => {
  // on retire le slash au debut
  if (url.startsWith('/')) {
    return url.substring(1)
  }

  // On récupère l'URL sans les paramètres get s'il y en a
  if (url) {
    const search = url.match(/\/(.*?)\?/)
    if (search && search.length >= 2) {
      return search[1]
    }
  }

  return url
}

const isMssUrl = (url: string) => {
  const mssUrlsPatternForXMssToken = [/^mss.*/]
  const oneUrlIsMatchingWithMssPattern = mssUrlsPatternForXMssToken.some((pattern) =>
    url.match(pattern),
  )
  return oneUrlIsMatchingWithMssPattern
}

let api: ApisauceInstance
let mapApi: ApisauceInstance
let keycloakApi: ApisauceInstance
let sephiraIconnectApi: ApisauceInstance
let ordoclicIconnectApi: ApisauceInstance

interface ApiClientOpts {
  addTransforms?: boolean
  addMonitor?: boolean
  addBearer?: boolean
}
function createApiClient(
  store: Store<RootState>,
  baseURL: string,
  { addBearer, addTransforms }: ApiClientOpts = {},
) {
  const apiInstance = apisauce.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (addBearer) {
    apiInstance.addAsyncRequestTransform(async (request) => {
      if (AuthService.isLogged() && request.headers) {
        await AuthService.updateToken()
        request.headers.Authorization = `Bearer ${AuthService.getToken()}`
      }
    })
  }

  if (addTransforms) {
    apiInstance.addRequestTransform((request) => {
      store.dispatch(incrementRequestCount())
    })

    apiInstance.addResponseTransform((response) => {
      store.dispatch(decrementRequestCount())
      if (response.status === 503) {
        redirectToErrorPage('maintenance')
      }
    })

    // Usurpation
    apiInstance.addRequestTransform((request) => {
      const state = store.getState()
      const usurpedUser: User | null = usurpedSelector(state)
      const loggedUser: User | null = loggedUserSelector(state)

      // On ne doit pas appliquer l'usurpation sur ces URL
      const useStellairAssistantAccount =
        isAssistant(loggedUser) && loggedUser.preferences.useStellairAssistantAccount
      const adminUrls = [
        /^users$/,
        /^users\/me$/,
        /^admin/,
        /^score\/base\/duplicate-from-score-user/,
        /^survey\/clone/,
        /^documents\/clone/,
        /^file_templates\/clone/,
        ...(useStellairAssistantAccount ? [/^stellair/, /^external-services/] : []),
        /^notifications/,
        /^legal_documents/,
      ]

      const urlWithoutParameters = getUrlWithoutParameters(request.url)

      // En cas d'usurpation active, et pour une requête autre que sur une URL réservée aux admins
      // Alors on passe le paramètre d'usurpation
      if (usurpedUser) {
        const oneUrlIsMatching = adminUrls.some((pattern) => !!urlWithoutParameters.match(pattern))
        if (urlWithoutParameters && !oneUrlIsMatching) {
          request.params = {
            ...request.params,
            _switch_user: usurpedUser.email,
            _switch_user_id: usurpedUser.id,
          }
        }
      }
    })

    // MSS : Ajout des query params sur les requêtes authentifiées
    apiInstance.addRequestTransform((request) => {
      const urlWithoutParameters = getUrlWithoutParameters(request.url)
      if (!isMssUrl(urlWithoutParameters)) return

      const atomStore = getDefaultStore()
      const inUseMailAddress: string | null = atomStore.get(selectedEmailAddressAtom)
      const mssAuthToken = atomStore.get(mailWebserviceAuthToken)

      const mssTokenKey = 'X-MSS-TOKEN'

      request.headers ??= {}
      if (mssAuthToken && !request.headers[mssTokenKey]) {
        request.headers[mssTokenKey] = mssAuthToken
      }

      //Il faut exclure ici les paths qui n'ont pas besoin du paramètre _mss_email_address
      const mssUrlsPatternWithExcludes = [/^mss\/(?!email_addresses).*/]

      if (inUseMailAddress) {
        const oneUrlIsMatching = mssUrlsPatternWithExcludes.some((pattern) =>
          urlWithoutParameters.match(pattern),
        )
        if (urlWithoutParameters && oneUrlIsMatching) {
          request.params = {
            ...request.params,
            _mss_email_address: inUseMailAddress,
          }
        }
      }
    })

    // Logout quand 401
    apiInstance.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const {
          response: { status, data },
        } = error
        if (status === 401) {
          const atomStore = getDefaultStore()
          atomStore.set(selectedEmailAddressAtom, null)
          atomStore.set(mailWebserviceAuthToken, null)
          AuthService.requestLogin()
        }
        console.error(data?.detail)
        return error
      },
    )

    // Logout mss quand 401 dans le détail sur les URL MSS
    apiInstance.addMonitor(({ data, status, headers, config }) => {
      if (status !== 400 || !data) return

      const rawUrl = config?.url ?? ''
      const url = getUrlWithoutParameters(rawUrl)

      const mssAuthentUrl = /^mss\/auth\/.*/
      const isMssAuthUrl = mssAuthentUrl.test(url)
      if (!isMssUrl(url) || isMssAuthUrl) return

      const isTokenExpired = subCodeTokenExpired(data, status)
      if (!isTokenExpired) return

      console.error('MSS authentication expired')

      const atomStore = getDefaultStore()
      atomStore.set(selectedEmailAddressAtom, null)
      atomStore.set(mailWebserviceAuthToken, null)

      const noRedirect = config?.headers?.[MAIL_HEADER_NO_REDIRECT]
      if (noRedirect) return

      const redirect = headers?.redirect_uri
      if (redirect) {
        window.location.replace(redirect)
      } else {
        store.dispatch(
          addError(
            'Erreur de connexion',
            'Désolé, votre session a expiré. Veuillez vous reconnecter pour continuer.',
            { id: 'mss_logout' },
          ),
        )
        customHistory.navigate('/authenticate/mail')
      }
    })
  }

  return apiInstance
}

function createApiClientWithLoadingWatcher(store: Store<RootState>, config: ApisauceConfig) {
  const apiInstance = apisauce.create(config)
  apiInstance.addRequestTransform(() => {
    store.dispatch(incrementRequestCount())
  })

  apiInstance.addResponseTransform(() => {
    store.dispatch(decrementRequestCount())
  })

  return apiInstance
}

export function initClient(appStore: Store<RootState>) {
  api = createApiClient(appStore, `/api`, {
    addBearer: true,
    addMonitor: true,
    addTransforms: true,
  })

  mapApi = createApiClient(appStore, `/map-api/v1`, {
    addBearer: true,
  })

  keycloakApi = apisauce.create({
    baseURL: AUTH_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  sephiraIconnectApi = createApiClientWithLoadingWatcher(appStore, {
    baseURL: SEPHIRA_ICONNECT_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    },
  })

  ordoclicIconnectApi = createApiClientWithLoadingWatcher(appStore, {
    baseURL: ORDOCLIC_ICONNECT_URL,
    withCredentials: true,
  })
}

export function getMapApi() {
  return mapApi
}

export function getKeycloakApi() {
  return keycloakApi
}

export default function getApi() {
  return api
}

export function getSephiraIconnectApi() {
  return sephiraIconnectApi
}

export function getOrdoclicIconnectApi() {
  return ordoclicIconnectApi
}
