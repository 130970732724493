import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { messageTemplatesKeys } from './messageTemplates.keys'
import { updateDefaultMessageTemplate } from '../../../data/messageTemplates/updateDefaultMessageTemplate'

interface MessageTemplateMutateVariable {
  id: number | null
}

export const useUpdateDefaultMessageTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id }: MessageTemplateMutateVariable) => {
      const { data, ok } = await updateDefaultMessageTemplate(id)
      if (!ok) {
        throw new UserFriendlyError('error', 'La modification du modèle de message a échoué')
      }
      return data
    },

    onSuccess: (messageTemplate) => {
      queryClient.invalidateQueries({ queryKey: messageTemplatesKeys.list })
      queryClient.invalidateQueries({ queryKey: messageTemplatesKeys.defaultMessage })
    },
  })
}
