import { useCallback, useContext, useMemo } from 'react'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'
import { Filters } from '../../../../model/Filters'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { getNotifications } from '../../../../data/admin'
import { getNextPageParam } from '../../../../misc/query.utilities'
import { isAdmin } from '../../../../misc/user.utilities'
import { UserFriendlyError } from '../../../../model/Error'
import { useFlatPaginatedData } from '../../../utils/query/useFlatPaginatedData.hook'
import { usePaginationState } from '../../../utils/query'
import { adminNotifKeys } from './adminNotifications.keys'
import { startingPage } from '../../../../constants'

interface GetInfiniteNotificationsParams {
  filters?: Filters
  limit?: number
  enabled?: boolean
}

export const useGetNotifications = ({
  filters,
  limit,
  enabled = true,
}: GetInfiniteNotificationsParams) => {
  const queryKey = useMemo(() => adminNotifKeys.list(filters), [filters])

  const { currentUser } = useContext(ConnectedUserContext)

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getNotifications(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des notifications`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled: isAdmin(currentUser) && enabled,
    initialPageParam: startingPage,
    getNextPageParam,
  })

  const queryClient = useQueryClient()

  const notificationsList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, notificationsList, paginationState, cancelPendingQuery }
}
