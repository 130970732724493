import {
  PatientSex as Sex,
  Title,
  IdentityState,
  TrustedSource,
  FollowTypes,
  Civility,
  CHILD_LIMIT_AGE,
  CHILD_CIVILITY,
  SEX_LABELS,
  CIVILITY_LABELS,
  CIVILITY_LABELS_ABBREVIATIONS,
} from '@follow/cdk'

export {
  Sex,
  Title,
  IdentityState,
  TrustedSource,
  Civility,
  SEX_LABELS,
  CIVILITY_LABELS,
  CIVILITY_LABELS_ABBREVIATIONS,
  CHILD_LIMIT_AGE,
  CHILD_CIVILITY,
}
export type IdentityCheckStatus = FollowTypes.Patient.IdentityCheckStatus
export type IdentityStatus = FollowTypes.Patient.IdentityStatus
export type AssigningAuthority = FollowTypes.Patient.AssigningAuthority
export type LightPatient = FollowTypes.Patient.LightPatient
export type Patient = FollowTypes.Patient.Patient
export type PatientCustomField = FollowTypes.Patient.PatientCustomField

export const MIN_MAJOR_AGE = 18

export const IdentityStateLabel = {
  [IdentityState.TEMPORARY]: 'Provisoire',
  [IdentityState.VALIDATED]: 'Validée',
  [IdentityState.INSI_CHECKED]: 'Récupérée',
  [IdentityState.QUALIFIED]: 'Qualifiée',
}

export const PHONE_REGEX = /^\+?[\d\s-]+$/
export const NAME_REGEX = /^[A-Z\s\-']+$/
export const FIRST_NAME_REGEX = /^[A-Za-z\s\-ç']+$/
export const BIRTH_PLACE_CODE_REGEX = /^((0[1-9])|([1-8][0-9])|(9[0-9])|(2A)|(2B))[0-9]{3}$/

export interface PendingFollowPatient {
  email: string | null
  telephone1: string | null
  telephone2: string | null
  address: string | null
  zipcode: string | null
  city: string | null
}

export interface PatientMutationParams extends Record<string, any> {
  external?: boolean
  forceInsiCheck?: boolean
}
