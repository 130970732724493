import { useCallback, useContext, useMemo } from 'react'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { getUserTeams } from '../../../../data/admin'
import { isDefined } from '../../../../misc/functions.utilities'
import { UserFriendlyError } from '../../../../model/Error'
import { getNextPageParam } from '../../../../misc/query.utilities'
import { isAdmin } from '../../../../misc/user.utilities'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'
import { usePaginationState } from '../../../utils/query'
import { useFlatPaginatedData } from '../../../utils/query/useFlatPaginatedData.hook'
import { teamKeys } from './teams.keys'
import { startingPage } from '../../../../constants'

export const useGetUserTeams = (userId?: string) => {
  const queryKey = useMemo(() => teamKeys.userTeams(userId), [userId])
  const { currentUser } = useContext(ConnectedUserContext)
  const queryClient = useQueryClient()

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      if (!isDefined(userId)) return

      const { data, ok } = await getUserTeams(pageParam, userId, signal)
      if (!data || !ok) {
        throw new UserFriendlyError('error', "Impossible de récupérer les équipes de l'utilisateur")
      }
      return data
    },
    enabled: isDefined(userId) && isAdmin(currentUser),
    initialPageParam: startingPage,
    getNextPageParam,
  })

  const userTeamList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, userTeamList, paginationState, cancelPendingQuery }
}
