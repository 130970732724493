import { uiActionsFactory } from '../../restux/ui/index'
import {
  medicalEventConfig,
  MedicalEventsUiActionTypes,
  MedicalEventFilter,
} from './medicalEvents.model'

const uiActions = uiActionsFactory(medicalEventConfig).actions

export const medicalEventUiActions = {
  ...uiActions,

  selectMedicalEventDocument: (
    filter: MedicalEventFilter | null,
    keepParams = false,
    sending?: string | null,
  ) => ({
    type: MedicalEventsUiActionTypes.SELECT_DOCUMENT,
    filter,
    keepParams,
    sending,
  }),
  filterMedicalEventDocument: (filter: MedicalEventFilter | null) => ({
    type: MedicalEventsUiActionTypes.FILTER_DOCUMENT,
    filter,
  }),
  /**
   * Clear des filters avec systeme de prevention de sauvegarde de l'éditeur
   */
  askToClearFilters: () => ({
    type: MedicalEventsUiActionTypes.ASK_TO_CLEAR_FILTERS,
  }),

  /**
   * Action à appeler avec précaution puisque le systeme de prevention de sauvegarde de l'éditeur ne sera pas activé
   */
  clearFilters: () => ({
    type: MedicalEventsUiActionTypes.CLEAR_FILTERS,
  }),
  displayAddFileDropzone: () => ({
    type: MedicalEventsUiActionTypes.DISPLAY_ADD_FILE_DROPZONE,
  }),
  hideAddFileDropzone: () => ({
    type: MedicalEventsUiActionTypes.HIDE_ADD_FILE_DROPZONE,
  }),
  clearState: () => ({
    type: MedicalEventsUiActionTypes.CLEAR,
  }),
}
