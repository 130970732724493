import { ReactNode } from 'react'
import { UnmountTransitionProps } from '../../../../misc/withUnmountTransition.hoc'

export interface BottomPanelOwnProps extends UnmountTransitionProps {
  title: string
  onRequestClose: () => void
  actions?: ReactNode
  displayCloseButton?: boolean
  rootId?: string
  hint?: string
  hintTooltip?: ReactNode
}

export interface ComponentUsingBottomPanelProps {
  onRequestClose: () => void
}

export interface SearchComponentUsingBottomPanelProps<T> extends ComponentUsingBottomPanelProps {
  onSelect: (item: T) => void
}

export interface EditComponentUsingBottomPanelProps<T, I extends string | number> {
  onRequestClose: () => void
  itemId?: I | null
  onItemEdited?: (item: T) => void
}

export const BOTTOM_PANEL_CONTENT_ROOT = 'newBottomPanelContentRoot'
