import { useMutation, useQueryClient } from '@tanstack/react-query'
import { readTask } from '../../../data/task'
import { taskKeys } from './tasks.keys'

export const useReadTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const { ok } = await readTask(true)
      if (!ok) {
        throw new Error(`Impossible de marquer les tâches comme lues`)
      }
      return null
    },

    onSuccess: () => {
      queryClient.setQueryData(taskKeys.count({ read: false }), 0)
    },
  })
}
