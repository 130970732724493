import { ApiResponse } from 'apisauce'
import { all, call, select, put } from 'redux-saga/effects'

import { AnswerValue } from '../../../model/AnswerValue'
import { inUseQuestionsWithAnswerValuesSelector } from './medicalEvents.selectors'

import * as DocumentAnswerValuesApi from '../documentAnswerValues/api'
import {
  createAnswerValuesByQuestionIdSelector,
  documentAnswerValuesDomainActions,
  documentAnswerValuesListSelector,
} from '../documentAnswerValues'
import { addError } from '../../message'
import { SaveAnswerValueBodyType } from './medicalEvents.model'
import { isDefined } from '../../../misc/functions.utilities'
import { patientImportantInformationsKeys } from '../../../hooks/queries/patientImportantInformations/patientImportantInformations.keys'
import { queryClient } from '../../../App'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'

function* apiSaveDocumentAnswerValue(item: SaveAnswerValueBodyType, ignoreErrors = false) {
  const response: ApiResponse<AnswerValue, AnswerValue> = yield call(
    DocumentAnswerValuesApi.saveDocumentAnswerValue,
    item,
  )
  if (!response.ok) {
    if (!ignoreErrors) {
      yield put(documentAnswerValuesDomainActions.setAnswerValueError(true))
    } else {
      yield put(addError('La sauvegarde de votre dernière réponse à échoué.'))
    }
  }
  const patientId = getCurrentPatientId()
  patientId
    ? queryClient.invalidateQueries({
        queryKey: patientImportantInformationsKeys.listAll(patientId),
      })
    : queryClient.invalidateQueries({ queryKey: patientImportantInformationsKeys.lists })
}

function* apiDeleteDocumentAnswerValue(id: number) {
  const response: ApiResponse<void, void> = yield call(
    DocumentAnswerValuesApi.deleteDocumentAnswerValue,
    id,
  )
  if (!response.ok) {
    yield put(documentAnswerValuesDomainActions.setAnswerValueError(true))
  }

  const patientId = getCurrentPatientId()
  patientId
    ? queryClient.invalidateQueries({
        queryKey: patientImportantInformationsKeys.listAll(patientId),
      })
    : queryClient.invalidateQueries({ queryKey: patientImportantInformationsKeys.lists })
}

/**
 * Fonction a appeler UNIQUEMENT depuis une saga
 */
export function* saveSimpleAnswerValue(
  medicalEventId: number,
  documentId: number,
  questionId: number,
  value: string | number | undefined,
) {
  if (isDefined(value)) {
    yield call(
      apiSaveDocumentAnswerValue,
      {
        medicalEventId,
        documentId,
        questionId,
        value: `${value}`,
      },
      true,
    )
  } else {
    const answerValues: ReturnType<typeof documentAnswerValuesListSelector> = yield select(
      documentAnswerValuesListSelector,
      documentId,
    )
    if (answerValues) {
      const answerValue = answerValues.find((answer) => answer.questionId === questionId)
      if (answerValue) yield call(apiDeleteDocumentAnswerValue, answerValue.id)
    }
  }
}

/**
 * Fonction a appeler UNIQUEMENT depuis une saga
 */
export function* saveChoiceAnswerValue(
  medicalEventId: number,
  documentId: number,
  questionId: number,
  value: number | undefined,
) {
  const existingAnswerValues: ReturnType<
    ReturnType<typeof createAnswerValuesByQuestionIdSelector>
  > = yield select(createAnswerValuesByQuestionIdSelector(documentId, [questionId]))

  // Si de-selection de la réponse  => suppression de toutes les réponses existantes
  if (value === undefined) {
    if (existingAnswerValues) {
      yield all(existingAnswerValues.map(({ id }) => call(apiDeleteDocumentAnswerValue, id)))
    }
  } else {
    yield call(apiSaveDocumentAnswerValue, {
      medicalEventId,
      documentId,
      questionId,
      answerId: value,
    })
  }
  // Nettoyage des sous questions
  if (existingAnswerValues) {
    yield call(
      deleteEventualChildQuestions,
      documentId,
      existingAnswerValues.filter(({ id }) => id !== value),
    )
  }
}

/**
 * Fonction a appeler UNIQUEMENT depuis une saga
 */
export function* saveChoicesAnswerValues(
  medicalEventId: number,
  documentId: number,
  questionId: number,
  values: number[],
) {
  const existingAnswerValues: ReturnType<
    ReturnType<typeof createAnswerValuesByQuestionIdSelector>
  > = yield select(createAnswerValuesByQuestionIdSelector(documentId, [questionId]))

  if (!existingAnswerValues) {
    // En attente du chargement de l'api
    return
  }
  const toBeCreatedAnswerValues = values.filter(
    (value) => !existingAnswerValues.some(({ answerId }) => answerId === value),
  )
  const toBeDeletedAnswerValues = existingAnswerValues.filter(
    ({ answerId }) => !values.some((value) => answerId === value),
  )
  const creations = toBeCreatedAnswerValues.map((value) =>
    call(apiSaveDocumentAnswerValue, {
      medicalEventId,
      documentId,
      questionId,
      answerId: value,
    }),
  )
  const deletions = toBeDeletedAnswerValues.map(({ id }) => call(apiDeleteDocumentAnswerValue, id))
  yield all([...creations, ...deletions])
  if (toBeDeletedAnswerValues.length > 0) {
    yield call(deleteEventualChildQuestions, documentId, toBeDeletedAnswerValues)
  }
}

/**
 * Fonction a appeler UNIQUEMENT depuis une saga
 */
function* deleteEventualChildQuestions(
  documentId: number,
  answerValues: ReadonlyArray<AnswerValue>,
) {
  const questions: ReturnType<typeof inUseQuestionsWithAnswerValuesSelector> = yield select(
    inUseQuestionsWithAnswerValuesSelector,
  )
  if (!questions) {
    return
  }

  const answerIds = answerValues.map(({ answerId }) => answerId)

  const matchingChildQuestionIds = questions
    .flatMap((question) => question.answers)
    .flatMap((answer) => (answer.childQuestions ? answer.childQuestions : []))
    .filter(
      (childQuestion) =>
        childQuestion.parentAnswerId && answerIds.includes(childQuestion.parentAnswerId),
    )
    .map(({ id }) => id)

  const toBeDeletedChildAnswerValues: ReturnType<
    ReturnType<typeof createAnswerValuesByQuestionIdSelector>
  > = yield select(createAnswerValuesByQuestionIdSelector(documentId, matchingChildQuestionIds))

  if (
    toBeDeletedChildAnswerValues &&
    toBeDeletedChildAnswerValues.length > 0 &&
    window.confirm(
      'Vous vous apprêtez à supprimer la valeur des sous questions. En êtes-vous sur ?',
    )
  ) {
    yield all(toBeDeletedChildAnswerValues.map(({ id }) => call(apiDeleteDocumentAnswerValue, id)))
  }
}
