import { useAtom } from 'jotai'
import { useContext, useMemo } from 'react'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { isDoctor } from '../../../misc/user.utilities'
import { User } from '../../../model/User'
import { selectedEmailAddressAtom } from '../../../state/mail/selectedEmailAddress.atom'

export const useMailConnectionStatus = (currentUser: User | null) => {
  const [selectedEmailAddress] = useAtom(selectedEmailAddressAtom)

  const isWebServiceOk = useMemo(() => !!selectedEmailAddress, [selectedEmailAddress])

  const isSmtpOk = useMemo(
    () =>
      isDoctor(currentUser) &&
      currentUser.preferences.enabledFeatures.mssSmtp &&
      !!currentUser.mailizEmailAddress,
    [currentUser],
  )

  return isSmtpOk || isWebServiceOk
}

export const useCurrentMailConnectionStatus = () => {
  const { currentUser } = useContext(ConnectedUserContext)

  return useMailConnectionStatus(currentUser)
}
