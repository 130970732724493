import { FeaturesType } from '@follow/cdk'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getPendingPatientData } from '../../../data/patients/doctolib'
import { UserFriendlyError } from '../../../model/Error'
import { useUserEnabledFeature } from '../../utils/user'
import { doctolibPendingChangesKeys } from './doctolibPendingChanges.keys'

interface PendingFollowVariables {
  patientId?: number
  enabled?: boolean
}

export const useGetPendingFollowPatient = ({
  patientId,
  enabled = true,
}: PendingFollowVariables) => {
  const queryKey = useMemo(
    () => doctolibPendingChangesKeys.detail(patientId?.toString()),
    [patientId],
  )
  const hasDoctolibPendingChangesFeatureFlag = useUserEnabledFeature(FeaturesType.doctolibUpdates)

  const queryResult = useQuery({
    queryKey,

    queryFn: async () => {
      if (!patientId) return

      const { ok, data } = await getPendingPatientData(patientId)

      if (!ok || !data) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer les informations modifiées sur le site Doctolib',
        )
      }
      return data
    },
    // à retirer lors de la suppression du FF doctolibUpdates
    enabled: enabled && hasDoctolibPendingChangesFeatureFlag,
  })

  return { query: queryResult || [] }
}
