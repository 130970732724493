import { createContext } from 'react'
import { EditorConfig } from '@/model/Config'
import { defaultImageFetch } from '@/utils/image.utils'
import { EDITOR_ALL_TOOLS } from '@/model/Toolbar'

export const defaultEditorConfig: EditorConfig = {
  tools: Array.from(EDITOR_ALL_TOOLS),
  authenticatedImageRequest: defaultImageFetch,
  themes: [],
}

export const EditorConfigContext = createContext<EditorConfig>(defaultEditorConfig)
