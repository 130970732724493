import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { TaskTable } from '../../task'
import {
  useCreateTask,
  useDeleteTask,
  useGetTaskCount,
  useGetTasks,
  useUpdateTask,
} from '../../../hooks/queries/task'
import { TaskCategory, TaskFilters, TaskMutationPayload, TaskStatus } from '../../../model/Task'
import styles from './TaskList.module.scss'
import { TaskTableColumn } from '../../task/TaskTable/TaskTable.model'
import {
  AccordionItem,
  Badge,
  DeleteValidationModal,
  IconChip,
  Loader,
  RoundedButton,
} from '../../shared'
import {
  defaultTaskPageContext,
  TaskPageContentFiltersContext,
  TaskPageContentFiltersContextType,
  TaskPageContextState,
} from '../../task/TaskPageContent/TaskPageContent.context'
import { isDefined } from '../../../misc/functions.utilities'
import { TaskEmptyState } from './TaskEmptyState'
import { formatISO } from 'date-fns'
import { TaskListProps } from './TaskList.model'

const COMPLETED_SINCE = formatISO(new Date().setHours(new Date().getHours() - 24))

export const TaskList: FunctionComponent<TaskListProps> = ({
  patient,
  document,
  contentEmptyState,
}) => {
  const [showList, setShowList] = useState<boolean>(false)
  const [contextFilters, setContextFilters] = useState<TaskPageContextState>({
    ...defaultTaskPageContext,
    hideAutoCreated: true,
  })
  const [newTask, setNewTask] = useState<TaskMutationPayload>()
  const [toBeDeletedTaskId, setToBeDeletedTaskId] = useState<number>()
  const [showMore, setShowMore] = useState<boolean>(false)

  const { mutate: createTask } = useCreateTask()
  const { mutate: updateTask } = useUpdateTask()
  const { mutate: deleteTask } = useDeleteTask()

  const filters = useMemo<TaskFilters>(() => {
    if (document) {
      return {
        orderBy: contextFilters.orderBy,
        order: contextFilters.order,
        patientId: patient?.id,
        documentId: document.id,
        autoCreated: contextFilters.hideAutoCreated ? false : undefined,
        includeCompletedSince: showMore ? undefined : COMPLETED_SINCE,
      }
    } else {
      return {
        orderBy: contextFilters.orderBy,
        order: contextFilters.order,
        patientId: patient.id,
        autoCreated: contextFilters.hideAutoCreated ? false : undefined,
        includeCompletedSince: showMore ? undefined : COMPLETED_SINCE,
      }
    }
  }, [
    document,
    contextFilters.orderBy,
    contextFilters.order,
    contextFilters.hideAutoCreated,
    patient.id,
    showMore,
  ])

  const countFilters = useMemo<TaskFilters>(
    () => ({
      ...filters,
      includeCompletedSince: undefined,
    }),
    [filters],
  )

  const contextValue = useMemo<TaskPageContentFiltersContextType>(
    () => ({
      filters: contextFilters,
      setFilters: (contextFilters) => {
        setContextFilters((oldContextFilters) => ({ ...oldContextFilters, ...contextFilters }))
      },
    }),
    [contextFilters],
  )

  const {
    taskList,
    query: { isLoading: isListLoading },
  } = useGetTasks({
    filters: filters,
  })

  const { data: totalTasks } = useGetTaskCount(countFilters)

  const todoTasks = useMemo(
    () =>
      taskList.filter(
        (task) => task.status === TaskStatus.TODO && (document ? task.document : !task.document),
      ),
    [document, taskList],
  )

  const doneTasks = useMemo(
    () =>
      taskList.filter(
        (task) => task.status === TaskStatus.DONE && (document ? task.document : !task.document),
      ),
    [document, taskList],
  )

  const tasks = useMemo(() => {
    const tasks = [...todoTasks, ...doneTasks]
    return newTask ? [newTask, ...tasks] : tasks
  }, [newTask, todoTasks, doneTasks])

  const totalTodoTasks = useMemo<number>(
    () => todoTasks.filter((task) => task.id !== undefined).length,
    [todoTasks],
  )

  const displayShowMore = useMemo<boolean>(
    () => (!!totalTasks && totalTasks > taskList.length) ?? false,
    [totalTasks, taskList],
  )

  const openListAccordion = useMemo<boolean>(() => {
    if (showList === undefined) {
      return tasks.length > 0
    }

    return showList
  }, [showList, tasks.length])

  const toggleListAccordion = useCallback(() => {
    if (showList === undefined) {
      setShowList(!openListAccordion)
    } else {
      setShowList(!showList)
    }
  }, [openListAccordion, setShowList, showList])

  const handleNewTask = useCallback(() => {
    if (document) {
      setNewTask({
        title: 'Traitement document',
        category: TaskCategory.EDIT,
        status: TaskStatus.TODO,
        createdAt: new Date(),
        editMode: true,
      })
    } else {
      setNewTask({ title: '', status: TaskStatus.TODO, createdAt: new Date(), editMode: true })
    }
  }, [document])

  const resetNewTask = useCallback(() => {
    setNewTask(undefined)
  }, [])

  const handleCreateOrUpdateTask = useCallback(
    (task: TaskMutationPayload) => {
      if (task.id) {
        updateTask({
          taskId: task.id,
          variables: { ...task, autoCreated: false, documentId: document ? document.id : null },
        })
      } else if (task.title) {
        createTask(
          { ...task, patientId: patient.id, documentId: document ? document.id : null },
          {
            onSuccess: () => resetNewTask(),
          },
        )
      } else {
        resetNewTask()
      }
    },
    [createTask, document, patient.id, resetNewTask, updateTask],
  )

  const resetToBeDeletedTaskId = useCallback(() => {
    setToBeDeletedTaskId(undefined)
  }, [setToBeDeletedTaskId])

  const handleConfirmDeleteTask = useCallback(() => {
    if (isDefined(toBeDeletedTaskId)) {
      deleteTask({ taskId: toBeDeletedTaskId })
    } else {
      resetNewTask()
    }
    resetToBeDeletedTaskId()
  }, [deleteTask, resetNewTask, resetToBeDeletedTaskId, toBeDeletedTaskId])

  const handleShowMore = useCallback(() => {
    setShowMore(true)
  }, [setShowMore])

  return (
    <>
      <TaskPageContentFiltersContext.Provider value={contextValue}>
        <div className={styles.taskListContainer}>
          <AccordionItem
            open={openListAccordion}
            onOpenClose={toggleListAccordion}
            overflow={true}
            renderLabel={() => (
              <>
                <span className={styles.accordionTitle}>Tâches</span>
                {!isListLoading && <Badge value={totalTodoTasks} />}
              </>
            )}
            testId="list-task-accordion-item"
          >
            {isListLoading ? (
              <div className={styles.placeholderWrapper}>
                <Loader height={70} width={70} />
              </div>
            ) : (
              <>
                <div className={styles.actionButtonsWrapper}>
                  <RoundedButton
                    label="Nouvelle tâche"
                    icon="add"
                    size="normal"
                    theme="primary"
                    onClick={handleNewTask}
                    disabled={!!newTask}
                    testId="list-task-new-btn"
                  />
                </div>
                {tasks.length === 0 ? (
                  <TaskEmptyState content={contentEmptyState} />
                ) : (
                  <>
                    <div className={styles.tableWrapper}>
                      <TaskTable
                        tasks={tasks}
                        displayColumns={
                          document
                            ? [
                                TaskTableColumn.DocumentStep,
                                TaskTableColumn.AssignedTo,
                                TaskTableColumn.CreatedAt,
                                TaskTableColumn.Actions,
                              ]
                            : [
                                TaskTableColumn.Title,
                                TaskTableColumn.Category,
                                TaskTableColumn.CreatedAt,
                                TaskTableColumn.Actions,
                              ]
                        }
                        size="small"
                        onCreateOrUpdate={handleCreateOrUpdateTask}
                        onDeleteTask={setToBeDeletedTaskId}
                        removeNewTask={resetNewTask}
                        testId="list-task-table"
                      />
                    </div>
                    {displayShowMore && !showMore && (
                      <div className={styles.showMoreButtonWrapper}>
                        <IconChip
                          size="small"
                          icon="chevron"
                          iconPosition="right"
                          borderSize={1}
                          label="Afficher les tâches masquées"
                          onClick={handleShowMore}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </AccordionItem>
        </div>
      </TaskPageContentFiltersContext.Provider>
      <DeleteValidationModal
        display={!!toBeDeletedTaskId}
        title="Voulez-vous vraiment supprimer cette tâche ?"
        onClose={resetToBeDeletedTaskId}
        onSubmit={handleConfirmDeleteTask}
        testId="list-task-delete-one-modal"
      />
    </>
  )
}
