import React, { FunctionComponent } from 'react'

import { IconChipProps } from './IconChip.model'

import styles from '../Chip/Chip.module.scss'
import { Chip } from '../Chip/Chip.component'
import { ColorsV2 } from '../../../../design-system/colors_v2'
import { ChipLabel } from '../ChipLabel'
import { Icon } from '../../Icon'

export const IconChip: FunctionComponent<IconChipProps> = ({
  renderLabel,
  icon,
  iconRotate = 0,
  iconPosition = 'left',
  colorPreset = 'dark',
  label,
  selected,
  editMode,
  title,
  onTitleEdit,
  onTitleSave,
  sublabel,
  ...restProps
}) => {
  let iconColor: ColorsV2
  switch (colorPreset) {
    case 'dark':
      iconColor = selected ? ColorsV2.contentLight1 : ColorsV2.contentDark1
      break

    case 'danger':
      iconColor = selected ? ColorsV2.contentLight1 : ColorsV2.error
      break

    case 'light':
      iconColor = ColorsV2.dsWhite
      break

    default:
      iconColor = ColorsV2.contentDark1
  }

  return (
    <Chip
      label={label}
      renderLabel={(labelToDisplay) => (
        <>
          {iconPosition === 'left' && (
            <Icon icon={icon} color={iconColor} size="nano" rotate={iconRotate} />
          )}
          {renderLabel && renderLabel(labelToDisplay)}
          {!renderLabel && (
            <>
              {editMode ? (
                <input
                  type="text"
                  autoFocus
                  value={label}
                  className={styles.input}
                  onKeyDown={(event) => event.key === 'Enter' && onTitleSave && onTitleSave()}
                  onFocus={(event) => event.target.select()}
                  onBlur={onTitleSave}
                  onChange={(value) => onTitleEdit && onTitleEdit(value.target.value)}
                />
              ) : (
                <ChipLabel label={labelToDisplay} sublabel={sublabel} title={title} />
              )}
            </>
          )}
          {iconPosition === 'right' && (
            <Icon icon={icon} color={iconColor} size="nano" rotate={iconRotate} />
          )}
        </>
      )}
      colorPreset={colorPreset}
      selected={selected}
      title={title}
      {...restProps}
    />
  )
}
