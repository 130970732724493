import { combineReducers } from 'redux'
import { documentInstancesReducers } from './documentInstances'
import { documentTemplatesReducers } from './documentTemplates'
import { filesReducers } from './files'
import { CacheState } from './cache.model'
import { userMeTeamsReducers } from './users'
import { questionnairesReducers } from './questionnaires'
import { questionInstancesReducers } from './questionInstances'
import { medicalEventsReducers } from './medicalEvents'
import { resourceHistoryReducers } from './resourceHistory'
import { questionTemplatesReducers } from './questionTemplates'

export default combineReducers<CacheState>({
  documentInstances: documentInstancesReducers,
  documentTemplates: documentTemplatesReducers,
  files: filesReducers,
  medicalEvents: medicalEventsReducers,
  questionnaires: questionnairesReducers,
  questions: questionInstancesReducers,
  questionTemplates: questionTemplatesReducers,
  resourceHistory: resourceHistoryReducers,
  userMeTeams: userMeTeamsReducers,
})
