import { FeaturesType, isDefined } from '@follow/cdk'
import { useCallback, useMemo } from 'react'
import Waypoint from 'react-waypoint'
import { useGetInfiniteMedicalEvents } from '../../../../../hooks/queries/medicalEvents'
import { formatFr, DATE_FORMAT_FRONT_SHORT } from '../../../../../misc/date.utilities'
import { getFileKind, isExtensionAllowed } from '../../../../../misc/files.utilities'
import { MedicalEventContentTypeIcon } from '../../../../../model/MedicalEvent'
import { TRANSVERSAL_NAVIGATION_MEDICAL_EVENTS_PAGE_SIZE } from '../../../../../pages/patient'
import { WAYPOINT_DETECTION_ZONE } from '../../../../patient/PatientTransversalNavigation/PatientTransversalNavigationMedicalEventList/PatientTransversalNavigationMedicalEventList.model'
import { IconButton } from '../../../buttons'
import { Loader } from '../../../loading'
import { MedicalEventsDocumentsListProps } from './MedicalEventsDocumentsList.model'
import { MedicalEventsDocumentsListItem } from './MedicalEventsDocumentsListItem'
import { hasDocOrFile } from '../../../../../misc/medicalEvent.utilities'
import { ReactComponent as EventsWithoutDocsIcon } from '../../../../../assets/images/transversalNavigation/working-people.svg'
import { FwDocumentType } from '../../../../../model/Document'
import styles from './MedicalEventsDocumentsList.module.scss'
import { useUserEnabledFeature } from '../../../../../hooks/utils/user'
import { TooltipWrapper } from '../../../tooltip'

export const MedicalEventsDocumentsList = ({
  patient,
  selectedItemsIds,
  onToggleFileSelection,
  onToggleDocumentSelection,
}: MedicalEventsDocumentsListProps) => {
  const mssSmtpEnabled = useUserEnabledFeature(FeaturesType.mssSmtp)

  const {
    query: { isLoading, fetchNextPage: fetchNextMedicalEvents, hasNextPage },
    medicalEventList,
    paginationState: medicalEventPagination,
  } = useGetInfiniteMedicalEvents({
    filters: { patientId: patient.id },
    limit: TRANSVERSAL_NAVIGATION_MEDICAL_EVENTS_PAGE_SIZE,
    enabled: isDefined(patient),
  })

  const handleInfiniteScroll = useCallback(() => fetchNextMedicalEvents(), [fetchNextMedicalEvents])

  const medicalEventsWithFollowDocsOrFiles = useMemo(
    () => medicalEventList.some((medicalEvent) => hasDocOrFile(medicalEvent)),
    [medicalEventList],
  )

  const handleFileSelection = useCallback(
    (id: number) => () => {
      onToggleFileSelection(id)
    },
    [onToggleFileSelection],
  )

  const handleDocumentSelection = useCallback(
    (id: number, type: FwDocumentType, title: string) => () => {
      onToggleDocumentSelection(id, type, title)
    },
    [onToggleDocumentSelection],
  )

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    )
  }

  if (!medicalEventsWithFollowDocsOrFiles) {
    return (
      <div className={styles.noItemsContainer}>
        <EventsWithoutDocsIcon />
        <span>
          Il n'existe aucun document Follow rattaché aux événements médicaux pour ce patient.
        </span>
      </div>
    )
  }

  return (
    <div className={styles.MedicalEventsDocumentsListContainer}>
      {medicalEventList.map((medicalEvent) => {
        const { id, title, date, pinned, files, documents } = medicalEvent

        return (
          hasDocOrFile(medicalEvent) && (
            <div key={id} className={styles.medicalEventContainer}>
              <div className={styles.medicalEventHeaderContainer}>
                <div className={styles.medicalEventDescription}>
                  {pinned && <IconButton icon="pin" theme="transparent-dark" noBorder />}
                  <h4>{title}</h4>
                  <span className={styles.medicalEventDate}>
                    {formatFr(new Date(date), DATE_FORMAT_FRONT_SHORT)}
                  </span>
                </div>
              </div>

              <div className={styles.documentItems}>
                {documents.map((doc) => {
                  const forbiddenExtension =
                    doc.type !== 'farte' && !isExtensionAllowed(doc.type, mssSmtpEnabled)
                  return (
                    <TooltipWrapper
                      content="L'envoi de ce type de fichier est impossible"
                      display={forbiddenExtension}
                      pointerDirection="top"
                      key={doc.id}
                    >
                      <MedicalEventsDocumentsListItem
                        id={doc.id}
                        title={doc.title}
                        selected={selectedItemsIds.includes(doc.id)}
                        icon={MedicalEventContentTypeIcon[doc.category]}
                        onDocumentClick={handleDocumentSelection(doc.id, doc.type, doc.title)}
                        disabled={forbiddenExtension}
                      />
                    </TooltipWrapper>
                  )
                })}
                {files.map((file) => {
                  const forbiddenExtension = !isExtensionAllowed(file.extension, mssSmtpEnabled)
                  return (
                    <TooltipWrapper
                      content="L'envoi de ce type de fichier est impossible"
                      display={forbiddenExtension}
                      pointerDirection="top"
                      key={file.id}
                    >
                      <MedicalEventsDocumentsListItem
                        id={file.id}
                        title={file.visibleName}
                        selected={selectedItemsIds.includes(file.id)}
                        icon={MedicalEventContentTypeIcon[getFileKind(file.extension)]}
                        onFileClick={handleFileSelection(file.id)}
                        disabled={forbiddenExtension}
                      />
                    </TooltipWrapper>
                  )
                })}
              </div>
            </div>
          )
        )
      })}
      {hasNextPage && (
        <Waypoint
          key={medicalEventPagination?.currentPage}
          bottomOffset={WAYPOINT_DETECTION_ZONE}
          onEnter={handleInfiniteScroll}
        />
      )}
    </div>
  )
}
