import { PaginatedList } from '../../../../model/Pagination'
import { DocumentInstance, DocumentInstanceListItem } from '../../../../model/DocumentInstance'
import {
  deserializeDocumentInstancePaginatedResponse,
  deserializeDocumentInstanceResponse,
  serializeDocumentInstance,
} from './DocumentInstances.mapper'
import getApi from '../../../../api/apisauceConfig'
import { RestuxApiConfig } from '../../../restux/cache/restuxCache.model'
import {
  ApiDocumentInstanceBatchResponse,
  ApiDocumentInstanceCreationResource,
  ApiDocumentInstanceListItemResource,
  ApiDocumentInstanceResource,
} from './DocumentInstances.model'

export const restuxDocumentInstanceApiConfig: RestuxApiConfig<
  DocumentInstanceListItem,
  DocumentInstance
> = {
  url: '/documents',
  getPaginatedItems: (route) =>
    getApi()
      .get<PaginatedList<ApiDocumentInstanceListItemResource>>(route)
      .then(deserializeDocumentInstancePaginatedResponse),
  getItem: (route) =>
    getApi().get<ApiDocumentInstanceResource>(route).then(deserializeDocumentInstanceResponse),
  deleteItem: (route) => getApi().delete(route),
  updateItem: (route, item) =>
    getApi()
      .put<ApiDocumentInstanceResource>(route, serializeDocumentInstance(item))
      .then(deserializeDocumentInstanceResponse),
}

export const batchDocumentInstances = (
  route: string,
  documents: Array<ApiDocumentInstanceCreationResource>,
) => getApi().post<ApiDocumentInstanceBatchResponse>(route, { documents })
