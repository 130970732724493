import { FunctionComponent, useMemo } from 'react'
import { TaskDocumentStepSelectProps } from './TaskDocumentStepSelect.model'
import { Icon, SelectInput, Tag, TooltipWrapper } from '../../../../../shared'
import {
  TASK_DOCUMENT_STEP_ICON,
  TASK_DOCUMENT_STEP_LABEL,
  TASK_DOCUMENT_STEP_OPTIONS,
} from '../../TaskTableRow.model'
import styles from './TaskDocumentStepSelect.module.scss'

export const TaskDocumentStepSelect: FunctionComponent<TaskDocumentStepSelectProps> = ({
  documentStep,
  isEditModeEnabled,
  setDocumentStep,
}) => {
  const documentStepValue = useMemo(
    () => TASK_DOCUMENT_STEP_OPTIONS.find((localCategory) => documentStep === localCategory.value),
    [documentStep],
  )

  return (
    <div className={styles.container}>
      {isEditModeEnabled ? (
        <SelectInput
          placeholder="Étapes"
          options={TASK_DOCUMENT_STEP_OPTIONS}
          value={documentStepValue}
          onSelect={(documentStep) => setDocumentStep(documentStep.value)}
        />
      ) : (
        <Tag key={documentStep} ellipsis={false}>
          <div className={styles.displayValueWrapper}>
            <div className={styles.iconWrapper}>
              {documentStep && <Icon size="nano" icon={TASK_DOCUMENT_STEP_ICON[documentStep]} />}
            </div>
            <div>
              <TooltipWrapper
                content={
                  <div className={styles.tooltipWrapperContent}>
                    {documentStep ? TASK_DOCUMENT_STEP_LABEL[documentStep] : ''}
                  </div>
                }
                pointerDirection="bottom"
              >
                <div className={styles.tooltipChildrenWrapper}>
                  {documentStep ? TASK_DOCUMENT_STEP_LABEL[documentStep] : ''}
                </div>
              </TooltipWrapper>
            </div>
            <div className={styles.iconSelectWrapper}>
              <Icon size="femto" icon="chevron" />
            </div>
          </div>
        </Tag>
      )}
    </div>
  )
}
