import React, { ReactNode } from 'react'

import { ColorsV2 } from '../../../../../design-system/colors_v2'
import { formatStringFr } from '../../../../../misc/date.utilities'

import { Icon } from '../../../../../components/shared'

import { DocumentWithRevision, isDocumentHistory } from '../../../../../model/DocumentHistory'

import styles from './SearchDocumentHistoryItem.module.scss'

type SearchDocumentHistoryItemProps<Item extends DocumentWithRevision> = {
  item: Item
  renderAction: (item: Item) => ReactNode
}

export function SearchDocumentHistoryItem<Item extends DocumentWithRevision>({
  item,
  renderAction,
}: SearchDocumentHistoryItemProps<Item>) {
  const icon = isDocumentHistory(item) ? 'save_disk' : 'documentText'
  const label = isDocumentHistory(item) ? 'Révision' : 'Impressions & Téléchargements'
  return (
    <div className={styles.container}>
      <div className={styles.infos}>
        <Icon icon={icon} size="micro" color={ColorsV2.dsDark4} />
        <span className={styles.type}>{label}</span>
        <span className={styles.date}>{formatStringFr(item.createdAt, 'dd MMMM yyyy, HH:mm')}</span>
      </div>
      <div className={styles.actions}>
        <div className={styles.versionning}>{renderAction(item)}</div>
      </div>
    </div>
  )
}
