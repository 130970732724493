import { useCallback } from 'react'
import { EMPTY_DEFAULT_ADDRESS } from '../../../components/patient/PatientForm/PatientForm.model'
import { PendingDataFieldsValues } from '../../../components/patient/PatientInfos/PatientGeneralInfos/DoctolibPatientInfoUpdateModal/DoctolibPatientInfoUpdateModal.model'
import { PatientMutationPayload } from '../../../data/patients/mapper/patients.model'
import { isAddressPart } from '../../../misc/patient.utilities'
import { Address } from '../../../model/Address'
import { Patient } from '../../../model/Patient'
import { useDeletePendingFollowPatientData } from '../../queries/doctolib'
import { useUpdatePatient } from '../../queries/patients'

const handleAdressMapping = (
  dataToUpdate: PendingDataFieldsValues,
  payload: PatientMutationPayload,
  address: Address,
) => {
  const subAddressField = dataToUpdate.field.replace('address.', '')
  if (subAddressField in address) {
    address[subAddressField] = dataToUpdate.suggestedValue
    payload.address = address
  }
}

export const useDoctolibPendingChangesActions = (patient?: Patient | Partial<Patient> | null) => {
  const { mutate: updatePatient } = useUpdatePatient()
  const { mutate: deletePendingFollowPatientData } = useDeletePendingFollowPatientData()

  const handleClose = useCallback(() => {
    if (!patient) return
    deletePendingFollowPatientData({ patient: patient as Patient })
  }, [patient, deletePendingFollowPatientData])

  const handleSubmit = useCallback(
    (selectedPendingData: PendingDataFieldsValues[]) => {
      if (!patient?.id) return

      const payload: PatientMutationPayload = {}
      const address: Address = patient.address
        ? { ...patient.address }
        : { ...EMPTY_DEFAULT_ADDRESS }

      selectedPendingData.forEach((dataToUpdate) => {
        isAddressPart(dataToUpdate.field)
          ? handleAdressMapping(dataToUpdate, payload, address)
          : (payload[dataToUpdate.field] = dataToUpdate.suggestedValue)
      })

      updatePatient(
        { id: patient.id, payload: { ...patient, ...payload } },
        { onSuccess: (patient) => deletePendingFollowPatientData({ patient }) },
      )
    },
    [deletePendingFollowPatientData, patient, updatePatient],
  )

  return { handleClose, handleSubmit }
}
