import { useMemo } from 'react'
import { MultiSelectOption, MultiSelectOptionGroup } from '../../../model/SelectOption'
import { ContactMssEmail, ContactMssEmailOption } from '../../../model/ContactMssEmail'
import {
  mapContactMssEmailToContactMssEmailOption,
  MapContactMssEmailToContactMssEmailOptionParams,
  mapPatientContactMssEmailToContactMssEmailOption,
} from '../../../misc/contactMssEmail.utilities'
import { PatientContactMssEmail } from '../../../model/PatientContactMssEmail'
import { RecipientSuggestion } from '../../../model/RecipientSuggestion'

export const useContactMssEmailOptions = (
  recipientSuggestion: RecipientSuggestion | undefined,
  addressInput: string,
  emptyContactMssEmail: ContactMssEmail,
) =>
  useMemo(() => {
    const contactMssEmailGroups: MultiSelectOptionGroup[] = []
    const contactMssEmailOptions: MultiSelectOption<ContactMssEmailOption>[] = []

    if (!recipientSuggestion) {
      return { contactMssEmailGroups, contactMssEmailOptions }
    }

    const addContactMssGroupAndOptions = (
      group: MultiSelectOptionGroup,
      contactMssEmails?: ContactMssEmail[] | null,
      mapContactMssEmailToContactMssEmailOptionParams?: MapContactMssEmailToContactMssEmailOptionParams,
      patientContactMssEmail?: PatientContactMssEmail | null,
    ) => {
      if (!contactMssEmails && !patientContactMssEmail && !group.emptyOptionsMessage) return

      contactMssEmailGroups.push(group)

      if (patientContactMssEmail) {
        contactMssEmailOptions.push({
          label: patientContactMssEmail.mssEmailAddress,
          value: mapPatientContactMssEmailToContactMssEmailOption(patientContactMssEmail),
          groupKey: group.key,
        })
      }
      if (contactMssEmails) {
        contactMssEmailOptions.push(
          ...contactMssEmails.map((contactMssEmail) => ({
            label: contactMssEmail.mssEmailAddress,
            value: mapContactMssEmailToContactMssEmailOption(
              contactMssEmail,
              mapContactMssEmailToContactMssEmailOptionParams,
            ),
            groupKey: group.key,
          })),
        )
      }
    }

    addContactMssGroupAndOptions(
      { key: 'manual_entry' },
      addressInput.length > 0 ? [{ ...emptyContactMssEmail, mssEmailAddress: addressInput }] : null,
      { isEmptyContact: true },
    )
    addContactMssGroupAndOptions(
      {
        label: 'Contacts patient',
        key: 'patient_contacts',
      },
      recipientSuggestion.patientContacts,
      undefined,
      recipientSuggestion.patientContact,
    )
    addContactMssGroupAndOptions(
      {
        label: 'Contacts fréquents',
        key: 'frequent_contacts',
      },
      recipientSuggestion.frequentContacts,
    )
    addContactMssGroupAndOptions(
      {
        label: 'Résultats Annuaire Santé et contacts privés',
        key: 'contacts',
        emptyOptionsMessage: !recipientSuggestion.contacts
          ? 'Taper au moins deux caractères pour lancer la recherche'
          : 'Aucun résultat trouvé',
      },
      recipientSuggestion.contacts,
    )

    return { contactMssEmailGroups, contactMssEmailOptions }
  }, [addressInput, recipientSuggestion, emptyContactMssEmail])
