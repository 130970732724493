import { isDefined } from '@follow/cdk'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getExistingPatientFromMail } from '../../../misc/insi.utilities'
import { MailPatient } from '../../../model/Mail'
import { Patient } from '../../../model/Patient'
import { FeaturesType } from '../../../model/Preference'
import { addValid } from '../../../store/message'
import { useActionDispatch } from '../../utils'
import { useUserEnabledFeature } from '../../utils/user'
import { useInsiIdentityCheck } from '../insi'
import { useUpdatePatient } from '../patients'
import { patientsKeys } from '../patients/patients.keys'
import { mailKeys } from './mail.keys'

interface UseUpdatePatientFromMailVariables {
  patientId: number
  patient: MailPatient
  mailId: string
  bypassCheck?: boolean
}

export const useUpdatePatientFromMail = () => {
  const queryClient = useQueryClient()
  const dispatchValid = useActionDispatch(addValid)

  const insiService = useUserEnabledFeature(FeaturesType.insiValidation)
  const mssExtractPatient = useUserEnabledFeature(FeaturesType.mssExtractPatient)

  const { mutateAsync: identityCheck } = useInsiIdentityCheck()
  const { mutateAsync: updatePatient } = useUpdatePatient()

  const mutation = useMutation<Patient | null, Error, UseUpdatePatientFromMailVariables>({
    mutationFn: async ({ patient, patientId, bypassCheck }) => {
      if (bypassCheck || (!insiService && mssExtractPatient)) {
        return await updatePatient({
          id: patientId,
          payload: patient,
        })
      }

      const identityCheckResult = await identityCheck({ patient })

      if (!isDefined(identityCheckResult)) return null

      if (insiService === 'insiValidationRest') {
        return await updatePatient({
          id: patientId,
          payload: patient,
          params: { external: true, forceInsiCheck: true },
        })
      } else {
        return await updatePatient({
          id: patientId,
          payload: getExistingPatientFromMail(patient),
        })
      }
    },
    onSuccess: (data, { mailId, patientId }) => {
      if (!isDefined(data)) return null

      dispatchValid('Patient mis à jour')
      queryClient.invalidateQueries({ queryKey: mailKeys.detail(mailId) })
      queryClient.invalidateQueries({ queryKey: patientsKeys.lists })
      queryClient.invalidateQueries({ queryKey: patientsKeys.detail(patientId) })
    },
  })

  return mutation
}
