import { DrugIdentity } from '../../../model/Drug'
import { Filters } from '../../../model/Filters'

const all = ['drugs']
const detail = (drug: DrugIdentity | null) => [...all, drug?.type, drug?.id]
const lists = [...all, 'list']
const list = (filters: Filters | null) => [...lists, filters]

const activePrinciples = [...all, 'activePrinciples']
const activePrinciplesList = (search: string) => [...activePrinciples, search]

const indications = [...all, 'indications']
const indicationsList = (search: string) => [...indications, search]

const atcClass = [...all, 'atcClass']
const atcClassLists = [...atcClass, 'list']
const atcClassList = (search?: string) => [...atcClassLists, search]
const atcClassDetail = (id: number) => [...atcClass, id]
const atcClassDetailChildren = (id: number) => [...atcClass, id, 'children']

const sideEffects = [...all, 'sideEffects']
const sideEffectsList = (search: string) => [...sideEffects, search]

export const drugsKeys = {
  all,
  detail,
  lists,
  list,
  activePrinciples,
  activePrinciplesList,
  indications,
  indicationsList,
  atcClass,
  atcClassLists,
  atcClassList,
  atcClassDetail,
  atcClassDetailChildren,
  sideEffects,
  sideEffectsList,
}
