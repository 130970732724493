import { DocumentCategoryKeys, FollowTypes } from '@follow/cdk'
import { ColorsV2 } from '../design-system/colors_v2'
import { IconsType } from '../components/shared'

export type DocumentCategory = FollowTypes.DocumentCategory.DocumentCategory

export { DocumentCategoryKeys }

export enum BizoneMarker {
  ALD = 'aldEnd',
  NON_ALD = 'nonAldEnd',
}

export const DocumentCategoryIcon: { [property in DocumentCategoryKeys]: IconsType } = {
  [DocumentCategoryKeys.ConsultationNote]: `stethoscope`,
  [DocumentCategoryKeys.DischargeSummaryNote]: `out`,
  [DocumentCategoryKeys.PrivacyPolicyAcknowledgment]: `fingerPrint`,
  [DocumentCategoryKeys.SurgicalOperationNote]: `scalpel`,
  [DocumentCategoryKeys.ReferralNote]: `stethoscope`,
  [DocumentCategoryKeys.PrescriptionForMedication]: `pills`,
  [DocumentCategoryKeys.BizonePrescriptionForMedication]: `pills`,
  [DocumentCategoryKeys.Certificate]: `fileChecked`,
  [DocumentCategoryKeys.Quotation]: `calculator`,
  [DocumentCategoryKeys.Checkup]: `stethoscope`,
  [DocumentCategoryKeys.Other]: `documentText`,
  [DocumentCategoryKeys.PdfForm]: 'fileInstance',
  [DocumentCategoryKeys.Survey]: 'chat',
  [DocumentCategoryKeys.ReceivedDocument]: 'receivedDocument',
  [DocumentCategoryKeys.MedicalSummary]: 'mailbox',
  [DocumentCategoryKeys.RequestForTelehealth]: 'camera',
  [DocumentCategoryKeys.FacilityDischargeSummaryNote]: 'mail',

  [DocumentCategoryKeys.PrescriptionForFootCare]: 'pills',
  [DocumentCategoryKeys.PrescriptionForInfirmaryAct]: 'pills',
  [DocumentCategoryKeys.PrescriptionForMedicalBiology]: 'pills',
  [DocumentCategoryKeys.PrescriptionForOrthoptics]: 'pills',
  [DocumentCategoryKeys.PrescriptionForPhysicalTherapy]: 'pills',
  [DocumentCategoryKeys.PrescriptionForSpeechTherapy]: 'pills',
}

export const DocumentCategoryColor: { [property in DocumentCategoryKeys]: ColorsV2 } = {
  [DocumentCategoryKeys.ConsultationNote]: ColorsV2.dsPrimaryDark,
  [DocumentCategoryKeys.DischargeSummaryNote]: ColorsV2.dsSecondaryDark,
  [DocumentCategoryKeys.PrivacyPolicyAcknowledgment]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.SurgicalOperationNote]: ColorsV2.dsSecondaryDark,
  [DocumentCategoryKeys.ReferralNote]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForMedication]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.BizonePrescriptionForMedication]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.Certificate]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.Quotation]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.Checkup]: ColorsV2.dsDark1,
  [DocumentCategoryKeys.Other]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.PdfForm]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.Survey]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.ReceivedDocument]: ColorsV2.backgroundBlue6,

  [DocumentCategoryKeys.FacilityDischargeSummaryNote]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.MedicalSummary]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForFootCare]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForInfirmaryAct]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForMedicalBiology]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForOrthoptics]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForPhysicalTherapy]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.PrescriptionForSpeechTherapy]: ColorsV2.backgroundBlue6,
  [DocumentCategoryKeys.RequestForTelehealth]: ColorsV2.backgroundBlue6,
}
