import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { questionTemplatesKeys } from './questionTemplate.keys'
import { getQuestionTemplate } from '../../../data/questionTemplates'

interface GetQuestionTemplateParams {
  id: number
  enabled?: boolean
}

export const useGetQuestionTemplate = ({ id, enabled = true }: GetQuestionTemplateParams) => {
  const queryKey = useMemo(() => questionTemplatesKeys.detail(id), [id])

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await getQuestionTemplate(id)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la question`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
