import getApi from '../../api/apisauceConfig'
import { singleItemPageLimit } from '../../constants'
import { AtcClass } from '../../model/Drug'
import { PaginatedList } from '../../model/Pagination'

export const getAtcClasses = (
  page: number,
  search?: string,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<AtcClass>>('/atc_classifications', { page, limit, search }, { signal })
