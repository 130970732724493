import { useQueryClient, useMutation } from '@tanstack/react-query'
import { UiQuoteline } from '../../../../components/ccam/QuoteLineForm/QuoteLineForm.model'
import { updateQuoteLine } from '../../../../data/ccam/quoteLines'
import { UserFriendlyError } from '../../../../model/Error'
import { documentInstancesActions } from '../../../../store/cache/documentInstances'
import { documentTemplatesActions } from '../../../../store/cache/documentTemplates'
import {
  medicalEventContentUiActions,
  SelectedContentType,
} from '../../../../store/ui/medicalEvents/medicalEventContent'
import { useActionDispatch } from '../../../utils'
import { actesKeys } from '../actes/actes.keys'

interface UpdateQuoteLineVariables {
  quoteLineId: string
  quoteLinePayload: UiQuoteline
  documentInstanceId?: number
  documentTemplateId?: number
}

export const useUpdateQuoteLine = () => {
  const queryClient = useQueryClient()
  const dispatchGetDocInstanceAction = useActionDispatch(
    documentInstancesActions.actions.apiGetItemDetails,
  )
  const dispatchGetDocTemplateAction = useActionDispatch(
    documentTemplatesActions.actions.apiGetItemDetails,
  )
  const dispatchSelectMedicalEventAction = useActionDispatch(
    medicalEventContentUiActions.selectMedicalEventContent,
  )

  const mutate = useMutation({
    mutationFn: async ({ quoteLineId, quoteLinePayload }: UpdateQuoteLineVariables) => {
      const { data, ok } = await updateQuoteLine(quoteLineId, quoteLinePayload)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'La sauvegarde de la configuration du code CCAM a échoué',
        )
      }
      return null
    },

    onSuccess: (_, { quoteLineId, documentInstanceId, documentTemplateId }) => {
      queryClient.invalidateQueries({ queryKey: actesKeys.detail(quoteLineId) })
      documentInstanceId && dispatchGetDocInstanceAction(documentInstanceId)
      documentTemplateId && dispatchGetDocTemplateAction(documentTemplateId)
      dispatchSelectMedicalEventAction({
        type: SelectedContentType.EDITOR,
      })
    },
  })
  return mutate
}
