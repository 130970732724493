import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { updateManualPrescription } from '../../../data/manualPrescriptions'
import { UserFriendlyError } from '../../../model/Error'
import { ManualPrescription } from '../../../model/ManualPrescription'
import { documentInstancesActions } from '../../../store/cache/documentInstances'
import { documentTemplatesActions } from '../../../store/cache/documentTemplates'
import { getVariableData } from '../../../store/renderer'
import { patientManualPrescriptionsKeys } from '../patientManualPrescriptions'

interface UseUpdateManualPrescriptionsVariables {
  id: string
  manualPrescription: Partial<ManualPrescription>
}

export const useUpdateManualPrescriptions = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation<ManualPrescription, Error, UseUpdateManualPrescriptionsVariables>({
    mutationFn: async ({ id, manualPrescription }) => {
      const { ok, data } = await updateManualPrescription(id, manualPrescription)

      if (!ok || !data) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la mise à jour de la prescription manuelle',
        )
      }

      return data
    },
    onSuccess: (data) => {
      if (data.patientId) {
        queryClient.invalidateQueries({
          queryKey: patientManualPrescriptionsKeys.list(data.patientId),
        })
      }
      if (data.documentId) {
        dispatch(getVariableData())
        dispatch(documentInstancesActions.actions.apiGetItemDetails(data.documentId))
      }
      if (data.documentTemplateId) {
        dispatch(documentTemplatesActions.actions.apiGetItemDetails(data.documentTemplateId))
      }
    },
  })
}
