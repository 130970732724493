import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  DatePicker,
  GridElement,
  GridLayout,
  MultiSelectSearch,
  SearchInput,
  SelectSearch,
  Switch,
} from '../../shared'
import { TASK_CATEGORY_OPTIONS } from '../TaskTable/TaskTableRow/TaskTableRow.model'
import { TaskCategory } from '../../../model/Task'
import { MultiSelectOption, SelectOption } from '../../../model/SelectOption'
import { LightPatient } from '../../../model/Patient'
import { useDebounce } from 'react-use'
import { TaskListFiltersProps } from './TaskListFilters.model'
import { TaskPageContentFiltersContext } from '../TaskPageContent/TaskPageContent.context'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { isDoctor } from '../../../misc/user.utilities'
import { addTestIdSuffix } from '../../../misc/testId.utilities'
import { useGetInfinitePatient } from '../../../hooks/queries/patients'
import { isEqual } from 'lodash'
import { useUserEnabledFeature } from '../../../hooks/utils/user'
import { FeaturesType } from '@follow/cdk'

const PATIENT_FILTERS_INITIAL_STATE = { search: '' }

export const TaskListFilters: FunctionComponent<TaskListFiltersProps> = ({ testId }) => {
  const [patientSearchText, setPatientSearchText] = useState('')
  const [titleSearch, setTitleSearch] = useState('')
  const [patientFilters, setPatientFilters] = useState(PATIENT_FILTERS_INITIAL_STATE)
  const [patients, setPatients] = useState<LightPatient[]>([])
  const { filters, setFilters } = useContext(TaskPageContentFiltersContext)
  const { currentUser } = useContext(ConnectedUserContext)
  const {
    query: { isLoading },
    patientList,
  } = useGetInfinitePatient({
    enabled: !isEqual(patientFilters, PATIENT_FILTERS_INITIAL_STATE),
    filters: patientFilters,
  })

  useEffect(() => {
    if (!isLoading) {
      setPatients(patientList)
    }
  }, [patientList, isLoading])

  const patientOptions = useMemo(
    () =>
      !isEqual(patientFilters, PATIENT_FILTERS_INITIAL_STATE)
        ? patients.map((patient) => ({
            value: patient,
            label: patient.identity,
          }))
        : [],
    [patientFilters, patients],
  )

  const displayAutocreatedFilter = useMemo(() => {
    if (!currentUser || !isDoctor(currentUser)) return false
    const {
      preferences: { enabledFeatures },
    } = currentUser
    return enabledFeatures.insiValidation || enabledFeatures.cda
  }, [currentUser])

  useDebounce(
    () =>
      setPatientFilters((prev) => {
        return patientSearchText.length >= 3
          ? { ...prev, search: patientSearchText }
          : { ...prev, search: PATIENT_FILTERS_INITIAL_STATE.search }
      }),
    500,
    [setPatientFilters, patientSearchText],
  )

  useDebounce(() => setFilters({ title: titleSearch }), 500, [titleSearch])

  const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleSearch(event.target.value)
  }, [])

  const handleCategoryChange = useCallback(
    (selected: MultiSelectOption<TaskCategory>[]) => {
      setFilters({ types: selected })
    },
    [setFilters],
  )

  const handlePatientChange = useCallback(
    (selected: SelectOption<LightPatient> | undefined) => {
      setFilters({ patient: selected ?? null })
      if (selected) {
        setPatientSearchText(selected.label)
      } else {
        setPatientSearchText(PATIENT_FILTERS_INITIAL_STATE.search)
      }
    },
    [setFilters],
  )

  const handleHideAutoCreatedChange = useCallback(
    (selected: boolean) => {
      setFilters({ hideAutoCreated: selected })
    },
    [setFilters],
  )

  const handleAssignedToMeChange = useCallback(
    (selected: boolean) => {
      setFilters({ assignedToMe: selected })
    },
    [setFilters],
  )

  const handleStartDateChange = useCallback(
    (date: Date | null) => {
      setFilters({ startDate: date })
    },
    [setFilters],
  )

  const handleEndDateChange = useCallback(
    (date: Date | null) => {
      setFilters({ endDate: date })
    },
    [setFilters],
  )

  const hasDocumentTaskEnabled = useUserEnabledFeature(FeaturesType.documentTask)

  return (
    <GridLayout
      columnsTemplate="2fr 2fr 2fr 1fr 1fr"
      gap="medium"
      rowsOnMobile={2}
      data-test-id={addTestIdSuffix('root', testId)}
    >
      <SearchInput
        value={titleSearch}
        placeholder="Rechercher par nom"
        onChange={handleNameChange}
        testId={addTestIdSuffix('search', testId)}
      />
      <MultiSelectSearch<TaskCategory>
        placeholder="Rechercher par catégorie"
        icon="tag"
        options={TASK_CATEGORY_OPTIONS}
        value={filters.types}
        onSelect={handleCategoryChange}
        testId={addTestIdSuffix('category-select', testId)}
      />
      <SelectSearch<LightPatient>
        placeholder="Rechercher par patient"
        textValue={patientSearchText}
        onTextChange={setPatientSearchText}
        value={filters.patient ?? undefined}
        options={patientOptions}
        onSelect={handlePatientChange}
        testId={addTestIdSuffix('patient-select', testId)}
        clearable
      />
      <DatePicker
        label="Début"
        icon="calendar"
        colorPreset="light"
        placeholder="Début"
        value={filters.startDate}
        onChange={handleStartDateChange}
        inputType="search"
        showCalendarButton={false}
        testId={addTestIdSuffix('start-at-input', testId)}
      />
      <DatePicker
        label="Fin"
        icon="calendar"
        colorPreset="light"
        placeholder="Fin"
        value={filters.endDate}
        onChange={handleEndDateChange}
        inputType="search"
        showCalendarButton={false}
        testId={addTestIdSuffix('end-at-input', testId)}
      />
      {displayAutocreatedFilter && (
        <GridElement
          gridColumn="1 / -1"
          className="w-fit font-medium text-shades-2"
          justifySelf="end"
        >
          {hasDocumentTaskEnabled && (
            <div className="mb-2">
              <Switch
                checked={filters.assignedToMe}
                onChange={handleAssignedToMeChange}
                name="Tâches qui me sont assignées"
              />
            </div>
          )}

          <Switch
            checked={filters.hideAutoCreated}
            onChange={handleHideAutoCreatedChange}
            name="Masquer les tâches créées automatiquement"
          />
        </GridElement>
      )}
    </GridLayout>
  )
}
