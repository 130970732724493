import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { medicalEventDomainActions } from '../../../store/domain/medicalEvents'
import {
  dmpDomainActions,
  dmpIframeStatusSelector,
  dmpRequestsSelector,
} from '../../../store/domain/dmp'
import { addError, addValid } from '../../../store/message'
import { EfficienceIframe } from './EfficienceIframe.component'
import { EfficienceIframeDispatchProps, EfficienceIframeStateProps } from './EfficienceIframe.model'
import { metricsActions } from '../../../store/domain/metrics'

const mapStateToProps = (state: RootState): EfficienceIframeStateProps => ({
  requests: dmpRequestsSelector(state),
  iframeStatus: dmpIframeStatusSelector(state),
})

const mapDispatchToProps: EfficienceIframeDispatchProps = {
  updateDocument: medicalEventDomainActions.updateDocumentThenFetchEvent,
  updateFile: medicalEventDomainActions.updateFileThenFetchEvent,
  closeDmpSession: dmpDomainActions.closeDmpSession,
  addValid: addValid,
  addError: addError,
  sendDmpMetrics: metricsActions.sendDmpMetrics,
  setIframeStatus: dmpDomainActions.setIframeStatus,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(EfficienceIframe)
export { withConnect as EfficienceIframe }
