import { FC, useMemo, useState } from 'react'
import { CountryCodeOptionProps } from './CountryCodeOption.model'
import styles from './CountryCodeOption.module.scss'
import { FLAG_CDN_BASEURL } from '../../../../../../../model/Phone'
import { DropdownItem } from '../../../../../dropdown'
import { CUSTOM_COUNTRY_CODE } from '../FormikInternationalPhoneInput.model'
import { Icon } from '../../../../../Icon'
import { getV3ColorCssVariable } from '../../../../../../../design-system/colors_v3'

export const CountryCodeOption: FC<CountryCodeOptionProps> = ({
  option,
  currentValue,
  isHovered,
}) => {
  const [imgLoadFailed, setImgLoadFailed] = useState(false)

  const url = useMemo(() => {
    if (option.value.countryCode === CUSTOM_COUNTRY_CODE || imgLoadFailed) {
      return undefined
    } else {
      return `${FLAG_CDN_BASEURL}/h20/${option.value.countryCode.toLocaleLowerCase()}.jpg`
    }
  }, [imgLoadFailed, option.value.countryCode])

  const highlight = isHovered || currentValue?.value.countryCode === option.value.countryCode

  return (
    <DropdownItem selected={highlight} layout="thin">
      <div className={styles.option}>
        {option.value.countryCode === CUSTOM_COUNTRY_CODE || imgLoadFailed ? (
          <div className={styles.img}>
            <Icon
              size="nano"
              icon="map"
              color={getV3ColorCssVariable('shades', highlight ? 'white' : 'shade2')}
            />
          </div>
        ) : (
          <img
            className={styles.img}
            alt={option.value.label}
            loading="lazy"
            src={url}
            onError={() => setImgLoadFailed(true)}
          />
        )}
        <div>
          <span>{option.value.label}</span>
          <span className={styles.phoneCode}>+{option.value.phoneCode}</span>
        </div>
      </div>
    </DropdownItem>
  )
}
