import getApi from '../../api'
import { Drug } from '../../model/Drug'
import { PosologyFormResource, PosologyPreview } from '../../model/Posology'

export const generatePrescriptionPreview = (
  posologyIntervals: Array<PosologyFormResource>,
  drugs: Array<Drug>,
  signal?: AbortSignal,
) =>
  getApi().post<PosologyPreview>(`prescriptions/preview`, { drugs, posologyIntervals }, { signal })
