import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { TaskSection } from './TaskSection'
import { TaskEmptyState } from './TaskEmptyState'
import {
  TaskPageContentProps,
  TaskPageFiltersState,
  defaultTaskPageFilters,
} from './TaskPageContent.model'
import { TaskInformation, TaskStatus } from '../../../model/Task'
import { TaskListFilters } from '../TaskListFilters'
import { CdaContext } from '../../../model/CdaContext'
import { AnalysisResultModal } from '../../cda'
import { useLocalStorage } from 'react-use'
import {
  TaskPageContentFiltersContext,
  TaskPageContentFiltersContextType,
} from './TaskPageContent.context'
import { addTestIdSuffix } from '../../../misc/testId.utilities'
import { TaskInformationModal } from './TaskInformationModal'
import { TaskTableColumn } from '../TaskTable/TaskTable.model'
import { useUserEnabledFeature } from '../../../hooks/utils/user'
import { FeaturesType } from '@follow/cdk'

export const TaskPageContent: FunctionComponent<TaskPageContentProps> = ({
  tasksTodo,
  tasksDone,
  tasksDocument,
  loading,
  getTasks,
  onCreateOrUpdate,
  onDeleteTask,
  newTask,
  removeNewTask,
  onDeleteAllDoneTasks,
  testId,
}) => {
  const [filters, setFilters] = useState<TaskPageFiltersState>(defaultTaskPageFilters)
  const [hideAutoCreated, setHideAutoCreated] = useLocalStorage<boolean>(
    'task-hide-autocreated',
    false,
  )

  const [assignedToMe, setAssignedToMe] = useLocalStorage<boolean>('task-assigned-to-me', false)

  const contextValue: TaskPageContentFiltersContextType = useMemo(
    () => ({
      filters: { ...filters, hideAutoCreated, assignedToMe },
      setFilters: ({
        hideAutoCreated: hideAutoCreatedParam,
        assignedToMe: assignedToMeParam,
        ...params
      }) => {
        setFilters((oldFilters) => ({ ...oldFilters, ...params }))
        setHideAutoCreated(hideAutoCreatedParam ?? hideAutoCreated)
        setAssignedToMe(assignedToMeParam ?? assignedToMe)
      },
    }),
    [assignedToMe, filters, hideAutoCreated, setAssignedToMe, setHideAutoCreated],
  )

  useEffect(() => {
    getTasks({ ...filters, hideAutoCreated, assignedToMe })
  }, [filters, getTasks, hideAutoCreated, assignedToMe])

  const totalTaskItem = useMemo(
    () => tasksTodo.filter((task) => task.id !== undefined).length,
    [tasksTodo],
  )

  const totalTaskDocumentItem = useMemo(
    () => tasksDocument.filter((task) => task.id !== undefined).length,
    [tasksDocument],
  )

  const totalItemCount = useMemo(
    () => totalTaskItem + tasksDone.length + totalTaskDocumentItem,
    [totalTaskItem, tasksDone.length, totalTaskDocumentItem],
  )

  const tasksDoneFiltered = useMemo(() => tasksDone.filter((task) => !task.document), [tasksDone])

  const tasksDocumentDone = useMemo(() => tasksDone.filter((task) => task.document), [tasksDone])

  const [taskInformation, setTaskInformation] = useState<TaskInformation[] | undefined>()
  const [cdaContext, setCdaContext] = useState<CdaContext>()

  const handleOpenTaskInformation = useCallback((taskInformation: TaskInformation[]) => {
    setTaskInformation(taskInformation)
  }, [])

  const handleOpenCdaInformation = useCallback((cdaContext: CdaContext) => {
    setCdaContext(cdaContext)
  }, [])

  const hasDocumentTaskEnabled = useUserEnabledFeature(FeaturesType.documentTask)

  return (
    <TaskPageContentFiltersContext.Provider value={contextValue}>
      <div className="p-6" data-test-id={addTestIdSuffix('root', testId)}>
        <div className="mb-5">
          <TaskListFilters testId={addTestIdSuffix('filters', testId)} />
        </div>
        {hasDocumentTaskEnabled && (
          <>
            <TaskSection
              tasksStatus={TaskStatus.TODO}
              onlyDocumentLinked
              itemCount={totalTaskDocumentItem}
              totalItemCount={totalItemCount}
              tasks={tasksDocument}
              displayColumns={[
                TaskTableColumn.Document,
                TaskTableColumn.Patient,
                TaskTableColumn.DocumentStep,
                TaskTableColumn.AssignedTo,
                TaskTableColumn.CreatedAt,
                TaskTableColumn.Actions,
              ]}
              onCreateOrUpdate={onCreateOrUpdate}
              onDeleteTask={onDeleteTask}
              removeNewTask={removeNewTask}
              openTaskInformation={handleOpenTaskInformation}
              openCdaInformation={handleOpenCdaInformation}
              testId="task-page-section-document"
            />
            <TaskSection
              tasksStatus={TaskStatus.DONE}
              itemCount={tasksDocumentDone.length}
              totalItemCount={totalItemCount}
              onlyDocumentLinked
              displayColumns={[
                TaskTableColumn.Document,
                TaskTableColumn.Patient,
                TaskTableColumn.DocumentStep,
                TaskTableColumn.AssignedTo,
                TaskTableColumn.CreatedAt,
                TaskTableColumn.Actions,
              ]}
              tasks={tasksDocumentDone}
              onCreateOrUpdate={onCreateOrUpdate}
              onDeleteTask={onDeleteTask}
              onDeleteAllDoneTasks={onDeleteAllDoneTasks}
              testId="task-page-section-done"
            />
          </>
        )}
        <TaskSection
          tasksStatus={TaskStatus.TODO}
          itemCount={totalTaskItem}
          totalItemCount={totalItemCount}
          tasks={tasksTodo}
          displayColumns={[
            TaskTableColumn.Title,
            TaskTableColumn.Category,
            TaskTableColumn.Patient,
            TaskTableColumn.CreatedAt,
            TaskTableColumn.Actions,
          ]}
          onCreateOrUpdate={onCreateOrUpdate}
          onDeleteTask={onDeleteTask}
          removeNewTask={removeNewTask}
          openTaskInformation={handleOpenTaskInformation}
          openCdaInformation={handleOpenCdaInformation}
          testId="task-page-section-todo"
        />
        {tasksTodo.length === 0 &&
          tasksDone.length === 0 &&
          tasksDocument.length === 0 &&
          !assignedToMe &&
          !loading && (
            <div className="mt-4 mb-4">
              <TaskEmptyState newTask={newTask} />
            </div>
          )}
        <TaskSection
          tasksStatus={TaskStatus.DONE}
          itemCount={tasksDoneFiltered.length}
          totalItemCount={totalItemCount}
          tasks={tasksDoneFiltered}
          displayColumns={[
            TaskTableColumn.Title,
            TaskTableColumn.Category,
            TaskTableColumn.Patient,
            TaskTableColumn.CreatedAt,
            TaskTableColumn.Actions,
          ]}
          onCreateOrUpdate={onCreateOrUpdate}
          onDeleteTask={onDeleteTask}
          onDeleteAllDoneTasks={onDeleteAllDoneTasks}
          testId="task-page-section-done"
        />
        <TaskInformationModal
          display={!!taskInformation}
          taskInformation={taskInformation}
          onClose={() => setTaskInformation(undefined)}
        />
        <AnalysisResultModal
          display={!!cdaContext}
          onClose={() => setCdaContext(undefined)}
          result={cdaContext?.analysisResult ?? []}
        />
      </div>
    </TaskPageContentFiltersContext.Provider>
  )
}
