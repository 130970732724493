import { useMutation } from '@tanstack/react-query'
import { isDefined } from '../../../misc/functions.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { ATTACHMENT_MAX_SIZE_IN_BYTES } from '../../../model/Mail'
import { fetchFile, fileToBlob } from '../../../store/cache/files/api'

interface UseFetchFileForMailVariables {
  fileId: number
}
interface UseFetchFileForMailParams {
  onSuccess: (file: File) => void
}

const toasterId = 'MAIL_EDITOR_FETCH_FILE_ERROR'

export const useFetchFileForMail = ({ onSuccess }: UseFetchFileForMailParams) => {
  const mutation = useMutation<void, Error, UseFetchFileForMailVariables>({
    mutationFn: async ({ fileId }) => {
      const { data, ok } = await fetchFile(fileId)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Une erreur est survenue lors de la récupération de certains fichiers',
          undefined,
          { id: toasterId },
        )
      }

      if (isDefined(data.size) && data.size >= ATTACHMENT_MAX_SIZE_IN_BYTES) {
        throw new UserFriendlyError(
          'error',
          `Erreur lors de l'ajout de "${data.visibleName}"`,
          'Le fichier dépasse la taille autorisée',
        )
      }

      try {
        const blob = await fileToBlob(data)
        if (!blob) {
          throw new Error(`Blob is empty for file ${data.id} ${data.visibleName}`)
        }
        onSuccess(blob)
      } catch (e) {
        console.error(e)
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le contenu de "${data.visibleName}"`,
        )
      }
    },
  })

  return mutation
}
