import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createMedicalEvent } from '../../../data/medicalEvents'
import { UserFriendlyError } from '../../../model/Error'
import { medicalEventsKeys } from './medicalEvents.keys'
import { MedicalEventMutationPayload } from '../../../data/medicalEvents/mapper/medicalEvents.model'

export const useCreateMedicalEvent = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: MedicalEventMutationPayload) => {
      const { data, ok } = await createMedicalEvent(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', `La création de l'évènement médical a échoué`)
      }
      return data
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
    },
  })
}
