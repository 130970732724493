import { ApiErrorContent } from '../model/Error'
import { Mail } from '../model/Mail'
import { formatFr } from './date.utilities'

export const subCodeTokenExpired = (data: ApiErrorContent, status: number | undefined) => {
  try {
    return status === 400 && data?.detail && JSON.parse(data.detail).statusCode === 401
  } catch (e) {
    return false
  }
}

export const formatMailReply = (mail: Mail) =>
  `<p>
<p>___________________________________________</p>
<p>De: ${mail.nameFrom} &lt;${mail.emailFrom}&gt;</p>
<p>À: "&lt;${mail.emailTo}&gt;"</p>
<p>Envoyé: ${formatFr(new Date(mail.date.date), 'dd/MM/yyyy')} </p>
<p>Objet: ${mail.subject}</p>
<p>${mail.body}</p>
</p>`
    .replace(/\n+/g, '')
    .trim()
