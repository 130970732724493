import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useGetSideEffects } from '../../../hooks/queries/drugs'
import { SelectOption } from '../../../model/SelectOption'
import { DropdownItem, SearchInput } from '../../shared'
import { SideEffectsSelectProps } from './SideEffectsSelect.model'

export const SideEffectsSelect: FunctionComponent<SideEffectsSelectProps> = ({
  setSideEffectSelected,
}) => {
  const [search, setSearch] = useState('')

  const {
    query: { data: sideEffectsList },
  } = useGetSideEffects({ search, enabled: search.length > 2 })

  const sideEffectsOptions = useMemo(() => {
    if (sideEffectsList) {
      const options = sideEffectsList.map(({ externalId, name }) => ({
        value: externalId,
        label: name,
      }))
      return options
    }
  }, [sideEffectsList])

  const handleSelect = useCallback(
    (selected: SelectOption<number>) => {
      setSideEffectSelected({
        externalId: selected.value,
        name: selected.label,
        title: selected.label,
      })
      setSearch(selected.label)
    },
    [setSideEffectSelected],
  )

  const handleChange = (searchValue: string) => {
    setSearch(searchValue)
    if (searchValue.length < 3) {
      setSideEffectSelected(undefined)
    }
  }

  return (
    <SearchInput
      label="Effets indésirables"
      placeholder="3 caractères min"
      colorPreset="dark"
      value={search}
      results={sideEffectsOptions}
      onSelect={handleSelect}
      renderResult={(option, isHovered) => (
        <DropdownItem selected={isHovered}>
          <div className="max-w-full flex items-center whitespace-normal">
            <span className="capitalize">{option.label}</span>
          </div>
        </DropdownItem>
      )}
      onChange={({ target: { value } }) => {
        handleChange(value)
      }}
    />
  )
}
