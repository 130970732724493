import { useCallback, useMemo, useState } from 'react'
import createSearchPanel from '../../../../containers/SearchPanel/SearchPanel.container'
import { DocumentInstanceListItem } from '../../../../model/DocumentInstance'
import { BottomPanel } from '../BottomPanel'
import { SearchMedicalEventDocumentsBottomPanelProps } from './SearchMedicalEventDocumentsBottomPanel.model'
import { DocumentListItem } from '../../../document'
import { Button } from '../../buttons'
import { isRenderableFileExtension } from '../../../../misc/files.utilities'
import { CustomFile } from '../../../../model/File'
import { FileListItem } from '../../../file/FileListItem'

export const SearchMedicalEventDocumentsBottomPanel = ({
  isUnmounting,
  onRequestClose,
  onSelect,
  medicalEvent,
  title,
}: SearchMedicalEventDocumentsBottomPanelProps) => {
  const SearchPanel = createSearchPanel<DocumentInstanceListItem | CustomFile>()
  const [selected, setSelected] = useState<ReadonlyArray<DocumentInstanceListItem | CustomFile>>([])

  const documentItems: ReadonlyArray<DocumentInstanceListItem> = medicalEvent?.documents

  const fileItems: ReadonlyArray<CustomFile> = useMemo(
    () => medicalEvent?.files.filter((file) => isRenderableFileExtension(file.extension)),
    [medicalEvent?.files],
  )

  const isDocumentItem = useCallback(
    (item: DocumentInstanceListItem | CustomFile): item is DocumentInstanceListItem =>
      (documentItems as ReadonlyArray<object>).includes(item),
    [documentItems],
  )

  const isFileItem = useCallback(
    (item: DocumentInstanceListItem | CustomFile): item is CustomFile =>
      (fileItems as ReadonlyArray<object>).includes(item),
    [fileItems],
  )

  const onConfirm = useCallback(
    (document?: DocumentInstanceListItem | CustomFile) => {
      if (document) {
        onSelect({
          documents: isDocumentItem(document) ? [document] : [],
          files: isFileItem(document) ? [document] : [],
        })
      } else {
        onSelect({ documents: selected.filter(isDocumentItem), files: selected.filter(isFileItem) })
      }

      onRequestClose()
    },
    [isDocumentItem, isFileItem, onRequestClose, onSelect, selected],
  )

  const items = [...documentItems, ...fileItems]

  const topBarActions = selected.length > 0 && (
    <Button theme="primary" label="Sélectionner" onClick={() => onConfirm()} />
  )

  const renderItem = useCallback(
    (item: DocumentInstanceListItem | CustomFile) =>
      isDocumentItem(item) ? <DocumentListItem item={item} /> : <FileListItem item={item} />,
    [isDocumentItem],
  )

  return (
    <BottomPanel
      title={title}
      isUnmounting={isUnmounting}
      onRequestClose={onRequestClose}
      actions={topBarActions}
    >
      <SearchPanel
        filterable={false}
        items={items}
        loading={false}
        itemCount={items.length}
        pageCount={1}
        onClearItems={() => setSelected([])}
        onMultiSelectionChange={(selection) => setSelected(selection)}
        onPrimaryAction={(selection) => onConfirm(selection)}
        getItems={() => items}
        renderItem={renderItem}
      />
    </BottomPanel>
  )
}
