import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getMail } from '../../../data/mail'
import { UserFriendlyError } from '../../../model/Error'
import { mailErrorsMessage, mailErrorsTitle } from './mail.errors'
import { mailKeys } from './mail.keys'

interface UseGetMailParams {
  id: string
  enabled?: boolean
}

export const useGetMail = ({ id, enabled }: UseGetMailParams) => {
  const queryKey = useMemo(() => mailKeys.detail(id), [id])

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const { data, ok } = await getMail(id)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorMail,
          mailErrorsMessage.errorWhileFetchingMail,
        )
      }

      return data
    },
    enabled,
  })

  return { query }
}
