import React, { FC, useContext } from 'react'
import styles from './VariableMenu.module.scss'
import { BubbleMenu, useCurrentEditor } from '@tiptap/react'
import { PluginNames } from '@/model/Plugins'
import { VariableEditorContext } from '@/context'

export const VariableMenu: FC = () => {
  const { variableMenu } = useContext(VariableEditorContext)
  const { editor } = useCurrentEditor()

  if (!editor) return null

  return (
    <BubbleMenu
      editor={editor}
      pluginKey={PluginNames.Variable}
      shouldShow={({ editor }) => editor.isActive(PluginNames.Variable)}
      className={styles['menu']}
      tippyOptions={{
        maxWidth: 600,
      }}
    >
      {variableMenu}
    </BubbleMenu>
  )
}
