import React, { FC, useEffect, useMemo } from 'react'
import { VariableEditorProps } from './VariableEditor.model'
import { EditorContent, EditorContext } from '@tiptap/react'
import { TableBubbleMenu, VariableMenu } from '@/plugins'
import { ImageResizeBubbleMenu } from '@/plugins/imageResize/ImageResizeBubbleMenu'
import { Toolbar } from '../Toolbar'
import styles from '../../styles/editor/elfe.module.scss'
import { VariableEditorConfig } from '@/model/Config'
import { VariableEditorContext, EditorConfigContext } from '@/context'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryContent } from '@/components/ErrorBoundaryContent'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const VariableEditor: FC<VariableEditorProps> = ({
  editor,
  config,
  variableMenu,
  variableMap,
  disabled,
  toolbarStyle,
  extraTools,
  sanitizeHtmlVariable,
}) => {
  const variableEditorConfig = useMemo<VariableEditorConfig>(
    () => ({ variableMap, variableMenu, sanitizeHtmlVariable }),
    [sanitizeHtmlVariable, variableMap, variableMenu],
  )

  useEffect(() => {
    editor?.setEditable(!disabled)
  }, [disabled, editor])

  return (
    <div className={cx('elfe-base', 'elfe-container', config.themes)}>
      <EditorContext.Provider value={{ editor }}>
        <VariableEditorContext.Provider value={variableEditorConfig}>
          <EditorConfigContext.Provider value={config}>
            {!disabled && (
              <>
                <Toolbar extraTools={extraTools} toolbarStyle={toolbarStyle} />
                <ImageResizeBubbleMenu />
                <TableBubbleMenu />
                <VariableMenu />
              </>
            )}
            <EditorContent editor={editor} />
          </EditorConfigContext.Provider>
        </VariableEditorContext.Provider>
      </EditorContext.Provider>
    </div>
  )
}

const VariableEditorWithBoundary = withErrorBoundary(VariableEditor, {
  fallbackRender: (props) => <ErrorBoundaryContent {...props} />,
})

export { VariableEditorWithBoundary as VariableEditor }
