import { PluginNames } from '@/model/Plugins'
import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react'
import { VariableWrapper } from './VariableWrapper'
import { VariableAttrbs, VariableDataAttrbs } from '@/model/Variable'

export interface VariableOptions {
  HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.Variable]: {
      /**
       * Adds a variable
       */
      insertVariable: (id: string) => ReturnType

      /**
       * Replace variable id
       */
      updateVariableId: (newVariableId: string) => ReturnType

      /**
       * Sets the display config of the selected variable
       */
      setDisplayConfig: (config: string | undefined) => ReturnType
    }
  }
}

export const Variable = Node.create<VariableOptions>({
  name: PluginNames.Variable,

  atom: true,

  inline: true,

  group: 'inline',

  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      [VariableAttrbs.variableId]: {
        default: null,
        parseHTML: (element) => element.getAttribute(VariableDataAttrbs.variableId),
      },
      [VariableAttrbs.displayConfig]: {
        parseHTML: (element) => element.getAttribute(VariableDataAttrbs.displayConfig),
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs(node) {
          const id = node.getAttribute(VariableDataAttrbs.variableId)
          return !!id && null
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const variableId = HTMLAttributes[VariableAttrbs.variableId]
    const displayconfig = HTMLAttributes[VariableAttrbs.displayConfig]

    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, {
        [VariableDataAttrbs.variableId]: variableId,
        [VariableDataAttrbs.displayConfig]: displayconfig,
      }),
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(VariableWrapper)
  },

  addCommands() {
    return {
      insertVariable:
        (id) =>
        ({ commands }) => {
          return commands.insertContent(
            {
              type: this.name,
              attrs: { [VariableAttrbs.variableId]: id },
            },
            { updateSelection: true },
          )
        },
      setDisplayConfig:
        (config) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { [VariableAttrbs.displayConfig]: config })
        },
      updateVariableId:
        (newVariableId) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, {
            [VariableAttrbs.variableId]: newVariableId,
          })
        },
    }
  },
})
