import getApi from '../../../api'
import { User } from '../../../model/User'
import { deserializeUserResponse, serializeUserMutation } from './mapper/users.mapper'
import { SerializedUser } from './mapper/users.model'
import { ApiErrorContent } from '../../../model/Error'

export const postUser = (user: Partial<User>) =>
  getApi()
    .post<SerializedUser, ApiErrorContent>(`/admin/users`, serializeUserMutation(user))
    .then(deserializeUserResponse)
