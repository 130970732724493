import { createContext } from 'react'
import { TaskPageFiltersState, defaultTaskPageFilters } from './TaskPageContent.model'

export interface TaskPageContextState extends TaskPageFiltersState {
  hideAutoCreated: boolean
  assignedToMe: boolean
}

export interface TaskPageContentFiltersContextType {
  filters: TaskPageContextState
  setFilters: (filters: Partial<TaskPageContextState>) => void
}

export const defaultTaskPageContext: TaskPageContextState = {
  ...defaultTaskPageFilters,
  hideAutoCreated: false,
  assignedToMe: false,
}

export const TaskPageContentFiltersContext = createContext<TaskPageContentFiltersContextType>({
  filters: defaultTaskPageContext,
  setFilters: () => {},
})
