export enum RestuxResources {
  codedInformations = 'codedInformations',
  contact = 'contact',
  documentHistory = 'documentHistory',
  documentInstance = 'documentInstance',
  documentTemplate = 'documentTemplate',
  file = 'file',
  medicalEvent = 'medicalEvent',
  medicalEventContent = 'medicalEventContent',
  patientCurrentTreatments = 'patientCurrentTreatments',
  question = 'question',
  questionTemplate = 'questionTemplate',
  questionnaire = 'questionnaire',
  quoteLine = 'quoteLine',
  resourceHistory = 'resourceHistory',
  user = 'user',
  userTeams = 'userTeams',
  metrics = 'metrics',
}
