import { Task, TaskCategory, TaskMutationPayload, TaskSorting } from '../../../model/Task'
import { LightPatient } from '../../../model/Patient'
import { MultiSelectOption, SelectOption } from '../../../model/SelectOption'
import { TestProps } from '../../../misc/types.utilities'
import { SORT_DIRECTION } from '../../../model/Resource'

export interface TaskPageContentProps extends TestProps {
  tasksTodo: Array<Partial<Task>>
  tasksDone: Array<Partial<Task>>
  tasksDocument: Array<Partial<Task>>
  onCreateOrUpdate: (task: TaskMutationPayload) => void
  onDeleteTask: (taskId?: number) => void
  removeNewTask: () => void
  newTask?: () => void
  loading: boolean
  onDeleteAllDoneTasks: (hasDocument?: boolean) => void
  getTasks: (
    filters: TaskPageFiltersState & { hideAutoCreated: boolean; assignedToMe: boolean },
  ) => void
}

export interface TaskPageFiltersState {
  title: string
  types: MultiSelectOption<TaskCategory>[]
  patient: SelectOption<LightPatient> | null
  startDate: Date | null
  endDate: Date | null
  order: SORT_DIRECTION
  orderBy: TaskSorting
}

export const defaultTaskPageFilters: TaskPageFiltersState = {
  title: '',
  types: [],
  patient: null,
  startDate: null,
  endDate: null,
  order: SORT_DIRECTION.DESC,
  orderBy: 'date',
}
