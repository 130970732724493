import { retrieveVariableUuid } from '@follow/cdk'
import { formatISO } from 'date-fns'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { ManualPrescriptionForm } from '../../../components/drug'
import { ManualPrescriptionFormValue } from '../../../components/drug/ManualPrescriptionForm/ManualPrescriptionForm.model'
import { useUpdateManualPrescriptions } from '../../../hooks/queries/manualPrescriptions'
import { CommonVariableActions } from '../CommonVariableActions'
import { ManualPrescriptionConfigurationProps } from './ManualPrescriptionConfiguration.model'

const ManualPrescriptionConfiguration: FunctionComponent<ManualPrescriptionConfigurationProps> = ({
  variableId,
  inUseEntity,
  onRemoveVariable,
  hide,
  ...restProps
}) => {
  const { mutate: updateManualPrescription } = useUpdateManualPrescriptions()

  const manualPrescription = useMemo(
    () =>
      inUseEntity !== null
        ? inUseEntity.manualPrescriptions.find(
            (currentManualPrescription) =>
              currentManualPrescription.variableUuid === retrieveVariableUuid(variableId),
          )
        : undefined,
    [inUseEntity, variableId],
  )

  const [manualPrescriptionForm, setManualPrescriptionForm] = useState<ManualPrescriptionFormValue>(
    {
      content: manualPrescription?.content ?? '',
      startDate: manualPrescription?.startDate
        ? new Date(manualPrescription.startDate)
        : new Date(),
      endDate: manualPrescription?.endDate ? new Date(manualPrescription.endDate) : null,
    },
  )

  useDebounce(
    () => {
      if (
        manualPrescription?.uuid &&
        manualPrescriptionForm.content &&
        manualPrescriptionForm.startDate &&
        (manualPrescriptionForm.endDate
          ? manualPrescriptionForm.endDate.getTime() > manualPrescriptionForm.startDate.getTime()
          : true)
      ) {
        updateManualPrescription({
          id: manualPrescription.uuid,
          manualPrescription: {
            content: manualPrescriptionForm.content,
            startDate: formatISO(manualPrescriptionForm.startDate),
            ...(manualPrescriptionForm.endDate && {
              endDate: formatISO(manualPrescriptionForm.endDate),
            }),
          },
        })
      }
    },
    500,
    [manualPrescriptionForm],
  )

  const handleDelete = useCallback(() => {
    onRemoveVariable(variableId)
    hide()
  }, [hide, onRemoveVariable, variableId])

  return (
    <>
      <h3 className="text-shades-3 mb-2">Prescription Libre</h3>
      <ManualPrescriptionForm
        currentManualPrescription={manualPrescriptionForm}
        onChange={(manualPrescriptionUpdate) => {
          setManualPrescriptionForm((currentManualPrescription) => ({
            ...currentManualPrescription,
            ...manualPrescriptionUpdate,
          }))
        }}
      />
      <CommonVariableActions
        customActions={[
          {
            icon: 'trash',
            hint: 'Supprimer la prescription libre',
            onClick: handleDelete,
          },
        ]}
        {...restProps}
      ></CommonVariableActions>
    </>
  )
}

export default ManualPrescriptionConfiguration
