import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EditorTool, SimpleEditor, ToolbarButton, useElfeEditor } from '@follow/elfe'
import {
  Button,
  ClickableLink,
  DropdownItem,
  Input,
  MultiSelectInput,
  Switch,
  TooltipWrapper,
  ValidationModal,
} from '../../shared'
import LogoMsSante from '../../../assets/images/logo_mssante.png'
import { MailEditorProps } from './MailEditor.model'
import { emailRegex } from '../../../misc/regex'
import { LightPatient } from '../../../model/Patient'
import { useDebounce } from 'react-use'
import { FwTrackingEvent, TrackingService } from '../../../misc/Tracking'
import { MultiSelectOption } from '../../../model/SelectOption'
import { MessageTemplateManager } from '../MessageTemplateManager'
import { useGetDefaultMessageTemplate } from '../../../hooks/queries/messageTemplates/useGetDefaultMessageTemplate.query'
import { isHtmlEmptyValue } from '../../../misc/html.utils'
import { useAllowPatientsMail, useSendMail } from '../../../hooks/queries/mail'
import { BottomPanel } from '../../shared/bottomPanel/BottomPanel'
import { MailEditorAttachments } from './MailEditorAttachments'
import { useUserEnabledFeature } from '../../../hooks/utils/user'
import { FeaturesType } from '@follow/cdk'
import { isDefined } from '../../../misc/functions.utilities'
import { useRenderPdfFromDocuments } from '../../../hooks/queries/document/useRenderPdfFromDocuments.query'

import { useActionDispatch } from '../../../hooks/utils'
import { addValid } from '../../../store/message'
import { useGetRecipientSuggestion } from '../../../hooks/queries/recipientSuggestions'
import { ContactMssEmail, ContactMssEmailOption } from '../../../model/ContactMssEmail'
import { ContactMssEmailDropdownItem } from '../../contactMssEmail'
import { mapContactMssEmailToContactMssEmailOption } from '../../../misc/contactMssEmail.utilities'
import { useContactMssEmailOptions } from '../../../hooks/utils/mail/useContactMssEmailOptions.hook'
import {
  getDocumentsToRender,
  getRenderableDocumentsVariable,
  isFileAlreadyAttachedToEmail,
} from './MailEditor.utils'
import { SendableDocument } from '../../../model/DocumentInstance'
import { useFetchFileForMail } from './useFetchFileForMail.query'

const editorTools: Array<EditorTool> = ['heading', 'fontSize', 'marks', 'align', 'list', 'undo']

const EMPTY_CONTACT_MSS_EMAIL_ID = '0'
const emptyContactMssEmail: ContactMssEmail = {
  uuid: EMPTY_CONTACT_MSS_EMAIL_ID,
  mssEmailAddress: '',
  contact: {
    uuid: '',
    title: '',
    firstName: '',
    familyName: '',
    profession: null,
    speciality: null,
    organizations: [],
    private: false,
  },
}

const enableReturnTo = false

const getEmailOption = (emailAddress: string): MultiSelectOption<ContactMssEmailOption> => ({
  label: emailAddress,
  value: mapContactMssEmailToContactMssEmailOption({
    ...emptyContactMssEmail,
    mssEmailAddress: emailAddress,
  }),
})

const FILE_ADD_TOAST_ID = 'file_add_from_mail_editor'

export const MailEditor: FC<MailEditorProps> = ({
  isUnmounting,
  initialRecipients,
  initialContent,
  initialSubject,
  initialPatient,
  initialDocuments,
  initialFileIds,
  medicalEventId,
  onClose,
}) => {
  const [localMailSubject, setLocalMailSubject] = useState(initialSubject ?? '')
  const [addressInput, setAddressInput] = useState('')
  const [selectedPatient, setSelectedPatient] = useState<LightPatient | undefined>(initialPatient)
  const [emailAddresses, setEmailAddresses] = useState<MultiSelectOption<ContactMssEmailOption>[]>(
    initialRecipients?.map(getEmailOption) ?? [],
  )

  const [displayCloseModal, setDisplayCloseModal] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [replyToEmail, setReplyToEmail] = useState<string>()
  const [askReadAcknowledgement, setAskReadAcknowledgement] = useState(false)
  const [openedMessageTemplateManager, setOpenedMessageTemplateManager] = useState(false)
  const [recipientSuggestionSearch, setRecipientSuggestionSearch] = useState('')

  const {
    query: { data: defaultMessageTemplate },
  } = useGetDefaultMessageTemplate({})
  const {
    query: { data: recipientSuggestion },
    cancelPendingQuery: cancelRecipientSuggestionSearchQuery,
  } = useGetRecipientSuggestion({
    search: recipientSuggestionSearch,
    patientId: selectedPatient?.id,
  })
  const isMailAttachmentsEnabled = useUserEnabledFeature(FeaturesType.mailAttachments)

  const dispatchValid = useActionDispatch(addValid)
  const { mutate: sendMail, isPending: isSendMailLoading } = useSendMail()

  const { render } = useRenderPdfFromDocuments({
    onSuccess: (rendered) => {
      const newFile = new File([rendered.blob], rendered.fileName || 'Document')

      if (!isFileAlreadyAttachedToEmail(selectedFiles, newFile)) {
        setSelectedFiles((current) => [...current, newFile])
      }
    },
  })

  const handleAddSendableDocuments = useCallback(
    (documents: Array<SendableDocument>) => {
      if (documents.length === 0) return

      dispatchValid("Documents en cours d'ajout", undefined, { id: FILE_ADD_TOAST_ID })

      const renderables = getDocumentsToRender(documents)
      renderables.forEach((doc) => render(getRenderableDocumentsVariable(doc)))
    },
    [dispatchValid, render],
  )

  const { mutate: addFileToAttachments } = useFetchFileForMail({
    onSuccess: (file) => {
      if (!isFileAlreadyAttachedToEmail(selectedFiles, file)) {
        setSelectedFiles((current) => [...current, file])
      }
    },
  })

  const selectedEmailAddresses = useMemo(
    () => emailAddresses.map(({ value }) => value.mssEmailAddress),
    [emailAddresses],
  )

  const { blackListedPatients, unqualifiedPatients } = useAllowPatientsMail({
    emails: selectedEmailAddresses,
  })

  const handleAddFiles = useCallback(
    (fileIds: Array<number>) => {
      if (fileIds.length === 0) return

      dispatchValid("Documents en cours d'ajout", undefined, { id: FILE_ADD_TOAST_ID })

      fileIds.forEach((id) => addFileToAttachments({ fileId: id }))
    },
    [addFileToAttachments, dispatchValid],
  )

  const initialDocumentsHaveBeenRendered = useRef(false)
  useEffect(() => {
    if (initialDocumentsHaveBeenRendered.current) return

    initialDocumentsHaveBeenRendered.current = true

    if (initialDocuments) {
      handleAddSendableDocuments(initialDocuments)
    }
    if (initialFileIds) {
      handleAddFiles(initialFileIds)
    }
  }, [handleAddFiles, handleAddSendableDocuments, initialDocuments, initialFileIds])

  const { contactMssEmailGroups, contactMssEmailOptions } = useContactMssEmailOptions(
    recipientSuggestion,
    addressInput,
    emptyContactMssEmail,
  )

  const formError = useMemo(() => {
    if (emailAddresses.length === 0) {
      return 'Veuillez saisir une adresse mail valide'
    }

    if (replyToEmail && !emailRegex.test(replyToEmail)) {
      return 'Veuillez saisir une adresse mail de retour valide'
    }

    if (blackListedPatients.length > 0) {
      const base =
        blackListedPatients.length === 1
          ? "Un patient refuse d'être contacté"
          : "Plusieurs patients saisis refusent d'être contactés"
      return `${base} via la messagerie sécurisée`
    }

    if (unqualifiedPatients.length > 0) {
      const patientBlock = unqualifiedPatients.length < 2 ? 'du patient' : 'des patients'
      return `L'identité ${patientBlock} doit être qualifiée`
    }

    if (selectedPatient && selectedFiles.length === 0) {
      return `Vous ne pouvez pas envoyer de mail à propos d'un patient sans ajouter de pièce jointe`
    }

    return null
  }, [
    blackListedPatients.length,
    emailAddresses.length,
    replyToEmail,
    selectedFiles.length,
    selectedPatient,
    unqualifiedPatients.length,
  ])

  const { editor, config } = useElfeEditor(
    {
      editorType: 'simple',
      tools: editorTools,
      placeholder: 'Écrivez votre message ici...',
    },
    {
      content: initialContent ?? '',
    },
  )

  useEffect(() => {
    if (!editor || !isHtmlEmptyValue(editor.getHTML())) return
    if (!defaultMessageTemplate) return

    editor.commands.setContent(defaultMessageTemplate.body)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, defaultMessageTemplate])

  useDebounce(
    () => {
      setRecipientSuggestionSearch(addressInput)
    },
    500,
    [addressInput],
  )

  const handleAddressInputChange = useCallback(
    (value: string) => {
      setAddressInput(value)
      cancelRecipientSuggestionSearchQuery()
    },
    [cancelRecipientSuggestionSearchQuery],
  )

  const handleSelectEmailAddress = useCallback(
    (value: MultiSelectOption<ContactMssEmailOption>[]) => {
      const filtered = value.filter(
        (item) => item.value.mssEmailAddress && emailRegex.test(item.value.mssEmailAddress),
      )

      setEmailAddresses(filtered)

      // Si l'email en cours de saisie est valide, on vide l'input text
      if (value.length === filtered.length) {
        setAddressInput('')
      }
    },
    [],
  )

  const handleBackToEditorModal = useCallback(() => {
    setDisplayCloseModal(false)
  }, [])

  const handleSend = useCallback(() => {
    const recipients = emailAddresses.map(({ value }) => value.mssEmailAddress)
    const htmlContent = editor?.getHTML()
    const html = `<div>${htmlContent}</div>`
    const replyTo = replyToEmail && emailRegex.test(replyToEmail) ? replyToEmail : undefined

    sendMail(
      {
        payload: {
          subject: localMailSubject,
          content: html,
          recipientEmails: recipients,
          files: selectedFiles,
          askReadAcknowledgement,
          documentIds: initialDocuments?.map(({ id }) => id),
          replyToEmail: replyTo,
          patientId: selectedPatient?.id,
        },
      },
      {
        onSuccess: () => {
          onClose()
          if (medicalEventId) {
            TrackingService.sendEvent(FwTrackingEvent.MSS_MEDICAL_EVENT, {
              medicalEventId,
            })
          }
        },
      },
    )
  }, [
    emailAddresses,
    editor,
    replyToEmail,
    sendMail,
    localMailSubject,
    selectedFiles,
    askReadAcknowledgement,
    initialDocuments,
    selectedPatient?.id,
    onClose,
    medicalEventId,
  ])

  const handleConfirmClose = useCallback(() => {
    setDisplayCloseModal(false)
    onClose()
  }, [onClose])

  const handleClose = useCallback(() => {
    const isEditorEmpty = editor?.getText() === ''
    const isSubjectEmpty = localMailSubject.length === 0
    const isEmailEmpty = emailAddresses.length === 0
    const isPatientEmpty = selectedPatient === undefined

    const isFormEmpty = isEditorEmpty && isSubjectEmpty && isEmailEmpty && isPatientEmpty

    if (isFormEmpty) {
      handleConfirmClose()
    } else {
      setDisplayCloseModal(true)
    }
  }, [editor, emailAddresses.length, handleConfirmClose, localMailSubject.length, selectedPatient])

  const lockSendButton = !!formError && !isSendMailLoading

  return (
    <>
      <BottomPanel
        isUnmounting={isUnmounting}
        onRequestClose={handleClose}
        title="Nouveau message"
        displayCloseButton={false}
        actions={
          <>
            <Button label="Annuler" theme="dark" onClick={handleClose} />
            <TooltipWrapper display={lockSendButton} content={formError} size="small">
              <div>
                <Button
                  type="submit"
                  label="Envoyer le message"
                  theme="primary"
                  disabled={lockSendButton}
                  onClick={handleSend}
                />
              </div>
            </TooltipWrapper>
          </>
        }
      >
        <div className="bg-white px-2 overflow-y-scroll overflow-x-hidden flex flex-col justify-between h-full">
          <div className="px-4 pt-2 pb-4 space-y-2">
            <div className="flex items-center">
              <MultiSelectInput
                title="Destinataire(s)"
                mode="multiline"
                placeholder="Rechercher un destinataire"
                value={emailAddresses}
                options={contactMssEmailOptions}
                optionGroups={contactMssEmailGroups}
                disabled={isSendMailLoading}
                valid={addressInput.length > 0 ? emailRegex.test(addressInput) : undefined}
                emitChange={handleAddressInputChange}
                onSelect={handleSelectEmailAddress}
                renderOption={({ value }, _, isHovered) =>
                  value.isEmptyContact ? (
                    <DropdownItem selected={isHovered}>
                      <span className="mx-2">{value.mssEmailAddress}</span>
                    </DropdownItem>
                  ) : (
                    <ContactMssEmailDropdownItem
                      contactMssEmailOption={value}
                      selected={isHovered}
                    />
                  )
                }
              />
              <div className="ml-4 w-80 font-medium text-shades-2">
                <Switch
                  checked={askReadAcknowledgement}
                  onChange={setAskReadAcknowledgement}
                  name="Accusé de réception"
                />
              </div>
              <span className="ml-8 mr-2 text-shades-2 font-bold text-base whitespace-nowrap">
                Envoyé via
              </span>
              <img src={LogoMsSante} width="52px" height="auto" alt="logo-mss" />
            </div>
            {enableReturnTo && (
              <>
                {replyToEmail === undefined ? (
                  <div className="ml-2">
                    <ClickableLink
                      label="Ajouter une adresse de retour différente"
                      icon="add"
                      onClick={() => setReplyToEmail('')}
                    />
                  </div>
                ) : (
                  <Input
                    label="Adresse de retour"
                    name="mailToAddress"
                    placeholder="Entrer une adresse de retour"
                    colorPreset="light"
                    valid={replyToEmail.length > 0 ? emailRegex.test(replyToEmail) : undefined}
                    error={
                      replyToEmail.length > 0 && !emailRegex.test(replyToEmail)
                        ? "L'adresse saisie n'est pas valide"
                        : undefined
                    }
                    disabled={isSendMailLoading}
                    value={replyToEmail}
                    onChange={(event) => setReplyToEmail(event.target.value)}
                  />
                )}
              </>
            )}
            <Input
              label="Objet"
              name="object"
              colorPreset="light"
              value={localMailSubject}
              disabled={isSendMailLoading}
              onChange={(event) => setLocalMailSubject(event.target.value)}
            />
          </div>

          {isMailAttachmentsEnabled && (
            <MailEditorAttachments
              disabled={isSendMailLoading}
              disabledPatientSelect={isDefined(initialPatient)}
              selectedPatient={selectedPatient}
              selectedFiles={selectedFiles}
              onSelectPatient={setSelectedPatient}
              setSelectedFiles={setSelectedFiles}
              onAddDocuments={handleAddSendableDocuments}
              onAddFiles={handleAddFiles}
            />
          )}
          <SimpleEditor
            editor={editor}
            config={config}
            extraTools={
              <ToolbarButton
                title="Modèles de message"
                label="Modèles de message"
                onClick={() => setOpenedMessageTemplateManager(true)}
              />
            }
          />
        </div>
      </BottomPanel>
      <ValidationModal
        display={displayCloseModal}
        title="Votre saisie ne sera pas sauvegardée, voulez-vous fermer l'éditeur de message ?"
        onSubmit={handleConfirmClose}
        onClose={handleBackToEditorModal}
      />
      <MessageTemplateManager
        display={openedMessageTemplateManager}
        onClose={() => setOpenedMessageTemplateManager(false)}
        editor={editor}
      />
    </>
  )
}
