import { FC, useCallback, useMemo, useState } from 'react'
import { MAIL_SEARCH_MIN_CHAR } from '../../../model/Mail'
import { List, RoundedButton, Icon, IconButton, TooltipWrapper, SearchInput } from '../../shared'
import { MailListProps } from './MailList.model'
import { MailListItem } from './MailListItem'
import Waypoint from 'react-waypoint'
import { ListItem } from '../../shared/List/ListItem'
import styles from './MailList.module.scss'
import { useParams } from 'react-router-dom'
import { isDefined } from '../../../misc/functions.utilities'
import { EmptyPlaceHolder } from '../../shared/EmptyPlaceHolder'
import classNames from 'classnames/bind'
import { debounce } from 'lodash'
import { useUserEnabledFeature } from '../../../hooks/utils/user'
import { FeaturesType } from '@follow/cdk'

const cx = classNames.bind(styles)

export const MailList: FC<MailListProps> = ({
  isLoading,
  hasError,
  search,
  mailList,
  fetchNextPage,
  openMailEditor,
  setSearch,
  refetch,
}) => {
  const { mailId } = useParams<'mailId'>()
  const [localSearch, setLocalSearch] = useState('')

  const isSmtpEnabled = useUserEnabledFeature(FeaturesType.mssSmtp)

  const debounceSearch = useMemo(() => debounce(setSearch, 500), [setSearch])
  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value
      setLocalSearch(value)
      if (value.length >= MAIL_SEARCH_MIN_CHAR) {
        debounceSearch(value)
      } else {
        debounceSearch('')
      }
    },
    [debounceSearch],
  )

  const requestIsCompleted = isDefined(mailList)

  const footerType = useMemo(() => {
    if (isLoading) return 'loading'
    if (!fetchNextPage) return 'endOfList'
    if (hasError) return 'error'
    return null
  }, [fetchNextPage, hasError, isLoading])

  return (
    <div className={styles.mailListContainer}>
      <div className={styles.mailActionsWithSearch}>
        <div className={styles.mailActions}>
          <RoundedButton label="Nouveau message" icon="add" onClick={openMailEditor} />
          <TooltipWrapper pointerDirection="left" content="Charger les nouveaux messages">
            <div className={styles.reloadButtonWrapper}>
              <IconButton icon="reload" theme="transparent-dark" size="micro" onClick={refetch} />
            </div>
          </TooltipWrapper>
        </div>
        {isSmtpEnabled && (
          <div className={styles.searchWrapper}>
            <SearchInput
              value={localSearch ?? ''}
              onChange={handleSearch}
              placeholder="Rechercher dans les messages (4 caractères minimum)"
            />
          </div>
        )}
      </div>
      <div
        className={cx(styles.listWrapper, {
          'border-right': !requestIsCompleted,
        })}
      >
        {requestIsCompleted && (
          <List
            noRef
            items={mailList}
            renderItem={(item) => <MailListItem mail={item} />}
            highlightedId={mailId}
            onUpdateList={() => {}}
            pageCount={null}
            loading={isLoading}
            fullPageMode={false}
            renderEmptyPlaceHolder={
              <div className={styles.emptyPlaceHolderWrapper}>
                <EmptyPlaceHolder
                  icon={null}
                  message={!!search ? 'Aucun élément ne correspond à votre recherche' : undefined}
                />
              </div>
            }
            renderListFooter={
              <>
                {footerType === 'loading' && (
                  <ListItem>
                    <div className={styles.loaderNextItem}>
                      <div className={styles.iconLoader}>
                        <Icon icon="arrowCircle" size="normal" />
                      </div>
                      Chargement en cours
                    </div>
                  </ListItem>
                )}
                {footerType === 'endOfList' && (
                  <ListItem>
                    <div className={styles.loaderNextItem}>
                      Fin des messages dans votre boîte de réception
                    </div>
                  </ListItem>
                )}
                {footerType === 'error' && (
                  <ListItem>
                    <div className={styles.loaderNextItem}>
                      Une erreur est survenue lors du chargement des données
                    </div>
                  </ListItem>
                )}
              </>
            }
          />
        )}
      </div>
      {fetchNextPage && <Waypoint topOffset={1700} onEnter={() => fetchNextPage()} />}
    </div>
  )
}
