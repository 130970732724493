import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { formatDateWithDaytime } from '../../../../misc/date.utilities'
import { Checkbox, CheckboxState, Icon, TooltipWrapper } from '../../../shared'
import { TaskTableRowProps } from './TaskTableRow.model'
import { TaskStatus, TaskCategory } from '../../../../model/Task'
import { TaskCategorySelect } from './Columns/TaskCategorySelect'
import { TaskPatientSearch } from './Columns/TaskPatientSearch'
import { useAutoresizeTextArea } from '../../../../hooks/utils'
import { Link } from 'react-router-dom'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'
import { TaskTableColumn } from '../TaskTable.model'
import styles from './TaskTableRow.module.scss'
import classNames from 'classnames/bind'
import { useCurrentMailConnectionStatus } from '../../../../hooks/utils/mail'
import { TaskDocumentStepSelect } from './Columns/TaskDocumentStepSelect'
import { TaskAssignedSelect } from './Columns/TaskAssignedSelect'

const cx = classNames.bind(styles)

const TASK_TITLE_MAX_LENGTH = 255

export const TaskTableRow: FunctionComponent<TaskTableRowProps> = ({
  editMode = false,
  task,
  onCreateOrUpdate,
  onDeleteTask,
  removeNewTask,
  handleSwitchEditModeRequest,
  openTaskInformation,
  openCdaInformation,
  displayColumns = Object.values(TaskTableColumn),
  size = 'normal',
  testId,
}) => {
  const [title, setTitle] = useState(task.title ?? '')
  const [category, setCategory] = useState(task.category ? task.category : TaskCategory.OTHER)
  const [documentStep, setDocumentStep] = useState(
    task.documentStep ? task.documentStep : undefined,
  )

  const [selectedPatient, setSelectedPatient] = useState(task.patient)
  const [selectedAssigned, setSelectedAssigned] = useState(task.assignedTo)

  const [checked, setIsChecked] = useState(
    task.status === TaskStatus.TODO ? CheckboxState.UNCHECKED : CheckboxState.CHECKED,
  )

  const { height, ref: titleRef, resetHeight } = useAutoresizeTextArea(title, true)

  const isMssLoggedIn = useCurrentMailConnectionStatus()

  const handleCreateTask = useCallback(() => {
    if (!editMode) {
      return
    }

    const { patient, document, assignedTo, ...taskPayload } = task
    onCreateOrUpdate({
      ...taskPayload,
      title,
      patientId: selectedPatient ? selectedPatient.id : null,
      assignedId: selectedAssigned ? selectedAssigned.id : null,
      documentStep: documentStep ?? undefined,
      documentId: undefined,
      category: category,
    })

    if (task.id || title === '') {
      handleSwitchEditModeRequest(task.id)
    }
  }, [
    editMode,
    task,
    onCreateOrUpdate,
    title,
    selectedPatient,
    selectedAssigned,
    documentStep,
    category,
    handleSwitchEditModeRequest,
  ])

  const handleTaskChecked = useCallback(() => {
    setIsChecked(
      checked === CheckboxState.UNCHECKED ? CheckboxState.CHECKED : CheckboxState.UNCHECKED,
    )
    if (task.id) {
      const { patient, document, assignedTo, ...taskPayload } = task
      onCreateOrUpdate({
        ...taskPayload,
        status: checked === CheckboxState.UNCHECKED ? TaskStatus.DONE : TaskStatus.TODO,
      })
    }
  }, [onCreateOrUpdate, task, checked])

  const handleRemoveTask = useCallback(() => {
    if (!task.id && removeNewTask) {
      removeNewTask()

      return
    }

    onDeleteTask(task.id)
  }, [task, removeNewTask, onDeleteTask])

  const handleEditMode = useCallback(() => {
    if (task.status === TaskStatus.DONE) {
      return
    }

    handleSwitchEditModeRequest(task.id)
  }, [task, handleSwitchEditModeRequest])

  const handleOnKeyDown = useCallback(
    (event) => {
      if (event.key !== 'Enter') {
        return
      }

      handleCreateTask()
    },
    [handleCreateTask],
  )

  const onTitleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value.replace(/(\r\n|\n|\r)/gm, '')
    setTitle(value)
    resetHeight()
  }

  useEffect(() => {
    if (!editMode) {
      return
    }

    titleRef.current?.focus()
  }, [editMode, titleRef])

  return (
    <tr
      tabIndex={0}
      className={cx(
        task.status === TaskStatus.DONE ? 'statusDone' : 'statusTodo',
        task.read ? 'read' : 'unread',
        !!title ? 'isValid' : 'isInvalid',
        styles.row,
        size,
        { editMode },
      )}
      data-test-id={addTestIdSuffix('root', testId)}
      onKeyDown={handleOnKeyDown}
    >
      <td className={styles.dataCellCheckbox}>
        <Checkbox
          disabled={editMode}
          checked={checked}
          onChange={handleTaskChecked}
          testId={addTestIdSuffix('checkbox', testId)}
        />
      </td>
      {displayColumns.includes(TaskTableColumn.Title) && (
        <td className={styles.dataCellTitle} onClick={handleEditMode}>
          {!task.read && (
            <TooltipWrapper content="Non lue">
              <span className={styles.unreadChip}></span>
            </TooltipWrapper>
          )}
          <textarea
            className={styles.nameInput}
            data-test-id={addTestIdSuffix('name-input', testId)}
            style={{ height }}
            placeholder="Nom de la tâche"
            value={title}
            onChange={onTitleInput}
            disabled={!editMode}
            ref={titleRef}
            autoFocus
            rows={1}
            maxLength={TASK_TITLE_MAX_LENGTH}
          />
        </td>
      )}
      {displayColumns.includes(TaskTableColumn.CdaContext) && (
        <td className={styles.dataCellCdaContext} onClick={handleEditMode}>
          <div className={styles.dataCellCdaContextWrapper}>
            {task.cdaContext && (
              <div
                className={styles.clickable}
                onClick={() =>
                  task.cdaContext && openCdaInformation ? openCdaInformation(task.cdaContext) : null
                }
              >
                <Icon icon="warningTriangle" size="normal" />
              </div>
            )}
            {task.email && (
              <>
                {!isMssLoggedIn && (
                  <TooltipWrapper content="Vous devez être connecté(e) à la Messagerie de Santé Sécurisée pour visualiser le document">
                    <Icon icon="warningCircle" size="normal" />
                  </TooltipWrapper>
                )}
                {isMssLoggedIn && (
                  <TooltipWrapper content="Consulter l'email lié">
                    <Link to={`/mail/inbox/${task.email.id}`}>
                      <Icon icon="mail" size="normal" />
                    </Link>
                  </TooltipWrapper>
                )}
              </>
            )}
          </div>
        </td>
      )}
      {displayColumns.includes(TaskTableColumn.Category) && (
        <td className={styles.dataCellCategory} onClick={handleEditMode}>
          <TaskCategorySelect
            category={category}
            isEditModeEnabled={editMode}
            setCategory={setCategory}
          />
        </td>
      )}
      {displayColumns.includes(TaskTableColumn.Document) && (
        <td className={styles.dataCellDocument} onClick={handleEditMode}>
          {selectedPatient && selectedPatient.id ? (
            selectedPatient.deletedAt === null ? (
              task.document?.deletedAt ? (
                <>
                  <TooltipWrapper content="Le document a été supprimé">
                    <span className="inline">
                      {task.document ? task.document.title : 'Aucun document'}
                    </span>
                  </TooltipWrapper>
                </>
              ) : (
                <>
                  <TooltipWrapper content="Naviguer vers le document">
                    <Link
                      to={`/patients/${selectedPatient?.id}/medicalEvent/${task.document?.medicalEventId}?docId=${task.document?.id}`}
                    >
                      <span className="inline hover:cursor-pointer">
                        {task.document ? task.document.title : 'Aucun document'}
                      </span>
                    </Link>
                  </TooltipWrapper>
                </>
              )
            ) : (
              <>
                <TooltipWrapper content="Le dossier patient où le document se situe à été supprimé">
                  <span className="inline">
                    {task.document ? task.document.title : 'Aucun document'}
                  </span>
                </TooltipWrapper>
              </>
            )
          ) : (
            <></>
          )}
        </td>
      )}
      {displayColumns.includes(TaskTableColumn.DocumentStep) && (
        <td className={styles.dataCellDocumentStep} onClick={handleEditMode}>
          <TaskDocumentStepSelect
            documentStep={documentStep}
            isEditModeEnabled={editMode}
            setDocumentStep={setDocumentStep}
          />
        </td>
      )}
      {displayColumns.includes(TaskTableColumn.AssignedTo) && (
        <td className={styles.dataCellAssignedTo} onClick={handleEditMode}>
          <TaskAssignedSelect
            selectedAssigned={selectedAssigned}
            setSelectedAssigned={setSelectedAssigned}
            isEditModeEnabled={editMode}
          />
        </td>
      )}
      {displayColumns.includes(TaskTableColumn.Patient) && (
        <td className={styles.dataCellPatient} onClick={handleEditMode}>
          <TaskPatientSearch
            selectedPatient={selectedPatient}
            setSelectedPatient={setSelectedPatient}
            isEditModeEnabled={editMode && !task.document}
          />
        </td>
      )}
      {displayColumns.includes(TaskTableColumn.CreatedAt) && (
        <td className={styles.dataCellCreatedAt}>
          {task.createdAt && <span>{formatDateWithDaytime(task.createdAt)}</span>}
        </td>
      )}
      {displayColumns.includes(TaskTableColumn.Actions) && (
        <td className={styles.dataCellActions}>
          <div className={styles.dataCellActionsWrapper}>
            {!editMode && task.information && (
              <div
                className={styles.clickable}
                onClick={() => {
                  if (openTaskInformation && task.information) openTaskInformation(task.information)
                }}
              >
                <Icon icon="infoCircle" size="nano" />
              </div>
            )}
            {!editMode && task.status === TaskStatus.TODO && (
              <div
                className={styles.clickable}
                onClick={() => handleSwitchEditModeRequest(task.id)}
              >
                <Icon icon="pencil" size="nano" />
              </div>
            )}
            {editMode && (
              <div
                className={cx(styles.validateBtn, styles.clickable)}
                onClick={handleCreateTask}
                data-test-id={addTestIdSuffix('validate-btn', testId)}
              >
                <Icon icon="checkCircle" size="nano" />
              </div>
            )}
            <div className={styles.clickable} onClick={handleRemoveTask}>
              <Icon icon="trash" size="nano" />
            </div>
          </div>
        </td>
      )}
    </tr>
  )
}
