import { Filters } from '../../../model/Filters'

const all = ['contacts']
const lists = [...all, 'list']
const list = (filters?: Filters) => [...lists, filters]
const detail = (id: string | null) => [...all, id]

export const contactsKeys = {
  all,
  lists,
  list,
  detail,
}
