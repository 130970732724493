import { FunctionComponent, useCallback, useState } from 'react'
import { QuestionnaireQueryFilters, SearchScoresProps } from './SearchQuestionnairesSurvey.model'
import createSearchPanel, { FiltersType } from '../../../containers/SearchPanel'
import { LightQuestionnaire } from '../../../model/Questionnaire'
import { Button } from '../../shared'
import { QuestionnaireListItem } from '../atoms'
import { useGetInfiniteQuestionnaireTemplates } from '../../../hooks/queries/questionnaireTemplates'
import { isDefined } from '../../../misc/functions.utilities'
import { isEqual } from 'lodash'
import { BottomPanel } from '../../shared/bottomPanel/BottomPanel'
import { addScoresToTree, buildSurveyScores } from '../../../misc/survey.utilities'
import { useUpdateSurvey } from '../../../hooks/queries/survey'

const SearchPanel = createSearchPanel<LightQuestionnaire>()

export const SearchQuestionnairesSurvey: FunctionComponent<SearchScoresProps> = ({
  survey,
  isUnmounting,
  branch,
  onClose,
}) => {
  const [selected, setSelected] = useState<ReadonlyArray<LightQuestionnaire>>([])

  const [queryFilters, setQueryFilters] = useState<QuestionnaireQueryFilters>({})
  const {
    query: { isLoading, fetchNextPage },
    questionnaireTemplateList: items,
    paginationState,
    cancelPendingQuery,
  } = useGetInfiniteQuestionnaireTemplates({ filters: queryFilters })
  const { mutate: updateSurvey } = useUpdateSurvey()

  const handleFiltersChange = useCallback(
    (filters) => {
      const newQueryFilters: QuestionnaireQueryFilters = {
        ...(filters.search && { search: filters.search }),
        ...(isDefined(filters.private) && { private: filters.private }),
        ...(filters.tagIds.length && { tagIds: filters.tagIds.join(',') }),
      }

      if (!isEqual(queryFilters, newQueryFilters)) {
        cancelPendingQuery()
        setQueryFilters(newQueryFilters)
      }
    },
    [cancelPendingQuery, queryFilters],
  )

  const applySelection = (item?: LightQuestionnaire) => {
    const selection: number[] = item
      ? [item.id]
      : items
          .filter((questionnaire) => selected.some(({ id }) => id === questionnaire.id))
          .map((score) => score.id)

    const updatedContent = addScoresToTree(
      buildSurveyScores(selection),
      survey.content,
      branch.uuid,
    )

    updateSurvey({
      surveyId: survey.id,
      survey: { content: updatedContent },
    })

    onClose()
  }

  return (
    <BottomPanel
      isUnmounting={isUnmounting}
      title="Ajouter un questionnaire"
      onRequestClose={onClose}
      actions={
        selected.length > 0 ? (
          <Button theme="primary" label="Valider" onClick={() => applySelection()} />
        ) : null
      }
    >
      <SearchPanel
        items={items}
        loading={isLoading}
        itemCount={paginationState?.itemCount ?? null}
        pageCount={paginationState?.pageCount ?? null}
        currentPage={paginationState?.currentPage ?? 0}
        onMultiSelectionChange={(selection) => setSelected(selection)}
        onPrimaryAction={applySelection}
        getItems={() => fetchNextPage()}
        onFiltersChange={handleFiltersChange}
        renderItem={(item) => <QuestionnaireListItem item={item} />}
        filterCapabilities={[FiltersType.Title, FiltersType.Visibility, FiltersType.Tags]}
      />
    </BottomPanel>
  )
}
