import { parseISO } from 'date-fns'
import { Icon, InlineTag, TooltipWrapper } from '../../../shared'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { formatFr } from '../../../../misc/date.utilities'
import { PatientInfosItemEditableProps } from './PatientInfosItemEditable.model'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

/**
 * Can be used to display patient informations
 * (Treatments, Allergies)
 */
export const PatientInfosItemEditable = ({
  name,
  title,
  tags,
  startDate,
  endDate,
  actions,
  prescriber,
  testId,
}: PatientInfosItemEditableProps) => (
  <div
    className="border-b border-shades-7 last:border-b-0 last:mb-0 py-2"
    data-test-id={addTestIdSuffix('root', testId)}
  >
    <div className="flex flex-row justify-between width-full">
      <div className="font-medium text-shades-2">
        {tags && (
          <div className="flex flex-row gap-2 items-center mb-2">
            {tags.map((tag, index) => (
              <TooltipWrapper
                key={index}
                content={tag.info ?? ''}
                display={tag.info !== undefined}
                size="small"
              >
                <InlineTag color={tag.color}>{tag.name}</InlineTag>
              </TooltipWrapper>
            ))}
          </div>
        )}
        <TooltipWrapper content={title ?? ''} display={!!title}>
          <span>{name}</span>
        </TooltipWrapper>
      </div>
      <div className="flex flex-row gap-1 ml-2">{actions}</div>
    </div>
    {prescriber && <span className="text-shades-4 text-xs font-medium">{prescriber}</span>}
    {(startDate || endDate) && (
      <div className="font-medium text-shades-4 text-xs flex flex-row items-center space-x-2 mt-1">
        {startDate && (
          <>
            <Icon icon="calendar" size="nano" color={getV3ColorCssVariable('shades', 'shade4')} />
            <span>
              {formatFr(
                typeof startDate === 'string' ? parseISO(startDate) : startDate,
                'dd/MM/yyyy',
              )}
            </span>
          </>
        )}
        {endDate && (
          <>
            <Icon
              icon="arrow"
              rotate={90}
              size="nano"
              color={getV3ColorCssVariable('shades', 'shade4')}
            />
            <Icon icon="calendar" size="nano" color={getV3ColorCssVariable('shades', 'shade4')} />
            <span>
              {formatFr(typeof endDate === 'string' ? parseISO(endDate) : endDate, 'dd/MM/yyyy')}
            </span>
          </>
        )}
      </div>
    )}
  </div>
)
