import { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames/bind'
import { useCurrentPatient } from '../../../../../hooks/utils'
import { PendingDoctolibPatientData } from '../../../../../model/Doctolib'
import { Patient } from '../../../../../model/Patient'
import { Checkbox, CheckboxState, Icon } from '../../../../shared'
import { ValidationModal } from '../../../../shared/modal/ValidationModal/ValidationModal.component'
import {
  DoctolibPatientInfoUpdateModalProps,
  ModalFields,
  PendingDataFieldsValues,
} from './DoctolibPatientInfoUpdateModal.model'
import { isAddressPart } from '../../../../../misc/patient.utilities'
import styles from './DoctolibPatientInfoUpdateModal.module.scss'

const cx = classNames.bind(styles)

const mapPendingData = (
  patient: Patient,
  pendingPatientData: PendingDoctolibPatientData,
): PendingDataFieldsValues => {
  let previousValue: string | null

  if (isAddressPart(pendingPatientData.pendingField)) {
    const subAdressField = pendingPatientData.pendingField.replace('address.', '')
    previousValue = patient.address?.[subAdressField]
  } else {
    previousValue = patient[pendingPatientData.pendingField]
  }

  return {
    id: pendingPatientData.id,
    label: ModalFields[pendingPatientData.pendingField],
    field: pendingPatientData.pendingField,
    suggestedValue: pendingPatientData.pendingValue,
    previousValue,
  }
}

const title =
  'Les données de Doctolib sont différentes de celles connues de Follow pour ce patient:'

export const DoctolibPatientInfoUpdateModal = ({
  pendingPatientData,
  isUnmounting,
  onSubmit,
  onClose,
}: DoctolibPatientInfoUpdateModalProps) => {
  const { currentPatient } = useCurrentPatient()
  const [selectedPendingData, setSelectedPendingData] = useState<PendingDataFieldsValues[]>([])

  const pendingDataToDisplay = useMemo(
    () =>
      currentPatient ? pendingPatientData.map((data) => mapPendingData(currentPatient, data)) : [],
    [currentPatient, pendingPatientData],
  )

  const selected = useCallback(
    (item: PendingDataFieldsValues) => selectedPendingData.includes(item),
    [selectedPendingData],
  )

  const toggleSelect = useCallback(
    (item: PendingDataFieldsValues) => () => {
      if (selected(item)) {
        const selectedPendingDataWithoutItem = selectedPendingData.filter(
          (selected) => selected.id !== item.id,
        )
        setSelectedPendingData(selectedPendingDataWithoutItem)
      } else {
        setSelectedPendingData((prev) => [...prev, item])
      }
    },
    [selected, selectedPendingData],
  )

  const handleSubmit = useCallback(() => {
    onSubmit(selectedPendingData)
  }, [onSubmit, selectedPendingData])

  return (
    <ValidationModal
      title={title}
      isUnmounting={isUnmounting}
      size="xl"
      testId="patient_form-doctolib_patient_update_modal"
      onSubmit={handleSubmit}
      onClose={onClose}
      confirmTheme="primary"
      cancelLabel="Annuler"
      confirmLabel="Mettre à jour"
      disableSubmit={!selectedPendingData.length}
    >
      <table>
        <tbody>
          {pendingDataToDisplay.map((data) => (
            <tr key={data.id}>
              <td>
                <div className={`${styles.data} ${styles.withCheckbox}`}>
                  <Checkbox
                    checked={selected(data) ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
                    onChange={toggleSelect(data)}
                    size="small"
                  />
                  <p className={styles.dataType}>{data.label}:</p>
                </div>
              </td>
              <td>
                <div className={styles.data}>
                  <p className={styles.previousValue}>{data.previousValue ?? 'Non renseigné'}</p>
                </div>
              </td>
              <td>
                <div className={styles.data}>
                  <div className={styles.suggestedValueWithIcon}>
                    <div>
                      <Icon size="nano" icon="longArrowRight" />
                    </div>
                    <p
                      className={cx(styles.suggestedValue, {
                        [styles.deletedValue]: !data.suggestedValue,
                      })}
                    >
                      {data.suggestedValue ?? data.previousValue}
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className={styles.approval}>Souhaitez-vous effectuer les mises à jour sélectionnées?</p>
    </ValidationModal>
  )
}
