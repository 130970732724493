import { useMutation, useQueryClient } from '@tanstack/react-query'
import { sendMail } from '../../../data/mail'
import { UserFriendlyError } from '../../../model/Error'
import { SendMailPayload } from '../../../model/Mail'
import { addValid } from '../../../store/message'
import { useActionDispatch } from '../../utils'
import { recipientSuggestionsKeys } from '../recipientSuggestions/recipientSuggestions.keys'
import { mailErrorsTitle, mailErrorsMessage } from './mail.errors'

interface UseSendMailVariables {
  payload: SendMailPayload
}

export const useSendMail = () => {
  const dispatchValid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const mutation = useMutation<void, Error, UseSendMailVariables>({
    mutationFn: async ({ payload }) => {
      const { ok, status } = await sendMail(payload)

      if (status === 403) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorWhileSending,
          mailErrorsMessage.invalidInsi,
        )
      }

      if (!ok) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorWhileSending,
          mailErrorsMessage.errorWhileSendingMail,
        )
      }
    },
    onSuccess() {
      dispatchValid('Message envoyé')
      queryClient.invalidateQueries({ queryKey: recipientSuggestionsKeys.all })
    },
  })

  return mutation
}
