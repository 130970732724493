import { NotificationModalItemOwnProps } from './NotificationModalItem.model'
import { FunctionComponent } from 'react'
import { NotificationTypeIcon, NotificationTypeLabel } from '../../../../../model/Notification'
import { formatFr, getDistanceInWordsToNow } from '../../../../../misc/date.utilities'
import { Icon, TooltipWrapper } from '../../../../shared'
import { parseISO } from 'date-fns'
import { getV3ColorCssVariable } from '../../../../../design-system/colors_v3'
import classNames from 'classnames'

export const NotificationModalItem: FunctionComponent<NotificationModalItemOwnProps> = ({
  notification,
}) => {
  return (
    <div
      key={notification.id}
      className={classNames(
        'w-full relative bg-shades-white py-3 px-6 border-t border-shades-6 flex-col flex items-start',
        {
          'bg-shades-8': notification.read,
        },
      )}
    >
      <div className="flex items-center shrink-0 relative w-full">
        <span
          className={classNames('text-lg font-semibold ml-2 text-shades-4 leading-5', {
            'text-shades-1': !notification.read,
          })}
        >
          {notification.title}
        </span>
        {!notification.read && (
          <div className="absolute rounded-full bg-status-invalid right-0 w-2 h-2 font-bold text-xs"></div>
        )}
      </div>
      <div className="text-shades-4">
        <div className="flex items-center mx-2 my-2">
          <Icon
            icon={NotificationTypeIcon[notification.type]}
            size="pico"
            color={getV3ColorCssVariable('shades', 'shade4')}
          />
          <span className="ml-1">{NotificationTypeLabel[notification.type]}</span>
          <span className="ml-1 rounded-full bg-shades-4 w-0.5 h-0.5"></span>
          <TooltipWrapper
            content={formatFr(new Date(notification.publishedAt), 'dd MMMMMM yyyy à h:m')}
            pointerDirection="bottom"
          >
            <span className="ml-1">
              {getDistanceInWordsToNow(parseISO(notification.publishedAt), {
                addSuffix: true,
                includeSeconds: false,
              })}
            </span>
          </TooltipWrapper>
          {notification.recipients.length !== 0 && (
            <TooltipWrapper content="Notification privée" pointerDirection="right">
              <div className="ml-2">
                <Icon color={getV3ColorCssVariable('shades', 'shade4')} icon="lock" size="pico" />
              </div>
            </TooltipWrapper>
          )}
        </div>
        <div
          className={classNames('font-normal mx-2 text-shades-4', {
            'text-shades-3': !notification.read,
          })}
        >
          {notification.content.split('\n').map((str) => (
            <p>{str}</p>
          ))}
        </div>
      </div>
    </div>
  )
}
