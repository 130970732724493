import { useMutation } from '@tanstack/react-query'
import { createInsiLogs } from '../../../data/insi'

interface UseCreateInsiLogsVariables {
  payload: Record<string, string>
}

export const useCreateInsiLogs = () => {
  const mutation = useMutation<void, Error, UseCreateInsiLogsVariables>({
    mutationFn: async ({ payload }) => {
      const { ok, data } = await createInsiLogs(payload)

      if (!ok) {
        throw new Error(`Unable to create INSi logs : ${JSON.stringify(data)}`)
      }

      return
    },
  })

  return mutation
}
