import getApi from '../../api/apisauceConfig'
import { singleItemPageLimit } from '../../constants'
import { DocumentCategory } from '../../model/DocumentCategory'
import { PaginatedList } from '../../model/Pagination'

export const getDocumentCategories = (page: number, limit = singleItemPageLimit) =>
  getApi().get<PaginatedList<DocumentCategory>>('/document_categories', {
    page,
    limit,
  })
