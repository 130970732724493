import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getDocumentLayout } from '../../../data/documentLayouts'
import { documentLayoutsKeys } from './documentLayouts.keys'
import { useMemo } from 'react'

interface GetDocumentLayoutParams {
  id: number
  enabled?: boolean
}

export const useGetDocumentLayout = ({ id, enabled = true }: GetDocumentLayoutParams) => {
  const queryKey = useMemo(() => documentLayoutsKeys.detail(id), [id])

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await getDocumentLayout(id)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer les données de la mise en page`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
