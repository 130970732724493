import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { MailEditor } from './MailEditor.component'
import { MailEditorDispatchProps, MailEditorStoreProps } from './MailEditor.model'
import { withUnmountTransition } from '../../../misc/withUnmountTransition.hoc'

const mapStateToProps = (state: RootState): MailEditorStoreProps => ({})

const mapDispatchToProps: MailEditorDispatchProps = {}

const withHoc = withUnmountTransition(MailEditor)
const withConnect = connect(mapStateToProps, mapDispatchToProps)(withHoc)
export { withConnect as MailEditor }
