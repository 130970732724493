import classNames from 'classnames'
import { FunctionComponent, useCallback } from 'react'
import { ColorsV2 } from '../../../../../design-system/colors_v2'
import { getV3ColorCssVariable } from '../../../../../design-system/colors_v3'
import { Icon } from '../../../../shared'
import { PatientTransversalNavigationMedicalEventDocumentProps as DocumentProps } from './PatientTransversalNavigationMedicalEventDocumentListItem.model'
import styles from './PatientTransversalNavigationMedicalEventDocumentListItem.module.scss'

const cx = classNames.bind(styles)

export const PatientTransversalNavigationMedicalEventDocumentListItem: FunctionComponent<
  DocumentProps
> = ({ id, title, focusedDocOrFileId, icon, onClick }: DocumentProps) => {
  const handleSelectDocument = useCallback(() => onClick(id), [id, onClick])

  return (
    <div
      key={id}
      className={cx(styles.documentItemContainer, {
        [styles.focused]: focusedDocOrFileId === id,
      })}
      onClick={handleSelectDocument}
    >
      <div className={styles.documentItemDescription}>
        <Icon
          icon={icon}
          size="nano"
          color={
            focusedDocOrFileId === id ? ColorsV2.dsWhite : getV3ColorCssVariable('shades', 'shade2')
          }
        />
        <span className={styles.documentItemName}>{title}</span>
      </div>
    </div>
  )
}
