import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getOrdoclicUrl } from '../../../data/ordoclic'
import { UserFriendlyError } from '../../../model/Error'
import { ordoclicKeys } from './ordoclic.keys'

interface GetOrdoclicUrlParams {
  enabled: boolean
  patientId?: number
}

export const useGetOrdoclicUrl = ({ patientId, enabled = true }: GetOrdoclicUrlParams) => {
  const queryKey = useMemo(() => ordoclicKeys.detail(patientId), [patientId])

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      if (!patientId) return

      const { data, ok } = await getOrdoclicUrl(patientId)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "Une erreur s'est produite",
          "Impossible de générer l'e-prescription",
        )
      }
      return data
    },
    enabled,
    staleTime: 0,
    refetchOnWindowFocus: false,
  })

  return queryResult
}
