import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getUsedDocumentCategories } from '../../../data/documentCategories'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { FwResourceType } from '../../../model/Resource'
import { useFlatPaginatedData } from '../../utils/query'
import { getDocumentCategoriesKeys } from './documentCategories.keys'

interface UsedDocumentCategoriesParams {
  usedInResource: FwResourceType
}

export const useGetUsedDocumentCategories = ({ usedInResource }: UsedDocumentCategoriesParams) => {
  const queryKey = useMemo(() => getDocumentCategoriesKeys.used(usedInResource), [usedInResource])

  const queryResult = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => {
      const { data, ok } = await getUsedDocumentCategories(usedInResource, pageParam)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer les catégories du document',
          `Une erreur technique s'est produite`,
        )
      }

      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    staleTime: Infinity,
    gcTime: Infinity,
  })
  const usedDocumentCategories = useFlatPaginatedData(queryResult.data)

  return { query: queryResult, usedDocumentCategories }
}
