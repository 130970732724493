import { FC } from 'react'
import { ResourceHistoryType } from '../../../model/ResourceHistory'
import { List } from '../../shared'
import { ResourceHistoryPanelProps } from './ResourceHistoryPanel.model'
import { ResourceHistoryPanelItem } from './ResourceHistoryPanelItem'
import styles from './ResourceHistoryPanel.module.scss'
import { useGetInfiniteResourceHistory } from '../../../hooks/queries/resourceHistory'
import { BottomPanel } from '../../shared/bottomPanel/BottomPanel'

const getPanelTitle = (type: ResourceHistoryType) => {
  switch (type) {
    case 'patient':
      return 'Historique informations patient'
    default:
      return 'Historique'
  }
}

export const ResourceHistoryPanel: FC<ResourceHistoryPanelProps> = ({
  entityId,
  entityType,
  isUnmounting,
  onClose,
}) => {
  const {
    historyList,
    paginationState,
    query: { isLoading, fetchNextPage },
  } = useGetInfiniteResourceHistory({
    entity: { id: entityId, type: entityType },
  })

  return (
    <BottomPanel
      title={getPanelTitle(entityType)}
      onRequestClose={onClose}
      isUnmounting={isUnmounting}
    >
      <div className={styles['container']}>
        <List
          noRef
          fullPageMode={false}
          items={historyList}
          loading={isLoading}
          itemCount={paginationState?.itemCount}
          pageCount={paginationState?.pageCount ?? 0}
          onUpdateList={() => fetchNextPage()}
          renderItem={(item) => <ResourceHistoryPanelItem historyDetail={item} type={entityType} />}
        />
      </div>
    </BottomPanel>
  )
}
