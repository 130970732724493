import { takeEvery, call, put, select } from 'redux-saga/effects'
import { ApiResponse } from 'apisauce'
import { isSuccessfulApiResponse } from '../../restux/cache/restuxCacheSagas.factory'
import { addResponseError } from '../../message'
import { PaginatedList } from '../../../model/Pagination'
import { AnswerValue } from '../../../model/AnswerValue'
import { getDocumentAnswerValues } from './api'
import { documentAnswerValuesDomainActions } from './documentAnswerValues.actions'
import { DocumentAnswerValuesDomainActionsTypes } from './documentAnswerValues.model'
import { queryClient } from '../../../App'
import { questionnaireInstancesKeys } from '../../../hooks/queries/questionnaireInstances/questionnaireInstances.keys'
import { inUseMedicalEventContentIdSelector } from '../../ui/medicalEvents/medicalEventContent'
import { documentInstanceQuestionnaireSelector } from '../documentInstances/documentInstances.selectors'
import { isNumberGuard } from '../../../misc/types.utilities'

function* fetchDocumentAnswerValuesWorker({
  documentId,
}: ReturnType<typeof documentAnswerValuesDomainActions.fetchDocumentAnswerValues>) {
  const response: ApiResponse<PaginatedList<AnswerValue>> = yield call(
    getDocumentAnswerValues,
    documentId,
  )
  if (!isSuccessfulApiResponse(response)) {
    yield put(addResponseError(response))
  } else if (response.data) {
    yield put(
      documentAnswerValuesDomainActions.onFetchSuccessDocumentAnswerValues(
        documentId,
        response.data.items,
      ),
    )
  }
}

function* fetchDocumentAnswerValuesWatcher() {
  yield takeEvery(DocumentAnswerValuesDomainActionsTypes.FETCH, fetchDocumentAnswerValuesWorker)
}

function* fetchDocumentQuestionnaireScoreWorker({
  documentId,
}: ReturnType<typeof documentAnswerValuesDomainActions.fetchDocumentAnswerValues>) {
  const inUseMedicalEventQuestionnaireId: ReturnType<typeof inUseMedicalEventContentIdSelector> =
    yield select(inUseMedicalEventContentIdSelector)
  if (documentId && isNumberGuard(inUseMedicalEventQuestionnaireId)) {
    const questionnaire: ReturnType<typeof documentInstanceQuestionnaireSelector> = yield select(
      documentInstanceQuestionnaireSelector,
      documentId,
      inUseMedicalEventQuestionnaireId,
    )
    if (questionnaire && questionnaire.scoreConfig) {
      queryClient.invalidateQueries({
        queryKey: questionnaireInstancesKeys.score(inUseMedicalEventQuestionnaireId),
      })
    }
  }
}

function* fetchDocumentQuestionnaireScoreWatcher() {
  yield takeEvery(
    DocumentAnswerValuesDomainActionsTypes.FETCH_SCORE,
    fetchDocumentQuestionnaireScoreWorker,
  )
}

export const domainAnswerValuesSagas = {
  fetchDocumentAnswerValuesWatcher,
  fetchDocumentQuestionnaireScoreWatcher,
}
