import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { getNextPageParam } from '../../../misc/query.utilities'
import { questionTemplatesKeys } from './questionTemplate.keys'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData } from '../../utils/query/useFlatPaginatedData.hook'
import { usePaginationState } from '../../utils/query'
import { useCallback, useMemo } from 'react'
import { singleItemPageLimit, startingPage } from '../../../constants'
import { getQuestionTemplates } from '../../../data/questionTemplates'

interface GetInfiniteQuestionTemplatesParams {
  enabled?: boolean
  filters?: Filters
  limit?: number
}

export const useGetInfiniteQuestionTemplates = ({
  enabled = true,
  filters,
  limit = singleItemPageLimit,
}: GetInfiniteQuestionTemplatesParams = {}) => {
  const queryKey = useMemo(() => questionTemplatesKeys.list(filters), [filters])

  const queryReturn = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getQuestionTemplates(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des questions`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
    initialPageParam: startingPage,
    getNextPageParam,
  })

  const queryClient = useQueryClient()

  const questionList = useFlatPaginatedData(queryReturn.data)
  const paginationState = usePaginationState(queryReturn.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query: queryReturn, questionList, paginationState, cancelPendingQuery }
}
