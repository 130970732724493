import { useMutation } from '@tanstack/react-query'
import { duplicateSurveyToAnotherUser } from '../../../data/survey'
import { UserFriendlyError } from '../../../model/Error'
import { addValid } from '../../../store/message'
import { useActionDispatch } from '../../utils'

interface UseDuplicateSurveyToAnotherUserVariables {
  surveyId: number
  userId: string
}

export const useDuplicateSurveyToAnotherUser = () => {
  const dispatchValid = useActionDispatch(addValid)

  return useMutation<void, Error, UseDuplicateSurveyToAnotherUserVariables>({
    mutationFn: async ({ surveyId, userId }) => {
      const { ok } = await duplicateSurveyToAnotherUser({ surveyId, userId })

      if (!ok) {
        throw new UserFriendlyError('error', 'La duplication a échoué')
      }
    },
    onSuccess: (_, { userId }) => {
      dispatchValid(
        'Duplication réalisée',
        `Le questionnaire a bien été dupliqué sur l'utilisateur ${userId} !`,
      )
    },
  })
}
