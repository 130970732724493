import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { switchPrescriptionToVMP } from '../../../data/prescriptions'
import { UserFriendlyError } from '../../../model/Error'
import { Prescription } from '../../../model/Prescription'
import { documentInstancesActions } from '../../../store/cache/documentInstances'
import { documentTemplatesActions } from '../../../store/cache/documentTemplates'
import { addWarning } from '../../../store/message'
import { getVariableData } from '../../../store/renderer'
import { useActionDispatch } from '../../utils'

interface UseSwitchPrescriptionToVMPVariables {
  prescription: Prescription
}

export const useSwitchPrescriptionToVMP = () => {
  const dispatchWarning = useActionDispatch(addWarning)
  const dispatch = useDispatch()

  return useMutation<Prescription, Error, UseSwitchPrescriptionToVMPVariables>({
    mutationFn: async ({ prescription }) => {
      const { ok, data } = await switchPrescriptionToVMP(prescription.uuid)

      if (!ok || !data) {
        throw new UserFriendlyError('error', 'La conversion en dénomination commune a échoué')
      }

      const baseLength = prescription.posologyIntervals.length
      const newLength = data.posologyIntervals.length

      if (baseLength > 0 && baseLength !== newLength) {
        dispatchWarning(
          newLength === 0
            ? "La posologie n'a pas pu être conservée"
            : "Une partie de la posologie n'a pas pu être conservée",
          'Le médicament de base et la dénomination commune utilisent des unités incompatibles',
        )
      }

      return data
    },
    onSuccess: (_, { prescription }) => {
      if (prescription.documentId) {
        dispatch(getVariableData())
        dispatch(documentInstancesActions.actions.apiGetItemDetails(prescription.documentId))
      } else if (prescription.documentTemplateId) {
        dispatch(
          documentTemplatesActions.actions.apiGetItemDetails(prescription.documentTemplateId),
        )
      }
    },
  })
}
