import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getCountries } from '../../../data/countries'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { countriesKeys } from './countryKeys'

interface UseGetInfiniteCountriesParams {
  filters: Filters
  enabled?: boolean
  limit?: number
}

export const useGetInfiniteCountries = ({
  filters,
  enabled = true,
  limit,
}: UseGetInfiniteCountriesParams) => {
  const queryKey = useMemo(() => countriesKeys.list(filters), [filters])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getCountries(pageParam, filters, signal, limit)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer la liste des pays',
          "Une erreur technique s'est produite",
        )
      }

      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    enabled,
  })

  const queryClient = useQueryClient()
  const countryList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, countryList, paginationState, cancelPendingQuery }
}
