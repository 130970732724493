import { atomWithStorage } from 'jotai/utils'
import { AUTHENTICATION_ID_MAIL, TMP_AUTHENTICATION_ID_MAIL } from '../../model/Mail'

export const mailWebserviceAuthToken = atomWithStorage<string | null>(
  AUTHENTICATION_ID_MAIL,
  null,
  undefined,
  {
    getOnInit: true,
  },
)

export const mailWebserviceAuthFirstStepToken = atomWithStorage<string | null>(
  TMP_AUTHENTICATION_ID_MAIL,
  null,
  undefined,
  {
    getOnInit: true,
  },
)
