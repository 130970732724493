import { useMutation, useQueryClient } from '@tanstack/react-query'
import { publishNotification } from '../../../../data/admin'
import { checkIfPagesContainItem } from '../../../../misc/query.utilities'
import { UserFriendlyError } from '../../../../model/Error'
import { ServerSentNotification } from '../../../../model/Notification'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { adminNotifKeys } from './adminNotifications.keys'

export const usePublishNotification = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation({
    mutationFn: async (id: string) => {
      const { ok, data } = await publishNotification(id)
      if (!ok || !data) {
        throw new UserFriendlyError('error', 'Impossible de publier la notification')
      }
      return data
    },

    onSuccess: (_data, notificationId) => {
      valid('La notification a été publiée')
      queryClient.refetchQueries({
        queryKey: adminNotifKeys.all,
        predicate: (query) =>
          checkIfPagesContainItem<ServerSentNotification>(query.state.data, notificationId),
      })
    },
  })

  return queryReturn
}
