import { combineReducers } from 'redux'
import { createReducer } from 'redux-starter-kit'

import { medicalEventDocumentInstancesReducer as medicalEventDocumentInstances } from './medicalEventDocumentInstances/medicalEventDocumentInstances.reducer'
import { medicalEventFilesReducer as medicalEventFiles } from './medicalEventFiles/medicalEventFiles.reducer'
import { medicalEventContentReducer as medicalEventContent } from './medicalEventContent/medicalEventContent.reducer'
import { inUseReducerFactory, paginationReducerFactory } from '../../restux/ui/index'
import {
  medicalEventConfig,
  MedicalEventState,
  MedicalEventsUiActionTypes,
  MedicalEventFilterState,
  MedicalEventAddFileState,
} from './medicalEvents.model'
import { medicalEventUiActions } from './medicalEvents.actions'

const inUse = inUseReducerFactory(medicalEventConfig)
const pagination = paginationReducerFactory(medicalEventConfig)

const FILTER_INITIAL_STATE: MedicalEventFilterState = {
  documentInstance: null,
  questionnaire: null,
}
const FILTER_ACTION_HANDLERS = {
  [MedicalEventsUiActionTypes.FILTER_DOCUMENT]: (
    state: MedicalEventFilterState = FILTER_INITIAL_STATE,
    { filter }: ReturnType<typeof medicalEventUiActions.selectMedicalEventDocument>,
  ) => ({ ...state, documentInstance: filter }),

  [MedicalEventsUiActionTypes.CLEAR_FILTERS]: () => FILTER_INITIAL_STATE,
}

const ADD_FILE_INITIAL_STATE: MedicalEventAddFileState = { displayDropzone: false }
const ADD_FILE_ACTION_HANDLERS = {
  [MedicalEventsUiActionTypes.DISPLAY_ADD_FILE_DROPZONE]: (
    state: MedicalEventAddFileState = ADD_FILE_INITIAL_STATE,
  ) => ({ ...state, displayDropzone: true }),

  [MedicalEventsUiActionTypes.HIDE_ADD_FILE_DROPZONE]: (
    state: MedicalEventAddFileState = ADD_FILE_INITIAL_STATE,
  ) => ({ ...state, displayDropzone: false }),
}

export const medicalEventReducer = combineReducers<MedicalEventState>({
  inUse,
  pagination,
  documentInstances: medicalEventDocumentInstances,
  files: medicalEventFiles,
  medicalEventContent,
  filters: createReducer<MedicalEventFilterState, any>(
    FILTER_INITIAL_STATE,
    FILTER_ACTION_HANDLERS,
  ),
  addFile: createReducer<MedicalEventAddFileState, any>(
    ADD_FILE_INITIAL_STATE,
    ADD_FILE_ACTION_HANDLERS,
  ),
})
