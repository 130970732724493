import { patientStatisticsKeys } from './patientStatistics.keys'
import { useQuery } from '@tanstack/react-query'
import { getPatientStatistics } from '../../../data/patientStatistics'
import { UserFriendlyError } from '../../../model/Error'

interface GetPatientStatisticsParams {
  enabled: boolean
  patientId: number
}
export const useGetPatientStatistics = ({
  enabled = true,
  patientId,
}: GetPatientStatisticsParams) => {
  const queryResult = useQuery({
    queryKey: patientStatisticsKeys.detail(patientId),

    queryFn: async () => {
      const { data, ok } = await getPatientStatistics(patientId)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Impossible de récupérer les statistiques du patient')
      }
      return data
    },
    enabled,
  })

  return { query: queryResult }
}
