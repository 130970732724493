import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { deleteTask } from '../../../data/task/deleteTask'
import { taskKeys } from './tasks.keys'
import { Task } from '../../../model/Task'
import { checkIfPagesContainItem } from '../../../misc/query.utilities'

interface TaskMutateVariable {
  taskId: number
}

export const useDeleteTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ taskId }: TaskMutateVariable) => {
      const { ok } = await deleteTask(taskId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la tâche a échoué')
      }
      return null
    },

    onSuccess: (_data, { taskId }) => {
      queryClient.refetchQueries({
        queryKey: taskKeys.all,
        predicate: (query) => checkIfPagesContainItem<Task>(query.state.data, taskId),
      })
    },
  })
}
