import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import { computeAgeLabel, getDisplayedName } from '../../../misc/patient.utilities'
import { LightPatient, Sex } from '../../../model/Patient'
import { IdentityCard, DropdownItem, Icon, SearchInput } from '../../shared'
import { PatientDropDownItemSelectorOwnProps } from './PatientDropDownItemSelector.model'
import { useGetInfinitePatient } from '../../../hooks/queries/patients'
import { isEqual } from 'lodash'

const PATIENT_FILTERS_INITIAL_STATE = { search: '' }

export const PatientDropDownItemSelector: FunctionComponent<
  PatientDropDownItemSelectorOwnProps
> = ({ selectedPatient, setSelectedPatient, hasInseeNumber = false, disabled = false }) => {
  const [patientSearchText, setPatientSearchText] = useState('')
  const [patientFilters, setPatientFilters] = useState(PATIENT_FILTERS_INITIAL_STATE)
  const [patients, setPatients] = useState<LightPatient[]>([])
  const {
    query: { isLoading },
    patientList,
  } = useGetInfinitePatient({
    enabled: !isEqual(patientFilters, PATIENT_FILTERS_INITIAL_STATE),
    filters: { ...patientFilters, hasInseeNumber },
  })

  useEffect(() => {
    if (!isLoading) {
      setPatients(patientList)
    }
  }, [patientList, isLoading])

  const renderPatientOption = (patient: LightPatient, isHovered: boolean) => {
    const { firstname, lastname } = getDisplayedName(patient)
    return (
      <DropdownItem selected={isHovered}>
        <div className="flex items-center space-x-2">
          {patient.sex !== Sex.UNKNOWN && (
            <Icon
              icon={patient.sex === Sex.FEMALE ? 'female' : 'male'}
              size="micro"
              color={getV3ColorCssVariable('shades', 'shade2')}
            />
          )}
          <div key={patient.id} className="flex flex-col">
            <span className="inline">
              {firstname} <span className="font-bold">{lastname}</span>
            </span>
            <span className="text-sm text-shades-4">{computeAgeLabel(patient.birthDate)}</span>
          </div>
        </div>
      </DropdownItem>
    )
  }

  useDebounce(
    () => {
      setPatientFilters((prev) => {
        return patientSearchText.length >= 3
          ? { ...prev, search: patientSearchText }
          : { ...prev, search: PATIENT_FILTERS_INITIAL_STATE.search }
      })
    },
    500,
    [patientSearchText],
  )

  const handleSelectPatient = useCallback(
    (selected: LightPatient) => {
      setSelectedPatient(selected)
      setPatientSearchText('')
    },
    [setSelectedPatient, setPatientSearchText],
  )

  const displayedName = selectedPatient ? getDisplayedName(selectedPatient) : null

  return (
    <>
      <SearchInput
        placeholder="Rechercher un patient"
        value={patientSearchText}
        onChange={({ target: { value } }) => setPatientSearchText(value)}
        results={patients}
        renderResult={renderPatientOption}
        onSelect={handleSelectPatient}
        disabled={disabled}
      />
      {selectedPatient && (
        <div className="mt-2">
          <IdentityCard
            title={
              displayedName ? `${displayedName.firstname} ${displayedName.lastname}` : 'Inconnu'
            }
            icon={selectedPatient.sex === Sex.FEMALE ? 'female' : 'male'}
            subtitle={computeAgeLabel(selectedPatient.birthDate)}
            onRemove={() => setSelectedPatient(undefined)}
            disabled={disabled}
          />
        </div>
      )}
    </>
  )
}
