import React, { FunctionComponent } from 'react'
import { Badge, Icon } from '../../../shared'
import { TaskSectionProps } from './TaskSection.model'
import { TaskNotFound } from '../TaskNotFound'
import { TaskStatus } from '../../../../model/Task'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { TaskTable } from '../../TaskTable'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

export const TaskSection: FunctionComponent<TaskSectionProps> = ({
  tasksStatus,
  itemCount,
  tasks,
  onlyDocumentLinked,
  displayColumns,
  onCreateOrUpdate,
  onDeleteTask,
  totalItemCount,
  removeNewTask,
  onDeleteAllDoneTasks,
  openTaskInformation,
  openCdaInformation,
  testId,
}) => {
  return (
    <div data-test-id={addTestIdSuffix('root', testId)}>
      <div className="flex flex-row items-center">
        <h2 className="text-lg mr-3">
          {tasksStatus === TaskStatus.TODO
            ? onlyDocumentLinked
              ? 'Documents à traiter'
              : 'À réaliser'
            : tasksStatus === TaskStatus.DONE
              ? onlyDocumentLinked
                ? 'Documents traités'
                : 'Terminée'
              : null}
        </h2>
        <Badge value={itemCount} size="large" testId={addTestIdSuffix('item-count', testId)} />
        {tasksStatus === TaskStatus.DONE && tasks.length > 0 && !onlyDocumentLinked && (
          <div
            className="inline-flex leading-none items-center ml-auto font-semibold text-primary-default cursor-pointer space-x-1"
            data-test-id={addTestIdSuffix('', testId)}
            onClick={() => onDeleteAllDoneTasks && onDeleteAllDoneTasks(false)}
          >
            <Icon icon="trash" size="nano" color={getV3ColorCssVariable('primary', 'default')} />
            <span>Effacer les tâches terminées</span>
          </div>
        )}
        {tasksStatus === TaskStatus.DONE && tasks.length > 0 && onlyDocumentLinked && (
          <div
            className="inline-flex leading-none items-center ml-auto font-semibold text-primary-default cursor-pointer space-x-1"
            data-test-id={addTestIdSuffix('', testId)}
            onClick={() => onDeleteAllDoneTasks && onDeleteAllDoneTasks(true)}
          >
            <Icon icon="trash" size="nano" color={getV3ColorCssVariable('primary', 'default')} />
            <span>Effacer les tâches documents traitées</span>
          </div>
        )}
      </div>
      {tasks.length > 0 && (
        <div className="mb-8">
          <TaskTable
            tasks={tasks}
            onCreateOrUpdate={onCreateOrUpdate}
            displayColumns={displayColumns}
            onDeleteTask={onDeleteTask}
            removeNewTask={removeNewTask}
            openTaskInformation={openTaskInformation}
            openCdaInformation={openCdaInformation}
            testId={addTestIdSuffix('table', testId)}
          />
        </div>
      )}
      {totalItemCount > 0 && tasks.length === 0 && <TaskNotFound />}
    </div>
  )
}
