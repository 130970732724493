import { useMutation, useQueryClient } from '@tanstack/react-query'
import { lockUser, unlockUser } from '../../../../data/admin'
import { LightUser } from '../../../../model/User'
import { UserFriendlyError } from '../../../../model/Error'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { userKeys } from './users.keys'
import { checkIfPagesContainItem } from '../../../../misc/query.utilities'

interface UserLockVariables {
  userId: string
  lock: boolean
}

export const useLockUser = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation({
    mutationFn: async ({ userId, lock }: UserLockVariables) => {
      const endpoint = lock ? lockUser : unlockUser
      const { ok } = await endpoint(userId)
      if (!ok) {
        throw new UserFriendlyError(
          'error',
          `Le ${lock ? '' : 'dé'}verrouillage de l'utilisage a échoué`,
        )
      }
      return null
    },

    onSuccess: (_data, { userId, lock }) => {
      valid(`Utilisateur ${lock ? '' : 'dé'}verrouillé`)
      queryClient.refetchQueries({
        queryKey: userKeys.all,
        predicate: (query) => checkIfPagesContainItem<LightUser>(query.state.data, userId),
      })
    },
  })

  return queryReturn
}
