import { ComponentType } from 'react'

import {
  BottomPanelComponentType,
  BottomPanelState,
  setBottomPanelOptions,
  toggleExpandBottomPanel,
} from '../../store/ui/bottomPanel'

import SearchBaseVariable from '../BottomSearchPanel/SearchBaseVariable'
import SearchDocumentHistory from '../BottomSearchPanel/SearchDocumentHistory'
import { DocumentAlertsPanel } from '../../components/document'
import { AssistantPermissions } from '../../components/user/AssistantPermissions'
import { EditNotificationPanel } from '../Notification'
import { PublishEphemeralNotificationPanel } from '../EphemeralNotification'

export interface BottomPanelWrapperDispatchProps {
  setBottomPanelOptions: typeof setBottomPanelOptions
  toggleExpandBottomPanel: typeof toggleExpandBottomPanel
}

export type BottomPanelWrapperStateProps = BottomPanelState
export type BottomPanelWrapperProps = BottomPanelWrapperStateProps & BottomPanelWrapperDispatchProps

type ComponentTypes = { [code in BottomPanelComponentType]: ComponentType<any> }

export const BOTTOM_PANEL_COMPONENTS: ComponentTypes = {
  [BottomPanelComponentType.SearchBaseVariable]: SearchBaseVariable,
  [BottomPanelComponentType.EditNotification]: EditNotificationPanel,
  [BottomPanelComponentType.SearchDocumentHistory]: SearchDocumentHistory,
  [BottomPanelComponentType.PublishEphemeralNotification]: PublishEphemeralNotificationPanel,
  [BottomPanelComponentType.DocumentAlerts]: DocumentAlertsPanel,
  [BottomPanelComponentType.AssistantPermissions]: AssistantPermissions,
}
