import { combineReducers } from 'redux'
import { documentAnswerValuesReducer as documentAnswerValues } from './documentAnswerValues/documentAnswerValues.reducer'
import { documentTemplatesReducer as documentTemplates } from './documentTemplates/documentTemplates.reducer'
import { meReducer as me } from './me/me.reducer'
import { codesReducer as codes } from './codes/codes.reducer'
import { DomainState } from './domain.model'
import { externalServicesReducer as externalServices } from './externalServices/externalServices.reducer'
import { documentAlertsReducer as documentAlerts } from './documentAlerts/documentAlerts.reducer'
import { dmpReducer as dmp } from './dmp'
import { metricsReducer as metrics } from './metrics/metrics.reducer'
import { domainEditorReducer } from './editor/editor.reducer'

export default combineReducers<DomainState>({
  documentAnswerValues,
  documentTemplates,
  documentAlerts,
  externalServices,
  editor: domainEditorReducer,
  me,
  codes,
  dmp,
  metrics,
})
