import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { getMailList } from '../../../data/mail'
import { isDefined } from '../../../misc/functions.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { DEFAULT_LIMIT_MAIL_LIST, DEFAULT_OFFSET_MAIL_LIST } from '../../../model/Mail'
import { mailErrorsTitle, mailErrorsMessage } from './mail.errors'
import { mailKeys } from './mail.keys'

interface GetMailListParams {
  search?: string
  enabled?: boolean
}

const limit = DEFAULT_LIMIT_MAIL_LIST

export const useGetMailList = ({ search = '', enabled = true }: GetMailListParams) => {
  const queryKey = useMemo(() => mailKeys.list({ search }), [search])
  const queryClient = useQueryClient()

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getMailList(pageParam, limit, signal, search)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorMail,
          mailErrorsMessage.errorWhileFetchingAllMail,
        )
      }

      return data
    },
    initialPageParam: DEFAULT_OFFSET_MAIL_LIST,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.itemsCount < limit ? undefined : allPages.length + 1,
    enabled,
  })

  const mailList = useMemo(() => {
    const items = query.data?.pages.filter(isDefined).flatMap(({ items }) => items) ?? []
    return items
  }, [query.data?.pages])

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, mailList, cancelPendingQuery }
}
