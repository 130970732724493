import classNames from 'classnames'
import { FC } from 'react'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { DATE_FORMAT_FRONT_SHORT, formatFr, formatStringFr } from '../../../../misc/date.utilities'
import { MailFlag } from '../../../../model/Mail'
import { Icon } from '../../../shared'
import { MailListItemProps } from './MailListItem.model'
import { Link } from 'react-router-dom'

export const MailListItem: FC<MailListItemProps> = ({ mail }) => (
  <Link
    to={`/mail/inbox/${mail.id}`}
    className={classNames('p-2 flex flex-col w-full', {
      'font-semibold': mail.flags?.includes(MailFlag.UNREAD),
    })}
  >
    <span
      title={mail.subject !== '' ? mail.subject : 'aucun objet'}
      className="mb-1 text-shades-2 text-base truncate pr-2 max-w-screen-1/6"
    >
      {mail.subject !== '' ? mail.subject : 'aucun objet'}
    </span>
    <div
      title={mail.nameFrom ? mail.nameFrom + ' - ' + mail.emailFrom : mail.emailFrom}
      className="truncate pr-4 max-w-screen-1/6"
    >
      {mail.nameFrom ? `${mail.nameFrom} <${mail.emailFrom}>` : mail.emailFrom}
    </div>
    <div className="flex justify-between mt-1">
      <div className="flex items-center">
        <Icon icon="calendar" size="nano" color={getV3ColorCssVariable('shades', 'shade4')} />
        <span
          className="ml-2 text-shades-4 text-xs font-medium"
          title={formatFr(new Date(mail.date.date), 'dd/MM/yyyy HH:mm:ss')}
        >
          {formatStringFr(mail.date.date, DATE_FORMAT_FRONT_SHORT)}
        </span>
      </div>
      {mail.attachmentCount > 0 && (
        <div className="flex items-center">
          <span className="mr-2 text-shades-4 text-xs font-medium">{mail.attachmentCount}</span>
          <Icon icon="paperClip" size="nano" color={getV3ColorCssVariable('shades', 'shade4')} />
        </div>
      )}
    </div>
  </Link>
)
