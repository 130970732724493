import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateContact } from '../../../data/contact'
import { Contact } from '../../../model/Contact'
import { UserFriendlyError } from '../../../model/Error'
import { patientsKeys } from '../patients/patients.keys'
import { recipientSuggestionsKeys } from '../recipientSuggestions/recipientSuggestions.keys'
import { contactsKeys } from './contacts.keys'

interface UpdateContactVariables {
  id: string
  contactPayload: Partial<Contact>
  patientId?: number
}

export const useUpdateContact = () => {
  const queryClient = useQueryClient()

  const mutate = useMutation({
    mutationFn: async ({ id, contactPayload }: UpdateContactVariables) => {
      const { data, ok } = await updateContact(id, contactPayload)

      if (!data || !ok) {
        throw new UserFriendlyError('error', "L'édition du contact a échoué")
      }
      return data
    },

    onSuccess: (contact, { patientId }) => {
      queryClient.invalidateQueries({ queryKey: contactsKeys.detail(contact.id) })
      queryClient.invalidateQueries({ queryKey: recipientSuggestionsKeys.all })
      patientId && queryClient.invalidateQueries({ queryKey: patientsKeys.detail(patientId) })
    },
  })

  return mutate
}
