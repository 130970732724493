import { FollowTypes } from '@follow/cdk'
import { Profession, Speciality } from './Professions'

export type BaseContact = FollowTypes.Contact.BaseContact
export type ContactOrganization = FollowTypes.Contact.ContactOrganization
export type Contact = FollowTypes.Contact.Contact
export type Recipient = FollowTypes.Contact.Recipient
export type RecipientContact = FollowTypes.Contact.RecipientContact
export type MailContact = FollowTypes.Mail.MailContact
export type MssEmail = FollowTypes.Mail.MssEmail
export type ContactConfiguration = FollowTypes.Contact.ContactConfiguration
export type ContactStartGreeting = FollowTypes.Contact.ContactStartGreeting
export type ContactEndGreeting = FollowTypes.Contact.ContactEndGreeting

export interface City {
  id: string
  name: string
  zipCode: string
  latitude: number
  longitude: number
  inseeCode: string
  department: string
}

export interface Country {
  id: string
  name: string
  isoCode?: string
  inseeCode?: number
}

export enum PP_IDENTIFIER_TYPE {
  RPPS = 8,
  ADELI = 0,
}

export interface SuggestionContact {
  uuid: string
  title: string
  firstName: string
  familyName: string
  profession: Profession | null
  speciality: Speciality | null
  organizations: ContactOrganization[]
  private: boolean
}

export type GenericContact = RecipientContact | Contact
