import classNames from 'classnames/bind'
import React, { PropsWithChildren } from 'react'
import { ColorsV2 } from '../../../../../design-system/colors_v2'
import { Icon } from '../../../Icon'

import { SideSelectItemProps } from './SideSelectItem.model'

import styles from './SideSelectItem.module.scss'

const cx = classNames.bind(styles)

export function SideSelectItem({
  active,
  colorPreset,
  children,
  icon,
  onClick,
}: PropsWithChildren<SideSelectItemProps>) {
  return (
    <div
      className={cx(styles.item, { [colorPreset]: true, active, inactive: !active })}
      onClick={() => onClick()}
      role="button"
    >
      <div className={styles.icon}>
        <Icon
          icon={icon}
          color={active || colorPreset === 'dark' ? ColorsV2.dsLight9 : ColorsV2.dsDark2}
          size="micro"
        />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
