import { FeaturesType } from '@follow/cdk'
import { useQueries } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getPatients } from '../../../data/patients'
import { SECURITE_SOCIAL_NUMBER_REGEX_WITHOUT_SPACE } from '../../../misc/inseeNumber.utils'
import { isIdentityStateQualified } from '../../../misc/patient.utilities'
import { emailRegex } from '../../../misc/regex'
import { LightPatient } from '../../../model/Patient'
import { addError } from '../../../store/message'
import { useActionDispatch } from '../../utils'
import { useUserEnabledFeature } from '../../utils/user'
import { mailErrorsMessage } from './mail.errors'
import { mailKeys } from './mail.keys'

interface UseAllowPatientsMailUseParams {
  emails: Array<string>
}

type UseAllowPatientsMailUseResult = {
  status: 'forbidden' | 'unverified' | 'ok'
  patient: LightPatient
  email: string
}

/** Si parmi les emails fournies, des emails patients sont présents,
 *    vérifie que le patient en question n'a pas déclaré ne pas souhaité être
 *    contacté par la MSS.
 *  @returns
 *  - null si pas de patient correspondant
 *  - 'forbidden' si le patient ne souhaite pas être contacté
 *  - 'unverified' si l'identité du patient n'est pas vérifié (utilisateurs INSi)
 *  - 'ok' sinon
 */
export const useAllowPatientsMail = ({ emails }: UseAllowPatientsMailUseParams) => {
  const insiService = useUserEnabledFeature(FeaturesType.insiValidation)

  const dispatchError = useActionDispatch(addError)

  const queries = useQueries({
    queries: emails.map((emailAddress) => ({
      queryKey: mailKeys.allowPatientEmailUse(emailAddress),
      queryFn: async (): Promise<UseAllowPatientsMailUseResult | null> => {
        const emailParts = emailRegex.exec(emailAddress)
        const emailUsername = emailParts?.at(1)

        // L'adresse email n'est pas correctement formaté
        if (!emailUsername) return null

        const isInseeNumber = SECURITE_SOCIAL_NUMBER_REGEX_WITHOUT_SPACE.test(emailUsername)
        if (!isInseeNumber) return null

        // Recherche des patients correspondants à cet email
        const { data, ok } = await getPatients(1, { search: emailUsername }, undefined, 1)
        const patient = data?.items.at(0)

        // Pas de patient correspondant dans la liste patient
        if (!data || !ok || !patient) return null

        if (patient.disallowMSSMessaging) {
          dispatchError(
            "Impossible d'envoyer le message",
            `Le patient ${patient.birthFirstName} ${patient.birthLastName} (${emailUsername}) refuse d'être contacté via la messagerie de santé sécurisée`,
          )
          return { status: 'forbidden', patient, email: emailUsername }
        }

        if (insiService && !isIdentityStateQualified(patient.identityStatus)) {
          dispatchError(
            mailErrorsMessage.invalidInsi,
            `Vérifiez l'identité du patient ${patient.birthFirstName} ${patient.birthLastName} (${emailUsername})`,
          )
          return { status: 'unverified', patient, email: emailUsername }
        }

        return { status: 'ok', patient, email: emailUsername }
      },
    })),
  })

  const blackListedPatients = useMemo(
    () =>
      queries
        .filter((query) => query.data?.status === 'forbidden')
        .map((query) => query.data ?? null),
    [queries],
  )

  const unqualifiedPatients = useMemo(
    () =>
      queries
        .filter((query) => query.data?.status === 'unverified')
        .map((query) => query.data ?? null),
    [queries],
  )

  return { queries, blackListedPatients, unqualifiedPatients }
}
