import React, { FC } from 'react'
import { CreatePatientThroughMailModalProps } from './CreatePatientThroughMailModal.model'
import { Heading } from '../../shared'
import { ValidationModal } from '../../shared/modal/ValidationModal/ValidationModal.component'

export const CreatePatientThroughMailModal: FC<CreatePatientThroughMailModalProps> = ({
  isUnmounting,
  onSubmit,
  onClose,
}) => {
  return (
    <ValidationModal
      isUnmounting={isUnmounting}
      title="La vérification via le téléservice INSi a échoué pour ce patient"
      onSubmit={onSubmit}
      onClose={onClose}
      confirmTheme="primary"
    >
      <Heading size={3}>Souhaitez-vous tout de même créer ce patient?</Heading>
    </ValidationModal>
  )
}
