import { FunctionComponent, useCallback, useState } from 'react'
import { PrescriptionTemplateListProps } from './PrescriptionTemplateList.model'
import { DropdownContainer, DropdownItem, IconButton } from '../../../shared'
import styles from './PrescriptionTemplateList.module.scss'
import { PrescriptionTemplate } from '../../../../model/PrescriptionTemplate'
import {
  useDeletePrescriptionTemplate,
  useGetInfinitePrescriptionTemplates,
} from '../../../../hooks/queries/prescriptionTemplates'

export const PrescriptionTemplateList: FunctionComponent<PrescriptionTemplateListProps> = ({
  drugs,
  onSelectPrescriptionTemplate,
}) => {
  const [opened, setOpened] = useState(false)

  const { prescriptionTemplateList } = useGetInfinitePrescriptionTemplates({
    drugs,
    enabled: drugs.length > 0,
  })
  const { mutate: deletePrescriptionTemplate } = useDeletePrescriptionTemplate()

  const handleDeletePrescriptionTemplate = useCallback(
    (prescriptionTemplate: PrescriptionTemplate) => {
      deletePrescriptionTemplate({
        id: prescriptionTemplate.uuid,
        drugs: drugs,
      })
    },
    [deletePrescriptionTemplate, drugs],
  )

  if (!prescriptionTemplateList || prescriptionTemplateList.length < 1) return null
  return (
    <div className={styles.buttonWrapper}>
      <IconButton
        icon="star"
        showOptionsCaret={true}
        onClick={(e) => {
          e.stopPropagation()
          setOpened(!opened)
        }}
      />
      <div className={styles.dropdownWrapper}>
        <DropdownContainer
          verticalScroll={false}
          opened={opened}
          closeDropdown={() => setOpened(false)}
        >
          {prescriptionTemplateList.map((prescriptionTemplate) => (
            <div className={styles.dropdownItemWrapper} key={prescriptionTemplate.id}>
              <DropdownItem
                layout="thin"
                onClick={(e) => {
                  e.stopPropagation()
                  onSelectPrescriptionTemplate(prescriptionTemplate)
                }}
              >
                <div className={styles.dropdownItemLabelWrapper}>{prescriptionTemplate.name}</div>
                <div className={styles.dropdownItemActionsWrapper}>
                  <IconButton
                    icon="trash"
                    title="Supprimer"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDeletePrescriptionTemplate(prescriptionTemplate)
                    }}
                    theme="light"
                    noBorder
                  />
                </div>
              </DropdownItem>
            </div>
          ))}
        </DropdownContainer>
      </div>
    </div>
  )
}
