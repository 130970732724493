import React, { FC, useContext } from 'react'
import { BubbleMenu, useCurrentEditor } from '@tiptap/react'
import { ToolbarContainer, ToolbarButton, ToolbarSeparator } from '@/components/toolbar/atoms'
import { PluginNames } from '@/model/Plugins'
import { isDefined } from '@/utils/functions.utils'
import { IMAGE_DEFAULT_WIDTH } from '../imageResize.plugin'
import { EditorConfigContext } from '@/context'

const IMAGE_RESIZE_DELTA = 5

export const ImageResizeBubbleMenu: FC = () => {
  const { editor } = useCurrentEditor()
  const { tools } = useContext(EditorConfigContext)

  if (!editor || !tools.includes('image')) return null

  const updateAttributes = (attrbs: Record<string, any>) => {
    const selection = editor.state.selection.from
    editor
      .chain()
      .focus()
      .updateAttributes(PluginNames.ImageResize, attrbs)
      .setNodeSelection(selection)
      .run()
  }

  const onFloat = (float: 'left' | 'right') => {
    updateAttributes({
      float,
      textAlign: null,
    })
  }
  const onAlign = (textAlign: 'right' | 'center' | 'left') => {
    updateAttributes({
      float: null,
      textAlign,
    })
  }
  const onSizeChange = (sign: 1 | -1) => {
    const currentWidth: number =
      editor.getAttributes(PluginNames.ImageResize)['width'] || IMAGE_DEFAULT_WIDTH
    if (!isDefined(currentWidth) || !Number.isInteger(currentWidth)) return
    const newWidth = currentWidth + IMAGE_RESIZE_DELTA * sign
    updateAttributes({ width: newWidth })
  }

  return (
    <BubbleMenu
      editor={editor}
      shouldShow={({ editor }) => editor.isActive(PluginNames.ImageResize)}
      pluginKey={PluginNames.ImageResize}
    >
      <ToolbarContainer>
        <ToolbarButton
          icon="ImageFloatLeft"
          title="Image flottante à gauche"
          onClick={() => onFloat('left')}
        />
        <ToolbarButton
          icon="ImageFloatRight"
          title="Image flottante à droite"
          onClick={() => onFloat('right')}
        />
        <ToolbarSeparator />
        <ToolbarButton
          icon="ImageAlignLeft"
          title="Aligner à gauche, séparée du texte"
          onClick={() => onAlign('left')}
        />
        <ToolbarButton
          icon="ImageAlignCenter"
          title="Centrer, séparée du texte"
          onClick={() => onAlign('center')}
        />
        <ToolbarButton
          icon="ImageAlignRight"
          title="Aligner à droite, séparée du texte"
          onClick={() => onAlign('right')}
        />
        <ToolbarSeparator />
        <ToolbarButton icon="Minus" title="Rétrécir" onClick={() => onSizeChange(-1)} />
        <ToolbarButton icon="Plus" title="Agrandir" onClick={() => onSizeChange(1)} />
      </ToolbarContainer>
    </BubbleMenu>
  )
}
