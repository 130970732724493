import { FeaturesType } from '@follow/cdk'
import React, { FunctionComponent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'
import { useDeleteMail } from '../../../../hooks/queries/mail'
import { useUserEnabledFeature } from '../../../../hooks/utils/user'
import { formatFr } from '../../../../misc/date.utilities'
import { formatMailReply } from '../../../../misc/mail.utilities'
import { DeleteValidationModal, Icon, IconButton, TooltipWrapper } from '../../../shared'
import { MailEditor } from '../../MailEditor'
import { MailHeaderProps } from './MailHeader.model'
import styles from './MailHeader.module.scss'

export const MailHeader: FunctionComponent<MailHeaderProps> = ({ inUseMail }) => {
  const [showDeleteMailModal, setShowDeleteMailModal] = useState<boolean>(false)
  const [showMailEditor, setShowMailEditor] = useToggle(false)

  const isMssSmtpEnabled = useUserEnabledFeature(FeaturesType.mssSmtp)
  const { mutate: deleteMail } = useDeleteMail()
  const navigate = useNavigate()

  const name = inUseMail.nameFrom
    ? `${inUseMail.nameFrom} <${inUseMail.emailFrom}>`
    : inUseMail.emailFrom

  const handleDeleteMail = () => {
    setShowDeleteMailModal(true)
  }

  const handleDeleteMailValidation = (): void => {
    deleteMail(
      {
        mailId: inUseMail.id,
      },
      {
        onSuccess: () => {
          navigate('/mail/inbox')
        },
      },
    )
    setShowDeleteMailModal(false)
  }

  const mailReply = useMemo(
    () => ({
      subject: 'À propos de notre conversation',
      content: formatMailReply(inUseMail),
      recipients: [inUseMail.emailFrom],
    }),
    [inUseMail],
  )

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-grow-2 flex-col mr-5 justify-between min-w-0 shrink">
          <h1 className="text-shades-2 font-semibold text-3xl">
            {'' !== inUseMail.subject && inUseMail ? inUseMail.subject : 'aucun objet'}
          </h1>
          <span className={styles['sender']} title={name}>
            {name}
          </span>
        </div>
        <div className="flex-col items-end self-end justify-between shrink-0">
          <div className="flex justify-end mb-4">
            <IconButton icon="upLeft" theme="light" onClick={() => setShowMailEditor(true)} />
            {isMssSmtpEnabled && (
              <TooltipWrapper pointerDirection="left" content="Supprimer le message">
                <div>
                  <IconButton icon="trash" theme="light" onClick={handleDeleteMail} />
                </div>
              </TooltipWrapper>
            )}
          </div>
          <div className="flex flex-grow text-shades-4 fill-shades-4 justify-end">
            <Icon icon="calendar" size="nano" />
            <span className="text-sm ml-2 whitespace-nowrap leading-none">
              {formatFr(new Date(inUseMail.date.date), 'dd/MM/yyyy HH:mm')}
            </span>
          </div>
        </div>
      </div>
      <DeleteValidationModal
        display={showDeleteMailModal}
        title={`Voulez vous vraiment supprimer ce message ?`}
        testId="modal-delete-mail"
        onClose={() => setShowDeleteMailModal(false)}
        onSubmit={handleDeleteMailValidation}
      />
      <MailEditor
        display={showMailEditor}
        initialSubject={mailReply.subject}
        initialContent={mailReply.content}
        initialRecipients={mailReply.recipients}
        onClose={() => setShowMailEditor(false)}
      />
    </>
  )
}
