import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { terminatePatientTreatment } from '../../../data/patientTreatments/terminatePatientTreatment'
import { isDefined } from '../../../misc/functions.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { documentInstancesActions } from '../../../store/cache/documentInstances'
import { documentAlertsDomainActions } from '../../../store/domain/documentAlerts'
import { getVariableData } from '../../../store/renderer'
import { inUseMedicalEventDocumentIdSelector } from '../../../store/ui/medicalEvents/medicalEventDocumentInstances'
import { useCurrentPatientId } from '../../utils'
import { patientTreatmentsKeys } from './patientTreatments.keys'

interface UseTerminatePatientTreatmentVariables {
  treatmentId: string
  endDate: Date
  documentId?: number
}

export const useTerminatePatientTreatment = () => {
  const dispatch = useDispatch()
  const inUseDocumentId = useSelector(inUseMedicalEventDocumentIdSelector)
  const queryClient = useQueryClient()

  const patientId = useCurrentPatientId()

  return useMutation<void, Error, UseTerminatePatientTreatmentVariables>({
    mutationFn: async ({ treatmentId, endDate }) => {
      const { ok } = await terminatePatientTreatment(treatmentId, endDate)

      if (!ok) {
        throw new UserFriendlyError('error', 'Erreur lors de la mise à jour du traitement')
      }
    },
    onSuccess: (_, { documentId }) => {
      if (isDefined(patientId)) {
        queryClient.invalidateQueries({
          queryKey: patientTreatmentsKeys.lists(patientId),
          type: 'all',
        })
      }
      dispatch(documentAlertsDomainActions.getAlerts())
      dispatch(getVariableData())

      if (isDefined(inUseDocumentId) && documentId === inUseDocumentId) {
        dispatch(documentInstancesActions.actions.apiGetItemDetails(inUseDocumentId))
      }
    },
  })
}
