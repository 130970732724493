import { useState, useCallback, useMemo } from 'react'
import { FwDocumentType } from '../../../../model/Document'
import { SendableDocument } from '../../../../model/DocumentInstance'
import { RoundedButton } from '../../buttons'
import { ActionModalLayout, Modal } from '../Modal'
import { MedicalEventsDocumentsList } from './MedicalEventsDocumentsList/MedicalEventsDocumentsList.component'
import { MedicalEventsDocumentsModalProps } from './MedicalEventsDocumentsModal.model'
import styles from './MedicalEventsDocumentsModal.module.scss'

export const MedicalEventsDocumentsModal = ({
  isUnmounting,
  patient,
  onValidate,
  onClose,
}: MedicalEventsDocumentsModalProps) => {
  const [selectedfilesIds, setSelectedFilesIds] = useState<number[]>([])
  const [selectedDocuments, setSelectedDocuments] = useState<SendableDocument[]>([])

  const selectedItemsIds = useMemo(
    () => selectedDocuments.map((doc) => doc.id).concat(selectedfilesIds),
    [selectedDocuments, selectedfilesIds],
  )

  const handleToggleFileSelection = useCallback(
    (id: number) => {
      if (selectedfilesIds.includes(id)) {
        const selectedFilesWithoutItem = selectedfilesIds.filter((itemId) => itemId !== id)
        setSelectedFilesIds(selectedFilesWithoutItem)
      } else {
        setSelectedFilesIds((prev) => [...prev, id])
      }
    },
    [selectedfilesIds],
  )

  const handleToggleDocumentSelection = useCallback(
    (id: number, type: FwDocumentType, title: string) => {
      if (selectedDocuments.some((doc) => doc.id === id)) {
        const selectedDocsWithoutItem = selectedDocuments.filter((doc) => doc.id !== id)
        setSelectedDocuments(selectedDocsWithoutItem)
      } else {
        setSelectedDocuments((prev) => [...prev, { id, type, title }])
      }
    },
    [selectedDocuments],
  )

  const handleValidate = useCallback(() => {
    onValidate(selectedfilesIds, selectedDocuments)
  }, [onValidate, selectedDocuments, selectedfilesIds])

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onClose}>
      <ActionModalLayout
        title="Choisir des pièces jointes"
        onClose={onClose}
        actions={
          <div className={styles.buttonsContainer}>
            <RoundedButton
              label="Annuler"
              onClick={onClose}
              theme="transparent-dark"
              testId="cancel-follow-docs-selection"
            />
            <RoundedButton
              label="Ajouter"
              type="submit"
              onClick={handleValidate}
              disabled={!selectedItemsIds.length}
              testId="submit-follow-docs-selection"
            />
          </div>
        }
      >
        <MedicalEventsDocumentsList
          patient={patient}
          selectedItemsIds={selectedItemsIds}
          onToggleFileSelection={handleToggleFileSelection}
          onToggleDocumentSelection={handleToggleDocumentSelection}
        />
      </ActionModalLayout>
    </Modal>
  )
}
