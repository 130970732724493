import { PluginNames } from '@/model/Plugins'
import { VariableAttrbs, VariableDataAttrbs, VariableMap } from '@/model/Variable'
import { Node } from '@tiptap/core'
import { JSONContent, generateJSON, mergeAttributes } from '@tiptap/react'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.VariableFlatten]: {
      /**
       * Convert a variable node to a flat variable node, which display as basic content
       */
      flattenVariable: (variableMap: VariableMap, definitive?: boolean) => ReturnType
      /**
       * Convert a flat variable node to a variable node, removing its content
       */
      unflattenVariable: () => ReturnType
    }
  }
}

export const variableFlatten = Node.create({
  name: PluginNames.VariableFlatten,

  inline: true,
  group: 'inline',
  content: '(inline)*',

  addAttributes() {
    return {
      [VariableAttrbs.flatId]: {
        default: null,
        parseHTML: (element) => element.getAttribute(VariableDataAttrbs.flatId),
      },
      [VariableAttrbs.displayConfig]: {
        default: null,
        parseHTML: (element) => element.getAttribute(VariableDataAttrbs.displayConfig),
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs(node) {
          const id = node.getAttribute(VariableDataAttrbs.flatId)
          return !!id && null
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const flatId = HTMLAttributes[VariableAttrbs.flatId]
    const displayconfig = HTMLAttributes[VariableAttrbs.displayConfig]

    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, {
        [VariableDataAttrbs.flatId]: flatId,
        [VariableDataAttrbs.displayConfig]: displayconfig,
      }),
      0,
    ]
  },

  addCommands() {
    return {
      flattenVariable:
        (variableMap, definitive = false) =>
        ({ chain, editor }) => {
          // Récupération des attributs de la variable à applatir
          const variableAttrs = editor.getAttributes(PluginNames.Variable)
          const variableId: string | null = variableAttrs[VariableAttrbs.variableId] ?? null

          if (!variableId) return false

          const displayConfig: string | null = variableAttrs[VariableAttrbs.displayConfig] ?? null
          const variableData = variableMap[variableId]

          // Conversion de la valeur html de la variable en JSONContent
          const htmlVal = `<p>${variableData?.value ?? ''}</p>`
          const json: JSONContent = generateJSON(htmlVal, editor.extensionManager.extensions)
          const jsonContent = json?.content

          // Récupération des éléments JSON générés
          const isBlockContent = jsonContent ? jsonContent.length > 1 : false
          const inlineChild = jsonContent?.flatMap(({ content }) => content ?? [])
          const cleanedBlockChild = jsonContent?.filter(
            ({ type, content }) => !(type === 'paragraph' && (!content || content.length === 0)),
          )
          const child = isBlockContent ? cleanedBlockChild : inlineChild

          // Préparation du contenu du noeud applati
          const content: JSONContent =
            definitive && !!child
              ? child
              : {
                  type: isBlockContent
                    ? PluginNames.VariableFlattenBlock
                    : PluginNames.VariableFlatten,
                  attrs: {
                    [VariableAttrbs.flatId]: variableId,
                    [VariableAttrbs.displayConfig]: displayConfig,
                  },
                  content: variableData?.state === 'fallback' ? undefined : child,
                }

          return chain()
            .deleteNode(PluginNames.Variable)
            .command(({ state, chain }) => {
              // Cas spécial : Pour les variables block, seules dans leur paragraphe
              //  On clear le paragraph devenu vide pour ne pas altérer la mise en page
              if (!isBlockContent) return true

              const parentNode = state.selection.$anchor.node()

              if (
                parentNode.type.name === 'paragraph' &&
                !parentNode.text &&
                parentNode.childCount === 1 &&
                parentNode.child(0).attrs[VariableAttrbs.variableId] === variableId
              ) {
                return chain()
                  .setNodeSelection(state.selection.$anchor.pos - 1)
                  .deleteNode('paragraph')
                  .run()
              }
              return true
            })
            .insertContent(content, {
              updateSelection: true,
            })
            .run()
        },
      unflattenVariable:
        () =>
        ({ editor, chain }) => {
          const variableAttrs = {
            ...editor.getAttributes(PluginNames.VariableFlatten),
            ...editor.getAttributes(PluginNames.VariableFlattenBlock),
          }
          const variableId: string | null = variableAttrs[VariableAttrbs.flatId] ?? null
          const displayConfig: string | null = variableAttrs[VariableAttrbs.displayConfig] ?? null

          if (!variableId) return false

          return chain()
            .deleteNode(PluginNames.VariableFlatten)
            .deleteNode(PluginNames.VariableFlattenBlock)
            .insertContent({
              type: PluginNames.Variable,
              attrs: {
                [VariableAttrbs.variableId]: variableId,
                [VariableAttrbs.displayConfig]: displayConfig,
              },
            })
            .run()
        },
    }
  },
})
