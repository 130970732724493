import styled from '../../../../styles/legacy/styled/styled'
import { ColorsV2Border, ColorsV2, getV2Color } from '../../../../design-system/colors_v2'
import { ChipProps, StyledChipProps } from './Chip.model'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'

const fontColor = (colorPreset: ChipProps['colorPreset'], selected: boolean) => {
  if (colorPreset === 'danger')
    return selected
      ? getV3ColorCssVariable('shades', 'white')
      : getV3ColorCssVariable('status', 'danger')
  if (colorPreset === 'dark')
    return selected
      ? getV3ColorCssVariable('shades', 'white')
      : getV3ColorCssVariable('shades', 'shade2')

  return getV3ColorCssVariable('shades', 'white')
}

const computeBorderColor = (
  selected: boolean,
  borderColor?: ColorsV2,
  colorPreset?: ChipProps['colorPreset'],
) => {
  if (borderColor && !selected) {
    return borderColor
  }

  if (colorPreset === 'danger') {
    return ColorsV2.error
  }

  return selected ? ColorsV2.dsDark2 : ColorsV2.backgroundBlue6
}

const computeHoverBorderColor = (colorPreset?: ChipProps['colorPreset']) => {
  if (colorPreset === 'danger') return getV2Color(ColorsV2.error)
  if (colorPreset === 'light') return getV2Color(ColorsV2.dsGreyBlue)
  return getV2Color(ColorsV2Border.borderDark1)
}

const computeBackgroundColor = (
  colorPreset: ChipProps['colorPreset'],
  selected: boolean,
  borderColor?: ColorsV2,
) => {
  if (colorPreset === 'light') {
    if (selected) {
      return borderColor ? getV2Color(borderColor) : getV2Color(ColorsV2.dsDark2)
    }
    return 'transparent'
  } else if (colorPreset === 'dark') {
    if (selected) {
      return getV2Color(ColorsV2.dsDark2)
    }
  } else {
    if (selected) {
      return getV2Color(ColorsV2.error)
    }
  }
  return '#FFF'
}

export const StyledChip = styled.div<StyledChipProps>`
  font-family: Montserrat, sans-serif;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ size }) => (size === 'normal' ? '500' : '600')};
  font-size: ${({ size }) => (size === 'normal' ? '1rem' : '0.75rem')};
  padding: auto;
  padding-left: 16px;
  padding-right: 16px;
  height: ${({ size }) => (size === 'normal' ? '40px' : '32px')};
  border: ${({ borderSize }) => `${borderSize}px`} ${({ dotted }) => (dotted ? 'dashed' : 'solid')};
  border-radius: 20px;
  transition:
    border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  opacity: ${({ selected, disabled }) => (disabled && !selected ? '0.5' : '1')};
  ${({ selectable, disabled }) =>
    selectable && !disabled ? 'cursor: pointer;' : 'cursor: not-allowed;'}
  color: var(${({ colorPreset, selected }) => fontColor(colorPreset, !!selected)});
  border-color: ${({ selected, borderColor, colorPreset }) =>
    getV2Color(computeBorderColor(!!selected, borderColor, colorPreset))};
  background-color: ${({ colorPreset, selected, borderColor }) =>
    computeBackgroundColor(colorPreset, !!selected, borderColor)};

  .chip-sublabel {
    color: var(
      ${({ selected }) =>
        selected
          ? getV3ColorCssVariable('shades', 'shade5')
          : getV3ColorCssVariable('shades', 'shade4')}
    );
  }

  &:hover {
    ${({ disabled, colorPreset }) =>
      !disabled && `border-color: ${computeHoverBorderColor(colorPreset)};`}
  }
`

export const StyledChipList = styled.div`
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`
