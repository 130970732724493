import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { ServerSentNotification } from '../../../../model/Notification'
import { deleteNotification } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { adminNotifKeys } from './adminNotifications.keys'
import { checkIfPagesContainItem } from '../../../../misc/query.utilities'

export const useDeleteNotification = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation({
    mutationFn: async (id: string) => {
      const { ok } = await deleteNotification(id)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la notification a échoué')
      }
      return null
    },

    onSuccess: (_data, notificationId) => {
      valid('La notification a été supprimée')
      queryClient.refetchQueries({
        queryKey: adminNotifKeys.all,
        predicate: (query) =>
          checkIfPagesContainItem<ServerSentNotification>(query.state.data, notificationId),
      })
    },
  })

  return queryReturn
}
