import { useMutation } from '@tanstack/react-query'
import { disconnectOrdoclicSession } from '../../../data/ordoclic'

export const useDisconnectOrdoclicSession = () => {
  return useMutation<void, Error>({
    mutationFn: async () => {
      const reponse = await disconnectOrdoclicSession()

      if (!reponse.ok) console.error('Ordoclic disconnection failed')
    },
  })
}
