import { Drug } from '../../../model/Drug'
import { PosologyFormResource } from '../../../model/Posology'

const all = ['prescriptions']
const detail = (id: string) => [...all, id]
const previews = [...all, 'preview']
const preview = (posologyIntervals: PosologyFormResource[], drugs: Drug[]) => [
  ...previews,
  drugs.map(({ id, type }) => `${type}:${id}`),
  posologyIntervals,
]

export const prescriptionKeys = {
  all,
  detail,
  previews,
  preview,
}
