import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createDoctolibPendingPatientData } from '../../../data/patients/doctolib'
import { DoctolibPatient } from '../../../misc/Doctolib/doctolib.model'
import {
  doctolibToFollowPendingPatientMapper,
  mapPendingFollowPatientToPayload,
} from '../../../misc/Doctolib/doctolib.utils'
import { UserFriendlyError } from '../../../model/Error'
import { doctolibPendingChangesKeys } from './doctolibPendingChanges.keys'

interface CreatePendingFollowPatientDataVariables {
  patient: DoctolibPatient
}

export const useCreatePendingFollowPatientData = () => {
  const queryClient = useQueryClient()
  const mutate = useMutation({
    mutationFn: async ({ patient }: CreatePendingFollowPatientDataVariables) => {
      if (!patient.pms_id) {
        throw new UserFriendlyError('error', "Le patient n'a pas d'identifiant Follow")
      }

      const fwPatientId = parseInt(patient.pms_id, 10)
      if (!Number.isInteger(fwPatientId)) {
        throw new UserFriendlyError(
          'error',
          `L'identifiant du patient indiqué par doctolib n'a pas le bon format: (${JSON.stringify(
            patient.pms_id,
          )})`,
        )
      }

      const pendingFollowPatient = doctolibToFollowPendingPatientMapper(patient)
      const payload = mapPendingFollowPatientToPayload(pendingFollowPatient)

      const { ok } = await createDoctolibPendingPatientData(fwPatientId, payload)

      if (!ok) {
        throw new UserFriendlyError(
          'error',
          'La mise à jour des informations du patient venant de doctolib a échoué',
        )
      }
    },

    onSuccess: (_, { patient }) => {
      if (patient.pms_id) {
        queryClient.refetchQueries({ queryKey: doctolibPendingChangesKeys.detail(patient.pms_id) })
      }
    },
  })

  return mutate
}
