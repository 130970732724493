import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDocumentCategoryOptions } from '../../../hooks/utils'
import { DocumentCategoryKeys } from '../../../model/DocumentCategory'
import { SelectOption } from '../../../model/SelectOption'
import { CheckboxState, DatePicker, IconButton, RoundedButton, SelectInput } from '../../shared'
import { MailClassificationProps } from './MailClassification.model'
import { LightPatient } from '../../../model/Patient'
import { CustomFile } from '../../../model/File'
import { MailClassificationAttachmentItem } from './MailClassificationAttachmentItem'
import { PatientDropDownItemSelector } from '../../patient'
import { MailPatientInfo } from './MailPatientInfo'
import { MailPatientDetection } from './MailPatientDetection'
import { MailClassificationAttachment, MailPatient } from '../../../model/Mail'
import { CreatePatientThroughMailModal } from '../../insi'
import { MailClassificationModal } from './MailClassificationModal'
import {
  mapMailAttachmentsToSelectedAttachments,
  mapMailAttachmentToSelectedAttachment,
} from './MailClassification.utilities'
import { useClassifyAttachments } from '../../../hooks/queries/mail'
import { partialMailPatientModalAtom } from '../../../state/mail'
import { useAtomValue } from 'jotai'
import { isDefined } from '../../../misc/functions.utilities'
import { useCreatePatientFromMail } from '../../../hooks/queries/mail/useCreatePatientFromMail.query'
import { useUpdatePatientFromMail } from '../../../hooks/queries/mail/useUpdatePatientFromMail.query'
import { FeaturesType } from '../../../model/Preference'
import { useUserEnabledFeature } from '../../../hooks/utils/user'
import { useAutomaticIdentityCheck } from '../../../hooks/queries/insi/useAutomaticIdentityCheck.query'

export const MailClassification: FC<MailClassificationProps> = ({ inUseMail }) => {
  const categoriesOptions = useDocumentCategoryOptions()
  const defaultCategory = useMemo(
    () =>
      categoriesOptions.find(
        (option: SelectOption<DocumentCategoryKeys>) =>
          option.value === DocumentCategoryKeys.ReceivedDocument,
      ),
    [categoriesOptions],
  )

  const [documentCategory, setDocumentCategory] = useState<
    SelectOption<DocumentCategoryKeys> | undefined
  >(defaultCategory) // Catégorie "Documents reçus" par défaut
  const [date, setDate] = useState(new Date())
  const [selectedPatient, setSelectedPatient] = useState<LightPatient | MailPatient | undefined>()
  const [selectedAttachments, setSelectedAttachments] = useState<MailClassificationAttachment[]>([])
  const [showClassificationModal, setShowClassificationModal] = useState<boolean>(false)

  const { mutate: classifyDocuments } = useClassifyAttachments()
  const { mutate: createPatientFromMail } = useCreatePatientFromMail()
  const { mutate: updatePatientFromMail } = useUpdatePatientFromMail()

  const partialPatientModalState = useAtomValue(partialMailPatientModalAtom)

  const autoInsi = useUserEnabledFeature(FeaturesType.mssAutoInsiFetch)
  const insiService = useUserEnabledFeature(FeaturesType.insiValidation)
  const mssExtractPatient = useUserEnabledFeature(FeaturesType.mssExtractPatient)
  const mssSmtp = useUserEnabledFeature(FeaturesType.mssSmtp)

  const enableAutomaticCheck =
    mssExtractPatient && insiService === 'insiValidationInApp' && autoInsi
  const { query: automaticCheck } = useAutomaticIdentityCheck({
    mail: inUseMail,
    enabled: enableAutomaticCheck,
  })

  const setDefaultFormValues = useCallback(() => {
    setDate(new Date(inUseMail.date.date))
    setSelectedAttachments(mapMailAttachmentsToSelectedAttachments(inUseMail.attachments))
    setDocumentCategory(defaultCategory)
    setSelectedPatient(undefined)
    setSelectedPatient(inUseMail.patient?.id ? inUseMail.patient : undefined)
  }, [inUseMail, defaultCategory])

  useEffect(() => {
    setDefaultFormValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isDefined(documentCategory) && isDefined(defaultCategory)) {
      setDocumentCategory(defaultCategory)
    }
  }, [defaultCategory, documentCategory])

  const handleClassify = useCallback(
    (selectedAttachments: MailClassificationAttachment[]) => {
      if (
        documentCategory &&
        selectedPatient &&
        date &&
        selectedAttachments.length > 0 &&
        selectedPatient.id
      ) {
        classifyDocuments({
          messageId: inUseMail.id,
          selectedAttachments,
          patientId: selectedPatient.id,
          documentCategoryValue: documentCategory.value,
          date,
        })
        setShowClassificationModal(false)
        setDefaultFormValues()
      }
    },
    [
      classifyDocuments,
      date,
      documentCategory,
      inUseMail.id,
      selectedPatient,
      setDefaultFormValues,
    ],
  )

  const handleSelectFile = (selectedItem: CustomFile) => {
    const selection: MailClassificationAttachment[] = selectedAttachments.some(
      (item: MailClassificationAttachment) => item.id === selectedItem.id,
    )
      ? [
          ...selectedAttachments.filter(
            (item: MailClassificationAttachment) => item.id !== selectedItem.id,
          ),
        ]
      : [...selectedAttachments, mapMailAttachmentToSelectedAttachment(selectedItem)]

    setSelectedAttachments(selection)
  }

  const handlePatientCreation = useCallback(() => {
    if (!inUseMail.patient || !mssExtractPatient) return

    createPatientFromMail(
      {
        mailId: inUseMail.id,
        patient: inUseMail.patient,
        bypassCheck: isDefined(automaticCheck.data),
      },
      {
        onSuccess(data) {
          if (data) {
            setSelectedPatient(data)
          }
        },
      },
    )
  }, [
    automaticCheck.data,
    createPatientFromMail,
    inUseMail.id,
    inUseMail.patient,
    mssExtractPatient,
  ])

  const handlePatientUpdate = useCallback(() => {
    if (!inUseMail.patient?.id || !mssExtractPatient) return

    updatePatientFromMail({
      mailId: inUseMail.id,
      patientId: inUseMail.patient.id,
      patient: inUseMail.patient,
      bypassCheck: isDefined(automaticCheck.data),
    })
  }, [
    inUseMail.patient,
    inUseMail.id,
    mssExtractPatient,
    updatePatientFromMail,
    automaticCheck.data,
  ])

  return (
    <div className="bg-white w-full h-full space-y-4">
      {inUseMail.patient && mssExtractPatient && (
        <div className="mb-5">
          <div className="mb-5">
            <MailPatientDetection
              patient={inUseMail.patient}
              onUpdate={handlePatientUpdate}
              onCreate={handlePatientCreation}
            />
          </div>
          <MailPatientInfo patient={inUseMail.patient} />
        </div>
      )}
      <span className="text-shades-3 text-base font-medium">CLASSER LE(S) DOCUMENT(S)</span>
      <div className="mt-2">
        {inUseMail?.attachments.map((item) => (
          <div className="mt-2" key={item.id}>
            <MailClassificationAttachmentItem
              item={item}
              checked={
                selectedAttachments?.some(({ id }) => item.id === id)
                  ? CheckboxState.CHECKED
                  : CheckboxState.UNCHECKED
              }
              selectItem={handleSelectFile}
            />
          </div>
        ))}
      </div>
      <PatientDropDownItemSelector
        selectedPatient={selectedPatient}
        setSelectedPatient={setSelectedPatient}
      />
      <SelectInput
        title="Catégorie du document"
        value={documentCategory}
        icon={documentCategory?.icon ?? 'documentText'}
        options={categoriesOptions}
        onSelect={setDocumentCategory}
      />
      <DatePicker
        value={date}
        colorPreset="light"
        icon="calendar"
        showCalendarButton={false}
        label="Date"
        onChange={(value) => {
          if (value) {
            setDate(value)
          }
        }}
        inputType="input"
      />
      <div className="py-4">
        <div className="flex">
          <RoundedButton
            disabled={
              !selectedPatient || !documentCategory || !date || selectedAttachments.length === 0
            }
            label="Classer ce(s) document(s)"
            onClick={() => handleClassify(selectedAttachments)}
            appearance={mssSmtp ? 'left' : 'standalone'}
          />
          {mssSmtp && (
            <IconButton
              disabled={
                !selectedPatient || !documentCategory || !date || selectedAttachments.length === 0
              }
              icon="caret"
              rotate={180}
              appearance="right"
              theme="primary"
              showOptionsCaret={false}
              options={[
                {
                  label: 'Renommer et classer',
                  onClick: () => setShowClassificationModal(true),
                },
              ]}
            />
          )}
        </div>
      </div>
      <MailClassificationModal
        display={showClassificationModal}
        attachments={selectedAttachments}
        onClose={() => setShowClassificationModal(false)}
        onSubmit={(values: MailClassificationAttachment[]) => {
          setSelectedAttachments([...values])
          handleClassify(values)
        }}
        testId={`mail-classification-modal`}
      />
      <CreatePatientThroughMailModal
        display={isDefined(partialPatientModalState)}
        onSubmit={() => partialPatientModalState?.onSubmit(true)}
        onClose={() => partialPatientModalState?.onSubmit(false)}
      />
    </div>
  )
}
