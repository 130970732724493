import { isDefined } from '@follow/cdk'
import { useState, useCallback, useMemo } from 'react'
import DrugItem from '../../../drug/SearchDrug/DrugItem'
import { useGetInfinitePatientTreatments } from '../../../../hooks/queries/patientTreatments'
import { useEffectWithRef } from '../../../../hooks/utils'
import { openMonograph, openSMR } from '../../../../misc/drug.utilities'
import { SideEffect, AtcClass } from '../../../../model/Drug'
import { SelectOption } from '../../../../model/SelectOption'
import { Treatment, TreatmentsStatus } from '../../../../model/Treatment'
import { SideEffectsSelect, AtcClassSelect } from '../../../drug'
import { AbstractList, List } from '../../List'
import { SelectSearch } from '../../search'
import { BottomPanel } from '../BottomPanel'
import {
  TreatmentsHistoryBottomPanelProps,
  treatmentsStatusOptions,
} from './TreatmentsHistoryBottomPanel.model'
import styles from './TreatmentsHistoryBottomPanel.module.scss'

export const TreatmentsHistoryBottomPanel = ({
  patientId,
  isUnmounting,
  onRequestClose,
}: TreatmentsHistoryBottomPanelProps) => {
  const [listRef, setListRef] = useEffectWithRef<AbstractList<Treatment>>(() => {
    if (listRef) {
      listRef.updateList(true)
    }
  }, [])

  const [treatmentsSideEffect, setTreatmentsSideEffect] = useState<SideEffect | undefined>()
  const [atcClass, setAtcClass] = useState<AtcClass | null>(null)
  const [treatmentsStatus, setTreatmentsStatus] = useState<SelectOption<TreatmentsStatus>>(
    treatmentsStatusOptions.find((status) => status.value === TreatmentsStatus.PAST) ??
      treatmentsStatusOptions[0],
  )

  const filters = useMemo(
    () => ({
      status: treatmentsStatus.value !== TreatmentsStatus.ALL ? treatmentsStatus.value : undefined,
      sideEffectId: treatmentsSideEffect?.externalId,
      atcCodeExternalId: atcClass?.id,
    }),
    [atcClass?.id, treatmentsSideEffect?.externalId, treatmentsStatus.value],
  )
  const {
    query: { isLoading, fetchNextPage },
    treatmentsList,
    paginationState,
  } = useGetInfinitePatientTreatments({
    patientId,
    filters,
  })

  const handleOnChangeTreatmentsSelect = useCallback(
    (selectedOption: SelectOption<TreatmentsStatus> | undefined) => {
      if (isDefined(selectedOption)) {
        setTreatmentsStatus(selectedOption)
      }
    },
    [setTreatmentsStatus],
  )

  return (
    <BottomPanel
      title="Historique médicamenteux"
      isUnmounting={isUnmounting}
      onRequestClose={onRequestClose}
    >
      <div className={styles.bottomPanelContent}>
        <div className={styles.filtersContainer}>
          <div className={styles.filters}>
            <SelectSearch
              value={treatmentsStatus}
              options={treatmentsStatusOptions}
              onSelect={handleOnChangeTreatmentsSelect}
              colorPreset="dark"
              clearable={false}
              label="Statut"
            />
            <SideEffectsSelect setSideEffectSelected={setTreatmentsSideEffect} />
            <AtcClassSelect label="Classification ATC" onClassSelected={setAtcClass} />
          </div>
        </div>
        <div className={styles.listContainer}>
          <List
            ref={setListRef}
            items={treatmentsList}
            loading={isLoading}
            pageCount={paginationState?.pageCount ?? null}
            itemCount={paginationState?.itemCount ?? null}
            onUpdateList={() => fetchNextPage()}
            renderItem={(treatment) => (
              <DrugItem
                drug={treatment.drugConfig.drug}
                treatment={treatment}
                onMonographClick={() => openMonograph(treatment.drugConfig.drug)}
                onSMRClick={() => openSMR(treatment.drugConfig.drug)}
              />
            )}
            fullPageMode={false}
          />
        </div>
      </div>
    </BottomPanel>
  )
}
