import React, { useCallback, useMemo, useState } from 'react'
import {
  Checkbox,
  CheckboxState,
  LabeledButton,
  Radio,
  RadioGroup,
  TooltipWrapper,
  Heading,
  AccordionItem,
  IconsType,
  TextArea,
} from '../../../components/shared'
import { DrugThumbnail } from '../../../components/drug'
import { computePrescriptionVariableId, openMonograph } from '../../../misc/drug.utilities'
import { SelectedContentType } from '../../../store/ui/medicalEvents/medicalEventContent'
import { CommonVariableActions } from '../CommonVariableActions'
import styles from '../VariableConfiguration.module.scss'

import { AldTitles, DrugVariableConfigurationProps } from './DrugVariableConfiguration.model'
import { useDebounce } from 'react-use'
import { getDocumentTemplateEditorVariantSearchParams } from '../../../pages/ManagerPage/documentTemplate/DocumentTemplateDetailPage/FarteDocumentTemplatePage/FarteDocumentTemplatePage.utilities'
import {
  DrugTypes,
  UnsubstituableStatement,
  UnsubstituableStatementLabels,
} from '../../../model/Drug'
import { isDefined } from '../../../misc/functions.utilities'
import { BizoneMarker, DocumentCategoryKeys } from '../../../model/DocumentCategory'
import { VariableInsertionPosition } from '@follow/farte'
import { Prescription, PrescriptionVariable } from '../../../model/Prescription'
import { DocumentTemplateEditorVariantType } from '../../../model/DocumentTemplate'
import { useNavigate } from 'react-router-dom'
import { retrieveVariableUuid } from '@follow/cdk'
import { SearchDrug } from '../../../components/drug/SearchDrug'
import {
  useSwitchPrescriptionToVMP,
  useUpdatePrescription,
} from '../../../hooks/queries/prescriptions'

const NONE_VALUE = 'none'

export const retrievePrescriptionFromVariableId = (
  variableId: string,
  prescriptions: ReadonlyArray<Prescription>,
) => {
  const retrievedDrugVariableUuid = retrieveVariableUuid(variableId)
  return prescriptions.find(
    ({ prescriptionVariableUuid }) => retrievedDrugVariableUuid === prescriptionVariableUuid,
  )
}

export function DrugVariableConfiguration({
  variableId,
  variableTitle,
  displayConfig,
  renderTitle,
  inUseEntity,
  isAdmin,
  editorValue,
  selectMedicalEventContent,
  onSelectVariableDisplayConfig,
  onChangeVariablePosition,
  hideContextualMenu,
  ...restProps
}: DrugVariableConfigurationProps) {
  const prescription = useMemo(
    () =>
      inUseEntity !== null
        ? retrievePrescriptionFromVariableId(variableId, inUseEntity.prescriptions)
        : undefined,
    [inUseEntity, variableId],
  )

  const [note, setNote] = useState(prescription?.note || '')
  const [prescriptionReason, setPrescriptionReason] = useState(
    prescription?.prescriptionReason ?? '',
  )
  const [unsubstituableStatement, setUnsubstituableStatement] =
    useState<UnsubstituableStatement | null>(prescription?.unsubstituableStatement ?? null)
  const [isChronic, setIsChronic] = useState(prescription?.isChronic ?? false)
  const [isAld, setIsAld] = useState(prescription?.isAld ?? false)

  const [openUnsubstituableStatement, setOpenUnsubstituableStatement] = useState(false)
  const [openRecurringTreatment, setOpenRecurringTreatment] = useState(false)
  const [openNotes, setOpenNotes] = useState(false)
  const [openPrescriptionReason, setOpenPrescriptionReason] = useState(false)
  const [displaySearchDrug, setDisplaySearchDrug] = useState(false)

  const navigate = useNavigate()

  const { mutate: switchPrescriptionToVMP } = useSwitchPrescriptionToVMP()
  const { mutate: updatePrescription } = useUpdatePrescription()

  useDebounce(
    () => {
      if (prescription) {
        updatePrescription({
          id: prescription.uuid,
          prescription: {
            note,
            unsubstituableStatement,
            isChronic,
            isAld,
            prescriptionReason,
          },
        })
      }
    },
    100,
    [note, unsubstituableStatement, isChronic, prescriptionReason, isAld],
  )

  const handleSwitchToVMP = useCallback(() => {
    if (prescription && prescription.drugs.length === 1) {
      onSelectVariableDisplayConfig(
        undefined,
        computePrescriptionVariableId(prescription.prescriptionVariableUuid),
      )
      switchPrescriptionToVMP({ prescription })
    }
  }, [prescription, onSelectVariableDisplayConfig, switchPrescriptionToVMP])

  const handleSwitchToBioSimilar = useCallback(() => {
    if (
      prescription &&
      prescription.drugs.length === 1 &&
      isDefined(prescription.drugs[0].bioSimilarGroup)
    ) {
      setDisplaySearchDrug(true)
    }
  }, [prescription])

  const handleSelectBiosimilar = useCallback(
    (selected: PrescriptionVariable) => {
      const biosimilarDrug = selected.drugs.at(0)
      if (biosimilarDrug) {
        const biosimilarVariableId = computePrescriptionVariableId(
          selected.prescriptionVariableUuid,
        )
        onSelectVariableDisplayConfig(undefined, biosimilarVariableId)
      }
      hideContextualMenu()
    },
    [hideContextualMenu, onSelectVariableDisplayConfig],
  )

  const handleAddPosologyInterval = useCallback(() => {
    if (prescription) {
      if (prescription.documentId) {
        selectMedicalEventContent({
          type: SelectedContentType.PRESCRIPTION,
          prescriptionId: prescription.uuid,
          intervalId:
            prescription.posologyIntervals.length > 0
              ? prescription.posologyIntervals[0].id
              : 'new',
        })
      } else if (prescription.documentTemplateId) {
        const searchParams = getDocumentTemplateEditorVariantSearchParams({
          type: DocumentTemplateEditorVariantType.PRESCRIPTION,
          prescriptionId: prescription.uuid,
          intervalId:
            prescription.posologyIntervals.length > 0
              ? prescription.posologyIntervals[0].id
              : 'new',
        })
        navigate(searchParams, { replace: true })
      }
    }
  }, [navigate, prescription, selectMedicalEventContent])

  const handlePrescriptionReasonChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const formatedValue = event.currentTarget.value.replace('\n', '')
      setPrescriptionReason(formatedValue)
    },
    [],
  )

  const handleSwicthALD = useCallback(
    (ald: boolean) => {
      setIsAld(ald)
      if (
        inUseEntity &&
        inUseEntity.type === 'farte' &&
        inUseEntity.category.id === DocumentCategoryKeys.BizonePrescriptionForMedication
      ) {
        onChangeVariablePosition(
          variableId,
          VariableInsertionPosition.START,
          editorValue.document
            .getBlocks()
            .find((block) =>
              ald
                ? block?.data.get('marker') === BizoneMarker.ALD
                : block?.data.get('marker') === BizoneMarker.NON_ALD,
            ),
          true,
        )
      }
    },
    [inUseEntity, onChangeVariablePosition, variableId, editorValue],
  )

  return (
    <>
      <CommonVariableActions
        customActions={[
          ...(prescription && prescription.drugs.some((drug) => drug.hasMonograph)
            ? [
                {
                  icon: 'infoCircle' as IconsType,
                  hint: 'Ouvrir la monographie',
                  onClick: () =>
                    prescription.drugs
                      .filter((drug) => drug.hasMonograph)
                      .forEach((drug) => openMonograph(drug)),
                },
              ]
            : []),
          ...(prescription &&
          prescription.drugs.length === 1 &&
          prescription.drugs[0].hasVmpExternalId &&
          inUseEntity
            ? [
                {
                  icon: 'reload' as IconsType,
                  hint: 'Convertir en dénomination commune',
                  onClick: handleSwitchToVMP,
                },
              ]
            : []),
          ...(prescription &&
          prescription.drugs.length === 1 &&
          isDefined(prescription.drugs[0].bioSimilarGroup)
            ? [
                {
                  icon: 'pills' as IconsType,
                  hint: 'Remplacer par un biologique similaire',
                  onClick: handleSwitchToBioSimilar,
                },
              ]
            : []),
        ]}
        {...restProps}
      >
        <div className={styles.titleContainer}>
          {prescription?.drugs.map((drug, index) => (
            <div className={`${index > 0 && 'mt-4'}`} key={drug.id}>
              <DrugThumbnail
                drug={drug}
                prescription={prescription}
                size="big"
                displayInfo={drug.type !== DrugTypes.NON_PROPRIETARY}
                isAdditionalDrug={index > 0}
              />
            </div>
          ))}
          {isAdmin && <Heading size={5}>{variableId}</Heading>}
        </div>
      </CommonVariableActions>
      {prescription && (
        <div className="border-t border-b border-shades-7 py-4 mt-4">
          {prescription.posologyIntervals.length > 0 ? (
            <LabeledButton
              label="Éditer les posologies"
              icon="pencil"
              onClick={handleAddPosologyInterval}
            />
          ) : (
            <LabeledButton label="Posologie" icon="add" onClick={handleAddPosologyInterval} />
          )}
        </div>
      )}
      <div className="border-b border-shades-7 py-3">
        <AccordionItem
          renderLabel={() => (
            <span
              className={`text-shades-3 font-medium ${
                unsubstituableStatement ? 'font-bold' : 'font-medium'
              }`}
            >
              Mention non substituable {!unsubstituableStatement && '(non-renseigné)'}
            </span>
          )}
          overflow={true}
          open={openUnsubstituableStatement}
          onOpenClose={() => setOpenUnsubstituableStatement(!openUnsubstituableStatement)}
          theme="simple"
        >
          <div className="mb-2">
            <RadioGroup
              name="unsubstituableStatement"
              onChange={(_, value) => {
                if (value === NONE_VALUE) {
                  setUnsubstituableStatement(null)
                } else {
                  setUnsubstituableStatement(value as UnsubstituableStatement)
                }
              }}
              value={unsubstituableStatement ?? NONE_VALUE}
            >
              <Radio label="Aucune" value={NONE_VALUE} />
              <TooltipWrapper content={UnsubstituableStatementLabels.MTE}>
                <Radio label={UnsubstituableStatement.MTE} value={UnsubstituableStatement.MTE} />
              </TooltipWrapper>
              <TooltipWrapper content={UnsubstituableStatementLabels.EFG}>
                <Radio label={UnsubstituableStatement.EFG} value={UnsubstituableStatement.EFG} />
              </TooltipWrapper>
              <TooltipWrapper content={UnsubstituableStatementLabels.CIF}>
                <Radio label={UnsubstituableStatement.CIF} value={UnsubstituableStatement.CIF} />
              </TooltipWrapper>
            </RadioGroup>
          </div>
        </AccordionItem>
      </div>

      <div className="border-b border-shades-7 py-3">
        <AccordionItem
          renderLabel={() => (
            <span
              className={`text-shades-3 font-medium ${
                isChronic || isAld ? 'font-bold' : 'font-medium'
              }`}
            >
              Traitement chronique / ALD
            </span>
          )}
          overflow
          open={openRecurringTreatment}
          onOpenClose={() => setOpenRecurringTreatment(!openRecurringTreatment)}
          theme="simple"
        >
          <div className="flex flex-col my-1">
            <div className="inline-flex mb-1 py-1 space-x-4">
              <Checkbox
                checked={isChronic ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
                onChange={() => setIsChronic(!isChronic)}
                label="Traitement chronique"
              />
              <Checkbox
                checked={isAld ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
                onChange={() => {
                  handleSwicthALD(!isAld)
                }}
                label="Prise en charge ALD"
              />
            </div>
            {prescription?.aldStatus && (
              <span className="text-xs text-shades-3 py-1">
                {AldTitles[prescription.aldStatus]}
              </span>
            )}
          </div>
        </AccordionItem>
      </div>

      <div className="border-b border-shades-7 py-3">
        <AccordionItem
          renderLabel={() => (
            <span
              className={`text-shades-3 font-medium ${
                prescriptionReason ? 'font-bold' : 'font-medium'
              }`}
            >
              Motif de prescription {!prescriptionReason && '(non-renseigné)'}
            </span>
          )}
          overflow
          open={openPrescriptionReason}
          onOpenClose={() => setOpenPrescriptionReason(!openPrescriptionReason)}
          theme="simple"
        >
          <div className="mb-3">
            <TextArea
              name="prescriptionReason"
              value={prescriptionReason}
              onChange={handlePrescriptionReasonChange}
              placeholder="Motif"
              maxLength={255}
              initialRows={2}
              autoResize
            />
          </div>
        </AccordionItem>
      </div>

      <AccordionItem
        renderLabel={() => (
          <span className={`text-shades-3 font-medium py-3 ${note ? 'font-bold' : 'font-medium'}`}>
            Notes {!note && '(non-renseigné)'}
          </span>
        )}
        overflow={true}
        open={openNotes}
        onOpenClose={() => setOpenNotes(!openNotes)}
        theme="simple"
      >
        <TextArea
          name="Notes"
          placeholder="Notes"
          onChange={(event) => setNote(event.target.value)}
          value={note}
        />
      </AccordionItem>
      <SearchDrug
        display={displaySearchDrug}
        onSelect={handleSelectBiosimilar}
        onClose={() => setDisplaySearchDrug(false)}
        disabled
        initialSearch={{
          type: DrugTypes.BIOSIMILAR_GROUP,
          searchId: prescription?.drugs[0].id ?? -1,
        }}
        updatePendingVariables
      />
    </>
  )
}
