import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../App'
import { createContact } from '../../../data/contact'
import { Contact } from '../../../model/Contact'
import { UserFriendlyError } from '../../../model/Error'
import { patientsKeys } from '../patients/patients.keys'
import { recipientSuggestionsKeys } from '../recipientSuggestions/recipientSuggestions.keys'
import { contactsKeys } from './contacts.keys'

interface CreateContactVariables {
  contactPayload: Partial<SafeOmit<Contact, 'id'>>
  patientId?: number
}

export const useCreateContact = () => {
  const mutate = useMutation({
    mutationFn: async ({ contactPayload }: CreateContactVariables) => {
      const { data, ok } = await createContact(contactPayload)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création du contact a échoué')
      }
      return data
    },

    onSuccess: (contact, { patientId }) => {
      queryClient.invalidateQueries({ queryKey: contactsKeys.detail(contact.id) })
      queryClient.invalidateQueries({ queryKey: recipientSuggestionsKeys.all })
      patientId && queryClient.invalidateQueries({ queryKey: patientsKeys.detail(patientId) })
    },
  })

  return mutate
}
