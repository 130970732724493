import { adminUsersSagas } from './adminUsers/adminUsers.sagas'
import { bottomPanelBaseVariablesSagas } from './bottomPanelBaseVariables/bottomPanelBaseVariables.sagas'
import { bottomPanelDocumentHistorysSagas } from './bottomPanelDocumentHistory/bottomPanelDocumentHistory.sagas'
import { bottomPanelDocumentTemplatesSagas } from './bottomPanelDocumentTemplates/bottomPanelDocumentTemplates.sagas'
import { bottomPanelQuestionSagas } from './bottomPanelQuestions/bottomPanelQuestions.sagas'
import { managerDocumentTemplatesSagas } from './managerDocumentTemplates/managerDocumentTemplates.sagas'
import { medicalEventDocumentInstancesSagas } from './medicalEvents/medicalEventDocumentInstances'
import { medicalEventsFilesSagas } from './medicalEvents/medicalEventFiles/medicalEventFiles.sagas'
import { medicalEventQuestionnaires } from './medicalEvents/medicalEventContent/medicalEventContent.sagas'
import { medicalEventsSagas } from './medicalEvents/medicalEvents.sagas'
import { DocumentSagas } from './document/document.saga'
import { uiDocumentAlertsSagas } from './documentAlerts'
import { quoteLineSagas } from './quoteLine/quoteLine.sagas'

export const uiSagas = [
  ...Object.values(bottomPanelBaseVariablesSagas),
  ...Object.values(bottomPanelDocumentHistorysSagas),
  ...Object.values(bottomPanelDocumentTemplatesSagas),
  ...Object.values(bottomPanelQuestionSagas),
  ...Object.values(DocumentSagas),
  ...Object.values(uiDocumentAlertsSagas),
  ...Object.values(managerDocumentTemplatesSagas),
  ...Object.values(adminUsersSagas),
  ...Object.values(medicalEventDocumentInstancesSagas),
  ...Object.values(medicalEventsFilesSagas),
  ...Object.values(medicalEventQuestionnaires),
  ...Object.values(medicalEventsSagas),
  ...Object.values(quoteLineSagas),
]
