import classNames from 'classnames/bind'
import React, { createContext, FunctionComponent, useRef } from 'react'
import { ColorPreset } from '../../../../design-system/themes/base.model'
import { DropdownProps } from './DropdownContainer.model'
import styles from './DropdownContainer.module.scss'
import { useClickOutside } from '../../../../hooks/utils'

export const DropDownColorPresetContext = createContext<ColorPreset>('dark')

const classNamesCx = classNames.bind(styles)

export const DropdownContainer: FunctionComponent<DropdownProps> = ({
  colorPreset = 'dark',
  opened,
  closeDropdown,
  children,
  direction,
  level = 'top',
  verticalScroll = true,
  closeOnSelect = true,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  useClickOutside(dropdownRef, opened, closeOnSelect, closeDropdown)

  return (
    <>
      {opened && (
        <div ref={dropdownRef} className={classNamesCx({ container: true, [colorPreset]: true })}>
          {direction === 'left' && (
            <div className={classNamesCx(styles.leftTriangle, styles[level])} />
          )}
          <DropDownColorPresetContext.Provider value={colorPreset}>
            <div
              className={classNamesCx(styles.menu, {
                verticalScroll,
              })}
              role="menu"
            >
              {children}
            </div>
          </DropDownColorPresetContext.Provider>
          {direction === 'right' && (
            <div className={classNamesCx(styles.rightTriangle, styles[level])} />
          )}
        </div>
      )}
    </>
  )
}
