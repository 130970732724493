import { FunctionComponent, useMemo } from 'react'
import { Country } from '../../../model/Contact'
import { SelectOption } from '../../../model/SelectOption'
import { DropdownItem, SelectSearch } from '../../shared'
import { CountriesSearchProps } from './CountriesSearch.model'

const renderCountryOption = (
  { value }: SelectOption<Country>,
  currentValue: SelectOption<Country> | undefined,
) => {
  return (
    <DropdownItem selected={value.id === currentValue?.value.id}>
      <div key={value.id} className="flex flex-col">
        <span>{value.name}</span>
        <span className="font-normal text-sm">{value.inseeCode}</span>
      </div>
    </DropdownItem>
  )
}

export const CountriesSearch: FunctionComponent<CountriesSearchProps> = ({
  colorPreset = 'light',
  disabled = false,
  selectedCountry,
  countries,
  search,
  onSearch,
  onSelectCountry,
}) => {
  const countriesSearchOptions = useMemo(
    () =>
      countries.map((country) => {
        return {
          label: country.name,
          value: country,
        }
      }),
    [countries],
  )

  const selectedCountryOption = useMemo(
    () =>
      selectedCountry
        ? countriesSearchOptions.find(({ value }) => value.id === selectedCountry.id)
        : undefined,
    [countriesSearchOptions, selectedCountry],
  )

  return (
    <SelectSearch
      placeholder="Recherche par pays"
      colorPreset={colorPreset}
      icon="search"
      value={selectedCountryOption}
      options={countriesSearchOptions}
      onSelect={(selected) => onSelectCountry(selected?.value)}
      disabled={disabled}
      textValue={search}
      onTextChange={onSearch}
      renderOption={renderCountryOption}
    />
  )
}
