import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { DocumentLayoutMutationPayload } from '../../../model/DocumentLayout'
import { createDocumentLayout } from '../../../data/documentLayouts'
import { documentLayoutsKeys } from './documentLayouts.keys'

export const useCreateDocumentLayout = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: DocumentLayoutMutationPayload) => {
      const { data, ok } = await createDocumentLayout(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création de la mise en page a échoué')
      }
      return data
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: documentLayoutsKeys.lists })
    },
  })
}
