import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionnaireTemplatesKeys } from './questionnaireTemplates.keys'
import { useContext } from 'react'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { QuestionnaireTemplateToClonePayload } from '../../../data/questionnaireTemplates/mapper/questionnaireTemplate.model'
import { cloneQuestionnaireTemplate } from '../../../data/questionnaireTemplates'

export const useCloneQuestionnaireTemplate = () => {
  const queryClient = useQueryClient()
  const { currentUser } = useContext(ConnectedUserContext)

  return useMutation({
    mutationFn: async (payload: QuestionnaireTemplateToClonePayload) => {
      const { data, ok } = await cloneQuestionnaireTemplate(payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La duplication du questionnaire a échoué')
      }
      return data
    },

    onSuccess: (_, payload) => {
      if (payload.userTargetId === currentUser?.id) {
        queryClient.invalidateQueries({ queryKey: questionnaireTemplatesKeys.lists })
      }
    },
  })
}
