import { FunctionComponent, useMemo } from 'react'
import { DropdownItem, Icon } from '../../shared'
import { ContactOptionProps } from './ContactOption.model'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import classNames from 'classnames'

export const ContactOption: FunctionComponent<ContactOptionProps> = ({
  contact,
  selected = false,
  trusted,
  mssEmailAddress,
}) => {
  const organizationAddresses = useMemo(
    () =>
      contact.organizations.map(({ address }) => {
        if (!address) {
          return null
        }

        const { zipCode, city } = address
        return (zipCode && city && `${zipCode} - ${city}`) || city || zipCode
      }),
    [contact],
  )

  const { profession, speciality } = contact
  const subtitle: string | null =
    (speciality && profession && `${profession.description} ${speciality.description}`) ||
    speciality?.description ||
    profession?.description ||
    null

  return (
    <DropdownItem selected={selected}>
      <div className="text-base font-semibold mx-2">
        <div className="flex items-center space-x-2">
          {trusted && (
            <Icon size="nano" icon="star" color={getV3ColorCssVariable('primary', 'default')} />
          )}
          <span>
            {contact.firstName} {contact.familyName}
          </span>
          {mssEmailAddress && (
            <span
              className={selected ? 'text-shades-7' : 'text-shades-3'}
            >{`<${mssEmailAddress}>`}</span>
          )}
        </div>
        {subtitle !== null && (
          <div
            className={classNames('text-xs my-0.5', selected ? 'text-shades-7' : 'text-shades-4')}
          >
            {subtitle}
          </div>
        )}
        {organizationAddresses.length > 0 && (
          <div
            className={classNames(
              'text-xs font-medium',
              selected ? 'text-shades-7' : 'text-shades-4',
            )}
          >
            {organizationAddresses.join(' - ')}
          </div>
        )}
      </div>
    </DropdownItem>
  )
}
