import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getLegalDocument } from '../../../data/legalDocument'
import { isDefined } from '../../../misc/functions.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { LegalDocumentType, LegalDocumentTypeLabel } from '../../../model/LegalDocument'
import { legalDocumentKeys } from './legalDocument.keys'

export const useGetLegalDocument = (type: LegalDocumentType | undefined) => {
  const queryKey = useMemo(() => legalDocumentKeys.all, [])

  const queryReturn = useQuery({
    queryKey,

    queryFn: async ({ signal }) => {
      if (!isDefined(type)) return
      const { data, ok } = await getLegalDocument(type, signal)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer le document légal',
          `Document légal ${LegalDocumentTypeLabel[type]} inaccessible`,
        )
      }

      return data
    },
    staleTime: Infinity,
    enabled: isDefined(type),
  })

  return queryReturn
}
