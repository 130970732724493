import getApi from '../../api'
import { v4 as uuid } from 'uuid'
import { Survey } from '../../model/Survey'

export const createSurvey = (name: string) =>
  getApi().post<Survey>('/surveys', {
    name,
    finish: '',
    content: [
      {
        uuid: uuid(),
        level: 0,
        questions: [],
      },
    ],
    isActive: true,
  })
