import { Theme } from '../../../../design-system/themes/base.model'
import { TestProps } from '../../../../misc/types.utilities'

type Size = 'small' | 'medium'

export interface CheckboxProps extends TestProps {
  checked: CheckboxState
  disabled?: boolean
  theme?: Theme
  label?: string
  title?: string
  size?: Size
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export enum CheckboxState {
  UNCHECKED = 0,
  CHECKED = 1,
  INDETERMINATE = 2,
}
