import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { patientsKeys } from './patients.keys'
import { getPatient } from '../../../data/patients'

interface GetPatientParams {
  id: number
  enabled?: boolean
}

export const useGetPatient = ({ id, enabled = true }: GetPatientParams) => {
  const queryKey = useMemo(() => patientsKeys.detail(id), [id])

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await getPatient(id)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le patient`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
