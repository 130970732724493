import { useActionDispatch } from '../../utils'
import { addValid } from '../../../store/message'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TaskMutationPayload } from '../../../model/Task'
import { createTask } from '../../../data/task/createTask'
import { UserFriendlyError } from '../../../model/Error'
import { taskKeys } from './tasks.keys'

export const useCreateTask = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (variables: TaskMutationPayload) => {
      const { data, ok } = await createTask(variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création de la tâche a échoué')
      }
      return data
    },

    onSuccess: (data) => {
      if (!data.autoCreated) {
        valid('Tâche créée avec succès.')
      }
      queryClient.refetchQueries({ queryKey: taskKeys.all })
      queryClient.setQueryData(taskKeys.detail(data.id), data)
    },
  })
}
