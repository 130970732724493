import getApi from '../../api'
import { singleItemPageLimit } from '../../constants'
import { Drug } from '../../model/Drug'
import { DrugFilters } from '../../model/DrugFilters'
import { PaginatedList } from '../../model/Pagination'
import { serializeDrugFilters } from './mapper/drug.mapper'

export const getDrugs = (
  page: number,
  filters: DrugFilters,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<Drug>>(
    'drugs',
    { page, limit, ...serializeDrugFilters(filters) },
    { signal },
  )
