import React, { FunctionComponent, MouseEvent } from 'react'
import { withWidthLowerObserver } from '../../../../misc/WidthObserver.hoc'
import { breakSmall } from '../../../../misc/responsive'
import { DrugItemProps } from './DrugItem.model'
import { DrugThumbnail } from '../..'
import { ExternalResourceLink } from './ExternalResourceLink'
import { getVigilancePictogram } from '../../../../misc/drug.utilities'

const DrugItem: FunctionComponent<DrugItemProps> = ({
  drug,
  treatment,
  isLowerThanBreakpoint,
  onMonographClick,
  onSMRClick,
}) => {
  const handleMonographClick = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onMonographClick()
  }

  const handleSMRClick = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onSMRClick()
  }

  const vigilancePictogram = getVigilancePictogram(drug.vigilanceLevel)

  return (
    <div className="flex flex-row w-full justify-between">
      <div className="mr-4 flex-grow sm:flex-grow-0">
        <DrugThumbnail drug={drug} treatment={treatment} size="medium" />
      </div>
      <div className="flex items-center">
        {vigilancePictogram && (
          <img
            className="w-10 h-10 mx-4"
            src={vigilancePictogram.pictogram}
            alt={vigilancePictogram.tooltip}
            title={vigilancePictogram.tooltip}
          />
        )}
        {drug.hasSmr && (
          <ExternalResourceLink
            label="SMR"
            shrink={isLowerThanBreakpoint}
            onClick={handleSMRClick}
          />
        )}
        {drug.hasMonograph && (
          <ExternalResourceLink
            label="Monographie"
            shrink={isLowerThanBreakpoint}
            onClick={handleMonographClick}
          />
        )}
      </div>
    </div>
  )
}

export default withWidthLowerObserver(breakSmall)(DrugItem)
