import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getCities } from '../../../data/cities'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { citiesKeys } from './cities.keys'

interface UseGetInfiniteCitiesParams {
  filters: Filters
  enabled?: boolean
  limit?: number
}

export const useGetInfiniteCities = ({
  filters,
  enabled = true,
  limit,
}: UseGetInfiniteCitiesParams) => {
  const queryKey = useMemo(() => citiesKeys.list(filters), [filters])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getCities(pageParam, filters, signal, limit)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer la liste des villes',
          "Une erreur technique s'est produite",
        )
      }

      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    enabled,
  })

  const queryClient = useQueryClient()
  const cityList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, cityList, paginationState, cancelPendingQuery }
}
