import { useMutation } from '@tanstack/react-query'
import { getMailSubject } from '../../../data/mail'
import { UserFriendlyError } from '../../../model/Error'

interface UseGetMailSubjectParams {
  documentIds: Array<number>
}

export const useGetMailSubject = () => {
  const mutation = useMutation<string, Error, UseGetMailSubjectParams>({
    mutationFn: async ({ documentIds }) => {
      const { ok, data } = await getMailSubject(documentIds)

      if (!ok || !data) {
        throw new UserFriendlyError(
          'warn',
          "Impossible de préremplir l'objet du mail",
          "Une erreur technique s'est produite",
        )
      }

      return data.subject
    },
  })

  return mutation
}
