import React, { FunctionComponent } from 'react'

import { ColorsV2 } from '../../../design-system/colors_v2'
import { Icon } from '../../../components/shared'

import { AnswerImageProps, AnswerImageMode } from './AnswerImage.model'

import styles from './AnswerImage.module.scss'

const AnswerImage: FunctionComponent<AnswerImageProps> = ({ imageData, onClick }) => {
  const mode: AnswerImageMode = !imageData ? 'ADD' : 'DELETE'
  const title = mode === 'ADD' ? 'Ajouter une image' : `Supprimer l'image`
  return (
    <div
      onClick={onClick}
      style={{ backgroundImage: `url(${!!imageData ? imageData.base64 : ''})` }}
      className={styles.container}
      title={title}
      data-test-id="image-answer"
    >
      <div className={`${styles.baseIcon} ${styles.icon}`}>
        {mode === 'ADD' && <Icon icon="noimage" color={ColorsV2.contentLight1} size="normal" />}
      </div>
      <div className={`${styles.baseIcon} ${styles.hoverIcon}`}>
        {mode === 'ADD' && <Icon icon="camera" color={ColorsV2.contentLight1} size="normal" />}
        {mode === 'DELETE' && <Icon icon="cross" color={ColorsV2.contentLight1} size="normal" />}
      </div>
    </div>
  )
}
export default AnswerImage
