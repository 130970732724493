import { capitalize } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isDefined } from '../../../misc/functions.utilities'
import { AtcClass } from '../../../model/Drug'
import { AtcClassSelectProps } from './AtcClassSelect.model'
import { DropdownItem, Icon, SearchInput } from '../../shared'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import { SelectOption } from '../../../model/SelectOption'
import {
  useGetAtcClass,
  useGetInfiniteAtcClassChildren,
  useGetInfiniteAtcClasses,
} from '../../../hooks/queries/drugs'

const GOBACK_TO_ROOT = -1
const LOADING_VALUE = -2

const convertAtcClassIntoOption = (item: AtcClass) => ({
  label: `${item.code} - ${capitalize(item.title)}`,
  value: item.id,
})

const computeParentClassValue = (classItem: AtcClass | undefined) =>
  classItem?.parentExternalId ?? GOBACK_TO_ROOT

export const AtcClassSelect: FC<AtcClassSelectProps> = ({
  label,
  colorPreset = 'dark',
  classId,
  onClassSelected,
}) => {
  const [search, setSearch] = useState('')
  const [selectedClassId, setSelectedClassId] = useState<number | null>(classId ?? null)
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    atcClassList,
    query: { isLoading: isClassQueryLoading },
  } = useGetInfiniteAtcClasses({
    search,
  })

  const {
    query: { data: selectedClass, isFetching: isDetailLoading },
  } = useGetAtcClass({ id: selectedClassId ?? -1, enabled: isDefined(selectedClassId) })

  const {
    childrenAtcClassList,
    query: { isFetching: isChildrenQueryLoading },
  } = useGetInfiniteAtcClassChildren({
    id: selectedClassId ?? -1,
    enabled: isDefined(selectedClass) && selectedClass.totalChildren > 0,
  })

  const isLoading = isClassQueryLoading || isChildrenQueryLoading || isDetailLoading

  const atcClassesOptions = useMemo(() => {
    if (isLoading) {
      return [{ value: LOADING_VALUE, label: 'Chargement...' }]
    }

    const parentClass = computeParentClassValue(selectedClass)
    const gobackOption = { value: parentClass, label: 'Retour' }

    if (isDefined(selectedClass) && selectedClass.totalChildren === 0) {
      return [gobackOption]
    }

    if (isDefined(selectedClassId)) {
      const options = childrenAtcClassList.map(convertAtcClassIntoOption)
      return [gobackOption, ...options]
    } else {
      const options = atcClassList.map(convertAtcClassIntoOption)
      return options
    }
  }, [isLoading, selectedClass, selectedClassId, childrenAtcClassList, atcClassList])

  const handleSelect = useCallback(
    (selected: SelectOption<number>) => {
      if (selected.value === LOADING_VALUE) return
      if (selected.value === GOBACK_TO_ROOT) {
        setSelectedClassId(null)
        setSearch('')
      } else {
        setSelectedClassId(selected.value)
      }
      inputRef.current?.focus()
    },
    [setSelectedClassId],
  )

  useEffect(() => {
    if (selectedClass) {
      setSearch(selectedClass.code)
    }
    onClassSelected(selectedClass ?? null)
  }, [onClassSelected, selectedClass])

  return (
    <SearchInput
      placeholder="Rechercher une classe ATC"
      colorPreset={colorPreset}
      inputRef={inputRef}
      value={search}
      results={atcClassesOptions}
      onSelect={handleSelect}
      label={label}
      renderResult={(option, isHovered) => (
        <DropdownItem selected={isHovered}>
          <div className="max-w-full flex items-center whitespace-normal">
            {option.value === computeParentClassValue(selectedClass) && (
              <div className="mr-4">
                <Icon
                  icon="chevron"
                  rotate={90}
                  size="femto"
                  color={getV3ColorCssVariable('shades', 'shade2')}
                />
              </div>
            )}
            <span>{option.label}</span>
          </div>
        </DropdownItem>
      )}
      onChange={({ target: { value } }) => {
        setSearch(value)
        setSelectedClassId(null)
      }}
    />
  )
}
