import React, { FunctionComponent, useMemo, useState } from 'react'
import { IconButton, ValidationModal } from '../../../shared'
import { CodedInformationsTypeLabel, CodedInformations } from '../../../../model/CodedInformations'
import { PatientInformationsItemProps } from './PatientInformationsItem.model'
import { PatientInformations } from '../../../../model/PatientInformations'
import { PatientInfosItemEditable } from '../PatientInfosItemEditable/PatientInfosItemEditable.component'
import { PatientInfosTag } from '../PatientInfosItemEditable/PatientInfosItemEditable.model'

const computeDeletionMessage = ({ name, codedInformation }: PatientInformations) => {
  let textValue: string = ''

  switch (codedInformation?.type) {
    case 'ALLERGY':
      textValue = `l'allergie`
      break
    case 'MOLECULE':
      textValue = `l'allergie`
      break
    case 'CIM10':
      textValue = `la pathologie`
      break
  }

  return `Supprimer ${textValue} "${name}" ?`
}

const computeCodedInformationLabel = (info: CodedInformations | undefined) =>
  info?.ald ? `ALD n°${info.ald.code} : ${info.ald.title}` : undefined

export const PatientInformationsItem: FunctionComponent<PatientInformationsItemProps> = ({
  list,
  editPatientInformations,
  deleteItem,
  isEditable,
}) => {
  const [itemToBeDeleted, setItemToBeDeleted] = useState<PatientInformations>()

  const itemsWithLabels = useMemo(() => {
    return list.map((item) => {
      const tags: PatientInfosTag[] = []

      switch (item.codedInformation?.type) {
        case 'ALLERGY':
        case 'MOLECULE':
          tags.push({
            color: 'red',
            name: CodedInformationsTypeLabel[item.codedInformation?.type],
          })
          break
        case 'CIM10':
          tags.push({ color: 'orange', name: 'Pathologie' })
          break
      }

      if (item.codedInformation?.cim10) {
        tags.push({ color: 'lightGray', name: item.codedInformation.cim10 })
      }
      if (item.isAld) {
        tags.push({
          color: 'primaryDefault',
          name: 'ALD',
          info: computeCodedInformationLabel(item.codedInformation),
        })
      }
      if (!item.codedInformation) {
        tags.push({ color: 'lightGray', name: 'Manuel' })
      }

      return { ...item, tags }
    })
  }, [list])

  return (
    <div className="flex flex-col w-full px-4 py-2">
      {list &&
        itemsWithLabels.map((item, index) => (
          <PatientInfosItemEditable
            testId={`patient-allergies-item-${index}`}
            key={index}
            name={item.name ?? ''}
            tags={item.tags}
            actions={
              <>
                {isEditable && (
                  <div className="flex space-x-1">
                    <IconButton
                      icon="pencil"
                      title="Editer"
                      theme="transparent-dark"
                      size="nano"
                      onClick={() => editPatientInformations(item.id)}
                    />
                    <IconButton
                      icon="trash"
                      title="Supprimer"
                      theme="transparent-dark"
                      size="nano"
                      onClick={() => setItemToBeDeleted(item)}
                    />
                  </div>
                )}
              </>
            }
            startDate={item.startAt}
            endDate={item.endAt}
          />
        ))}
      <ValidationModal
        display={!!itemToBeDeleted}
        title={itemToBeDeleted ? computeDeletionMessage(itemToBeDeleted) : ''}
        onSubmit={() => {
          if (itemToBeDeleted) {
            deleteItem(itemToBeDeleted.id)
            setItemToBeDeleted(undefined)
          }
        }}
        onClose={() => setItemToBeDeleted(undefined)}
      />
    </div>
  )
}
