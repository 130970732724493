import { FC } from 'react'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { Icon } from '../../Icon'
import { IconButton } from '../../buttons'
import { IdentityCardProps } from './IdentityCard.model'

export const IdentityCard: FC<IdentityCardProps> = ({
  title,
  subtitle,
  icon,
  onRemove,
  disabled = false,
  leftCenteredIcon,
}) => (
  <div className="rounded border border-shades-5 p-1">
    <div className="p-3 flex items-center">
      {leftCenteredIcon && (
        <div className="pr-4">
          <Icon
            size="nano"
            icon={leftCenteredIcon}
            color={getV3ColorCssVariable('primary', 'default')}
          />
        </div>
      )}
      <div className="grow">
        <span className="text-lg text-shades-2 font-semibold break-all">{title}</span>
        <div className="flex space-x-2 items-center">
          {icon && (
            <div className="shrink-0">
              <Icon icon={icon} color={getV3ColorCssVariable('shades', 'shade4')} size="nano" />
            </div>
          )}
          <span className="text-sm text-shades-4 font-medium break-all">{subtitle}</span>
        </div>
      </div>
      {!disabled && <IconButton onClick={onRemove} icon="cross" size="nano" theme="light" />}
    </div>
  </div>
)
