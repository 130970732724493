import { useCallback, useMemo, useState } from 'react'
import createSearchPanel from '../../../../containers/SearchPanel/SearchPanel.container'
import { SearchInput } from '../../search'
import { BottomPanel } from '../BottomPanel'
import { SearchActeBottomPanelProps } from './SearchActeBottomPanel.model'
import { useGetInfiniteActes } from '../../../../hooks/queries/ccam/actes'
import { isEqual } from 'lodash'
import { useDebounce } from 'react-use'
import { ActeListItem } from '../../../ccam'
import {
  deserializeActeForList,
  serializeActeForList,
} from '../../../../data/ccam/actes/mapper/actes.mapper'
import { ActeForList } from '../../../../model/Acte'

const INITIAL_FILTERS = {
  search: '',
}

export const SearchActeBottomPanel = ({
  isUnmounting,
  onRequestClose,
  onSelect,
}: SearchActeBottomPanelProps) => {
  const SearchPanel = createSearchPanel<ActeForList>()
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const [searchValue, setSearchValue] = useState(INITIAL_FILTERS.search)
  const {
    query: { fetchNextPage, isLoading },
    acteList: items,
    paginationState,
    cancelPendingQuery,
  } = useGetInfiniteActes({ filters, enabled: !isEqual(INITIAL_FILTERS, filters) })

  const onPrimaryAction = useCallback(
    (selectedItem?: ActeForList) => {
      if (selectedItem) {
        onSelect(deserializeActeForList(selectedItem))
      }
    },
    [onSelect],
  )

  useDebounce(
    () => {
      cancelPendingQuery()
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: searchValue.length >= 3 ? searchValue : INITIAL_FILTERS.search,
      }))
    },
    500,
    [searchValue],
  )

  const handleChange = useCallback((search: string) => setSearchValue(search), [])

  const renderFilters = useCallback(
    () => (
      <>
        <SearchInput
          value={searchValue}
          onChange={({ target: { value } }) => handleChange(value)}
          colorPreset="dark"
          placeholder="Recherchez par code ou par libellé (3 caractères min)"
          autofocus
        />
      </>
    ),
    [handleChange, searchValue],
  )

  const renderItem = useCallback((item) => <ActeListItem item={item} />, [])

  const memoizedItems = useMemo(() => items.map(serializeActeForList), [items])

  return (
    <BottomPanel
      isUnmounting={isUnmounting}
      title="Ajouter un code CCAM"
      onRequestClose={onRequestClose}
    >
      <SearchPanel
        items={memoizedItems}
        loading={isLoading}
        itemCount={paginationState?.itemCount ?? null}
        pageCount={paginationState?.pageCount ?? null}
        currentPage={paginationState?.currentPage ?? 1}
        onPrimaryAction={onPrimaryAction}
        getItems={() => fetchNextPage()}
        renderItem={renderItem}
        filterCapabilities={[]}
        renderCustomFilters={renderFilters}
      />
    </BottomPanel>
  )
}
