import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getAssigningAuthorithies } from '../../../data/assigningAuthorities'
import { UserFriendlyError } from '../../../model/Error'
import { assigningAuthoritiesKeys } from './assigningAuthorities.keys'

export const useGetAssigningAuthorities = () => {
  const queryKey = useMemo(() => assigningAuthoritiesKeys.list, [])

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const { data, ok } = await getAssigningAuthorithies()

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la récupération des autorités d`affectations (O.I.D.)',
        )
      }

      return data
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })

  return { query }
}
