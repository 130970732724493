import { Node } from '@tiptap/core'
import { PluginNames } from '@/model/Plugins'
import { TextSelection } from '@tiptap/pm/state'
import { isDefined } from '@/utils/functions.utils'

export interface CursorControlStorage {
  savedSelection: number | null
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    [PluginNames.CursorControl]: {
      /** Move cursor after the node previously added */
      moveCursorAfter: () => ReturnType
      /** Sauvegarde l'ancre de sélection */
      saveNodeSelection: () => ReturnType
      /** Restaure l'ancre de sélection précédemment sauvegardé */
      restoreNodeSelection: (clearSavedSelection?: boolean) => ReturnType
    }
  }
}

export const CursorControl = Node.create<{}, CursorControlStorage>({
  name: PluginNames.CursorControl,

  addStorage() {
    return {
      savedSelection: null,
    }
  },

  addCommands() {
    return {
      moveCursorAfter:
        () =>
        ({ tr, dispatch }) => {
          if (dispatch) {
            const to = tr.selection.$to
            const posAfter = to.end()

            if (to.nodeAfter) {
              // Si il y a un noeud après, on déplace le curseur
              tr.setSelection(TextSelection.create(tr.doc, to.pos))
            } else {
              // Si pas de noeud, on en crée un
              const node = to.parent.type.contentMatch.defaultType?.create()
              if (node) {
                tr.insert(posAfter, node)
                // Après avoir inséré le noeud, on y déplace le curseur
                tr.setSelection(TextSelection.create(tr.doc, posAfter))
              }
            }

            tr.scrollIntoView()
          }

          return true
        },

      saveNodeSelection:
        () =>
        ({ editor }) => {
          const selection = editor.state.selection.$anchor.pos

          this.storage.savedSelection = selection
          return true
        },

      restoreNodeSelection:
        (clearSavedSelection = true) =>
        ({ commands }) => {
          const stored = this.storage.savedSelection
          if (!isDefined(stored)) return false

          if (clearSavedSelection) {
            this.storage.savedSelection = null
          }

          return commands.setNodeSelection(stored)
        },
    }
  },
})
