import React from 'react'
import { SharingConfigAccessLevel } from '../../../../model/Team'
import { SelectInput } from '../../../shared'
import { AccessLevelSelectProps, AccessLevelOptionValues } from './AccessLevelSelect.model'

const optionValues = Object.values(AccessLevelOptionValues)

const accessLevelToOption = (level: SharingConfigAccessLevel) => ({
  value: level,
  label: AccessLevelOptionValues[level].label,
})

export const AccessLevelSelect: React.FunctionComponent<AccessLevelSelectProps> = ({
  value,
  label,
  disabled,
  setValue,
}) => {
  return (
    <SelectInput
      colorPreset="dark"
      options={optionValues}
      value={accessLevelToOption(value)}
      onSelect={({ value }) => setValue(value)}
      title={label}
      disabled={disabled}
    />
  )
}
