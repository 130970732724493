import getApi from '../../../api'
import { User } from '../../../model/User'
import { deserializeUserResponse, serializeUserMutation } from './mapper/users.mapper'
import { SerializedUser } from './mapper/users.model'
import { ApiErrorContent } from '../../../model/Error'

export const putUser = (userId: string, user: Partial<User>) =>
  getApi()
    .put<SerializedUser, ApiErrorContent>(`/admin/users/${userId}`, serializeUserMutation(user))
    .then(deserializeUserResponse)
