import { MailWebServiceLoginFormFields } from '../../../../model/Mail'
import { User } from '../../../../model/User'

export enum MailOTPMethod {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export interface MailLoginFormProps {
  onSubmit: (formValues: MailWebServiceLoginFormFields) => void
  currentUser: User | null
}
