import { FollowTypes } from '@follow/cdk'
import { DrugIdentity } from './Drug'
import { Posology } from './Posology'

export type Treatment = FollowTypes.Treatment.Treatment

export enum TreatmentsStatus {
  ALL = 'all',
  CURRENT = 'current',
  PAST = 'past',
}

export interface TreamentFormResource {
  drug: DrugIdentity
  isAld: boolean
  posologyIntervals: Partial<Posology>[]
  prescriptionReason: string | null
  isChronic: boolean
  prescriberId: string | null
  manualPrescriber: string | null
}
