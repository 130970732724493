import React, { FunctionComponent, useState } from 'react'

import styles from './DocumentSection.module.scss'
import { GridLayout, Heading, Icon } from '../../shared'
import { ColorsV2 } from '../../../design-system/colors_v2'

import { DocumentSectionProps } from './DocumentSection.model'

export const DocumentSection: FunctionComponent<DocumentSectionProps> = ({
  title,
  subtitle,
  visible,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(!!visible)
  return (
    <div>
      <div className={styles.header}>
        <GridLayout
          columnsTemplate={`${subtitle ? 'auto' : ''} 1fr auto`}
          padding={['small', 'medium']}
          gap="extraSmall"
          shouldShrinkOnMobile={false}
          onClick={() => setIsVisible(!isVisible)}
          role="button"
        >
          <Heading size={5}>
            <span className={styles.title}>{title}</span>
          </Heading>
          {subtitle && (
            <Heading size={5}>
              <span className={styles.subtitle}>{subtitle}</span>
            </Heading>
          )}
          <Icon icon="chevron" rotate={isVisible ? 180 : 0} size="micro" color={ColorsV2.dsDark3} />
        </GridLayout>
      </div>
      {isVisible && <div className={styles.content}>{children}</div>}
    </div>
  )
}
