import { createSelector } from 'reselect'
import { RootState } from '../../reducers'

const dmpRequestRawSelector = (state: RootState) => {
  return {
    requests: state.domain.dmp.requests,
    isSending: state.domain.dmp.isSending,
    iframeStatus: state.domain.dmp.iframeStatus,
  }
}

export const dmpRequestsSelector = createSelector(
  [dmpRequestRawSelector],
  (state) => state.requests,
)

export const dmpIsReadySelector = createSelector(
  [dmpRequestRawSelector],
  ({ isSending, iframeStatus }) =>
    !isSending && (iframeStatus === 'ready' || iframeStatus === 'need_auth'),
)

export const dmpIframeStatusSelector = createSelector(
  [dmpRequestRawSelector],
  ({ iframeStatus }) => iframeStatus,
)

export const dmpIsSendingSelector = createSelector(
  [dmpRequestRawSelector],
  ({ isSending }) => isSending,
)

const stateHealthcareSettingsSelector = (state: RootState) => state.domain.dmp.healthcareSettings

export const healthcareSettingsSelector = createSelector(
  [stateHealthcareSettingsSelector],
  (settings) => settings,
)
