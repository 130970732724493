import styled from '../../../../styles/legacy/styled/styled'
import { BaseButtonAppearance, StyledButtonProps } from './BaseButton.model'

const validColor = (color) => (color.startsWith('--fw-color') ? `var(${color})` : color)

const renderBorder = (noBorder, borderSize, borderColor) =>
  !noBorder ? `border: ${borderSize}px solid ${validColor(borderColor)};` : ``

const computeRadius = (radius: string | undefined, appearance: BaseButtonAppearance) => {
  if (radius === undefined || appearance === 'standalone') {
    return radius
  }
  if (appearance === 'right') {
    return `0 ${radius} ${radius} 0`
  }
  if (appearance === 'left') {
    return `${radius} 0 0 ${radius}`
  }
}

export const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => `${disabled ? 'not-allowed' : 'pointer'}`};
  color: inherit;
  padding: ${({ paddingSize }) => `${paddingSize}`};
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  border: none;
  white-space: nowrap;
  border-radius: ${({ borderRadius, appearance }) => computeRadius(borderRadius, appearance)};
  height: ${({ heightSize }) => `${heightSize}`};
  width: ${({ widthSize }) => `${widthSize ? widthSize : ``}`};
  color: ${({ fontColor }) => validColor(fontColor)};
  background-color: ${({ backgroundColor }) => validColor(backgroundColor)};
  ${({ noBorder, borderSize, borderColor }) => renderBorder(noBorder, borderSize, borderColor)}
  transition: 0.2s ease all;

  &:hover {
    border-color: ${({ borderColorHover }) => `var(${borderColorHover})`};
  }
`
