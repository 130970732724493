import React, { FC, useEffect } from 'react'
import { EditorContent, EditorContext } from '@tiptap/react'
import { SimpleEditorProps } from './SimpleEditor.model'
import { Toolbar } from '../Toolbar'
import { TableBubbleMenu } from '@/plugins'
import { ImageResizeBubbleMenu } from '@/plugins/imageResize/ImageResizeBubbleMenu'
import styles from '../../styles/editor/elfe.module.scss'
import { EditorConfigContext } from '@/context'
import { withErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryContent } from '@/components/ErrorBoundaryContent'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const SimpleEditor: FC<SimpleEditorProps> = ({
  editor,
  config,
  disabled,
  toolbarStyle,
  extraTools,
}) => {
  useEffect(() => {
    editor?.setEditable(!disabled)
  }, [disabled, editor])

  return (
    <div className={cx('elfe-base', 'elfe-container', config.themes)}>
      <EditorContext.Provider value={{ editor }}>
        <EditorConfigContext.Provider value={config}>
          {!disabled && (
            <>
              <Toolbar extraTools={extraTools} toolbarStyle={toolbarStyle} />
              <ImageResizeBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
          <EditorContent editor={editor} className={styles['editor']} />
        </EditorConfigContext.Provider>
      </EditorContext.Provider>
    </div>
  )
}

const SimpleEditorWithBoundary = withErrorBoundary(SimpleEditor, {
  fallbackRender: (props) => <ErrorBoundaryContent {...props} />,
})

export { SimpleEditorWithBoundary as SimpleEditor }
