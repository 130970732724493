import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { updatePrescription } from '../../../data/prescriptions'
import { isDefined } from '../../../misc/functions.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { Prescription, PrescriptionUpdate } from '../../../model/Prescription'
import { documentInstancesActions } from '../../../store/cache/documentInstances'
import { documentTemplatesActions } from '../../../store/cache/documentTemplates'
import { documentAlertsDomainActions } from '../../../store/domain/documentAlerts'
import { getVariableData } from '../../../store/renderer'
import { useCurrentPatientId } from '../../utils'
import { patientTreatmentsKeys } from '../patientTreatments/patientTreatments.keys'
import { prescriptionKeys } from './prescriptions.keys'

interface UseUpdatePrescriptionVariables {
  id: string
  prescription: Partial<PrescriptionUpdate>
}

export const useUpdatePrescription = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const patientId = useCurrentPatientId()

  return useMutation<Prescription, Error, UseUpdatePrescriptionVariables>({
    mutationFn: async ({ id, prescription }) => {
      const { ok, data } = await updatePrescription(id, prescription)

      if (!ok || !data) {
        throw new UserFriendlyError('error', 'La mise à jour de la prescription a échoué')
      }

      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: prescriptionKeys.detail(data.uuid) })

      if (isDefined(patientId)) {
        queryClient.invalidateQueries({ queryKey: patientTreatmentsKeys.lists(patientId) })
      }

      if (data?.documentId) {
        dispatch(documentInstancesActions.actions.apiGetItemDetails(data.documentId))
        dispatch(documentAlertsDomainActions.getAlerts())
      } else if (data?.documentTemplateId) {
        dispatch(documentTemplatesActions.actions.apiGetItemDetails(data.documentTemplateId))
      }

      // Si mise à jour d'un traitement d'un patient
      if (!isDefined(data?.documentTemplateId)) {
        dispatch(getVariableData())
      }
    },
  })
}
