import { Contact, PP_IDENTIFIER_TYPE } from '../../../model/Contact'

export function serializeContact(contact: Partial<Contact>) {
  return {
    ...contact,
    ppIdentifier:
      contact.ppIdentifier === '' || contact.ppIdentifier === null ? null : contact.ppIdentifier,
    ppIdentifierType: contact.ppIdentifierType ? contact.ppIdentifierType : PP_IDENTIFIER_TYPE.RPPS,
    organizations: contact.organizations
      ? contact.organizations.map((organization) => ({
          ...organization,
          id: organization.id === '' || !contact.id ? undefined : organization.id,
          technicalId:
            organization.technicalId === '' || !contact.id ? undefined : organization.technicalId,
          private: true,
        }))
      : undefined,
  }
}
