import { isDefined } from '@follow/cdk'
import { Filters } from '../model/Filters'
import { PaginatedList } from '../model/Pagination'
import { normalizeSearchParams } from './url.utilities'

export function serializeQueryFilters(filters?: Filters): string {
  if (!isDefined(filters)) {
    return ''
  }
  const normalized = normalizeSearchParams(filters)
  const params = new URLSearchParams(normalized)
  return params.toString()
}

export const getNextPageParam = <T>(lastPage: PaginatedList<T> | undefined) => {
  if (!lastPage) return undefined

  const { currentPage, pageCount } = lastPage
  return currentPage < pageCount ? currentPage + 1 : undefined
}

export function checkIfPagesContainItem<T extends { id: number | string }>(
  data: unknown,
  itemId: number | string,
): boolean {
  if (data && typeof data === 'object' && 'pages' in data) {
    return (data.pages as PaginatedList<T>)[0].items.some(
      ({ id }) => id.toString() === itemId.toString(),
    )
  }
  return false
}
