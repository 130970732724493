import { RenderedPdf, useRenderPdfFromDocuments } from './useRenderPdfFromDocuments.query'
import { useCallback } from 'react'
import { downloadBlob } from '../../../misc/blob.utilities'
import { isPrescription } from '../../../misc/documents.utilities'
import { DocumentInstance } from '../../../model/DocumentInstance'
import { DocumentCategoryKeys } from '@follow/cdk'
import { DocumentFormat } from '../../../model/Document'
import { CustomFile, RenderableFileExtension } from '../../../model/File'
import { isRenderableFileExtension } from '../../../misc/files.utilities'
import { useDirectDownloadFile } from '../files/useDirectDownloadFile.query'

type DownloadableDocument = Pick<DocumentInstance, 'id' | 'type'> & {
  category: DocumentCategoryKeys
}
type DownloadableFile = Pick<CustomFile, 'id' | 'extension'>

export type DownloadableDocumentsVariable = {
  documents?: DownloadableDocument[]
  files?: DownloadableFile[]
  fileName?: string
  format?: DocumentFormat
}

export const useDownloadDocuments = () => {
  const download = useCallback((renderedPdf: RenderedPdf) => {
    downloadBlob(renderedPdf.blob, renderedPdf.fileName || 'Document')
  }, [])

  const { render } = useRenderPdfFromDocuments()
  const directDownload = useDirectDownloadFile()

  return ({ documents = [], files = [], fileName, format }: DownloadableDocumentsVariable) => {
    if (documents.length === 0 && files.length === 0) {
      return
    }

    // Cas spécial du téléchargement d'un seul fichier -> pas d'appel au Renderer
    if (documents.length === 0 && files.length === 1) {
      directDownload({ fileId: files[0].id })
      return
    }

    const documentsToLock = documents
      .filter(({ category, type }) => type === 'farte' && category && isPrescription(category))
      .map(({ id }) => id)

    render(
      {
        documents: documents.map((document) => ({
          documentId: document.id,
          type: document.type,
          documentCategoryId: document.category,
        })),
        files: files
          .filter((file) => isRenderableFileExtension(file.extension))
          .map((file) => ({
            fileId: file.id,
            extension: file.extension as RenderableFileExtension,
          })),
        documentsToLock,
        format,
        fileName,
      },
      { onSuccess: download },
    )
  }
}
