import { ReactNode } from 'react'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { FwCssVariableColor } from '../../../../design-system/design-system.model'
import { IconsType } from '../../../shared'

export type MessagesTypes = 'error' | 'warn' | 'info' | 'success'

export const InformationAlertIconColor: { [property in MessagesTypes]: FwCssVariableColor } = {
  error: getV3ColorCssVariable('status', 'danger'),
  warn: getV3ColorCssVariable('status', 'warning'),
  info: getV3ColorCssVariable('status', 'normal'),
  success: getV3ColorCssVariable('status', 'valid'),
} as const

export const InformationAlertIcon: { [property in MessagesTypes]: IconsType } = {
  error: 'crossCircle',
  warn: 'warningCircle',
  info: 'infoCircle',
  success: 'checkCircle',
} as const

export interface InformationAlertProps {
  children?: ReactNode
  type?: MessagesTypes
}
