import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { Team } from '../../../../model/Team'
import { TeamMutationPayload } from '../../../../data/admin/teams/teams.model'
import { putTeam } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { teamKeys } from './teams.keys'
import { checkIfPagesContainItem } from '../../../../misc/query.utilities'

interface TeamMutateVariables {
  teamId: string
  variables: TeamMutationPayload
}

export const usePutTeam = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  const queryReturn = useMutation({
    mutationFn: async ({ teamId, variables }: TeamMutateVariables) => {
      const { data, ok } = await putTeam(teamId, variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', "La mise à jour de l'équipe a échoué")
      }
      return data
    },

    onSuccess: (team) => {
      valid('Équipe mise à jour')
      queryClient.refetchQueries({
        queryKey: teamKeys.all,
        predicate: (query) => checkIfPagesContainItem<Team>(query.state.data, team.id),
      })
      queryClient.refetchQueries({
        queryKey: teamKeys.userTeamsList(),
      })
    },
  })

  return queryReturn
}
