import { FunctionComponent } from 'react'
import { DropdownItem, Tag } from '../../shared'
import { ContactMssEmailDropdownItemProps } from './ContactMssEmailDropdownItem.model'
import styles from './ContactMssEmailDropdownItem.module.scss'

export const ContactMssEmailDropdownItem: FunctionComponent<ContactMssEmailDropdownItemProps> = ({
  contactMssEmailOption,
  selected = false,
}) => {
  return (
    <DropdownItem selected={selected}>
      <div className={styles.itemWrapper}>
        <div className={styles.item}>
          <div className={styles.title}>
            <div>
              {contactMssEmailOption.firstName} {contactMssEmailOption.familyName}
            </div>
            <div>{`<${contactMssEmailOption.mssEmailAddress}>`}</div>
            {contactMssEmailOption.isPrivate && (
              <Tag>
                <span>Privé</span>
              </Tag>
            )}
          </div>
          <div className={styles.description}>
            {contactMssEmailOption.profession && <div>{contactMssEmailOption.profession}</div>}
            {contactMssEmailOption.speciality && <div>{contactMssEmailOption.speciality}</div>}
            {contactMssEmailOption.organizations && (
              <div>{contactMssEmailOption.organizations}</div>
            )}
          </div>
        </div>
      </div>
    </DropdownItem>
  )
}
