import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { finalizeAuthenticationMail } from '../../../../data/mail'
import { subCodeTokenExpired } from '../../../../misc/mail.utilities'
import { UserFriendlyError } from '../../../../model/Error'
import {
  MailWebServiceLoginOtpFormFields,
  MailWebServiceLoginResponse,
} from '../../../../model/Mail'
import { mailWebserviceAuthToken } from '../../../../state/mail'
import { mailErrorsMessage, mailErrorsTitle } from '../mail.errors'
import { mailWebserviceKeys } from './webservice.keys'

interface FinalizeAuthenticationVariables extends MailWebServiceLoginOtpFormFields {
  tmpAuthenticationId: string
}

export const useFinalizeMailAuthentication = () => {
  const [, setWebserviceToken] = useAtom(mailWebserviceAuthToken)

  const queryClient = useQueryClient()

  const mutation = useMutation<MailWebServiceLoginResponse, Error, FinalizeAuthenticationVariables>(
    {
      mutationFn: async ({ tmpAuthenticationId, ...variables }) => {
        const { ok, data, status } = await finalizeAuthenticationMail(
          variables,
          tmpAuthenticationId,
        )

        if ((!ok && data && subCodeTokenExpired(data, status)) || status === 401) {
          throw new UserFriendlyError('error', 'Échec de la connexion', 'Veuillez réessayer')
        }

        if (!ok || !data) {
          throw new UserFriendlyError(
            'error',
            mailErrorsTitle.connexionError,
            mailErrorsMessage.checkCredentials,
          )
        }

        return data
      },
      onSuccess: (data) => {
        setWebserviceToken(data.authenticationId)
        queryClient.resetQueries({ queryKey: mailWebserviceKeys.maintainAuth })
      },
    },
  )

  return mutation
}
