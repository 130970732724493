import getApi from '../../api/apisauceConfig'
import { singleItemPageLimit } from '../../constants'
import { PaginatedList } from '../../model/Pagination'
import { ResourceHistoryEntity } from '../../model/ResourceHistory'
import { deserializeResourceHistoryResponse, ResourceHistoryApi } from './mapper'

export const getResourceHistory = (
  entity: ResourceHistoryEntity,
  page: number,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi()
    .get<PaginatedList<ResourceHistoryApi>>(
      '/resources/history',
      { ...entity, page, limit },
      { signal },
    )
    .then(deserializeResourceHistoryResponse)
