import { all, call, fork } from 'redux-saga/effects'
import { redirectToErrorPage } from '../../misc/url.utilities'
import { cacheSagas } from '../cache'
import { clipboardSagas } from '../clipboard/clipboard.sagas'
import { domainSagas } from '../domain/domain.sagas'
import * as Renderer from '../renderer/renderer.sagas'
import { uiSagas } from '../ui'
import * as Zendesk from '../zendesk/zendesk.sagas'

function* callSaga(saga) {
  try {
    yield call(saga)
  } catch (exception: any) {
    console.error(
      '[SAGA_ERROR]',
      `Une saga (${saga.name}) a propagé une exception qui n'a pas été gérée.
    Terminaison imminente de la root saga associée et de toutes ses sagas.
    Message: ${exception.message}`,
    )
    redirectToErrorPage('error')
    throw exception
  }
}

const forkWithCatch = (saga) => {
  return fork(callSaga, saga)
}

export default function* root() {
  yield all(cacheSagas.map((cacheSaga) => forkWithCatch(cacheSaga)))
  yield all(uiSagas.map((uiSaga) => forkWithCatch(uiSaga)))
  yield all(domainSagas.map((domainSaga) => forkWithCatch(domainSaga)))
  yield all(Object.values(clipboardSagas).map((clipboardSaga) => forkWithCatch(clipboardSaga)))
  yield forkWithCatch(Renderer.getVariableDataWatcher)
  yield forkWithCatch(Zendesk.getUserWatcher)
}
