import { Filters } from '../../../model/Filters'

const all = ['cities']
const lists = [...all, 'list']
const list = (filters: Filters) => [...lists, filters]

export const citiesKeys = {
  all,
  lists,
  list,
}
