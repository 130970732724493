import { chunk, isEmpty } from 'lodash'
import { SendableDocument } from '../../../model/DocumentInstance'

const isLinkedDocument = (document: SendableDocument) => document.type === 'farte'

export const getDocumentsToRender = (documents: SendableDocument[]) => {
  const linkedDocuments: SendableDocument[] = documents.filter(isLinkedDocument)
  const separateDocuments: SendableDocument[] = documents.filter(
    (document) => !linkedDocuments.includes(document),
  )

  const toBeRenderedDocuments = linkedDocuments.length
    ? [linkedDocuments, ...chunk(separateDocuments, 1)]
    : chunk(separateDocuments, 1)
  const filteredDocuments = toBeRenderedDocuments.filter((docs) => !isEmpty(docs))

  return filteredDocuments
}

export const getRenderableDocumentsVariable = (documents: SendableDocument[]) => ({
  documents: documents.map((document) => ({
    documentId: document.id,
    type: document.type,
  })),
  fileName: !isLinkedDocument(documents[0]) ? documents[0].title : null,
})

export const isFileAlreadyAttachedToEmail = (addedFiles: File[], fileToAdd: File) =>
  addedFiles.some(
    (existingFile) => existingFile.size === fileToAdd.size && existingFile.name === fileToAdd.name,
  )
