import { FwResourceType } from '../../../model/Resource'

const all = ['documentCategories']
const list = [...all, 'list']
const used = (type: FwResourceType) => [...list, type]

export const getDocumentCategoriesKeys = {
  all,
  list,
  used,
}
