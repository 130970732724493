import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getDocumentCategories } from '../../../data/documentCategories'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData } from '../../utils/query'
import { getDocumentCategoriesKeys } from './documentCategories.keys'

export const useGetDocumentCategories = () => {
  const queryKey = useMemo(() => getDocumentCategoriesKeys.list, [])

  const queryResult = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => {
      const { data, ok } = await getDocumentCategories(pageParam)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer les catégories des documents',
          `Une erreur technique s'est produite`,
        )
      }

      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    staleTime: Infinity,
    gcTime: Infinity,
  })
  const documentCategories = useFlatPaginatedData(queryResult.data)

  return { query: queryResult, documentCategories }
}
