import { ContactMssEmail, ContactMssEmailOption } from '../model/ContactMssEmail'
import { PatientContactMssEmail } from '../model/PatientContactMssEmail'
import { getDisplayedFirstname, getDisplayedLastname } from '@follow/cdk'

export interface MapContactMssEmailToContactMssEmailOptionParams {
  isEmptyContact?: boolean
}

export const mapContactMssEmailToContactMssEmailOption = (
  contactMssEmail: ContactMssEmail,
  params?: MapContactMssEmailToContactMssEmailOptionParams,
): ContactMssEmailOption => {
  return {
    mssEmailAddress: contactMssEmail.mssEmailAddress,
    firstName: contactMssEmail.contact.firstName,
    familyName: contactMssEmail.contact.familyName,
    profession: contactMssEmail.contact.profession?.description || undefined,
    speciality: contactMssEmail.contact.speciality?.description
      ? `${contactMssEmail.contact.speciality.description}`
      : undefined,
    organizations: contactMssEmail.contact.organizations
      ? contactMssEmail.contact.organizations
          .filter((organization) => organization?.address !== null)
          .map(({ address }) => (address ? `${address.zipCode} - ${address.city}` : ''))
          .join(', ')
      : undefined,
    isPrivate: contactMssEmail.contact.private,
    isEmptyContact: params?.isEmptyContact || false,
    isPatient: false,
  }
}

export const mapPatientContactMssEmailToContactMssEmailOption = (
  patientContactMssEmail: PatientContactMssEmail,
): ContactMssEmailOption => {
  return {
    mssEmailAddress: patientContactMssEmail.mssEmailAddress,
    firstName: getDisplayedFirstname(patientContactMssEmail.patient),
    familyName: getDisplayedLastname(patientContactMssEmail.patient),
    isPrivate: false,
    isEmptyContact: false,
    isPatient: true,
  }
}
