import { DocumentCategoryKeys, DocumentCategoryIcon } from '../../model/DocumentCategory'
import { useMemo } from 'react'
import { SelectOption } from '../../model/SelectOption'
import { useGetDocumentCategories } from '../queries/documentCategories'

export const useDocumentCategoryOptions = (): Array<SelectOption<DocumentCategoryKeys>> => {
  const { documentCategories } = useGetDocumentCategories()

  return useMemo(
    () =>
      documentCategories
        .filter(({ id }) => id !== DocumentCategoryKeys.Survey)
        .map(({ id, label }) => ({
          value: id,
          label,
          icon: DocumentCategoryIcon[id],
        })),
    [documentCategories],
  )
}
