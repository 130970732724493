export interface BottomPanelState {
  title: string
  maximized: boolean
  withOverlay: boolean
  open: boolean
  componentType?: BottomPanelComponentType
  displayCloseButton: boolean
}

export enum BottomPanelComponentType {
  EditNotification = 'EditNotification',
  SearchBaseVariable = 'SearchBaseVariable',
  SearchDocumentHistory = 'SearchDocumentHistory',
  PublishEphemeralNotification = 'PublishEphemeralNotification',
  DocumentAlerts = 'DocumentAlerts',
  AssistantPermissions = 'AssistantPermissions',
}

export interface BottomPanelOptions {
  title?: string
  open?: boolean
  maximized?: boolean
  withOverlay?: boolean
  componentType?: BottomPanelComponentType
  displayCloseButton?: boolean
}

export enum BottomPanelActionTypes {
  SET_BOTTOM_PANEL_OPTIONS = 'SET_BOTTOM_PANEL_OPTIONS',
  BOTTOM_PANEL_DISPLAY = 'BOTTOM_PANEL_DISPLAY',
  BOTTOM_PANEL_HIDE = 'BOTTOM_PANEL_HIDE',
  BOTTOM_PANEL_TOGGLE_EXPAND = 'BOTTOM_PANEL_TOGGLE_EXPAND',
}
