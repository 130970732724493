import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'

import { EditNotificationPanelProps } from './EditNotificationPanel.model'
import { User } from '../../model/User'
import {
  AlertBannerItem,
  Input,
  MultiSelectInput,
  RoundedButton,
  SelectInput,
  TextArea,
} from '../../components/shared'
import { NotificationType, NOTIFICATION_TYPE_OPTIONS } from '../../model/Notification'
import { DropdownUserItem } from '../../components/user'
import { getFullName } from '../../misc/user.utilities'
import classNames from 'classnames'
import { MultiSelectOption } from '../../model/SelectOption'
import { searchLimit } from '../../constants'
import { useGetInfiniteUsers, usePutNotification } from '../../hooks/queries/admin'
import { useAtom } from 'jotai'
import { editNotificationPanelAtom } from '../../state/admin'
import { RESET } from 'jotai/utils'
import { usePostNotification } from '../../hooks/queries/admin/notifications/usePostNotification.query'
import { EditorTool, SimpleEditor, ToolbarButton, useElfeEditor } from '@follow/elfe'

const editorTools: Array<EditorTool> = [
  'heading',
  'fontSize',
  'marks',
  'align',
  'list',
  'undo',
  'image',
]

const userToOption = (user: User) => ({
  label: getFullName(user),
  value: user,
})

const optionsToIds = (options: ReadonlyArray<MultiSelectOption<User>>) =>
  options.map(({ value }) => value.id)

const areUsersEquals = (a: User, b: User) => a.id === b.id

export const EditNotificationPanel: FunctionComponent<EditNotificationPanelProps> = ({
  actions: Actions,
}) => {
  const [inUseNotification, setInUseNotification] = useAtom(editNotificationPanelAtom)

  const [title, setTitle] = useState<string>(inUseNotification?.title ?? '')
  const [type, setType] = useState<string>(inUseNotification?.type ?? '')
  const [content, setContent] = useState<string>(inUseNotification?.content ?? '')
  const [usersRecipient, setUsersRecipient] = useState<Array<MultiSelectOption<User>>>(
    inUseNotification?.recipients.map(userToOption) ?? [],
  )
  const { editor, config } = useElfeEditor(
    {
      editorType: 'simple',
      tools: editorTools,
      placeholder: 'Contenu de la notification...',
    },
    {
      content: inUseNotification?.content ?? '',
    },
  )

  const [userSearch, setUserSearch] = useState('')

  const { userList, cancelPendingQuery } = useGetInfiniteUsers({
    filters: {
      search: userSearch,
    },
    limit: searchLimit,
    enabled: userSearch.length > 0,
  })
  const { mutate: updateNotification } = usePutNotification()
  const { mutate: createNotification } = usePostNotification()

  const handleUserSearchChange = useCallback(
    (value: string) => {
      setUserSearch(value)
      cancelPendingQuery()
    },
    [cancelPendingQuery],
  )

  const userOptions = useMemo(() => userList.map(userToOption), [userList])

  useEffect(() => {
    return () => {
      setInUseNotification(RESET)
    }
  }, [setInUseNotification])

  const handleSelectRecipient = (selected: MultiSelectOption<User>[]) => {
    setUsersRecipient(selected)
  }

  const editDisabled =
    !!inUseNotification?.publishedAt && inUseNotification.type !== NotificationType.feature

  const handleSubmit = () => {
    let contentToSend = content

    if (NotificationType.feature === type && !!editor) {
      const serializedHtml = editor.getHTML()
      contentToSend = `<div>${serializedHtml}</div>`
    }

    const updates = {
      title,
      content: contentToSend,
      type,
      recipients: optionsToIds(usersRecipient),
    }

    if (inUseNotification) {
      updateNotification({ notificationId: inUseNotification.id, variables: updates })
    } else {
      createNotification(updates)
    }
  }

  const handleImage = () => {
    const src = window.prompt("URL de l'image")
    if (src) {
      editor?.chain().focus().setImage({ src }).run()
    }
  }

  return (
    <div
      className={classNames(
        type === NotificationType.feature ? 'overflow-y-scroll overflow-x-hidden' : '',
        'bg-white flex flex-col h-full',
      )}
    >
      <Actions>
        <RoundedButton
          theme="primary"
          label="Sauvegarder"
          onClick={handleSubmit}
          disabled={editDisabled && inUseNotification?.type !== NotificationType.feature}
        />
      </Actions>
      {editDisabled && NotificationType.feature !== type && (
        <AlertBannerItem
          code="notification_already_published"
          title="Notification publiée"
          text="Une notification publiée ne peut pas être modifiée excepté les notifications de type nouveautés"
          icon="infoCircle"
          color="gray"
        />
      )}
      <div className="px-4 pt-2 pb-4 h-full">
        <div className="flex items-center mb-2 flex-col w-full h-full">
          <div className="flex flex-row w-full gap-4 items-end">
            <Input
              name="title"
              label="Titre"
              autocomplete="off"
              disabled={editDisabled && type !== NotificationType.feature}
              placeholder="Saisir un titre"
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              colorPreset="light"
            />
            <SelectInput
              placeholder="Sélectionner un type"
              title="Type de notification"
              icon={undefined}
              disabled={editDisabled}
              colorPreset="light"
              options={NOTIFICATION_TYPE_OPTIONS}
              value={NOTIFICATION_TYPE_OPTIONS.find(({ value }) => value === type)}
              onSelect={(selected) => {
                if (selected) {
                  setType(selected?.value)
                  if (selected?.value === NotificationType.feature && usersRecipient.length > 0) {
                    setUsersRecipient([])
                  }
                }
              }}
            />
          </div>
          {(!editDisabled || inUseNotification?.recipients.length !== 0) &&
            NotificationType.feature !== type &&
            type && (
              <div className="mt-4 w-full">
                <MultiSelectInput
                  title="Utilisateurs à notifier"
                  placeholder="Laisser vide pour notifier tout le monde"
                  colorPreset="light"
                  mode="multiline"
                  disabled={editDisabled}
                  emitChange={handleUserSearchChange}
                  value={usersRecipient}
                  options={userOptions}
                  onSelect={(selected) => {
                    handleSelectRecipient(selected)
                    handleUserSearchChange('')
                  }}
                  areElementsEquals={areUsersEquals}
                  renderOption={({ value }) => (
                    <DropdownUserItem
                      user={value}
                      selectedValues={usersRecipient.map(({ value }) => value)}
                    />
                  )}
                />
              </div>
            )}

          {type === NotificationType.feature && (
            <div className="w-full grow mt-4">
              <SimpleEditor
                editor={editor}
                extraTools={
                  <ToolbarButton title="Ajouter une image" icon="Image" onClick={handleImage} />
                }
                config={config}
              />
            </div>
          )}

          {type && type !== NotificationType.feature && (
            <div className="w-full mt-4">
              <TextArea
                name="content"
                placeholder="message"
                onChange={(event) => setContent(event.target.value)}
                value={content}
                disabled={editDisabled}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
