import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateSurvey } from '../../../data/survey'
import { UserFriendlyError } from '../../../model/Error'
import { Survey, SurveyApi } from '../../../model/Survey'
import { surveyKeys } from './survey.keys'

interface UseUpdateSurveyVariables {
  surveyId: number
  survey: Partial<SurveyApi>
}

export const useUpdateSurvey = () => {
  const queryClient = useQueryClient()

  return useMutation<Survey, Error, UseUpdateSurveyVariables>({
    mutationFn: async ({ surveyId, survey }) => {
      const { ok, data } = await updateSurvey(surveyId, survey)

      if (!ok || !data) {
        throw new UserFriendlyError(
          'error',
          "La mise à jour du questionnaire en salle d'attente a échoué",
        )
      }

      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: surveyKeys.list })
      queryClient.invalidateQueries({ queryKey: surveyKeys.detail(data.id) })
    },
  })
}
