import React, { FunctionComponent, useEffect, useState } from 'react'
import { RoundedButton, List, AbstractList } from '../../../shared'
import Filters, { FiltersType } from '../../../../containers/SearchPanel/Filters'
import { useEffectWithRef } from '../../../../hooks/utils'
import { DocumentTemplateListItem } from '../../../../model/DocumentTemplate'
import { FwResourceType } from '../../../../model/Resource'
import { RestuxFilters } from '../../../../store/restux/restux.model'
import { isFiltersEmpty } from '../../../../store/restux/restux.utilities'
import { RestuxLoadingState } from '../../../../store/restux/ui'
import { DocumentListItem } from '../../../document'
import { AddMedicalEventContentProps } from './AddMedicalEventContent.model'
import { useGetTags, useGetUsedTags } from '../../../../hooks/queries/tag'
import { useGetUsedDocumentCategories } from '../../../../hooks/queries/documentCategories/useGetUsedDocumentCategories.query'
import styles from './AddMedicalEventContent.module.scss'

export const AddMedicalEventContent: FunctionComponent<AddMedicalEventContentProps> = ({
  medicalEventId,
  documentTemplatesPage: {
    items: documentTemplateItems,
    totalItems: documentTotalItems,
    totalPages: documentTotalPages,
    loadingState: documentLoadingState,
  },
  suggestions,
  getPaginatedDocumentTemplate,
  getDocumentTemplatesSuggestion,
  clearDocumentTemplateList,
  clearDocumentTemplatesSuggestion,
  addDocumentInstances,
}) => {
  const [searchFilters, setSearchFilters] = useState<RestuxFilters>()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedDocuments, setSelectedDocuments] =
    useState<ReadonlyArray<DocumentTemplateListItem>>()
  const [showSuggestion, setShowSuggestion] = useState(true)
  const { tagList: tags } = useGetTags()
  const { usedTagList: usedTags } = useGetUsedTags({
    usedInResource: FwResourceType.DocumentTemplates,
  })
  const { usedDocumentCategories } = useGetUsedDocumentCategories({
    usedInResource: FwResourceType.DocumentTemplates,
  })

  const [listRef, setListRef] = useEffectWithRef<AbstractList<DocumentTemplateListItem>>(() => {
    if (listRef) {
      listRef.updateList(true)
    }
  }, [searchFilters])

  useEffect(() => {
    return () => {
      clearDocumentTemplateList()
      clearDocumentTemplatesSuggestion(medicalEventId)
    }
  }, [clearDocumentTemplateList, clearDocumentTemplatesSuggestion, medicalEventId])

  useEffect(() => {
    if (searchFilters) {
      const filters = {
        title: searchFilters.search,
        categories: searchFilters.documentCategories,
        tagIds: searchFilters.tagIds,
      }

      if (showSuggestion && isFiltersEmpty(filters)) {
        clearDocumentTemplateList()
        getDocumentTemplatesSuggestion(medicalEventId)
      } else {
        clearDocumentTemplatesSuggestion(medicalEventId)
        getPaginatedDocumentTemplate({
          page: { currentPage, pageSize: 40 },
          filters,
        })
      }
    }
  }, [
    searchFilters,
    currentPage,
    showSuggestion,
    clearDocumentTemplateList,
    clearDocumentTemplatesSuggestion,
    getDocumentTemplatesSuggestion,
    getPaginatedDocumentTemplate,
    medicalEventId,
  ])

  const handleAddSelectedItem = () => {
    if (selectedDocuments) {
      if (selectedDocuments.length > 0) {
        addDocumentInstances([...selectedDocuments], { origin: 'medicalEvent' })
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <Filters
          tags={tags}
          usedTags={usedTags}
          documentCategories={usedDocumentCategories}
          capabilities={[FiltersType.Title, FiltersType.Tags, FiltersType.DocumentCategory]}
          onFiltersChange={(filters) => setSearchFilters(filters)}
          resetPagination={() => {
            if (listRef) {
              listRef.updateList(true)
            }
          }}
          renderActionItem={() => (
            <RoundedButton
              label="Voir tous mes Documents"
              theme="transparent"
              onClick={() => {
                setShowSuggestion(false)
              }}
            />
          )}
        />
      </div>
      <div className={styles.list}>
        <List
          items={suggestions ? suggestions.documentTemplates : documentTemplateItems}
          itemCount={suggestions ? suggestions.documentTemplates.length : documentTotalItems}
          ref={setListRef}
          onMultiSelectionChange={(selection) => setSelectedDocuments(selection)}
          loading={documentLoadingState !== RestuxLoadingState.IDLE}
          renderItem={(item) => <DocumentListItem item={item} />}
          pageCount={documentTotalPages}
          onUpdateList={(page) => setCurrentPage(page)}
          onAddSelectedItems={() => handleAddSelectedItem()}
          onPrimaryAction={(selection) => {
            addDocumentInstances([selection], {
              origin: 'medicalEvent',
            })
          }}
          selectedCount={selectedDocuments && selectedDocuments.length}
          intel={
            suggestions &&
            "Follow a déterminé ces documents comme ayant la plus grande probabilité d'être ceux que vous cherchez"
          }
          fullPageMode={false}
        />
      </div>
    </div>
  )
}
