import { capitalize } from 'lodash'
import { FunctionComponent, useCallback, useState } from 'react'
import { TerminateTreatmentModal } from '../../../drug'
import { IconButton, ValidationModal } from '../../../shared'
import { Treatment } from '../../../../model/Treatment'
import { PatientInfosItemEditable } from '../PatientInfosItemEditable'
import { PatientCurrentTraetmentsProps } from './PatientCurrentTreatments.model'
import { useTerminatePatientTreatment } from '../../../../hooks/queries/patientTreatments/useTerminatePatientTreatment.query'
import { useDeletePatientTreatment } from '../../../../hooks/queries/patientTreatments/useDeletePatientTreatment.query'

export const PatientCurrentTreatments: FunctionComponent<PatientCurrentTraetmentsProps> = ({
  isEditable,
  currentTreatments,
  patientId,
  openPosolosyBottomPanel,
  navigateToDocument,
}) => {
  const [terminatingTreatment, setTerminatingTreatment] = useState<Treatment>()
  const [treatmentToDelete, setTreatmentToDelete] = useState<Treatment | undefined>()

  const { mutate: terminateTreatment } = useTerminatePatientTreatment()
  const { mutate: deleteTreatment } = useDeletePatientTreatment()

  const handleRedirectToTreatment = (item: Treatment) => {
    if (item.medicalEventId && item.documentId) {
      navigateToDocument(item.medicalEventId, item.documentId)
    }
  }

  const handleCloseModal = useCallback(() => {
    setTerminatingTreatment(undefined)
  }, [])

  const handleTerminateTreatment = useCallback(
    (endDate: Date) => {
      if (!terminatingTreatment) return

      terminateTreatment({
        endDate,
        treatmentId: terminatingTreatment.drugConfig.prescriptionUuid,
        documentId: terminatingTreatment.documentId ?? undefined,
      })
      handleCloseModal()
    },
    [handleCloseModal, terminateTreatment, terminatingTreatment],
  )

  const handleDeleteTreatment = useCallback(() => {
    if (!treatmentToDelete) return

    deleteTreatment({
      patientId: patientId,
      treatmentId: treatmentToDelete.id,
    })
    setTreatmentToDelete(undefined)
  }, [treatmentToDelete, deleteTreatment, patientId])

  return (
    <div className="flex flex-col w-full px-4 py-2">
      {currentTreatments.map((item) => (
        <PatientInfosItemEditable
          testId={`patient-treatments-item-${item.id}`}
          key={item.id}
          name={capitalize(item.drugConfig.drug.activePrinciples ?? item.drugConfig.drug.name)}
          title={item.drugConfig.drug.name}
          startDate={item.startDate}
          endDate={item.endDate}
          prescriber={
            item.prescriber
              ? `${item.prescriber.firstName} ${capitalize(item.prescriber.familyName)}`
              : item.manualPrescriber
                ? item.manualPrescriber
                : undefined
          }
          actions={
            item.medicalEventId && item.documentId ? (
              <>
                {isEditable && (
                  <IconButton
                    icon="cross"
                    title="Arrêter le traitement"
                    theme="transparent-dark"
                    size="nano"
                    onClick={() => setTerminatingTreatment(item)}
                  />
                )}
                <IconButton
                  icon="logout"
                  title="Naviguer vers l'événement médical"
                  theme="transparent-dark"
                  size="nano"
                  onClick={() => handleRedirectToTreatment(item)}
                />
              </>
            ) : (
              <>
                {isEditable && (
                  <IconButton
                    icon="pencil"
                    title="Editer"
                    theme="transparent-dark"
                    size="nano"
                    onClick={() => openPosolosyBottomPanel(item.drugConfig.prescriptionUuid)}
                  />
                )}
                {isEditable && (
                  <IconButton
                    icon="trash"
                    title="Supprimer"
                    theme="transparent-dark"
                    size="nano"
                    onClick={() => setTreatmentToDelete(item)}
                  />
                )}
              </>
            )
          }
        />
      ))}
      <TerminateTreatmentModal
        display={!!terminatingTreatment}
        treatment={terminatingTreatment}
        onClose={handleCloseModal}
        onValid={handleTerminateTreatment}
      />
      <ValidationModal
        display={!!treatmentToDelete}
        title={`Voulez vous vraiment supprimer ce traitement en cours ?`}
        onClose={() => setTreatmentToDelete(undefined)}
        onSubmit={handleDeleteTreatment}
      />
    </div>
  )
}
