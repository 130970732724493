import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { generatePrescriptionPreview } from '../../../data/prescriptions'
import { Drug } from '../../../model/Drug'
import { UserFriendlyError } from '../../../model/Error'
import { PosologyFormResource } from '../../../model/Posology'
import { prescriptionKeys } from './prescriptions.keys'

interface UseGeneratePrescriptionPreviewParams {
  posologyIntervals: Array<PosologyFormResource>
  drugs: Array<Drug>
  enabled?: boolean
}

export const useGeneratePrescriptionPreview = ({
  drugs,
  posologyIntervals,
  enabled,
}: UseGeneratePrescriptionPreviewParams) => {
  const queryKey = useMemo(
    () => prescriptionKeys.preview(posologyIntervals, drugs),
    [drugs, posologyIntervals],
  )

  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { data, ok } = await generatePrescriptionPreview(posologyIntervals, drugs)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la génération du résumé de la posologie',
        )
      }

      return data
    },
    enabled,
  })

  return { query }
}
