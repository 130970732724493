import { FunctionComponent, useState } from 'react'
import classNames from 'classnames/bind'
import { useAtomValue } from 'jotai'
import { isDefined } from '@follow/cdk'
import { useGetPendingFollowPatient } from '../../../hooks/queries/doctolib'
import { useCurrentPatient, useWidthObserver } from '../../../hooks/utils'
import { breakSmall } from '../../../misc/responsive'
import { IconButton } from '../../shared'
import { DoctolibPatientInfoUpdateModal } from '../PatientInfos/PatientGeneralInfos/DoctolibPatientInfoUpdateModal'
import { PatientPageStatistics } from './PatientPageStatistics'
import { PatientTimeline } from './PatientTimeline'
import { useDoctolibPendingChangesActions } from '../../../hooks/utils/doctolib'
import { TaskList } from '../../task/TaskList'
import { isDoctolibLoaded } from '../../../state/doctolib'
import styles from './PatientPageContent.module.scss'

const classNamesCx = classNames.bind(styles)

export const PatientPageContent: FunctionComponent<{}> = () => {
  const { currentPatient: patient } = useCurrentPatient()
  const isMobile = useWidthObserver(breakSmall)
  const [displayStatistic, setDisplayStatistic] = useState(false)
  const { handleClose, handleSubmit } = useDoctolibPendingChangesActions(patient)
  const isZipperLoaded = useAtomValue(isDoctolibLoaded)

  const {
    query: { data: pendingDoctolibPatientData = [] },
  } = useGetPendingFollowPatient({
    patientId: patient?.id!,
    enabled: !!patient?.isEditable && isZipperLoaded,
  })

  if (!isDefined(patient)) {
    return null
  }

  return (
    <>
      {!isMobile && (
        <div className={classNamesCx(styles.statisticAccessContainer, { displayStatistic })}>
          <div
            className={styles.statisticAccessWrapper}
            onClick={() => setDisplayStatistic(!displayStatistic)}
          >
            {displayStatistic && (
              <IconButton size="nano" rotate={-90} icon="arrow" theme="transparent-dark" />
            )}
            <span className={styles.statisticTitle}>
              {displayStatistic ? 'Retour à la timeline' : 'Statistiques'}
            </span>
            {!displayStatistic && (
              <IconButton size="nano" rotate={90} icon="arrow" theme="transparent-dark" />
            )}
          </div>
        </div>
      )}
      {displayStatistic ? (
        <PatientPageStatistics />
      ) : (
        <>
          <div className={styles.taskListContainer}>
            <TaskList
              patient={patient}
              contentEmptyState="Vous n’avez actuellement aucune tâche à réaliser pour ce patient."
            />
          </div>
          <PatientTimeline patient={patient} />
          <DoctolibPatientInfoUpdateModal
            display={!!pendingDoctolibPatientData.length}
            onSubmit={handleSubmit}
            onClose={handleClose}
            pendingPatientData={pendingDoctolibPatientData}
          />
        </>
      )}
    </>
  )
}
