import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteMail } from '../../../data/mail'
import { UserFriendlyError } from '../../../model/Error'
import { MailListPage } from '../../../model/Mail'
import { mailErrorsTitle, mailErrorsMessage } from './mail.errors'
import { mailKeys } from './mail.keys'

interface UseDeleteMailVariables {
  mailId: string
}

export const useDeleteMail = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation<void, Error, UseDeleteMailVariables>({
    mutationFn: async ({ mailId }) => {
      const { ok, status } = await deleteMail(mailId)

      if (status === 403) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorWhileDeleting,
          mailErrorsMessage.forbidden,
        )
      }

      if (!ok) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorWhileDeleting,
          "Une erreur s'est produite",
        )
      }
    },
    onSuccess: (_, { mailId: deletedMailId }) => {
      queryClient.invalidateQueries({ queryKey: mailKeys.lists })
      // On anticipe le retour du refecth de la liste
      // Permet notamment une redirection plus rapide vers le prochain mail non-supprimé
      queryClient.setQueriesData<InfiniteData<MailListPage>>(
        { queryKey: mailKeys.lists },
        (oldData) => {
          if (!oldData) return oldData

          const mutatedPages = oldData.pages.map((page) => ({
            ...page,
            items: page.items.filter((mail) => mail.id !== deletedMailId),
          }))

          return { ...oldData, pages: mutatedPages }
        },
      )
    },
  })

  return mutation
}
