import { useMutation, useQueryClient } from '@tanstack/react-query'
import { changePatientOwner } from '../../../data/patients'
import { UserFriendlyError } from '../../../model/Error'
import { resourceHistoryKeys } from '../resourceHistory'
import { patientsKeys } from './patients.keys'

interface PatientMutateVariable {
  patientId: number
  newOwnerId: string
  includeSubResources: boolean
}

export const useChangePatientOwner = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ patientId, newOwnerId, includeSubResources }: PatientMutateVariable) => {
      const { ok } = await changePatientOwner(patientId, newOwnerId, includeSubResources)
      if (!ok) {
        throw new UserFriendlyError('error', 'Le changement de référent du patient a échoué')
      }
      return null
    },

    onSuccess: (_data, { patientId }) => {
      queryClient.invalidateQueries({ queryKey: patientsKeys.lists })
      queryClient.invalidateQueries({ queryKey: patientsKeys.detail(patientId) })
      queryClient.invalidateQueries({
        queryKey: resourceHistoryKeys.list({ type: 'patient', id: patientId }),
        type: 'all',
      })
    },
  })
}
