import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { ContactCard, ContactSearchAutocomplete, ContactsProfessionSearch } from '../../../contact'
import { Contact, ContactConfiguration, RecipientContact } from '../../../../model/Contact'
import {
  CONTACT_VISIBILITY_OPTIONS,
  PatientContactsFormBlockProps,
} from './PatientContactsFormBlock.model'
import { useField } from 'formik'
import { Input, SelectInput, SliderInput, Switch } from '../../../shared'
import { departmentRegex } from '../../../../misc/regex'
import { isDefined } from '../../../../misc/functions.utilities'
import { mapContactToRecipientContact } from '../../../../misc/contact.utilities'
import { ContactConfigureBottomPanel } from '../../../shared/bottomPanel/ContactConfigureBottomPanel'
import { useGetProfessions } from '../../../../hooks/queries/professions'
import { useGetInfiniteCities } from '../../../../hooks/queries/cities'
import { ContactBottomPanel } from '../../../shared/bottomPanel/ContactBottomPanel'
import { BottomPanelContactsContext } from '../../../shared/bottomPanel/ContactBottomPanel/ContactBottomPanel.model'
import { useGetInfiniteContacts } from '../../../../hooks/queries/contact'
import { Filters } from '../../../../model/Filters'

interface DebouncedAddress {
  zipCode: string
  city: string
}

export const PatientContactsFormBlock: FunctionComponent<PatientContactsFormBlockProps> = ({
  patientAddress,
  patientId,
  colorPreset,
}) => {
  const { query: professionQuery } = useGetProfessions()

  const [searchValue, setSearchValue] = useState('')
  const [contactsField, , helpers] = useField<ReadonlyArray<RecipientContact>>({
    name: 'contacts',
  })
  const [professionSearch, setProfessionSearch] = useState('')
  const [department, setDepartement] = useState('')
  const [isGeolocationActive, setIsGeolocationActive] = useState(false)
  const [geolocationRange, setGeolocationRange] = useState(50)
  const [contactVisibility, setContactVisibility] = useState(CONTACT_VISIBILITY_OPTIONS[0])
  const [debouncedAddress, setDebouncedAdress] = useState<DebouncedAddress>()
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null)
  const [showContactBottomPanel, setShowContactBottomPanel] = useState(false)
  const [showGreetingsBottomPanel, setShowGreetingsBottomPanel] = useState(false)
  const [filters, setFilters] = useState<Filters>()

  const { cityList } = useGetInfiniteCities({
    enabled: isDefined(debouncedAddress?.zipCode) && isDefined(debouncedAddress?.city),
    filters: { search: debouncedAddress?.city, zipCode: debouncedAddress?.zipCode },
    limit: 1,
  })

  const {
    query: { isLoading },
    contactsList,
  } = useGetInfiniteContacts({ enabled: filters?.search?.length > 1, filters })

  const isGeolocatable = isDefined(cityList.at(0))

  useDebounce(
    () => {
      if (patientAddress && isDefined(patientAddress.zipCode) && isDefined(patientAddress.city)) {
        setDebouncedAdress({ zipCode: patientAddress.zipCode, city: patientAddress.city })
      }
    },
    500,
    [patientAddress?.city, patientAddress?.zipCode],
  )

  useEffect(() => {
    if (!isGeolocatable && isGeolocationActive) {
      setIsGeolocationActive(false)
    }
  }, [isGeolocatable, isGeolocationActive])

  useDebounce(
    () => {
      handleSearchContact()
    },
    400,
    [searchValue],
  )

  const handleSearchContact = () => {
    if (searchValue.length > 1) {
      const firstCityResult = cityList.at(0)
      const geolocationFields =
        isGeolocationActive && isDefined(firstCityResult)
          ? {
              latitude: firstCityResult.latitude ?? undefined,
              longitude: firstCityResult.longitude ?? undefined,
              radius: geolocationRange,
            }
          : undefined

      setFilters({
        search: searchValue,
        withOrganizationOnly: 'true',
        profession: professionSearch,
        department,
        ...geolocationFields,
        private: contactVisibility?.value ?? '',
      })
    }
  }

  const handleAddContact = useCallback(
    (contact: Contact) => {
      setSearchValue('')
      if (contactsField.value.every(({ id }) => id !== contact.id)) {
        const recipient: RecipientContact = {
          ...mapContactToRecipientContact(contact),
          // Le premier contact ajouté est le médecin traitant / destinataire principal
          ...(contactsField.value.length === 0 && {
            assignedDoctor: true,
            addressingDoctor: true,
            mainRecipient: true,
          }),
        }
        helpers.setValue([...contactsField.value, recipient])
      }
    },
    [contactsField.value, helpers],
  )

  const handleDuplicatedContact = useCallback(
    (updatedContact: Contact, publicContactId?: string) => {
      const isCloningPublicContact = !contactsField.value.some(({ id }) => updatedContact.id === id)
      const toBeReplacedId = isCloningPublicContact ? publicContactId : updatedContact.id

      const updated = contactsField.value.map((contact) => {
        if (contact.id === toBeReplacedId) {
          return {
            ...contact,
            ...updatedContact,
          }
        } else {
          return contact
        }
      })
      helpers.setValue(updated)
    },
    [contactsField.value, helpers],
  )

  function handleChangeAssignedDoctor(contactId: string, value: boolean) {
    const addressingDoctor = contactsField.value.find(({ addressingDoctor }) => addressingDoctor)
    helpers.setValue(
      contactsField.value.map((contact) => ({
        ...contact,
        assignedDoctor: contact.id === contactId ? value : false,
        // Met le médecin traitant en adressant si il n'y en a pas
        addressingDoctor:
          !addressingDoctor && contact.id === contactId ? true : contact.addressingDoctor,
      })),
    )
  }

  function handleChangeAdressingDoctor(contactId: string, value: boolean) {
    helpers.setValue(
      contactsField.value.map((contact) => ({
        ...contact,
        addressingDoctor: contact.id === contactId ? value : false,
      })),
    )
  }

  function handleChangeCopyRecipient(contactId: string, value: boolean) {
    helpers.setValue(
      contactsField.value.map((contact) => ({
        ...contact,
        copyRecipient: contact.id === contactId ? value : contact.copyRecipient,
        mainRecipient: contact.id === contactId ? false : contact.mainRecipient,
      })),
    )
  }

  function handleChangeMainRecipient(contactId: string, value: boolean) {
    helpers.setValue(
      contactsField.value.map((contact) => ({
        ...contact,
        copyRecipient: contact.id === contactId ? false : contact.copyRecipient,
        mainRecipient: contact.id === contactId ? value : contact.mainRecipient,
      })),
    )
  }

  function handleChangeOrganization(contactId: string, organizationId: string) {
    helpers.setValue(
      contactsField.value.map((contact) => {
        const organization =
          contact.id === contactId
            ? contact.organizations.find(({ id }) => id === organizationId) || contact.organization
            : contact.organization
        return {
          ...contact,
          organization,
        }
      }),
    )
  }

  function handleChangeMssEmail(contactId: string, mssEmailId: string) {
    helpers.setValue(
      contactsField.value.map((contact) => {
        const mssEmail =
          contact.id === contactId
            ? contact.mssEmails.find(({ id }) => id === mssEmailId) || contact.mssEmail
            : contact.mssEmail
        return {
          ...contact,
          mssEmail,
        }
      }),
    )
  }

  function handleRemoveContact(contactId: string) {
    helpers.setValue(contactsField.value.filter(({ id }) => contactId !== id))
  }

  const handleCreateContact = useCallback(() => {
    setShowContactBottomPanel(true)
  }, [])

  const handleCloseContactBottomPanel = useCallback(() => {
    setShowContactBottomPanel(false)
    setSelectedContactId(null)
  }, [])

  const handleEditContactGreetings = useCallback((id: string) => {
    setShowGreetingsBottomPanel(true)
    setSelectedContactId(id)
  }, [])

  const handleCloseGreetingsBottomPanel = useCallback(() => {
    setShowGreetingsBottomPanel(false)
    setSelectedContactId(null)
  }, [])

  const handleEditContact = useCallback((id: string) => {
    setShowContactBottomPanel(true)
    setSelectedContactId(id)
  }, [])

  const handleContactGreetingEdited = useCallback(
    (item: ContactConfiguration) => {
      setSelectedContactId(null)
      helpers.setValue(
        contactsField.value.map((contact) => ({
          ...contact,
          contactConfiguration:
            selectedContactId === contact.id ? { ...item } : contact.contactConfiguration,
        })),
      )
      handleCloseGreetingsBottomPanel()
    },
    [contactsField.value, handleCloseGreetingsBottomPanel, helpers, selectedContactId],
  )

  const showContacts = useMemo(
    () => searchValue.length > 1 && searchValue === filters?.search,
    [filters?.search, searchValue],
  )

  return (
    <>
      <div
        className="w-full px-2 text-shades-4 text-xs font-medium mb-4"
        title={
          isGeolocatable
            ? undefined
            : "L'adresse du patient ne permet pas de recherche par géolocalisation"
        }
      >
        <Switch
          name="Rechercher autour de l'adresse du patient"
          checked={isGeolocationActive}
          disabled={!isGeolocatable}
          onChange={(value) => setIsGeolocationActive(value)}
        />
        {isGeolocationActive && (
          <div className="mt-4 flex flex-col w-full">
            <span className="mb-2">Rayon de recherche ({geolocationRange} km)</span>
            <SliderInput
              appearance="compact"
              value={geolocationRange}
              min={10}
              max={200}
              step={10}
              onChange={setGeolocationRange}
            />
          </div>
        )}
      </div>
      <div className="flex flex-nowrap w-full">
        <div className="flex-grow">
          <ContactsProfessionSearch
            search={professionSearch}
            onSearch={setProfessionSearch}
            loading={false}
            listOfProfessions={professionQuery.data ?? []}
            disabled={!isDefined(professionQuery.data)}
            colorPreset="light"
          />
        </div>
        <div className="ml-4 w-32">
          <Input
            label="Département"
            value={department}
            onChange={(e) => setDepartement(e.currentTarget.value)}
            name="contactDepartment"
            colorPreset="light"
            placeholder="09"
            valid={department.length > 0 ? departmentRegex.test(department) : undefined}
          />
        </div>
      </div>
      <div className="flex flex-nowrap w-full">
        <SelectInput<boolean | null>
          title="Type de contacts"
          options={CONTACT_VISIBILITY_OPTIONS}
          value={contactVisibility}
          onSelect={setContactVisibility}
        />
      </div>
      <ContactSearchAutocomplete
        searchValue={searchValue}
        predictions={showContacts ? contactsList : []}
        loading={isLoading}
        onSearchValueChange={(event) => setSearchValue(event.target.value)}
        onSelectContact={handleAddContact}
        createContact={handleCreateContact}
        onFocus={handleSearchContact}
        colorPreset={colorPreset}
      />
      {contactsField.value.map((contact) => (
        <div key={contact.id}>
          <ContactCard
            showAddresses={true}
            contact={contact}
            loading={false}
            onClickDisplayDetails={handleEditContact}
            onClickDisplayConfiguration={handleEditContactGreetings}
            onClose={() => handleRemoveContact(contact.id)}
            onChangeAssignedDoctor={(event) =>
              handleChangeAssignedDoctor(contact.id, event.target.checked)
            }
            onChangeAddressingDoctor={(event) =>
              handleChangeAdressingDoctor(contact.id, event.target.checked)
            }
            onChangeCopyRecipient={(event) =>
              handleChangeCopyRecipient(contact.id, event.target.checked)
            }
            onChangeMainRecipient={(event) =>
              handleChangeMainRecipient(contact.id, event.target.checked)
            }
            onChangeOrganization={(organizationId) =>
              handleChangeOrganization(contact.id, organizationId)
            }
            onChangeMssEmail={(mssEmailId) => handleChangeMssEmail(contact.id, mssEmailId)}
          />
        </div>
      ))}
      <ContactBottomPanel
        patientId={patientId}
        contactId={selectedContactId}
        display={showContactBottomPanel}
        onRequestClose={handleCloseContactBottomPanel}
        context={BottomPanelContactsContext.PATIENT_CONTACT}
        onContactCreated={handleAddContact}
        onContactDuplicated={handleDuplicatedContact}
      />
      <ContactConfigureBottomPanel
        itemId={selectedContactId}
        patientId={patientId}
        onItemEdited={handleContactGreetingEdited}
        onRequestClose={handleCloseGreetingsBottomPanel}
        display={showGreetingsBottomPanel}
      />
    </>
  )
}
