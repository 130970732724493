import React, { FunctionComponent } from 'react'
import { ManageMessageTemplateModalProps } from './ManageMessageTemplateModal.model'
import styles from './ManageMessageTemplateModal.module.scss'
import { MessageTemplateList } from './MessageTemplateList'
import { ActionModalLayout, Modal, RoundedButton } from '../../../shared'

export const ManageMessageTemplateModal: FunctionComponent<ManageMessageTemplateModalProps> = ({
  isUnmounting,
  onClose,
  onAdd,
  onRename,
  onReplace,
  onDelete,
  onUpdateDefault,
  onApply,
}) => {
  return (
    <Modal isUnmounting={isUnmounting}>
      <ActionModalLayout
        title="Modèles de message"
        onClose={onClose}
        actions={
          <div className={styles.actions}>
            <RoundedButton label="Retour" size="micro" theme="transparent-dark" onClick={onClose} />
          </div>
        }
      >
        <div className={styles.content}>
          <MessageTemplateList
            onAdd={onAdd}
            onRename={onRename}
            onReplace={onReplace}
            onDelete={onDelete}
            onUpdateDefault={onUpdateDefault}
            onApply={onApply}
          />
        </div>
      </ActionModalLayout>
    </Modal>
  )
}
