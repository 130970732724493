import { MailWebServiceLoginFormFields, MailWebServiceLoginResponse } from '../../../model/Mail'
import getApi from '../../../api'
import { ApiErrorContent } from '../../../model/Error'

export const initiateAuthenticationMail = (values: MailWebServiceLoginFormFields) =>
  getApi().post<MailWebServiceLoginResponse, ApiErrorContent>(`/mss/auth/initiate`, {
    rpps: values.rpps,
    channel: values.otpMethod,
    password: values.password,
  })
