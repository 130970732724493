import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { deleteDocumentLayout } from '../../../data/documentLayouts'
import { documentLayoutsKeys } from './documentLayouts.keys'

interface DocumentLayoutMutateVariable {
  documentLayoutId: number
}

export const useDeleteDocumentLayout = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ documentLayoutId }: DocumentLayoutMutateVariable) => {
      const { ok } = await deleteDocumentLayout(documentLayoutId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression de la mise en page a échoué')
      }
      return null
    },

    onSuccess: (_, { documentLayoutId }) => {
      queryClient.invalidateQueries({ queryKey: documentLayoutsKeys.lists })
      queryClient.removeQueries({ queryKey: documentLayoutsKeys.detail(documentLayoutId) })
    },
  })
}
