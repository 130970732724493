import { ResourceHistoryEntity } from '../../../model/ResourceHistory'

const all = ['resourceHistory']
const lists = [...all, 'list']
const list = ({ id, type }: ResourceHistoryEntity) => [...lists, type, id]

export const resourceHistoryKeys = {
  all,
  lists,
  list,
}
