import { ApiResponse } from 'apisauce'

export const deserializeQuestionnaireInstanceScoreResponse = (
  response: ApiResponse<{ value: number }>,
): ApiResponse<number> => {
  const { data, ...responseRest } = response
  if (!response.ok || !data) {
    return { ...responseRest }
  }

  return {
    ...responseRest,
    data: data.value,
  }
}
