import { ApiResponse } from 'apisauce'
import { ApiErrorContent, UserFriendlyError } from '../model/Error'

export const handleApiError = <T>(apiResponse: ApiResponse<T, ApiErrorContent>): void => {
  const { data, ok } = apiResponse

  if (!ok && data?.violations && data.violations.length > 0) {
    throw new UserFriendlyError(
      'error',
      data.violations.map((violation) => violation.message).join('\n'),
    )
  }
}
