import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { deleteAllDoneTasks } from '../../../data/task/deleteAllDoneTasks'
import { taskKeys } from './tasks.keys'

export const useDeleteAllDoneTasks = () => {
  const queryClient = useQueryClient()

  return useMutation<null, null, boolean>({
    mutationFn: async (hasDocument) => {
      const { ok } = await deleteAllDoneTasks(hasDocument)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression des tâches a échoué')
      }
      return null
    },

    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: taskKeys.all,
      })
    },
  })
}
