import getApi from '../../api/apisauceConfig'
import { singleItemPageLimit } from '../../constants'
import { DocumentCategory } from '../../model/DocumentCategory'
import { PaginatedList } from '../../model/Pagination'
import { FwResourceType } from '../../model/Resource'

export const getUsedDocumentCategories = (
  usedInResource: FwResourceType,
  page: number,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<DocumentCategory>>('/document_categories', {
    currentPage: page,
    pageSize: limit,
    usedInResource,
  })
