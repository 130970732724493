import { documentInstancesSagas } from './documentInstances'
import { documentTemplatesSagas } from './documentTemplates'
import { filesSagas } from './files'
import { userMeTeamsSagas } from './users'
import { questionnairesSagas } from './questionnaires'
import { questionInstancesSagas } from './questionInstances'
import { medicalEventsSagas } from './medicalEvents'
import { resourceHistorySagas } from './resourceHistory'
import { questionTemplatesSagas } from './questionTemplates'

export const cacheSagas = [
  ...Object.values(documentInstancesSagas),
  ...Object.values(documentTemplatesSagas),
  ...Object.values(filesSagas),
  ...Object.values(medicalEventsSagas),
  ...Object.values(questionnairesSagas),
  ...Object.values(questionInstancesSagas),
  ...Object.values(questionTemplatesSagas),
  ...Object.values(resourceHistorySagas),
  ...Object.values(userMeTeamsSagas),
]
