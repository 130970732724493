import { RenderedPdf, useRenderPdfFromDocuments } from './useRenderPdfFromDocuments.query'
import { useCallback } from 'react'
import { isPrescription } from '../../../misc/documents.utilities'
import { DocumentInstance } from '../../../model/DocumentInstance'
import { DocumentCategoryKeys } from '@follow/cdk'
import { requestPrintByBlobUrl } from '../../../misc/print.utilities'
import { CustomFile, RenderableFileExtension } from '../../../model/File'
import { isRenderableFileExtension } from '../../../misc/files.utilities'
import { useDirectPrintFile } from '../files'

type PrintableDocument = Pick<DocumentInstance, 'id' | 'type'> & { category: DocumentCategoryKeys }
type PrintableFile = Pick<CustomFile, 'id' | 'extension'>

export type PrintableDocumentsVariable = {
  documents?: PrintableDocument[]
  files?: PrintableFile[]
}

export const usePrintDocuments = () => {
  const print = useCallback(
    (renderedPdf: RenderedPdf) => requestPrintByBlobUrl(URL.createObjectURL(renderedPdf.blob)),
    [],
  )

  const { render } = useRenderPdfFromDocuments()
  const directPrint = useDirectPrintFile()

  return ({ documents = [], files = [] }: PrintableDocumentsVariable) => {
    if (documents.length === 0 && files.length === 0) {
      return
    }

    // Cas spécial de l'impression d'un seul fichier de type PDF -> pas d'appel au Renderer
    if (documents.length === 0 && files.length === 1 && files[0].extension === 'pdf') {
      directPrint({ fileId: files[0].id })
      return
    }

    const documentsToLock = documents
      .filter(({ category, type }) => type === 'farte' && category && isPrescription(category))
      .map(({ id }) => id)

    render(
      {
        documents: documents.map((document) => ({
          documentId: document.id,
          type: document.type,
          documentCategoryId: document.category,
        })),
        files: files
          .filter((file) => isRenderableFileExtension(file.extension))
          .map((file) => ({
            fileId: file.id,
            extension: file.extension as RenderableFileExtension,
          })),
        documentsToLock,
      },
      {
        onSuccess: print,
      },
    )
  }
}
