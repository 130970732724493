import React, { FunctionComponent, useState, useEffect } from 'react'
import { GenericContact } from '../../../model/Contact'
import styles from './ContactSearchAutocomplete.module.scss'
import { Icon, Input, AutocompleteInput, SuggestionItem } from '../../shared'
import { ColorsV2 } from '../../../design-system/colors_v2'
import { ContactSuggestion } from '../ContactSuggestion'
import { ContactSearchAutocompleteProps } from './ContactSearchAutocomplete.model'

export const ContactSearchAutocomplete: FunctionComponent<ContactSearchAutocompleteProps> = ({
  searchValue,
  onSearchValueChange,
  predictions,
  loading,
  onSelectContact,
  createContact,
  onHideSuggestion,
  onFocus,
  colorPreset,
  disabled = false,
  label,
}) => {
  const [isPredictionsShown, setIsPredictionsShown] = useState(false)
  const [focused, setFocused] = useState(false)

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (loading || predictions.length > 0) {
      setIsPredictionsShown(true)
    }
  }, [predictions, loading, searchValue])

  useEffect(() => {
    if (!isPredictionsShown && !!onHideSuggestion) {
      onHideSuggestion()
    }
  }, [isPredictionsShown, onHideSuggestion])

  const handleSelectContact = (contact: GenericContact) => {
    onSelectContact(contact)
    setIsPredictionsShown(false)
  }

  const handleSelectWithEnter = () => {
    const selectedPrediction = predictions.find((_, index) => index === selectedIndex)
    if (selectedPrediction) {
      handleSelectContact(selectedPrediction)
    }
  }

  const handleCreateContact = (event: React.MouseEvent) => {
    setIsPredictionsShown(false)
    createContact && createContact()
  }
  return (
    <AutocompleteInput
      renderInput={(SuggestionContainer) => (
        <>
          <Input
            type="search"
            name="searchContact"
            label={label ?? "Rechercher un correspondant dans l'Annuaire Santé"}
            placeholder="2 caractères minimum"
            icon="search"
            value={searchValue}
            onChange={onSearchValueChange}
            onFocus={() => {
              if (!!onFocus) {
                onFocus()
                setFocused(true)
              }
              setIsPredictionsShown(true)
            }}
            onBlur={() => setFocused(false)}
            autocomplete="off"
            colorPreset={colorPreset}
            disabled={disabled}
          />
          {focused && (
            <SuggestionContainer>
              {loading && (
                <SuggestionItem>
                  <div className={styles.suggestionItem}>Chargement ...</div>
                </SuggestionItem>
              )}
              {!loading &&
                isPredictionsShown &&
                predictions.map((contact, index) => (
                  <ContactSuggestion
                    key={index}
                    contact={contact}
                    hovered={index === selectedIndex}
                    onClick={() => handleSelectContact(contact)}
                  />
                ))}
              {!loading && createContact && (
                <SuggestionItem onClick={handleCreateContact}>
                  <div className={`${styles.createContact} ${styles.suggestionItem}`}>
                    <Icon icon="add" color={ColorsV2.dsDark3} size="nano" />
                    <span className={styles.label}>Créer un correspondant</span>
                  </div>
                </SuggestionItem>
              )}
            </SuggestionContainer>
          )}
        </>
      )}
      selectedIndex={selectedIndex}
      predictionsLength={predictions.length}
      setSelectedIndex={setSelectedIndex}
      suggestionsShown={isPredictionsShown}
      handleClickAway={() => setIsPredictionsShown(false)}
      handlePressEnter={handleSelectWithEnter}
    />
  )
}
