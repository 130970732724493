import { FunctionComponent } from 'react'
import { ColorsV2TagBackground } from '../../../design-system/colors_v2'
import { useReadVitalCard } from '../../../hooks/queries/vitalCard'
import { getAge } from '../../../misc/date.utilities'
import { MIN_MAJOR_AGE } from '../../../model/Patient'
import { IconButton, Tag } from '../../shared'
import { PatientDetailsPageHeaderProps } from './PatientDetailsPageHeader.model'
import styles from './PatientDetailsPageHeader.module.scss'

export const PatientDetailsPageHeader: FunctionComponent<PatientDetailsPageHeaderProps> = ({
  patient,
  isLoading,
  openPatientInformationsHistory,
}) => {
  const { mutate: readVitalCard } = useReadVitalCard(true)

  const label =
    patient && !!patient.id
      ? `${patient.usedFirstName ?? patient.birthFirstName} ${
          patient.usedLastName ?? patient.birthLastName
        }`
      : 'Nouveau patient'

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-wrap">
        {!isLoading ? (
          <>
            {label}
            <div className={styles.deceasedAndMinorLabelContainer}>
              {patient?.birthDate && getAge(patient.birthDate) < MIN_MAJOR_AGE && (
                <Tag color={ColorsV2TagBackground.dsTag03}>Mineur·e</Tag>
              )}
              {patient && patient.isDeceased && (
                <Tag color={ColorsV2TagBackground.dsTag09}>Décédé·e</Tag>
              )}
            </div>
          </>
        ) : (
          'Chargement...'
        )}
      </div>
      <div className="flex space-x-4">
        {patient && (
          <IconButton
            icon="history"
            size="normal"
            theme="transparent"
            title="Historique informations patient"
            onClick={openPatientInformationsHistory}
          />
        )}
        <IconButton
          onClick={() => readVitalCard()}
          icon="vitalCardReader"
          size="normal"
          theme="primary"
        />
      </div>
    </div>
  )
}
