import React, { StrictMode, Suspense, useEffect } from 'react'
import { Provider } from 'react-redux'
import { Route, Routes } from 'react-router'
import { UpdateBanner, GlobalStyles, AnalyticsHeartbeatService, Loader } from './components/shared'
import ConnectedViewsContainer from './ConnectedViewsContainer'
import SnackbarWrapper from './containers/SnackbarWrapper'
import { GlobalErrorBoundary } from './errors/GlobalErrorBoundary'
import { developmentMode } from './misc/environment.utilities'
import appStore from './store'
import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
// Styles
import './styles/main.scss'
import '@follow/elfe/dist/esm/index.css'
import { theme } from './styles/legacy/styled/styled'
import { LogoutPage } from './pages/authent'
import { BrowserRouter } from 'react-router-dom'
import { NavigationUtils } from './components/navigation'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { UserFriendlyError } from './model/Error'
import { addMessage } from './store/message'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from '@emotion/react'

// Cool feature, webpack output if(true) or if(false)
if (developmentMode) {
  console.warn('Dev mode enabled')
  // Redirection vers le basename en dev mode
  if (window.location.pathname === '/') {
    window.location.assign(process.env.PUBLIC_URL)
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * (60 * 1000), // 2 mins
      gcTime: 10 * (60 * 1000), // 10 mins
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof UserFriendlyError) {
        appStore.dispatch(addMessage(error.getMessage()))
      } else {
        console.error(error)
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error instanceof UserFriendlyError) {
        appStore.dispatch(addMessage(error.getMessage()))
      } else {
        console.error(error)
      }
    },
  }),
})

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
)

const App = () => {
  const [showDevtools, setShowDevtools] = React.useState(false)

  useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    <StrictMode>
      <GlobalStyles />
      <GlobalErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools buttonPosition="top-left" />
          {showDevtools && (
            <Suspense fallback={<Loader />}>
              <ReactQueryDevtoolsProduction />
            </Suspense>
          )}
          <UpdateBanner />
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <NavigationUtils />
            <Provider store={appStore}>
              <ThemeProvider theme={theme}>
                <DndProvider options={HTML5toTouch}>
                  <Routes>
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="*" element={<ConnectedViewsContainer />} />
                  </Routes>
                  <SnackbarWrapper />
                  {!developmentMode && <AnalyticsHeartbeatService />}
                  <div id="bottom-panel-root" />
                  <div id="modal-root" />
                </DndProvider>
              </ThemeProvider>
            </Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </GlobalErrorBoundary>
    </StrictMode>
  )
}
export default App
