import { useMemo } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'
import { startingPage } from '../../../constants'
import { getPatientTreatments } from '../../../data/patientTreatments'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { patientTreatmentsKeys } from './patientTreatments.keys'

export type GetPatientTreatmentsParams = {
  patientId: number
  page?: number
  filters?: Filters
  limit?: number
  enabled?: boolean
}

export const useGetInfinitePatientTreatments = ({
  patientId,
  filters,
  limit,
  enabled = true,
}: GetPatientTreatmentsParams) => {
  const queryKey = useMemo(
    () => patientTreatmentsKeys.list(patientId, filters),
    [patientId, filters],
  )

  const queryResult = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getPatientTreatments(
        patientId.toString(),
        pageParam,
        filters,
        signal,
        limit,
      )

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer l'historique des traitements du patient`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    enabled,
  })
  const treatmentsList = useFlatPaginatedData(queryResult.data)
  const paginationState = usePaginationState(queryResult.data)

  return { query: queryResult, treatmentsList, paginationState }
}
