import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import {
  addImportantInformation,
  removeImportantInformation,
} from '../../../data/importantInformations'
import { questionnaireTemplatesKeys } from '../questionnaireTemplates/questionnaireTemplates.keys'
import { questionTemplatesKeys } from '../questionTemplates/questionTemplate.keys'
import { patientImportantInformationsKeys } from '../patientImportantInformations/patientImportantInformations.keys'

interface ToggleImportantInformationMutateVariable {
  toggle: boolean
  questionId: number
  questionnaireId?: number
  onSuccessCallback?: () => void
}

export const useToggleImportantInformation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ toggle, questionId }: ToggleImportantInformationMutateVariable) => {
      const { data, ok } = toggle
        ? await addImportantInformation(questionId)
        : await removeImportantInformation(questionId)

      if (!ok) {
        throw new UserFriendlyError('error', `Le mise à jour de l'information importante a échoué`)
      }

      return data
    },

    onSuccess: (_, { questionId, questionnaireId }) => {
      queryClient.invalidateQueries({ queryKey: questionTemplatesKeys.detail(questionId) })
      queryClient.invalidateQueries({ queryKey: patientImportantInformationsKeys.lists })

      if (questionnaireId) {
        queryClient.invalidateQueries({
          queryKey: questionnaireTemplatesKeys.detail(questionnaireId),
        })
      } else {
        queryClient.invalidateQueries({ queryKey: questionnaireTemplatesKeys.all })
      }
    },
  })
}
