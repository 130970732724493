export enum DoctolibZipperStatus {
  LOADED = 'LOADED',
  NOT_LOADED = 'NOT_LOADED',
}

export enum PendingFollowPatientAPIField {
  Email = 'email',
  Telephone1 = 'telephone1',
  Telephone2 = 'telephone2',
  Street = 'address.street',
  Zipcode = 'address.zipCode',
  City = 'address.city',
}

interface PendingFollowPatientFieldValue {
  field: PendingFollowPatientAPIField
  value: string | null
}

export type PendingFollowPatientPayload = PendingFollowPatientFieldValue[]

export interface PendingDoctolibPatientData {
  id: string
  pendingField: PendingFollowPatientAPIField
  pendingValue: string | null
}
