import { FC, useCallback, useEffect, useState } from 'react'
import { DosageQuantityInputProps } from './DosageQuantityInput.model'
import { Icon, Input, TooltipWrapper } from '../../../../../shared'
import { getV3ColorCssVariable } from '../../../../../../design-system/colors_v3'
import { checkDrugDivisibility } from '../../../../../../misc/drug.utilities'
import { floatRegex } from '../../../../../../misc/regex'
import { isDefined } from '../../../../../../misc/functions.utilities'
import { convertFloatToDisplayFormat } from '../../../../../../misc/healthData.utilities'

const quantityRegex = /^[^0-9]*(\d+[,.]?(?:\d+)?).*$/

export const DosageQuantityInput: FC<DosageQuantityInputProps> = ({
  quantityValue,
  divisibility,
  disabled,
  label = 'Quantité',
  onChange,
}) => {
  const isDivisibilityRespected = (value: number) => checkDrugDivisibility(divisibility, value)
  const [quantityInput, setQuantityInput] = useState('0')

  useEffect(() => {
    // On ne met pas à jour le state si une valeur décimale est en cours de saisie, exemple : 12.
    const incompleteInputRegex = /^\d+[,.]$/

    const newValue = quantityValue.toString()
    setQuantityInput((current) => {
      if (current === newValue || incompleteInputRegex.test(current)) {
        return current
      } else {
        return newValue.replaceAll('.', ',')
      }
    })
  }, [quantityValue])

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (value === '') {
        setQuantityInput('')
      } else {
        const matches = quantityRegex.exec(value)
        const match = matches && matches[1] ? matches[1] : null

        if (isDefined(match)) {
          // Pour l'UI, le séparateur décimal doit être virgule
          const comaSeparated = convertFloatToDisplayFormat(match)
          setQuantityInput(comaSeparated)

          // Pour la méthode de parse, le séparateur doit être un point
          const parsed = parseFloat(match.replaceAll(',', '.'))

          if (!isNaN(parsed) && parsed !== quantityValue) {
            onChange(parsed)
          }
        }
      }
    },
    [onChange, quantityValue],
  )

  return (
    <div className="flex items-end">
      <Input
        type="text"
        name="quantity"
        label={label}
        value={quantityInput}
        testId={`posology-dosage-quantity-input`}
        colorPreset="light"
        onChange={handleInputChange}
        valid={floatRegex.test(quantityValue.toString()) && isDivisibilityRespected(quantityValue)}
        onWheel={(event) => {
          event.currentTarget.blur()
        }}
        disabled={disabled}
        error={
          isDivisibilityRespected(quantityValue)
            ? undefined
            : `Ce médicament n'est divisible que par ${divisibility}`
        }
      />
      <div className="p-2">
        <TooltipWrapper content="Seuls les chiffres et une virgule sont autorisés">
          <Icon
            icon="infoCircle"
            size="micro"
            color={getV3ColorCssVariable('primary', 'default')}
          />
        </TooltipWrapper>
      </div>
    </div>
  )
}
