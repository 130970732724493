import { DomainMedicalEventsActionTypes } from './medicalEvents.model'
import { Location } from 'react-router-dom'
import { MedicalEvent, MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { DocumentTemplateListItem } from '../../../model/DocumentTemplate'
import { SetAnswerValue } from '../../../model/AnswerValue'
import { DocumentRenderHistory } from '../../../model/DocumentHistory'
import { DocumentInstance } from '../../../model/DocumentInstance'
import { Prescription } from '../../../model/Prescription'
import { FwDocumentType } from '../../../model/Document'
import { RestuxCacheRefetchableActionParams } from '../../restux/cache/restuxCache.model'
import { CustomFile } from '../../../model/File'

export const medicalEventDomainActions = {
  initializeFromUrl: (location: Location, medicalEvent: MedicalEvent) => ({
    type: DomainMedicalEventsActionTypes.INITIALIZE_FROM_URL,
    location,
    medicalEvent,
  }),

  initializeContentFilterFromUrl: () => ({
    type: DomainMedicalEventsActionTypes.INITIALIZE_FILTER_FROM_URL,
  }),

  addDocumentInstances: (
    documentTemplates: DocumentTemplateListItem[],
    context: {
      origin: string
    },
  ) => ({
    type: DomainMedicalEventsActionTypes.ADD_DOCUMENT_INSTANCES,
    documentTemplates,
    context,
  }),

  downloadDocumentRenderHistory: (renderHistory: DocumentRenderHistory) => ({
    type: DomainMedicalEventsActionTypes.DOWNLOAD_DOCUMENT_RENDER_HISTORY,
    renderHistory,
  }),

  updateDocumentTitle: (
    id: number,
    title: string,
    eventDocumentType: MedicalEventDocumentType,
    documentType?: FwDocumentType,
  ) => ({
    type: DomainMedicalEventsActionTypes.UPDATE_DOCUMENT_TITLE,
    id,
    title,
    eventDocumentType,
    documentType,
  }),

  updateDocumentThenFetchEvent: (id: number, document: Partial<DocumentInstance>) => ({
    type: DomainMedicalEventsActionTypes.UPDATE_DOCUMENT_THEN_FETCH_EVENT,
    id,
    document,
  }),

  updateFileThenFetchEvent: (id: number, file: Partial<CustomFile>) => ({
    type: DomainMedicalEventsActionTypes.UPDATE_FILE_THEN_FETCH_EVENT,
    id,
    file,
  }),
  deleteDocument: (id: number, documentType: MedicalEventDocumentType) => ({
    type: DomainMedicalEventsActionTypes.DELETE_DOCUMENT,
    id,
    documentType,
  }),

  deleteQuestionnaireFromSelectedDocument: (questionnaireId: number) => ({
    type: DomainMedicalEventsActionTypes.DELETE_QUESTIONNAIRE_FROM_SELECTED_DOCUMENT,
    questionnaireId,
  }),

  deleteDrugFromSelectedDocument: (prescription: Prescription) => ({
    type: DomainMedicalEventsActionTypes.DELETE_DRUG_FROM_SELECTED_DOCUMENT,
    prescription,
  }),

  deleteQuoteLineFromSelectedDocument: (quoteLineUuid: string) => ({
    type: DomainMedicalEventsActionTypes.DELETE_QUOTELINE_FROM_SELECTED_DOCUMENT,
    quoteLineUuid,
  }),

  addFilesToMedicalEvent: (files: File[]) => ({
    type: DomainMedicalEventsActionTypes.ADD_FILES_TO_MEDICAL_EVENT,
    files,
  }),

  setAnswerValue: (questionId: number, setAnswerValue: SetAnswerValue) => ({
    type: DomainMedicalEventsActionTypes.SET_ANSWER_VALUE,
    questionId,
    setAnswerValue,
  }),

  clearMedicalEventContext: () => ({
    type: DomainMedicalEventsActionTypes.CLEAR_CONTEXT,
  }),

  duplicateMedicalEventDocuments: (medicalEventId: number, documentsIds: number[]) => ({
    type: DomainMedicalEventsActionTypes.DUPLICATE_MEDICAL_EVENT_DOCUMENTS,
    medicalEventId,
    documentsIds,
  }),
  lockMedicalEvent: (medicalEvent: MedicalEvent) => ({
    type: DomainMedicalEventsActionTypes.LOCK_MEDICAL_EVENT_DOCUMENTS,
    medicalEvent,
  }),

  pinMedicalEvent: (medicalEvent: MedicalEvent) => ({
    type: DomainMedicalEventsActionTypes.PIN_MEDICAL_EVENT_DOCUMENTS,
    medicalEvent,
  }),
  changeOwner: (medicalEvent: MedicalEvent, newOwnerId: string) => ({
    type: DomainMedicalEventsActionTypes.CHANGE_OWNER,
    medicalEvent,
    newOwnerId,
  }),
  updateMedicalEvent: (
    id: number,
    payload: Partial<MedicalEvent>,
    options?: RestuxCacheRefetchableActionParams,
  ) => ({
    type: DomainMedicalEventsActionTypes.UPDATE_MEDICAL_EVENT,
    id,
    payload,
    options,
  }),
}
