import { MailWebServiceLoginOtpFormFields, MailWebServiceLoginResponse } from '../../../model/Mail'
import getApi from '../../../api'
import { ApiErrorContent } from '../../../model/Error'

export const finalizeAuthenticationMail = (
  values: MailWebServiceLoginOtpFormFields,
  tmpAuthenticationId: string,
) =>
  getApi().post<MailWebServiceLoginResponse, ApiErrorContent>(
    `/mss/auth/finalize`,
    { otpPassword: values.otpPassword },
    {
      headers: {
        'X-MSS-TOKEN': tmpAuthenticationId,
      },
    },
  )
