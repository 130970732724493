import React, { FC, useContext } from 'react'
import { ToolbarElementProps } from '@/model/Toolbar'
import { FontFamilySelect } from './FontFamilySelect'
import styles from './FontGroup.module.scss'
import { FontSizeSelect } from './FontSizeSelect'
import { HeadingSelect } from './HeadingSelect'
import { EditorConfigContext } from '@/context'
import { ToolbarSeparator } from '../../atoms'

export const FontGroup: FC<ToolbarElementProps> = ({ editor }) => {
  const { tools } = useContext(EditorConfigContext)

  const displayHeading = tools.includes('heading')
  const displayFontFamily = tools.includes('fontFamily')
  const displayFontSize = tools.includes('fontSize')

  return (
    <>
      {displayHeading && (
        <div className={styles['headingSelect']}>
          <HeadingSelect editor={editor} />
        </div>
      )}
      <ToolbarSeparator />
      {displayFontFamily && (
        <div className={styles['fontFamilySelect']}>
          <FontFamilySelect editor={editor} />
        </div>
      )}
      <ToolbarSeparator />
      {displayFontSize && (
        <div className={styles['fontSizeSelect']}>
          <FontSizeSelect editor={editor} />
        </div>
      )}
      <ToolbarSeparator />
    </>
  )
}
