import { FeaturesType } from '@follow/cdk'
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react'
import { useUserEnabledFeature } from '../../../hooks/utils/user'
import {
  getFileSizeInMegaBytes,
  WEB_SERVICES_ACCEPTED_EXTENSIONS,
} from '../../../misc/files.utilities'
import { isDefined } from '../../../misc/functions.utilities'
import { IconButton, RoundedButton, TooltipWrapper } from '../../shared'
import { FileAddButtonProps } from './FileAddButton.model'
import styles from './fileAddButton.module.scss'

export const FileAddButton: FunctionComponent<FileAddButtonProps> = ({
  multiple = true,
  buttonLabel = 'Ajouter un fichier',
  maxSizeInBytes,
  onFollowDocsSelectionClick,
  onAdd,
  onError,
  disabled = false,
}) => {
  const mssSmtpEnabled = useUserEnabledFeature(FeaturesType.mssSmtp)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const files = Array.from(event.target.files) as File[]
      const filteredFiles = files.filter(
        ({ size }) => !isDefined(maxSizeInBytes) || size <= maxSizeInBytes,
      )

      if (onError) {
        if (files.some(({ size }) => isDefined(maxSizeInBytes) && size > maxSizeInBytes)) {
          onError('OVERSIZED_FILE')
        }
      }

      onAdd(filteredFiles)
    }
  }

  const handleComputerFilesExplorer = useCallback(
    () => fileInputRef.current && fileInputRef.current.click(),
    [],
  )

  const attachmentOptions = useMemo(
    () => [
      {
        label: 'Depuis mon poste',
        onClick: handleComputerFilesExplorer,
      },
      {
        label: 'Depuis le dossier Follow',
        onClick: onFollowDocsSelectionClick,
      },
    ],
    [handleComputerFilesExplorer, onFollowDocsSelectionClick],
  )

  return (
    <>
      <TooltipWrapper
        content={`La taille des fichiers est limitée à ${getFileSizeInMegaBytes(
          maxSizeInBytes ?? 0,
        )} Mo.`}
        display={isDefined(maxSizeInBytes)}
      >
        <div className={styles.buttonsContainer}>
          <RoundedButton
            label={buttonLabel}
            onClick={handleComputerFilesExplorer}
            disabled={disabled}
            appearance="left"
          />
          <IconButton
            disabled={disabled}
            icon="chevron"
            appearance="right"
            theme="primary"
            options={attachmentOptions}
          />
        </div>
      </TooltipWrapper>
      <input
        ref={fileInputRef}
        autoComplete="off"
        className="hidden"
        disabled={disabled}
        onClick={onInputClick}
        onChange={onInputChange}
        multiple={multiple}
        tabIndex={-1}
        type="file"
        accept={mssSmtpEnabled ? '*' : WEB_SERVICES_ACCEPTED_EXTENSIONS}
      />
    </>
  )
}
