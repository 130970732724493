import { FunctionComponent, useState, useCallback } from 'react'
import { MessageTemplateMenuActionsProps } from './MessageTemplateMenuActions.model'
import { DropdownContainer, DropdownItem, IconButton, Icon } from '../../../../../shared'
import { getV3ColorCssVariable } from '../../../../../../design-system/colors_v3'
import styles from './MessageTemplateMenuActions.module.scss'

export const MessageTemplateMenuActions: FunctionComponent<MessageTemplateMenuActionsProps> = ({
  messageTemplate,
  onRename,
  onReplace,
  onDelete,
  onUpdateDefault,
  onApply,
}) => {
  const [opened, setOpened] = useState(false)

  const handleApply = useCallback(() => {
    setOpened(false)
    onApply(messageTemplate)
  }, [messageTemplate, onApply])

  const handleRename = useCallback(() => {
    setOpened(false)
    onRename(messageTemplate)
  }, [messageTemplate, onRename])

  const handleReplace = useCallback(() => {
    setOpened(false)
    onReplace(messageTemplate)
  }, [messageTemplate, onReplace])

  const handleDelete = useCallback(() => {
    setOpened(false)
    onDelete(messageTemplate)
  }, [messageTemplate, onDelete])

  const handleUpdateDefault = useCallback(() => {
    setOpened(false)
    onUpdateDefault(messageTemplate)
  }, [messageTemplate, onUpdateDefault])

  return (
    <div className={styles.container}>
      <IconButton
        size="micro"
        theme="light"
        icon="more"
        onClick={() => setOpened(!opened)}
        testId="button-message-template-actions"
      />
      <div className={styles.dropdownWrapper}>
        <DropdownContainer
          direction="right"
          level="top"
          opened={opened}
          closeDropdown={() => setOpened(false)}
        >
          <DropdownItem onClick={handleApply} testId="button-apply-message-template">
            <Icon icon="done" size="nano" color={getV3ColorCssVariable('shades', 'shade2')} />
            <span className={styles.dropdownItemLabel}>Utiliser</span>
          </DropdownItem>
          <DropdownItem onClick={handleReplace} testId="button-replace-message-template">
            <Icon icon="reload" size="nano" color={getV3ColorCssVariable('shades', 'shade2')} />
            <span className={styles.dropdownItemLabel}>Remplacer</span>
          </DropdownItem>
          <DropdownItem onClick={handleRename} testId="button-rename-message-template">
            <Icon icon="pencil" size="nano" color={getV3ColorCssVariable('shades', 'shade2')} />
            <span className={styles.dropdownItemLabel}>Renommer</span>
          </DropdownItem>
          <DropdownItem onClick={handleDelete} testId="button-delete-message-template">
            <Icon icon="trash" size="nano" color={getV3ColorCssVariable('shades', 'shade2')} />
            <span className={styles.dropdownItemLabel}>Supprimer</span>
          </DropdownItem>
          <DropdownItem onClick={handleUpdateDefault} testId="button-set-default-message-template">
            <Icon
              icon={messageTemplate.isDefault ? `star` : `emptyStar`}
              size="nano"
              color={getV3ColorCssVariable('shades', 'shade2')}
            />
            <span className={styles.dropdownItemLabel}>
              {messageTemplate.isDefault
                ? `Retirer comme modèle par défaut`
                : `Définir comme modèle par défaut`}
            </span>
          </DropdownItem>
        </DropdownContainer>
      </div>
    </div>
  )
}
