import React, { FunctionComponent, useCallback, useState } from 'react'

import { Formik, FormikProps } from 'formik'
import {
  FormMessageTemplateModalForm,
  FormMessageTemplateModalProps,
} from './FormMessageTemplateModal.model'
import {
  Heading,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  RoundedButton,
  ThinModalLayout,
} from '../../../shared'

export const FormMessageTemplateModal: FunctionComponent<FormMessageTemplateModalProps> = ({
  isUnmounting,
  label,
  onClose,
  onSubmit,
  messageTemplate,
}) => {
  const [messageTemplateTitle, setMessageTemplateTitle] = useState(messageTemplate?.title ?? '')

  const handleSubmit = useCallback(() => {
    onSubmit(messageTemplateTitle)
  }, [onSubmit, messageTemplateTitle])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageTemplateTitle(event.currentTarget.value)
  }, [])

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onClose}>
      <ThinModalLayout size="medium">
        <Formik initialValues={{ title: messageTemplateTitle }} onSubmit={handleSubmit}>
          {(props: FormikProps<FormMessageTemplateModalForm>) => (
            <form onSubmit={props.handleSubmit}>
              <ModalContent>
                <Heading size={3}>{label}</Heading>
                <div>
                  <Input
                    name="title"
                    label="Saisissez le nom du modèle"
                    value={messageTemplateTitle}
                    onChange={handleChange}
                    colorPreset="light"
                  />
                </div>
              </ModalContent>
              <ModalActions>
                <div className="mr-4">
                  <RoundedButton
                    onClick={onClose}
                    icon="arrow"
                    iconRotate={-90}
                    theme="transparent-dark"
                    label="Annuler"
                  />
                </div>
                <RoundedButton
                  theme="primary"
                  type="submit"
                  label="Valider"
                  disabled={messageTemplateTitle === ''}
                />
              </ModalActions>
            </form>
          )}
        </Formik>
      </ThinModalLayout>
    </Modal>
  )
}
