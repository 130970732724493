import React, { FunctionComponent } from 'react'
import styles from './TimelineIcon.module.scss'
import { TimelineIconProps } from './TimelineIcon.model'
import { ColorsV2 } from '../../../../design-system/colors_v2'
import { Icon, IconsType } from '../../../shared'
import classnames from 'classnames/bind'
import { TimelineEventType } from '../../../../model/MedicalEvent'
import { DocumentCategoryIcon } from '../../../../model/DocumentCategory'

const classnamesCx = classnames.bind(styles)

export const TIMELINE_TYPE_TO_ICON: { [type in TimelineEventType]: IconsType } = {
  ...DocumentCategoryIcon,
  // Static Types
  AddMedicalEventAction: 'add',
  CreatedPatientEvent: 'profile',
  MedicalEvent: 'default',
}

export const TimelineIcon: FunctionComponent<TimelineIconProps> = ({ type, onClick }) => {
  const icon = TIMELINE_TYPE_TO_ICON[type]
  return (
    <div
      className={classnamesCx(styles.icon, { [type]: true })}
      onClick={onClick}
      data-test-id={`timeline-icon-${type}`}
    >
      <Icon color={ColorsV2.contentLight1} icon={icon} size="micro" />
    </div>
  )
}
