import { FunctionComponent, useCallback } from 'react'
import { IconButton } from '../../../shared'
import { ManualPrescriptionItem } from '../../../../model/ManualPrescription'
import { PatientInfosItemEditable } from '../PatientInfosItemEditable'
import { PatientManualPrescriptionsProps } from './PatientManualPrescriptions.model'

export const PatientManualPrescriptions: FunctionComponent<PatientManualPrescriptionsProps> = ({
  manualPrescriptions,
  navigateToDocument,
}) => {
  const handleRedirectToPrescription = useCallback(
    (item: ManualPrescriptionItem) => {
      if (item.medicalEventId && item.documentId) {
        navigateToDocument(item.medicalEventId, item.documentId)
      }
    },
    [navigateToDocument],
  )

  return (
    <div className="flex flex-col w-full px-4 py-2">
      {manualPrescriptions.map((item) => (
        <PatientInfosItemEditable
          key={item.id}
          name={item.content}
          startDate={item.startDate}
          endDate={item.endDate}
          actions={
            <IconButton
              icon="arrowRight"
              testId={`button-medical_event-${item.id}-navigate`}
              title="Naviguer vers l'événement médical"
              theme="transparent-dark"
              size="nano"
              onClick={() => handleRedirectToPrescription(item)}
            />
          }
        />
      ))}
    </div>
  )
}
