import { FunctionComponent } from 'react'
import { MailHeader } from './MailHeader'
import { MailContentProps } from './MailContent.model'
import { MailBody } from './MailBody'
import { MailAttachments } from './MailAttachments'

export const MailContent: FunctionComponent<MailContentProps> = ({
  inUseMail,
  selectedAttachmentId,
  selectedFile,
  setSelectedAttachmentId,
  handleDownloadOrPrintFile,
}) => {
  return (
    <div className="w-full flex flex-col grow-2 py-2 px-8">
      <div className="mt-4">
        <MailHeader inUseMail={inUseMail} />
      </div>
      <MailBody inUseMail={inUseMail} />
      {inUseMail.attachments.length > 0 && (
        <MailAttachments
          attachments={inUseMail.attachments}
          selectedAttachmentId={selectedAttachmentId}
          selectedFile={selectedFile}
          setSelectedAttachmentId={setSelectedAttachmentId}
          handleDownloadOrPrintFile={handleDownloadOrPrintFile}
        />
      )}
    </div>
  )
}
