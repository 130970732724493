import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getMailAttachment } from '../../../data/mail'
import { isValidBase64 } from '../../../misc/files.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { MailAttachement } from '../../../model/Mail'
import { mailErrorsMessage, mailErrorsTitle } from './mail.errors'
import { mailKeys } from './mail.keys'

interface UseGetMailAttachmentParams {
  mailId: string
  partId: number
  cdaId?: string
  enabled?: boolean
}

export const useGetMailAttachment = ({
  mailId,
  partId,
  cdaId,
  enabled,
}: UseGetMailAttachmentParams) => {
  const queryKey = useMemo(
    () => mailKeys.attachment(mailId, partId, cdaId),
    [cdaId, mailId, partId],
  )

  const query = useQuery<MailAttachement, Error>({
    queryKey,
    queryFn: async () => {
      const { data, ok } = await getMailAttachment(mailId, partId, cdaId)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorPj,
          mailErrorsMessage.errorWhileFetchingAttachment,
        )
      }

      if (data.base64 && !isValidBase64(data.base64)) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorPj,
          mailErrorsMessage.invalidAttachment,
        )
      }

      const formatedBase64 = `data:${data.extension};base64,${data.base64}`
      return {
        ...data,
        base64: formatedBase64,
      }
    },
    enabled,
  })

  return { query }
}
