import getApi from '../../api'
import { singleItemPageLimit } from '../../constants'
import { PaginatedList } from '../../model/Pagination'
import { Survey } from '../../model/Survey'

export const getSurveys = (page: number, signal?: AbortSignal, limit = singleItemPageLimit) =>
  getApi().get<PaginatedList<Survey>>(
    '/surveys',
    {
      page,
      limit,
    },
    {
      signal,
    },
  )
