const all = ['survey']
const list = [...all, 'list']
const detail = (id: number) => [...all, 'detail', id]
const questionnaire = (surveyId: number, questionnaireId: number) => [
  ...detail(surveyId),
  'questionnaire',
  questionnaireId,
]

export const surveyKeys = {
  all,
  list,
  detail,
  questionnaire,
}
