import { FC, useCallback, useState } from 'react'
import { useToggle } from 'react-use'
import { useActionDispatch } from '../../../../hooks/utils'
import { SendableDocument } from '../../../../model/DocumentInstance'
import { ATTACHMENT_MAX_SIZE_IN_BYTES } from '../../../../model/Mail'
import { LightPatient } from '../../../../model/Patient'
import { addError } from '../../../../store/message'
import { FileAddButton, FileAddButtonErrorType } from '../../../file'
import { PatientDropDownItemSelector } from '../../../patient'
import { Icon, IconsType, MenuChip } from '../../../shared'
import { MedicalEventsDocumentsModal } from '../../../shared/modal/MedicalEventsDocumentsModal'
import { MailEditorAttachmentsProps } from './MailEditorAttachments.model'

const getFileIcon = (contentType: string): IconsType => {
  if (contentType.includes('image')) return 'image'
  else if (contentType.includes('pdf')) return 'fileInstance'
  return 'documentDefault'
}

export const MailEditorAttachments: FC<MailEditorAttachmentsProps> = ({
  disabled,
  disabledPatientSelect,
  selectedPatient,
  selectedFiles,
  onSelectPatient,
  setSelectedFiles,
  onAddDocuments,
  onAddFiles,
}) => {
  const [selectedFileName, setSelectedFileName] = useState<string>('')
  const [showFollowDocsModal, setShowFollowDocsModal] = useToggle(false)

  const dispatchError = useActionDispatch(addError)

  const handleSelectPatient = useCallback(
    (patient?: LightPatient) => {
      if (patient && patient.disallowMSSMessaging) {
        dispatchError(
          `Impossible de sélectionner le patient`,
          `Le patient ${patient.identity} refuse d'être contacté ou que ses informations soient échangées via la messagerie de santé sécurisée`,
        )
        return
      }

      if (!patient) {
        setSelectedFiles([])
      }
      onSelectPatient(patient)
    },
    [dispatchError, onSelectPatient, setSelectedFiles],
  )

  const isAlreadySelected = (file: File) =>
    selectedFiles.some((item) => item.name === file.name && item.size === file.size)

  const handleAttachmentsToAdd = useCallback(
    (selectedfilesIds: number[], selectedDocuments: SendableDocument[]) => {
      setShowFollowDocsModal()

      if (selectedfilesIds.length) onAddFiles(selectedfilesIds)
      if (selectedDocuments.length) onAddDocuments(selectedDocuments)
    },
    [setShowFollowDocsModal, onAddFiles, onAddDocuments],
  )

  const handleAddFile = (filesToUpload: File[]) => {
    const files = filesToUpload.reduce(
      (previousValue, file) => (isAlreadySelected(file) ? previousValue : [...previousValue, file]),
      selectedFiles,
    )

    setSelectedFiles(files)
  }

  const handleFileButtonError = useCallback(
    (errorType: FileAddButtonErrorType) => {
      switch (errorType) {
        case 'OVERSIZED_FILE':
          dispatchError(
            `Fichier(s) trop volumineux`,
            'La taille des pièces jointes est limitée à 10 Mo.',
          )
          break
      }
    },
    [dispatchError],
  )

  const handleRemoveFile = useCallback(
    (file: File) => {
      setSelectedFiles([
        ...selectedFiles.filter((item) => !(item.name === file.name && item.size === file.size)),
      ])
    },
    [selectedFiles, setSelectedFiles],
  )

  return (
    <>
      <div className="flex mb-4 w-full justify-between relative px-4">
        <div className="w-1/4">
          <PatientDropDownItemSelector
            selectedPatient={selectedPatient}
            setSelectedPatient={handleSelectPatient}
            hasInseeNumber={true}
            disabled={disabledPatientSelect || disabled}
          />
        </div>
        <div className="text-shades-3 text-base font-medium w-3/4 flex items-start ml-4 pl-4 border-l-shades-5 border-l min-h-full">
          {selectedPatient ? (
            <div className="flex flex-wrap">
              {selectedFiles.length > 0 && (
                <>
                  {selectedFiles.map((file, index) => (
                    <div className="mr-2 mb-2" key={index}>
                      <MenuChip
                        label={file.name}
                        icon={file.type ? getFileIcon(file.type) : undefined}
                        selected={file.name === selectedFileName}
                        collapsed={file.name !== selectedFileName}
                        onClick={() => {
                          setSelectedFileName(file.name)
                        }}
                        onDelete={() => handleRemoveFile(file)}
                        disabled={disabled}
                      />
                    </div>
                  ))}
                </>
              )}
              <FileAddButton
                buttonLabel="Pièce(s) jointe(s)"
                maxSizeInBytes={ATTACHMENT_MAX_SIZE_IN_BYTES}
                onFollowDocsSelectionClick={setShowFollowDocsModal}
                disabled={disabled}
                onAdd={handleAddFile}
                onError={handleFileButtonError}
              />
            </div>
          ) : (
            <div className="flex items-center h-full">
              <Icon icon="infoCircle" size="nano" />
              <span className="ml-2 break-words">
                La sélection d'un patient avec un matricule INS est nécessaire pour ajouter des
                pièces jointes
              </span>
            </div>
          )}
        </div>
      </div>
      {selectedPatient && (
        <MedicalEventsDocumentsModal
          patient={selectedPatient}
          display={showFollowDocsModal}
          onClose={setShowFollowDocsModal}
          onValidate={handleAttachmentsToAdd}
        />
      )}
    </>
  )
}
