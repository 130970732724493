import React, { FunctionComponent } from 'react'

import { ColorsV2 } from '../../../../../design-system/colors_v2'

import { TagColor, Icon } from '../../../../shared'

import { TagItemProps } from './TagItem.model'
import styles from './TagItem.module.scss'
import { getFullName } from '../../../../../misc/user.utilities'
import { ADMIN_ROLES } from '../../../../../model/Roles'
import RoleRestriction from '../../../../../containers/RoleRestriction'

export const TagItem: FunctionComponent<TagItemProps> = ({ tag, currentUser }) => (
  <div className={styles.item}>
    <div className={styles.visibility} title={tag.private ? `Contenu privé` : `Contenu public`}>
      <Icon size="micro" icon={tag.private ? 'lock' : 'global'} color={ColorsV2.dsDark4} />
    </div>
    <div className={styles.color} title="Couleur du label">
      <TagColor color={tag.color} />
    </div>
    <div className={styles.titleContainer}>
      <div className={styles.title} title="Titre du label">
        {tag.label}
      </div>
      {tag.private && tag.owner.id !== currentUser?.id && (
        <div className={styles.ownerName}>{getFullName(tag.owner, true)}</div>
      )}
    </div>
    <div className={styles.description} title="Description du label">
      {tag.description}
    </div>
    <RoleRestriction roles={ADMIN_ROLES}>
      <div className={styles.identifier} title="Identifiant technique du label">
        {tag.id}
      </div>
    </RoleRestriction>
  </div>
)
