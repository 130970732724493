import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { updateAnswerInstance } from '../../../data/answerInstances'
import { questionInstancesKeys } from '../questionInstances/questionInstance.keys'
import { AnswerInstanceMutationPayload } from '../../../data/answerInstances/mapper/answerInstance.model'

interface AnswerInstanceMutateVariable {
  id: number
  payload: AnswerInstanceMutationPayload
  questionnaireId?: number
}

export const useUpdateAnswerInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, payload }: AnswerInstanceMutateVariable) => {
      const { data, ok } = await updateAnswerInstance(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour de la réponse a échoué')
      }
      return data
    },

    onSuccess: (answer, { questionnaireId }) => {
      queryClient.invalidateQueries({ queryKey: questionInstancesKeys.detail(answer.questionId) })
      // if (questionnaireId) {
      //   queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
      // }
    },
  })
}
