import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { LabeledButton, RoundedButton, MenuChip } from '../../shared'
import { PosologyFormResource } from '../../../model/Posology'
import { PosologyDetail } from './PosologyDetail'
import { PosologyFormProps } from './PosologyForm.model'
import {
  computeIntervalForms,
  computePosologyFormDefaultValue,
  convertFormIntoPosology,
  isPosologyFormComplete,
} from './PosologyForm.utils'
import { DATE_FORMAT_FRONT_SHORT, formatFr } from '../../../misc/date.utilities'
import { isDefined } from '../../../misc/functions.utilities'
import { timeUnitLabels } from './PosologyDetail/PosologyDetail.model'
import { PrescriptionTemplateList } from './PrescriptionTemplateList'
import { PrescriptionTemplateModal } from '../../shared/modal/PrescriptionTemplateModal'
import { useGeneratePrescriptionPreview } from '../../../hooks/queries/prescriptions'

const computeChipLabel = (interval: PosologyFormResource) => {
  const { startDate, endDate, intakeDurationType, intakeDurationValue } = interval

  if (startDate !== null && endDate !== null) {
    const start = formatFr(new Date(startDate), DATE_FORMAT_FRONT_SHORT)
    const end = formatFr(new Date(endDate), DATE_FORMAT_FRONT_SHORT)
    return `${start} ⟶ ${end}`
  }

  if (intakeDurationValue !== undefined && intakeDurationType !== undefined) {
    const unit = timeUnitLabels[intakeDurationType]
    if (unit !== undefined) {
      return `${intakeDurationValue} ${unit}`
    }
  }

  return 'Intervalle'
}

export const PosologyForm: FunctionComponent<PosologyFormProps> = ({
  prescriptionId,
  drugs,
  intervals,
  disabled = false,
  activeIntervalId,
  intervalForms,
  allowEmptyIntervals = true,
  availableAdministrationRoutes,
  setIntervalForms,
  setActiveIntervalId,
  onSubmit,
  onSubmitAndCreateTemplate,
}) => {
  const [activeIndex, setActiveIndex] = useState(
    intervalForms.findIndex((item) => item.id === activeIntervalId) ?? 0,
  )

  const areIntervalFormsValid = useMemo(
    () => intervalForms.every((interval) => isPosologyFormComplete(interval, drugs)),
    [drugs, intervalForms],
  )

  const posologyForm = useMemo<PosologyFormResource | undefined>(
    () => intervalForms[activeIndex] ?? undefined,
    [intervalForms, activeIndex],
  )

  const completeForms = useMemo(
    () => intervalForms.filter((form) => isPosologyFormComplete(form, drugs)),
    [drugs, intervalForms],
  )
  const {
    query: { data: prescriptionPreview },
  } = useGeneratePrescriptionPreview({
    drugs,
    posologyIntervals: completeForms,
    enabled: completeForms.length > 0,
  })

  const setActivePosology = useCallback(
    (index: number) => {
      setActiveIndex(index)
      const itemAtIndex = intervalForms[index]
      if (isDefined(itemAtIndex?.id)) {
        setActiveIntervalId(itemAtIndex?.id)
      } else {
        setActiveIntervalId(undefined)
      }
    },
    [intervalForms, setActiveIntervalId],
  )

  const handleAddInterval = useCallback(() => {
    setIntervalForms((oldForms) => [
      ...oldForms,
      computePosologyFormDefaultValue(undefined, drugs, availableAdministrationRoutes),
    ])
    setActivePosology(intervalForms.length)
  }, [
    availableAdministrationRoutes,
    drugs,
    intervalForms.length,
    setActivePosology,
    setIntervalForms,
  ])

  const handleDeleteInterval = useCallback(
    (index: number) => {
      setIntervalForms((forms) => forms.filter((_, i) => i !== index))
      setActivePosology(index > 0 ? index - 1 : 0)
    },
    [setActivePosology, setIntervalForms],
  )

  const setPosologyForm = useCallback(
    (form: PosologyFormResource) => {
      const newForms = [...intervalForms]
      newForms.splice(activeIndex, 1, form)
      setIntervalForms(newForms)
    },
    [activeIndex, intervalForms, setIntervalForms],
  )

  const handleAddPrescriptionTemplate = useCallback(
    (value) => {
      const posologyTemplateForm = computeIntervalForms(
        value.posologyIntervals,
        drugs,
        availableAdministrationRoutes,
      )
      const getNextActiveIndex = intervalForms.length

      setIntervalForms((prevState) => [...prevState, ...posologyTemplateForm])
      setActivePosology(getNextActiveIndex)
    },
    [
      drugs,
      availableAdministrationRoutes,
      setIntervalForms,
      setActivePosology,
      intervalForms.length,
    ],
  )

  useEffect(() => {
    if (activeIntervalId && activeIntervalId !== 'new') {
      const index = intervalForms.findIndex((item) => item.id === activeIntervalId)
      if (index !== undefined && index >= 0) {
        setActivePosology(index)
      }
    }
  }, [activeIntervalId, intervalForms, setActivePosology])

  useEffect(() => {
    setIntervalForms(computeIntervalForms(intervals, drugs, availableAdministrationRoutes))
    setActiveIndex(0)
  }, [availableAdministrationRoutes, drugs, intervals, setIntervalForms])

  useEffect(() => {
    if (activeIntervalId === 'new') {
      handleAddInterval()
      setActiveIndex(intervals.length)
    }
  }, [activeIntervalId, handleAddInterval, intervals.length])

  const sendPosologyForm = () => {
    if (onSubmit && areIntervalFormsValid) {
      onSubmit(convertFormIntoPosology(intervalForms))
    }
  }

  const readableSummaries = useMemo(() => {
    if (!prescriptionPreview) return []

    const { summaries } = prescriptionPreview

    return summaries.map((summary) =>
      summaries.length === 1
        ? summary.value
        : `${drugs.find((drug) => drug.id === summary.id)?.name}: ${summary.value}`,
    )
  }, [drugs, prescriptionPreview])

  const [openedFavoriteModal, setOpenedFavoriteModal] = useState(false)
  const [defaultPrescriptionTemplateName, setDefaultPrescriptionTemplateName] = useState('')

  useEffect(() => {
    setDefaultPrescriptionTemplateName(readableSummaries.join(' / '))
  }, [readableSummaries])

  const handleSubmitAndCreateTemplate = useCallback(
    (name: string) => {
      if (onSubmitAndCreateTemplate && areIntervalFormsValid) {
        onSubmitAndCreateTemplate(convertFormIntoPosology(intervalForms), name)
      }
    },
    [onSubmitAndCreateTemplate, areIntervalFormsValid, intervalForms],
  )

  const renderIntervalChip = (interval: PosologyFormResource, index: number) => {
    const summary = prescriptionPreview?.posologyIntervals.find(
      ({ id }) => interval.previewId === id,
    )?.summary
    const allowDelete = !disabled && (allowEmptyIntervals || intervalForms.length > 1)

    return (
      <div key={index} className="mr-4">
        <MenuChip
          icon={isPosologyFormComplete(interval, drugs) ? 'calendar' : 'alert'}
          label={
            isPosologyFormComplete(interval, drugs)
              ? computeChipLabel(interval)
              : 'Intervalle incomplet'
          }
          title={summary}
          collapsed={activeIndex !== index}
          selected={activeIndex === index}
          onClick={() => setActivePosology(index)}
          onDelete={allowDelete ? () => handleDeleteInterval(index) : undefined}
        />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full flex-grow">
      <div className="flex flex-row items-center px-4 mb-4 justify-between">
        <div className="flex-1 items-center">
          <div className="flex flex-col">
            <span className="text-shades-4 font-medium uppercase whitespace-nowrap">
              Posologie et intervalles
            </span>
            {prescriptionPreview &&
              readableSummaries.map((readableSummary, index) => (
                <span className="text-shades-2 font-light text-sm" key={index}>
                  {readableSummary}
                </span>
              ))}
          </div>
        </div>
        {!disabled && onSubmit && (
          <div className="flex space-x-2">
            {prescriptionId && (
              <>
                <PrescriptionTemplateModal
                  onSubmit={handleSubmitAndCreateTemplate}
                  display={openedFavoriteModal}
                  label="Ajout de posologie favorite"
                  defaultPrescriptionTemplateName={defaultPrescriptionTemplateName}
                  onClose={() => setOpenedFavoriteModal(false)}
                />
                <RoundedButton
                  disabled={!areIntervalFormsValid}
                  label="Enregistrer et ajouter aux favoris"
                  size="micro"
                  onClick={() => setOpenedFavoriteModal(true)}
                />
              </>
            )}
            <RoundedButton
              disabled={!areIntervalFormsValid}
              label="Enregistrer"
              size="micro"
              onClick={sendPosologyForm}
            />
          </div>
        )}
      </div>
      <div className="px-4 mb-4 flex flex-wrap items-center">
        <PrescriptionTemplateList
          drugs={drugs}
          onSelectPrescriptionTemplate={handleAddPrescriptionTemplate}
        />
        {intervalForms.map(renderIntervalChip)}
        {!disabled && (
          <div className="mt-1">
            <LabeledButton
              label="Intervalle"
              icon="add"
              size="medium"
              onClick={handleAddInterval}
            />
          </div>
        )}
      </div>
      {posologyForm ? (
        <PosologyDetail
          drugs={drugs}
          posologyForm={posologyForm}
          setPosologyForm={setPosologyForm}
          disabled={disabled}
          availableAdministrationRoutes={availableAdministrationRoutes}
          key={activeIndex}
        />
      ) : (
        <div className="flex items-center justify-center h-full text-shades-4 font-medium text-base">
          Aucun intervalle, appuyez sur "+ Intervalle" pour saisir une posologie.
        </div>
      )}
    </div>
  )
}
