import classNames from 'classnames'
import { FunctionComponent, useCallback } from 'react'
import Waypoint from 'react-waypoint'
import { Icon, Loader } from '../../../shared'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { NotificationModalOwnProps } from './NotificationModal.model'
import { NotificationModalItem } from './NotificationModalItem'
import {
  useGetInfiniteUserNotifications,
  useReadUserNotifications,
} from '../../../../hooks/queries/notifications'
import { NotificationType } from '../../../../model/Notification'

export const NotificationModal: FunctionComponent<NotificationModalOwnProps> = ({
  open,
  setOpen,
}) => {
  const {
    notificationList,
    query: { fetchNextPage, hasNextPage, isLoading },
  } = useGetInfiniteUserNotifications({
    filters: {
      types: [NotificationType.announcement, NotificationType.incident],
    },
    enabled: open,
  })
  const { mutate: readNotifications } = useReadUserNotifications()

  const handleCloseModal = useCallback(() => {
    if (open) {
      const notificationIds = notificationList.reduce<Array<string>>(
        (acc, { read, id }) => (read ? acc : [...acc, id]),
        [],
      )

      if (notificationIds.length > 0) {
        readNotifications({ notificationIds })
      }
      setOpen(false)
    }
  }, [notificationList, open, readNotifications, setOpen])

  return (
    <div
      className={classNames(
        'fixed top-0 left-0 w-full h-full bg-dimmer bg-opacity-25 flex items-center justify-center',
        open ? 'z-999 opacity-100' : '-z-1 opacity-0',
      )}
      onMouseDown={handleCloseModal}
    >
      <div
        className={classNames(
          'flex flex-col fixed h-1/2 bottom-10 ml-4 left-20 w-103 bg-opacity-100 bg-white text-white -z-1 rounded-lg',
          open ? 'z-999 opacity-100' : '-z-1 opacity-0',
        )}
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
      >
        <div className="flex w-full justify-between items-center py-4 rounded-t-lg text-shades-8 bg-shades-2 pl-6 pr-4">
          <div className="text-xl font-bold ">Notifications</div>
          <div className="cursor-pointer" onClick={handleCloseModal}>
            <Icon icon="cross" size="normal" color={getV3ColorCssVariable('shades', 'shade8')} />
          </div>
        </div>
        {isLoading && (
          <div className="flex justify-center items-center absolute top-0 left-0 h-full w-full z-max bg-shades-0">
            <Loader isLoading={true} height={70} width={70} />
          </div>
        )}
        <div className="overflow-y-auto overflow-x-hidden h-full rounded-lg">
          {notificationList.length > 0 &&
            notificationList.map((notification) => (
              <NotificationModalItem notification={notification} key={notification.id} />
            ))}
          {notificationList.length === 0 && !isLoading && (
            <div className="text-shades-2 text-lg h-full flex justify-center items-center">
              Aucune notification
            </div>
          )}
          {hasNextPage && (
            <Waypoint topOffset={800} debug={false} onEnter={() => fetchNextPage()} />
          )}
        </div>
      </div>
    </div>
  )
}
