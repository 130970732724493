import getApi from '../../api'
import { largePageLimit } from '../../constants'
import { PaginatedList } from '../../model/Pagination'
import { PrescriptionTemplate } from '../../model/PrescriptionTemplate'

export const getPrescriptionTemplates = (
  drugIds: Array<number>,
  page: number,
  signal?: AbortSignal,
  limit = largePageLimit,
) =>
  getApi().get<PaginatedList<PrescriptionTemplate>>(
    `prescription_templates`,
    {
      drugIds: drugIds.join(),
      page,
      limit,
    },
    { signal },
  )
