import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getActivePrinciples } from '../../../data/drugs'
import { UserFriendlyError } from '../../../model/Error'
import { drugsKeys } from './drugs.keys'

interface UseGetActivePrinciplesParams {
  search: string
  enabled?: boolean
}

export const useGetActivePrinciples = ({
  search,
  enabled = true,
}: UseGetActivePrinciplesParams) => {
  const queryKey = useMemo(() => drugsKeys.activePrinciplesList(search), [search])

  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { data, ok } = await getActivePrinciples(search, signal)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer la liste des principes actifs',
        )
      }

      return data
    },
    enabled,
  })

  return { query }
}
