import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { isDefined } from '../../../misc/functions.utilities'
import { PosologyFormResource } from '../../../model/Posology'
import { Button, Checkbox, CheckboxState, FullLoader, TextArea } from '../../shared'
import { PosologyForm, convertFormIntoPosology, isPosologyFormComplete } from '../PosologyForm'
import { PosologyBottomPanelProps } from './PosologyBottomPanel.model'
import { ContactCard, ContactSearchAutocomplete } from '../../contact'
import { useDebounce } from 'react-use'
import { Contact, RecipientContact } from '../../../model/Contact'
import { mapContactToRecipientContact } from '../../../misc/contact.utilities'
import { useGetDrug } from '../../../hooks/queries/drugs'
import { useGetPrescription, useUpdatePrescription } from '../../../hooks/queries/prescriptions'
import { BottomPanel } from '../../shared/bottomPanel/BottomPanel'
import { useCreatePatientTreatment } from '../../../hooks/queries/patientTreatments/useCreatePatientTreatment.query'
import { useCurrentPatientId } from '../../../hooks/utils'
import { Filters } from '../../../model/Filters'
import { useGetInfiniteContacts } from '../../../hooks/queries/contact'

export const PosologyBottomPanel: FC<PosologyBottomPanelProps> = ({
  isUnmounting,
  prescriptionId,
  drug,
  isAld,
  onClose,
}) => {
  const {
    query: { data: prescription },
  } = useGetPrescription({ id: prescriptionId ?? '', enabled: isDefined(prescriptionId) })

  // Default intervals if current treatment already exist
  const intervals = useMemo(() => prescription?.posologyIntervals ?? [], [prescription])

  const [selectedIntervalId, setSelectedIntervalId] = useState<string | undefined>(
    prescriptionId ? undefined : 'new',
  )
  const [intervalForms, setIntervalForms] = useState<PosologyFormResource[]>([])
  const [prescriptionReason, setPrescriptionReason] = useState(
    prescription?.prescriptionReason ?? '',
  )
  const [isChronic, setIsChronic] = useState(prescription?.isChronic ?? false)
  const [searchValue, setSearchValue] = useState('')
  const [prescriberContact, setPrescriberContact] = useState<RecipientContact | null>(
    (prescription?.prescriber && mapContactToRecipientContact(prescription?.prescriber)) ?? null,
  )
  const [manualPrescriber, setManualPrescriber] = useState<string | null>(
    prescription?.manualPrescriber ?? null,
  )

  const patientId = useCurrentPatientId()
  const { mutate: updatePrescription } = useUpdatePrescription()
  const { mutate: createTreatment } = useCreatePatientTreatment()
  const [filters, setFilters] = useState<Filters>()

  const {
    query: { isLoading },
    contactsList,
  } = useGetInfiniteContacts({ enabled: filters?.search?.length > 1, filters })

  useEffect(() => {
    if (prescription && selectedIntervalId === undefined) {
      const firstInterval = prescription.posologyIntervals.at(0)

      if (firstInterval) {
        setSelectedIntervalId(firstInterval.id)
      }
    }
  }, [prescription, selectedIntervalId])

  useEffect(() => {
    if (!prescription) return

    setManualPrescriber(prescription.manualPrescriber)
    setPrescriberContact(
      prescription.prescriber ? mapContactToRecipientContact(prescription.prescriber) : null,
    )
    setPrescriptionReason(prescription?.prescriptionReason ?? '')
    setIsChronic(prescription?.isChronic ?? false)
  }, [prescription])

  const inUseDrug = useMemo(() => {
    if (isDefined(prescription)) {
      return prescription.drugs.at(0) ?? null
    }

    return drug ?? null
  }, [drug, prescription])

  const {
    query: { data: drugDetails },
  } = useGetDrug({
    drug: inUseDrug ?? null,
    enabled: isDefined(inUseDrug),
  })

  const drugs = useMemo(() => (drugDetails ? [drugDetails] : []), [drugDetails])

  const areIntervalFormsValid = useMemo(
    () =>
      isDefined(drugDetails)
        ? intervalForms.every((interval) => isPosologyFormComplete(interval, [drugDetails]))
        : false,
    [drugDetails, intervalForms],
  )

  const handleSubmit = useCallback(() => {
    const posologyIntervals = convertFormIntoPosology(intervalForms)

    if (isDefined(drug) && isDefined(patientId)) {
      createTreatment(
        {
          patientId,
          treatmentForm: {
            drug,
            isAld: isAld ?? false,
            posologyIntervals,
            prescriptionReason,
            isChronic,
            prescriberId: prescriberContact?.id ?? null,
            manualPrescriber,
          },
        },
        {
          onSuccess: () => {
            onClose()
          },
        },
      )
    } else if (isDefined(prescription)) {
      updatePrescription(
        {
          id: prescription.uuid,
          prescription: {
            posologyIntervals,
            prescriptionReason,
            isChronic,
            prescriberId: prescriberContact?.id ?? null,
            manualPrescriber,
          },
        },
        {
          onSuccess: () => {
            onClose()
          },
        },
      )
    }
  }, [
    intervalForms,
    drug,
    patientId,
    prescription,
    createTreatment,
    isAld,
    prescriptionReason,
    isChronic,
    prescriberContact?.id,
    manualPrescriber,
    onClose,
    updatePrescription,
  ])

  useDebounce(
    () => {
      handleSearchContact()
    },
    400,
    [searchValue],
  )

  const handleSearchContact = () => {
    if (searchValue.length > 1) {
      setFilters({
        search: searchValue,
      })
    }
  }

  const handleAddContact = (contact: Contact) => {
    setPrescriberContact({
      ...mapContactToRecipientContact(contact),
    })
    setSearchValue('')
  }

  function handleRemoveContact() {
    setPrescriberContact(null)
  }

  const showContacts = useMemo(
    () => searchValue.length > 1 && searchValue === filters?.search,
    [filters?.search, searchValue],
  )

  return (
    <BottomPanel
      isUnmounting={isUnmounting}
      onRequestClose={onClose}
      title="Saisir manuellement un traitement en cours"
      actions={
        <>
          <Button label="Annuler" theme="dark" onClick={onClose} />
          <Button
            type="submit"
            label="Enregistrer"
            theme="primary"
            disabled={!areIntervalFormsValid}
            onClick={handleSubmit}
          />
        </>
      }
    >
      {isDefined(drugDetails) ? (
        <div className="grid grid-cols-30/70 h-full bg-shades-white">
          <div className="flex flex-col p-6 border-r border-shades-5 overflow-y-auto">
            <span className="text-shades-4 text-xs font-medium">MÉDICAMENT</span>
            <span className="text-shades-2 text-base font-semibold">{drugDetails.name}</span>
            <span className="text-shades-4 text-xs font-medium capitalize mt-1">
              {drugDetails.activePrinciples}
            </span>
            <div className="mt-5 space-y-4">
              <TextArea
                name="prescriptionReason"
                label="Motif de prescription"
                value={prescriptionReason ?? ''}
                onChange={({ target: { value } }) => setPrescriptionReason(value)}
                initialRows={2}
                autoResize
                maxLength={255}
              />
              <div>
                <span className="text-shades-4 text-xs font-medium">PRESCRIPTEUR</span>
                {!prescriberContact && (
                  <ContactSearchAutocomplete
                    label="Rechercher le prescripteur (2 caractères min)"
                    searchValue={searchValue}
                    predictions={showContacts ? contactsList : []}
                    loading={isLoading}
                    onSearchValueChange={(event) => setSearchValue(event.target.value)}
                    onSelectContact={handleAddContact}
                    onFocus={handleSearchContact}
                    colorPreset="light"
                  />
                )}
                {prescriberContact && (
                  <ContactCard
                    showAddresses={false}
                    contact={prescriberContact}
                    onClose={handleRemoveContact}
                    loading={false}
                  />
                )}
              </div>
              <div>
                <span className="inline-block mb-1 text-shades-4 font-medium">ou</span>
                <TextArea
                  name="manualPrescriber"
                  label="Prescripteur (saisie libre)"
                  value={manualPrescriber ?? undefined}
                  onChange={({ target: { value } }) => setManualPrescriber(value)}
                  initialRows={2}
                  autoResize
                  maxLength={255}
                />
              </div>
              <Checkbox
                checked={isChronic ? CheckboxState.CHECKED : CheckboxState.UNCHECKED}
                onChange={() => setIsChronic(!isChronic)}
                label="Traitement chronique"
              />
            </div>
          </div>
          <div className="p-4 pl-2 overflow-y-auto">
            <PosologyForm
              prescriptionId={prescription?.uuid}
              allowEmptyIntervals={false}
              intervalForms={intervalForms}
              setIntervalForms={setIntervalForms}
              drugs={drugs}
              intervals={intervals}
              disabled={false}
              activeIntervalId={selectedIntervalId}
              setActiveIntervalId={setSelectedIntervalId}
              availableAdministrationRoutes={drugDetails.administrationRoutes}
            />
          </div>
        </div>
      ) : (
        <div className="bg-shades-white h-full">
          <FullLoader />
        </div>
      )}
    </BottomPanel>
  )
}
