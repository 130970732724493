import React, { FC, useContext, useMemo } from 'react'
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import classNames from 'classnames/bind'
import styles from './VariableWrapper.module.scss'
import { VariableAttrbs, VariableData } from '@/model/Variable'
import { isDefined } from '@/utils/functions.utils'
import { VariableEditorContext } from '@/context'

const cx = classNames.bind(styles)

const extractVariableThemeStyle = (entry: VariableData) => {
  const themes = entry.themes?.map((theme) => `theme-${theme}`) ?? []
  const state = `state-${entry.state}`

  return [...themes, state]
}

export const VariableWrapper: FC<NodeViewProps> = ({ node, selected, editor }) => {
  const disabled = !editor.isEditable
  const { variableMap, sanitizeHtmlVariable } = useContext(VariableEditorContext)

  const variableId = node.attrs[VariableAttrbs.variableId]
  const variableEntry = variableMap[variableId]

  const sanitizedHtml = useMemo(
    () => (variableEntry?.value ? sanitizeHtmlVariable(variableEntry.value) : null),
    [sanitizeHtmlVariable, variableEntry?.value],
  )

  if (!variableEntry) {
    return (
      <NodeViewWrapper
        as="span"
        data-drag-handle=""
        className={cx('container', 'state-fallback', { disabled })}
      >
        <span data-variableid={variableId}>Chargement...</span>
      </NodeViewWrapper>
    )
  }

  return (
    <NodeViewWrapper
      as="span"
      data-drag-handle=""
      className={cx('container', extractVariableThemeStyle(variableEntry), variableEntry.type, {
        selected,
        disabled,
      })}
    >
      {isDefined(sanitizedHtml) && (
        <span
          data-variableid={variableId}
          className={cx('variable', `variable-${variableEntry.type}`)}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        />
      )}
    </NodeViewWrapper>
  )
}
