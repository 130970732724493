import React, { FunctionComponent, useCallback, useState } from 'react'
import { Modal, ModalActions, ModalContent, ThinModalLayout } from '../Modal'
import { Heading } from '../../fonts'
import { RoundedButton } from '../../buttons'
import { Formik, FormikProps } from 'formik'
import {
  PrescriptionTemplateListModalProps,
  PrescriptionTemplateModalForm,
} from './PrescriptionTemplateModal.model'
import { TextArea } from '../../inputs'
import styles from './PrescriptionTemplateModal.module.scss'
import { lineBreakRegex } from '../../../../misc/regex'

export const PrescriptionTemplateModal: FunctionComponent<PrescriptionTemplateListModalProps> = ({
  isUnmounting,
  label,
  defaultPrescriptionTemplateName = '',
  onClose,
  onSubmit,
}) => {
  const [prescriptionTemplateName, setPrescriptionTemplateName] = useState(
    defaultPrescriptionTemplateName,
  )

  const handleSubmit = useCallback(() => {
    onSubmit(prescriptionTemplateName)
  }, [onSubmit, prescriptionTemplateName])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const prescriptionTemplateName = event.target.value.replace(lineBreakRegex, '')
    setPrescriptionTemplateName(prescriptionTemplateName)
  }, [])

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onClose}>
      <ThinModalLayout size="medium">
        <Formik initialValues={{ name: prescriptionTemplateName }} onSubmit={handleSubmit}>
          {(props: FormikProps<PrescriptionTemplateModalForm>) => (
            <form onSubmit={props.handleSubmit}>
              <ModalContent>
                <Heading size={3}>{label}</Heading>
                <div>
                  <span className={styles.label}>Saisissez le nom de votre posologie</span>
                  <TextArea
                    size="big"
                    autoResize
                    name="name"
                    value={prescriptionTemplateName}
                    onChange={handleChange}
                  />
                </div>
              </ModalContent>
              <ModalActions>
                <div className="mr-4">
                  <RoundedButton
                    onClick={onClose}
                    icon="arrow"
                    iconRotate={-90}
                    theme="transparent-dark"
                    label="Annuler"
                  />
                </div>
                <RoundedButton
                  theme="primary"
                  type="submit"
                  label="Valider"
                  disabled={prescriptionTemplateName === ''}
                />
              </ModalActions>
            </form>
          )}
        </Formik>
      </ThinModalLayout>
    </Modal>
  )
}
