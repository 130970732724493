import { useCallback, useMemo } from 'react'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData } from '../../utils/query/useFlatPaginatedData.hook'
import { usePaginationState } from '../../utils/query'
import { Filters } from '../../../model/Filters'
import { getTasks } from '../../../data/task/getTasks'
import { taskKeys } from './tasks.keys'
import { getNextPageParam } from '../../../misc/query.utilities'
import { startingPage } from '../../../constants'

interface GetInfiniteTasksParams {
  filters?: Filters
  limit?: number
  enabled?: boolean
}

export const useGetTasks = ({ filters, limit }: GetInfiniteTasksParams) => {
  const queryKey = useMemo(() => taskKeys.list(filters), [filters])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getTasks(pageParam, filters, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des tâches`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
  })

  const queryClient = useQueryClient()

  const taskList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, taskList, paginationState, cancelPendingQuery }
}
