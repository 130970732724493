import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { contactsKeys } from './contacts.keys'
import { ContactConfiguration } from '../../../model/Contact'
import { updateContactConfiguration } from '../../../data/contact'

interface ContactMutateVariable {
  id: string
  payload: ContactConfiguration
}

export const useUpdateContactConfiguration = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ id, payload }: ContactMutateVariable) => {
      const { data, ok } = await updateContactConfiguration(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'La mise à jour de la configuration du contact a échoué',
        )
      }
      return data
    },

    onSuccess: (contact) => {
      queryClient.setQueryData(contactsKeys.detail(contact.id), contact)
    },
  })
}
