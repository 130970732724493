import classNames from 'classnames'
import { FieldArray, FormikProvider } from 'formik'
import React, { FunctionComponent } from 'react'
import { FormikTextInput, AddressFields } from '../../../components/shared'
import { ProfessionSelect } from '../../../components/user/userForm/GeneralInformationsBlock/ProfessionSelect'
import { ProfessionSpecialitiesSelect } from '../../../components/user/userForm/GeneralInformationsBlock/ProfessionSpecialitiesSelect'
import { ContactFormProps } from './ContactForm.model'
import styles from './ContactForm.module.scss'

export const ContactForm: FunctionComponent<ContactFormProps> = ({ disabled, formik }) => (
  <FormikProvider value={formik}>
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <div className={styles.fields}>
        <div className={styles.row}>
          <div className="flex flex-wrap gap-y-4 justify-between">
            <div className={classNames(styles.field, styles.fieldTitle)}>
              <FormikTextInput
                fieldName="title"
                label="Titre"
                autocomplete={false}
                disabled={disabled}
                colorPreset="dark"
              />
            </div>
            <div className={classNames(styles.field, styles.fieldFamilyName)}>
              <FormikTextInput
                fieldName="familyName"
                label="Nom ou raison sociale *"
                autocomplete={false}
                disabled={disabled}
                colorPreset="dark"
              />
            </div>
            <div className={classNames(styles.field, styles.fieldFirstName)}>
              <FormikTextInput
                fieldName="firstName"
                label="Prénom"
                autocomplete={false}
                disabled={disabled}
                colorPreset="dark"
              />
            </div>
            <div className={classNames(styles.field, styles.fieldProfession)}>
              <ProfessionSelect theme="dark" disabled={disabled} />
            </div>
            <div className="invisible">
              <ProfessionSpecialitiesSelect theme="dark" disabled={disabled} />
            </div>
            <div className={classNames(styles.field, styles.fieldIdentifier)}>
              <FormikTextInput
                fieldName="ppIdentifier"
                label="Numéro RPPS"
                autocomplete={false}
                disabled={disabled}
                colorPreset="dark"
              />
            </div>
            <div className={classNames(styles.field, styles.fieldIdentifier)}>
              <FormikTextInput
                fieldName="notes"
                label="Information complémentaires"
                autocomplete={false}
                disabled={disabled}
                colorPreset="dark"
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <FieldArray
            name="organizations"
            render={() =>
              formik.values.organizations.map((organization, index) => (
                <div className={styles.organization} key={organization.id}>
                  <div className={styles.inputSpacing}>
                    <FormikTextInput
                      fieldName={`organizations.${index}.name`}
                      label="Intitulé de l'adresse"
                      autocomplete={false}
                      disabled={disabled}
                      colorPreset="dark"
                    />
                  </div>
                  <div className={styles.inputSpacing}>
                    <FormikTextInput
                      fieldName={`organizations.${index}.emailAddress`}
                      label="Email"
                      autocomplete={false}
                      disabled={disabled}
                      colorPreset="dark"
                    />
                  </div>
                  <div className={styles.inputSpacing}>
                    <FormikTextInput
                      fieldName={`organizations.${index}.phoneNumber`}
                      label="Téléphone"
                      autocomplete={false}
                      disabled={disabled}
                      colorPreset="dark"
                    />
                  </div>
                  <AddressFields
                    type="contact"
                    key={index}
                    fieldNamePrefix={`organizations.${index}`}
                    colorPreset="dark"
                    disabled={disabled}
                  />
                </div>
              ))
            }
          />
        </div>
      </div>
    </form>
  </FormikProvider>
)
