import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getPrescription } from '../../../data/prescriptions'
import { UserFriendlyError } from '../../../model/Error'
import { prescriptionKeys } from './prescriptions.keys'

interface UseGetPrescriptionParams {
  id: string
  enabled?: boolean
}

export const useGetPrescription = ({ id, enabled = true }: UseGetPrescriptionParams) => {
  const queryKey = useMemo(() => prescriptionKeys.detail(id), [id])

  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { data, ok } = await getPrescription(id, signal)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer les détails de la prescription',
        )
      }

      return data
    },
    enabled,
  })

  return { query }
}
