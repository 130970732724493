import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getSideEffects } from '../../../data/sideEffects'
import { UserFriendlyError } from '../../../model/Error'
import { drugsKeys } from './drugs.keys'

interface UseGetSideEffectsParams {
  search: string
  enabled?: boolean
}

export const useGetSideEffects = ({ search, enabled }: UseGetSideEffectsParams) => {
  const queryKey = useMemo(() => drugsKeys.sideEffectsList(search), [search])

  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { data, ok } = await getSideEffects(search, signal)

      if (!ok || !data) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la récupération des effets secondaires',
        )
      }

      return data
    },
    enabled,
  })

  return { query }
}
