import { FwDocumentType } from '../../../model/Document'
import { RenderableDocument } from '../../renderer'
import {
  DmpDomainActionsTypes,
  DmpIframeStatus,
  DmpRequest,
  DmpRequestTypes,
  FileForDMP,
} from './dmp.model'
import { EfficienceUploadConfig, HealthcareSetting } from '../../../model/Efficience'
import { Patient } from '../../../model/Patient'
import { ExtendedUserDoctor } from '../../../model/User'

interface Request {
  documentId?: number
  request?: Record<string, any>
}

export const dmpDomainActions = {
  setRequests: (requests: Request[]) => ({
    type: DmpDomainActionsTypes.SET_REQUESTS,
    requests,
  }),
  removeRequest: (fileId: number, requests: DmpRequest[]) => ({
    type: DmpDomainActionsTypes.REMOVE_REQUEST,
    fileId,
    requests,
  }),
  uploadDocument: (
    documentId: number,
    documentType: FwDocumentType,
    config: EfficienceUploadConfig,
    requestType: DmpRequestTypes,
  ) => ({
    type: DmpDomainActionsTypes.UPLOAD_DOCUMENT,
    documentId,
    documentType,
    requestType,
    config,
  }),
  openDmp: (patientIns: string) => ({
    type: DmpDomainActionsTypes.OPEN_DMP,
    patientIns,
  }),
  closeDmpSession: () => ({
    type: DmpDomainActionsTypes.CLOSE_DMP_SESSION,
  }),
  sendDocument: (documents: Array<RenderableDocument>, requestType: DmpRequestTypes) => ({
    type: DmpDomainActionsTypes.SEND_DOCUMENT,
    requestType,
    documents,
  }),
  sendDocumentsToDMP: (
    patient: Patient,
    medicalEventOwner: ExtendedUserDoctor,
    efficienceConfig: EfficienceUploadConfig,
    requestType: DmpRequestTypes,
    files: FileForDMP[],
  ) => ({
    type: DmpDomainActionsTypes.SEND_PDFS_TO_DMP,
    patient,
    medicalEventOwner,
    efficienceConfig,
    files,
    requestType,
  }),
  deleteDocument: (
    documentId: number,
    dmpDocumentId: string,
    patientInseeNumber: string,
    requestType: DmpRequestTypes,
  ) => ({
    type: DmpDomainActionsTypes.DELETE_DOCUMENT,
    documentId,
    dmpDocumentId,
    patientInseeNumber,
    requestType,
  }),
  fetchHealthcareSettings: () => ({
    type: DmpDomainActionsTypes.FETCH_HEALTHCARE_SETTINGS,
  }),
  storeHealthcareSettings: (healthcareSettings: HealthcareSetting[]) => ({
    type: DmpDomainActionsTypes.STORE_HEALTHCARE_SETTINGS,
    healthcareSettings,
  }),
  setIframeStatus: (status: DmpIframeStatus) => ({
    type: DmpDomainActionsTypes.SET_IFRAME_STATE,
    iframeStatus: status,
  }),
}
