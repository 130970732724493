import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { tagsKeys } from './tags.keys'
import { deleteTag } from '../../../data/tag'
import { TagType } from '../../../model/Tag'

interface TagMutateVariable {
  tagId: string
}

export const useDeleteTag = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ tagId }: TagMutateVariable) => {
      const { ok } = await deleteTag(tagId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression du label a échoué')
      }
      return null
    },

    onSuccess: (_, { tagId }) => {
      queryClient.setQueryData<TagType[]>(
        tagsKeys.all,
        (oldTags) => oldTags && oldTags.filter((oldTag) => oldTag.id !== tagId),
      )
    },
  })
}
