import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getAtcClassChildren } from '../../../data/drugs'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { drugsKeys } from './drugs.keys'

interface UseGetInfiniteAtcClassChildrenParams {
  id: number
  enabled?: boolean
}

export const useGetInfiniteAtcClassChildren = ({
  id,
  enabled = true,
}: UseGetInfiniteAtcClassChildrenParams) => {
  const queryKey = useMemo(() => drugsKeys.atcClassDetailChildren(id), [id])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getAtcClassChildren(id, pageParam, signal)

      if (!ok || !data) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la récupération des informations de la classe ATC secondaire',
        )
      }

      return data
    },
    initialPageParam: startingPage,
    enabled,
    getNextPageParam,
  })

  const childrenAtcClassList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  return { query, childrenAtcClassList, paginationState }
}
