import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getSurveyQuestionnaire } from '../../../data/survey'
import { UserFriendlyError } from '../../../model/Error'
import { surveyKeys } from './survey.keys'

interface UseGetSurveyQuestionnaireParams {
  surveyId: number
  questionnaireId: number
  enabled?: boolean
}

export const useGetSurveyQuestionnaire = ({
  questionnaireId,
  surveyId,
  enabled = true,
}: UseGetSurveyQuestionnaireParams) => {
  const queryKey = useMemo(
    () => surveyKeys.questionnaire(surveyId, questionnaireId),
    [questionnaireId, surveyId],
  )

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const { data, ok } = await getSurveyQuestionnaire(surveyId, questionnaireId)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer les questionnaires paramétrés',
        )
      }

      return data
    },
    enabled,
  })

  return { query }
}
