import { FunctionComponent, useMemo } from 'react'
import { City } from '../../../model/Contact'
import { SelectOption } from '../../../model/SelectOption'
import { DropdownItem, SelectSearch } from '../../shared'
import { CitiesSearchProps } from './CitiesSearch.model'

const renderCityOption = (
  { value }: SelectOption<City>,
  currentValue: SelectOption<City> | undefined,
) => {
  return (
    <DropdownItem selected={value.id === currentValue?.value.id}>
      <div key={value.id} className="flex flex-col">
        <span>{value.name}</span>
        <span className="font-normal text-sm">
          {value.inseeCode} - {value.department}
        </span>
      </div>
    </DropdownItem>
  )
}

export const CitiesSearch: FunctionComponent<CitiesSearchProps> = ({
  colorPreset = 'light',
  disabled = false,
  selectedCity,
  search,
  cities,
  onSearch,
  onSelectCity,
}) => {
  const citiesSearchOptions = useMemo(
    () =>
      cities.map((city) => {
        return {
          label: city.name,
          value: city,
        }
      }),
    [cities],
  )

  const selectedCityOption = useMemo(
    () =>
      selectedCity
        ? citiesSearchOptions.find(({ value }) => value.id === selectedCity.id)
        : undefined,
    [citiesSearchOptions, selectedCity],
  )

  return (
    <SelectSearch
      placeholder="Recherche par ville"
      colorPreset={colorPreset}
      icon="search"
      value={selectedCityOption}
      options={citiesSearchOptions}
      onSelect={(selected) => onSelectCity(selected?.value)}
      disabled={disabled}
      textValue={search}
      onTextChange={onSearch}
      renderOption={renderCityOption}
    />
  )
}
