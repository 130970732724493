import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getContacts } from '../../../data/contact'
import { getNextPageParam } from '../../../misc/query.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { Filters } from '../../../model/Filters'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { contactsKeys } from './contacts.keys'

interface GetInfiniteContactsVariables {
  filters?: Filters
  limit?: number
  enabled?: boolean
}

export const useGetInfiniteContacts = ({
  filters,
  limit,
  enabled = true,
}: GetInfiniteContactsVariables) => {
  const queryKey = useMemo(() => contactsKeys.list(filters), [filters])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getContacts(pageParam, filters, signal, limit)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des contacts`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    enabled,
  })

  const contactsList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  const queryClient = useQueryClient()

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query, contactsList, paginationState, cancelPendingQuery }
}
