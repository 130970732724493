import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { startingPage } from '../../../constants'
import { getDrugs } from '../../../data/drugs'
import { isDefined } from '../../../misc/functions.utilities'
import { getNextPageParam } from '../../../misc/query.utilities'
import { DrugFilters } from '../../../model/DrugFilters'
import { UserFriendlyError } from '../../../model/Error'
import { useFlatPaginatedData, usePaginationState } from '../../utils/query'
import { drugsKeys } from './drugs.keys'

interface UseGetInfiniteDrugsParams {
  filters: DrugFilters | null
}

export const useGetInfiniteDrugs = ({ filters }: UseGetInfiniteDrugsParams) => {
  const queryKey = useMemo(() => drugsKeys.list(filters), [filters])

  const query = useInfiniteQuery({
    queryKey,
    queryFn: async ({ signal, pageParam }) => {
      if (!isDefined(filters)) return

      const { data, ok } = await getDrugs(pageParam, filters, signal)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Impossible de récupérer la liste des médicaments')
      }

      return data
    },
    initialPageParam: startingPage,
    getNextPageParam,
    enabled: isDefined(filters),
  })

  const drugList = useFlatPaginatedData(query.data)
  const paginationState = usePaginationState(query.data)

  return { query, drugList, paginationState }
}
