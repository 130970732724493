import classNames from 'classnames'
import { useSetAtom } from 'jotai'
import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Waypoint from 'react-waypoint'
import { formatFr, DATE_FORMAT_FRONT_SHORT } from '../../../../misc/date.utilities'
import { getFileKind } from '../../../../misc/files.utilities'
import { hasDocOrFile } from '../../../../misc/medicalEvent.utilities'
import { getDocOrFileAnchor } from '../../../../misc/navTrans.utilities'
import {
  MedicalEventContentTypeIcon,
  MedicalEventDocumentType,
} from '../../../../model/MedicalEvent'
import { UrlSearchParams } from '../../../../model/Navigation'
import {
  ScrollHighlightStatus,
  scrollHighlightStatusAtom,
  forceItemFocusAtom,
} from '../../../../state/navTrans'
import { Loader, IconButton } from '../../../shared'
import { PatientTransversalNavigationMedicalEventDocumentListItem as MedicalEventDocumentItem } from './PatientTransversalNavigationMedicalEventDocumentListItem'
import {
  MedicalEventListProps,
  WAYPOINT_DETECTION_ZONE,
} from './PatientTransversalNavigationMedicalEventList.model'
import styles from './PatientTransversalNavigationMedicalEventList.module.scss'

const cx = classNames.bind(styles)

export const PatientTransversalNavigationMedicalEventList: FunctionComponent<
  MedicalEventListProps
> = ({
  medicalEventList,
  isLoading,
  hasNextPage,
  currentPage,
  eventId,
  focusedDocOrFileId,
  selectedDocOrFileId,
  isFetchingNextPage,
  filters,
  fetchNextMedicalEvents,
}: MedicalEventListProps) => {
  const setScrollHighlightStatus = useSetAtom(scrollHighlightStatusAtom)
  const setForceItemFocus = useSetAtom(forceItemFocusAtom)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const fileAndDocTypeFilterParams = searchParams.get(UrlSearchParams.fileAndDocumentType)
  const selectedMedicalEvent = useRef<HTMLDivElement>(null)
  const medicalEventContainer = useRef<HTMLDivElement>(null)
  const isFirstRender = useRef(true)

  const moveEventToTopOfList = useCallback(() => {
    if (selectedMedicalEvent.current && medicalEventContainer.current) {
      const selectedEventTop = selectedMedicalEvent.current.getBoundingClientRect().top
      const eventsContainerTop = medicalEventContainer.current.getBoundingClientRect().top
      const offset = selectedEventTop - eventsContainerTop
      medicalEventContainer.current.scrollTo({
        behavior: 'smooth',
        top: medicalEventContainer.current.scrollTop + offset,
      })
    }
  }, [])

  useEffect(() => {
    if (
      isFirstRender.current &&
      medicalEventContainer.current &&
      selectedMedicalEvent.current &&
      !isLoading &&
      !isFetchingNextPage
    ) {
      isFirstRender.current = false
      moveEventToTopOfList()
    }
  }, [focusedDocOrFileId, isFetchingNextPage, isLoading, moveEventToTopOfList])

  useEffect(() => {
    if (
      !isFirstRender.current &&
      medicalEventContainer.current &&
      selectedMedicalEvent.current &&
      !isLoading &&
      !isFetchingNextPage
    ) {
      moveEventToTopOfList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedDocOrFileId, isLoading, moveEventToTopOfList, filters])

  const handleClick = useCallback(
    (medicalEventId: number, type: MedicalEventDocumentType) => (docOrFileId: number) => {
      const selectedFileOrDocAnchor = getDocOrFileAnchor(docOrFileId, type)
      const docTypesParams = `${UrlSearchParams.fileAndDocumentType}=${fileAndDocTypeFilterParams}`
      const urlRest = fileAndDocTypeFilterParams
        ? `&${docTypesParams}${selectedFileOrDocAnchor}`
        : `${selectedFileOrDocAnchor}`
      setScrollHighlightStatus(ScrollHighlightStatus.DISABLED)

      if (docOrFileId !== selectedDocOrFileId) {
        navigate(`?eventId=${medicalEventId}${urlRest}`, { replace: true })
      } else {
        setForceItemFocus(true)
      }
    },
    [
      fileAndDocTypeFilterParams,
      setScrollHighlightStatus,
      selectedDocOrFileId,
      navigate,
      setForceItemFocus,
    ],
  )

  const handleInfiniteScroll = useCallback(() => fetchNextMedicalEvents(), [fetchNextMedicalEvents])

  if (isLoading) return <Loader />

  return (
    <div className={styles.medicalEventsContainer} ref={medicalEventContainer}>
      {medicalEventList.map((medicalEvent) => {
        const { id, title, pinned, date, files, documents } = medicalEvent
        const withoutDocuments = !files.length && !documents.length

        return (
          <div
            key={id}
            className={cx(styles.medicalEventContainer, {
              [styles.withDocs]: hasDocOrFile(medicalEvent),
            })}
            ref={eventId === id.toString() ? selectedMedicalEvent : null}
          >
            <div className={styles.medicalEventHeaderContainer}>
              <div className={styles.medicalEventDescription}>
                {pinned && <IconButton icon="pin" theme="transparent-dark" noBorder />}
                <h4>{title}</h4>
                <span className={styles.medicalEventDate}>
                  {formatFr(new Date(date), DATE_FORMAT_FRONT_SHORT)}
                </span>
              </div>
            </div>
            {withoutDocuments ? (
              <p className={styles.withoutDocs}>Aucun document n'est rattaché à cet événement</p>
            ) : (
              <div className={styles.documentItems}>
                {documents.map((doc) => (
                  <MedicalEventDocumentItem
                    key={doc.id}
                    id={doc.id}
                    title={doc.title}
                    focusedDocOrFileId={focusedDocOrFileId}
                    icon={MedicalEventContentTypeIcon[doc.category]}
                    onClick={handleClick(id, MedicalEventDocumentType.FW_DOCUMENT)}
                  />
                ))}
                {files.map((file) => (
                  <MedicalEventDocumentItem
                    key={file.id}
                    id={file.id}
                    title={file.visibleName}
                    focusedDocOrFileId={focusedDocOrFileId}
                    icon={MedicalEventContentTypeIcon[getFileKind(file.extension)]}
                    onClick={handleClick(id, MedicalEventDocumentType.FILE)}
                  />
                ))}
              </div>
            )}
          </div>
        )
      })}
      {hasNextPage && (
        <Waypoint
          key={currentPage}
          bottomOffset={WAYPOINT_DETECTION_ZONE}
          onEnter={handleInfiniteScroll}
        />
      )}
    </div>
  )
}
