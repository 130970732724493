import { isDate } from 'date-fns'
import { DATE_FORMAT_FRONT_SHORT, formatFr } from './date.utilities'
import {
  HealthDataCode,
  HealthData,
  HealthDataFormat,
  HealthDataHistoryEntry,
  BLOOD_TYPE_OPTIONS,
  HealthDataType,
  LIVER_FAILURE_OPTIONS,
  CONTRACEPTION_OPTIONS,
  PREGNANCY_TYPE_OPTIONS,
  TWIN_PREGNANCY_TYPE_OPTIONS,
  HealthDataField,
  RENAL_FAILURE_OPTIONS,
  HEART_FAILURE_OPTIONS,
} from '../model/HealthData'
import { getFullName } from './user.utilities'
import { getLatestHealthDatas, findOngoingPregnancy } from '@follow/cdk'

export { getLatestHealthDatas, findOngoingPregnancy }

export const findLiverFailureOption = (selected: string) =>
  LIVER_FAILURE_OPTIONS.find(({ value }) => value === selected)

export const findRenalFailureOption = (selected: string) =>
  RENAL_FAILURE_OPTIONS.find(({ value }) => value === selected)

export const findHeartFailureOption = (selected: string) =>
  HEART_FAILURE_OPTIONS.find(({ value }) => value === selected)

export const computeTypeLabel = (type: HealthDataType) =>
  type.unit ? `${type.name} (${type.unit})` : type.name

export const findBloodTypeOption = (bloodType: string) =>
  BLOOD_TYPE_OPTIONS.find(({ value }) => value === bloodType)

export const findContraceptionOption = (contraception: string) =>
  CONTRACEPTION_OPTIONS.find(({ value }) => value === contraception)

export const findPregnancyTypeOption = (contraception: string) =>
  PREGNANCY_TYPE_OPTIONS.find(({ value }) => value === contraception)

export const findTwinPregnancyTypeOption = (contraception: string) =>
  TWIN_PREGNANCY_TYPE_OPTIONS.find(({ value }) => value === contraception)

export const stringifyHealthDataValue = (data: HealthData): string => {
  const { value } = data.value
  const { type } = data.moduleDataType
  if (typeof value === 'boolean') {
    return value ? 'Oui' : 'Non'
  }
  if (typeof value === 'number' && type === 'FLOAT') {
    return convertFloatToDisplayFormat(value)
  }
  if (type === 'DATETIME' && isDate(value)) {
    return formatFr(value as Date, DATE_FORMAT_FRONT_SHORT)
  }
  return `${value}`
}

export const stringifyHealthDataValueWithUnit = (data: HealthData) => {
  const { unit } = data.moduleDataType
  return `${stringifyHealthDataValue(data)}${unit ? ` ${unit}` : ''}`
}

export const convertFloatToDisplayFormat = (input: number | string, strict: boolean = true) => {
  let normalizedInput: string = typeof input === 'number' ? input.toString() : input
  normalizedInput = normalizedInput.replace(',', '.').replace(/\s+/g, '')

  // If the mode is strict or the input is a full float
  if (strict || /^-?\d+(\.\d+)?$/.test(normalizedInput)) {
    return parseFloat(normalizedInput).toLocaleString()
  }

  // If the mode is not strict and the input is an incomplete float
  if (/^-?\d*[.]$/.test(normalizedInput)) {
    return normalizedInput
  }

  // Try to convert in float
  let floatValue = parseFloat(normalizedInput)
  if (isNaN(floatValue)) {
    return ''
  }

  return floatValue.toLocaleString()
}

export const computeFormatedHealthDateHistory = (
  healthData: HealthData[],
  healthdataType: HealthDataCode,
) => {
  return healthData.reduce<HealthDataHistoryEntry[]>((accumulator, item) => {
    if (item.moduleDataType.code === healthdataType) {
      return [
        ...accumulator,
        {
          value: item.value.value,
          stringValue: stringifyHealthDataValue(item),
          date: item.createdAt ? formatFr(new Date(item.createdAt), DATE_FORMAT_FRONT_SHORT) : '',
          author: getFullName(item.creator),
        },
      ]
    } else {
      return accumulator
    }
  }, [])
}

export const computeHealthDataHistoryDomain = (
  formatedHistory: HealthDataHistoryEntry[],
  healthDataType: HealthDataFormat,
) => {
  if (formatedHistory && (healthDataType === 'INTEGER' || healthDataType === 'FLOAT')) {
    const values = formatedHistory
      .filter((entry): entry is HealthDataHistoryEntry<number> => typeof entry.value === 'number')
      .map(({ value }) => value)

    const min = Math.floor(Math.min(...values))
    const max = Math.ceil(Math.max(...values))
    return [min, max]
  }
  return undefined
}

export const serializeModuleDataForm = (fields: Array<HealthDataField>) => {
  const data = fields.reduce<Array<Partial<HealthData>>>((acc, item) => {
    if (item.type && item.value !== undefined && item.value !== null) {
      if (item.type.type === 'FLOAT' && typeof item.value === 'string') {
        return [
          ...acc,
          {
            moduleDataType: item.type,
            module: item.type.module,
            // Display Float value with "," to client side and send Float value with "." to back
            value: { value: parseFloat(item.value.replaceAll(',', '.')) },
            parentId: item.parentId,
          },
        ]
      }
      return [
        ...acc,
        {
          moduleDataType: item.type,
          module: item.type.module,
          value: { value: item.value },
          parentId: item.parentId,
        },
      ]
    }
    return acc
  }, [])

  return data
}
