import { FC, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { PatientPageNavBar } from './PatientPageNavBar'
import styles from './PatientLayout.module.scss'
import { PatientInfos } from '../../patient'
import { FullLoader, Icon } from '../../shared'
import { MedicalEventNavBar } from './MedicalEventNavBar'
import classNames from 'classnames/bind'
import { useLocalStorage } from 'react-use'
import { isDefined } from '../../../misc/functions.utilities'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import { PatientLayoutContext } from './PatientLayout.context'
import { useCurrentPatient, useWidthObserver } from '../../../hooks/utils'
import { breakMedium } from '../../../misc/responsive'

const cx = classNames.bind(styles)

export const PatientLayout: FC = () => {
  const { currentPatient: patient } = useCurrentPatient()
  const { medicalEventId } = useParams<'medicalEventId'>()
  const [openInformations, setOpenInformations] = useLocalStorage<boolean>(
    'ui.medicalEvent.userInformationsPanel.open',
    false,
  )
  const [ignoredAlertCodes, setIgnoredAlertCodes] = useState<string[]>([])
  const isLowerThanBreakpoint = useWidthObserver(breakMedium)
  const foldable = isDefined(medicalEventId)
  const hidePanel = !openInformations && foldable
  const disableAnimation = !patient || isLowerThanBreakpoint

  return (
    <PatientLayoutContext.Provider value={{ ignoredAlertCodes, setIgnoredAlertCodes }}>
      {medicalEventId ? <MedicalEventNavBar /> : <PatientPageNavBar />}

      <div className={styles.container}>
        <div
          className={cx(
            styles.patientInfo,
            {
              foldable,
              disableAnimation,
              medicalEventContext: !!medicalEventId,
            },
            hidePanel ? styles.hide : styles.show,
          )}
        >
          {patient ? (
            <PatientInfos patient={patient} key={patient.id} />
          ) : (
            <div className={styles.loading}>
              <FullLoader />
            </div>
          )}
          {foldable && (
            <div
              className={styles.grip}
              onClick={() => setOpenInformations(!openInformations)}
              title={!openInformations ? 'Fermer le panneau' : 'Ouvrir le panneau'}
            >
              <Icon
                icon="caret"
                size="micro"
                color={getV3ColorCssVariable('shades', 'white')}
                rotate={!openInformations ? 90 : -90}
              />
            </div>
          )}
        </div>
        <div
          className={cx(
            styles.patientInfoDummy,
            {
              foldable,
              disableAnimation,
            },
            hidePanel ? styles.shrink : styles.expand,
          )}
        ></div>
        <div className={cx(styles.content)}>
          <Outlet />
        </div>
      </div>
    </PatientLayoutContext.Provider>
  )
}
