import { FontFamily } from '@/model'
import { EditorConfig } from '@/model/Config'
import { DEFAULT_FONT_FAMILY } from '@/model/Font'
import { getCommonPlugins, getVariablePlugins } from '@/plugins'
import { EditorOptions, useEditor } from '@tiptap/react'
import { useMemo } from 'react'
import { useEditorConfig } from './useEditorConfig.hook'

type UseElfeEditorProps = Partial<EditorConfig> & {
  placeholder?: string
  editorType: 'simple' | 'variable'
  defaultFontFamily?: FontFamily
}

type TiptapProps = Partial<Omit<EditorOptions, 'extensions'>>

export const useElfeEditor = (
  { defaultFontFamily, editorType, placeholder, ...inputConfig }: UseElfeEditorProps,
  tiptapProps?: TiptapProps,
) => {
  const config = useEditorConfig(inputConfig)
  const { tools } = config

  const extensions = useMemo(() => {
    if (editorType === 'variable') {
      return getVariablePlugins({ defaultFontFamily, tools, placeholder })
    }
    return getCommonPlugins({ defaultFontFamily, tools, placeholder })
  }, [defaultFontFamily, editorType, placeholder, tools])

  const editor = useEditor({
    ...tiptapProps,
    extensions,
    editorProps: {
      ...tiptapProps?.editorProps,
      attributes: {
        ...tiptapProps?.editorProps?.attributes,
        class: 'with-ui',
        style: `--elfe-default-font-family: ${defaultFontFamily ?? DEFAULT_FONT_FAMILY}`,
      },
    },
  })

  return { editor, config }
}
