import { FeaturesType } from '@follow/cdk'
import { useQuery } from '@tanstack/react-query'
import { getAddresses } from '../../../data/mail'
import { MAIL_HEADER_NO_REDIRECT } from '../../../model/Mail'
import { useUserEnabledFeature } from '../../utils/user'
import { mailWebserviceKeys } from './webservice/webservice.keys'

const MAIL_CONNECTION_LOOP_DELAY = 1000 * 60 * 15

/* 
  En webservice le token meurt assez vite, forçant l'utilisateur à se reconnecter
  Pour ça, on maintient artificiellement le token en vie en l'utilisant toutes les 15 min
  La donnée récupérée n'a pas vocation à être utilisée, c'est pour cela qu'on ne fait pas vraiment de gestion d'erreur 
*/
export const useMaintainMailAuth = () => {
  const isMssSmtpActive = useUserEnabledFeature(FeaturesType.mssSmtp)

  const query = useQuery({
    queryKey: mailWebserviceKeys.maintainAuth,
    queryFn: async () => {
      const { ok, data } = await getAddresses({
        [MAIL_HEADER_NO_REDIRECT]: 'true',
      })

      if (!ok) {
        throw new Error(
          `Maintain MSS Webservice Auth Token request failed : ${JSON.stringify(data)}`,
        )
      }

      return data
    },
    enabled: !isMssSmtpActive,
    gcTime: Infinity,
    staleTime: Infinity,
    refetchInterval: (query) => (query.state.error ? 0 : MAIL_CONNECTION_LOOP_DELAY),
    refetchIntervalInBackground: true,
    retry: 0,
  })

  return { query }
}
