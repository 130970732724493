import { FC } from 'react'
import { ConsentTextBlockProps } from './ConsentTextBlock.model'
import { FormBlock } from '../../../shared'
import { EditorTool, SimpleEditor, useElfeEditor } from '@follow/elfe'

const editorTools: Array<EditorTool> = ['marks', 'align', 'list', 'undo']

export const ConsentTextBlock: FC<ConsentTextBlockProps> = ({
  consentText,
  onChangeConsentText,
}) => {
  const { editor, config } = useElfeEditor(
    {
      editorType: 'simple',
      tools: editorTools,
      themes: ['rounded', 'compact'],
      placeholder: 'Texte de consentement...',
    },
    {
      content: consentText,
      onUpdate: ({ editor }) => onChangeConsentText(editor.getHTML()),
    },
  )

  return (
    <FormBlock label="Texte de consentement" icon="documentText">
      <div className="overflow-auto h-103">
        <SimpleEditor editor={editor} config={config} />
      </div>
    </FormBlock>
  )
}
