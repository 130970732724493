import React, { ReactNode } from 'react'
import Waypoint from 'react-waypoint'
import { singleItemPageLimit } from '../../../constants'
import { ListItem } from './ListItem'
import { EmptyPlaceHolder } from '../EmptyPlaceHolder'
import styles from './List.module.scss'
import { ListActions } from './ListActions'
import { ListHeader } from './ListHeader'
import { ListItemType, AbstractList, ListProps } from './List.model'
import classNames from 'classnames/bind'
import { SharingConfigAccessLevel } from '../../../model/Team'
import { hasPermissionAction } from '../../../misc/entity.utilities'
import { ListExtendedActions } from './ListExtendedActions'
import { CheckboxState } from '../inputs'
import { FullLoader, Loader } from '../loading'

const cx = classNames.bind(styles)
const INITIAL_PAGE = 0

const computGlobalCheckboxState = (selectedItems: number, itemCount: number) => {
  if (selectedItems === itemCount) {
    return CheckboxState.CHECKED
  }
  if (selectedItems === 0) {
    return CheckboxState.UNCHECKED
  }
  return CheckboxState.INDETERMINATE
}

export class List<T extends ListItemType> extends AbstractList<T> {
  public state = {
    selected: [] as Array<T>,
    currentPage: INITIAL_PAGE,
  }

  private scrollabeRef = React.createRef<HTMLDivElement>()

  // Initialisation de la liste quand utilisée sans ref
  public componentDidMount() {
    if (this.props.noRef) {
      this.updateList(true)
    }
    if (this.props.currentPage) {
      this.setCurrentPage()
    }
  }

  public componentDidUpdate(prevProps: Readonly<ListProps<T>>) {
    if (this.props.currentPage !== prevProps.currentPage) {
      this.setCurrentPage()
    }
  }

  public setCurrentPage() {
    this.setState({ currentPage: this.props.currentPage ?? INITIAL_PAGE })
  }

  public render() {
    const {
      items = [],
      renderItem,
      renderCustomItemActions,
      renderHeaderActions,
      renderCustomActionButtons,
      renderListFooter,
      loading = true,
      renderEmptyPlaceHolder,
      pageCount,
      itemCount,
      onPrimaryAction,
      onEditItem,
      onDeleteItem,
      onMultiSelectionChange,
      selectedCount,
      addItemLabel,
      onAddItem,
      onAddSelectedItems,
      renderActions,
      renderExtendedActions,
      highlightedId,
      intel,
      extendedActionsOpen,
      toggleExtendedActions,
      testId,
      fullPageMode = true,
    } = this.props

    let contentPlaceholder: ReactNode | null = null
    if ((loading && this.state.currentPage === 1) || !this.state.currentPage) {
      contentPlaceholder = <FullLoader />
    } else if (items.length === 0) {
      contentPlaceholder = renderEmptyPlaceHolder || <EmptyPlaceHolder />
    }
    const isMultiSelect = !!onMultiSelectionChange
    return (
      <div
        className={cx(styles.container, { fullPageMode })}
        data-test-id={testId ? `list-${testId}` : undefined}
      >
        <div className={cx(styles.actionsHeader, { fullPageMode })}>
          {(onAddItem || renderActions) && (
            <ListActions
              onAddItem={onAddItem}
              addItemLabel={addItemLabel}
              onToggleExtendedActions={renderExtendedActions && toggleExtendedActions}
              extendedActionsOpen={extendedActionsOpen}
              renderCustomActionButtons={renderCustomActionButtons}
              testId={testId}
            >
              {renderActions && renderActions()}
            </ListActions>
          )}
          {renderExtendedActions && (
            <ListExtendedActions open={extendedActionsOpen}>
              {renderExtendedActions && renderExtendedActions()}
            </ListExtendedActions>
          )}
          <ListHeader
            itemCount={itemCount}
            selected={this.state.selected.length}
            onSelectAll={this.toggleSelectAll}
            showMultiSelectCheckbox={isMultiSelect}
            multiSelectChecked={computGlobalCheckboxState(
              this.state.selected.length,
              itemCount ?? 0,
            )}
            onAddSelectedItems={
              onAddSelectedItems
                ? () => {
                    this.setSelected([])
                    if (onAddSelectedItems) {
                      onAddSelectedItems()
                    }
                  }
                : undefined
            }
            selectedCount={selectedCount}
            renderHeaderActions={renderHeaderActions}
          />
        </div>
        <div
          className={styles.listContent}
          ref={this.scrollabeRef}
          data-test-id={testId ? `list-${testId}-content` : undefined}
        >
          {!contentPlaceholder && (
            <>
              {items.map((item, index) => (
                <ListItem
                  key={item.id}
                  testId={testId}
                  itemId={item.id}
                  onSelect={isMultiSelect ? () => this.setSelect(item) : undefined}
                  selected={this.state.selected.some(({ id }) => item.id === id)}
                  focused={!isMultiSelect && item.id === highlightedId}
                  onPrimaryAction={onPrimaryAction && (() => onPrimaryAction(item))}
                  onEdit={
                    onEditItem && hasPermissionAction(SharingConfigAccessLevel.write, item)
                      ? () => onEditItem(item)
                      : undefined
                  }
                  onDelete={
                    onDeleteItem && hasPermissionAction(SharingConfigAccessLevel.delete, item)
                      ? () => onDeleteItem(item)
                      : undefined
                  }
                  customActionsNode={renderCustomItemActions && renderCustomItemActions(item)}
                >
                  {renderItem(item)}
                  {/* On affiche le waypoint à la moitié de la fin de la dernière page affichée, tant qu'il y a des données à charger */}
                  {items.length - singleItemPageLimit / 2 === index &&
                    this.state.currentPage < (pageCount || 0) && (
                      <Waypoint onEnter={() => this.updateList()} />
                    )}
                </ListItem>
              ))}
              {renderListFooter && renderListFooter}
            </>
          )}
          {intel && (
            <ListItem>
              <span className={styles.intel}>{intel}</span>
            </ListItem>
          )}
          {contentPlaceholder}
          {loading && this.state.currentPage !== 1 && (
            <div className={styles.loader}>
              <Loader height={70} width={70} />
            </div>
          )}
        </div>
      </div>
    )
  }

  public updateList(reload: boolean = false) {
    const page = reload ? 1 : this.state.currentPage + 1
    this.setState({ currentPage: page })
    this.props.onUpdateList(page)
  }

  private toggleSelectAll = () => {
    const selection = this.state.selected.length === 0 ? this.props.items : []
    this.setSelected(selection)
  }

  private setSelect = (selectedItem: T) => {
    const selection = this.state.selected.some((item) => item.id === selectedItem.id)
      ? [...this.state.selected.filter((item) => item.id !== selectedItem.id)]
      : [...this.state.selected, selectedItem]

    this.setSelected(selection)
  }

  private setSelected = (selection: Array<T>) => {
    this.setState({ selected: selection })
    if (this.props.onMultiSelectionChange) {
      this.props.onMultiSelectionChange(selection)
    }
  }
}
