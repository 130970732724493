import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { createPatientTreatment } from '../../../data/patientTreatments/createPatientTreatment'
import { UserFriendlyError } from '../../../model/Error'
import { TreamentFormResource, Treatment } from '../../../model/Treatment'
import { getVariableData } from '../../../store/renderer'
import { patientTreatmentsKeys } from './patientTreatments.keys'

interface UseCreatePatientTreatmentVariables {
  patientId: number
  treatmentForm: TreamentFormResource
}

export const useCreatePatientTreatment = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation<Treatment, Error, UseCreatePatientTreatmentVariables>({
    mutationFn: async ({ patientId, treatmentForm }) => {
      const { data, ok } = await createPatientTreatment(patientId, treatmentForm)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Erreur lors de la création du traitement')
      }

      return data
    },
    onSuccess: (_, { patientId }) => {
      queryClient.invalidateQueries({ queryKey: patientTreatmentsKeys.lists(patientId) })
      dispatch(getVariableData())
    },
  })
}
