import { Formik } from 'formik'
import { FC, useCallback, useMemo, useState } from 'react'
import { cpsPincodeRegex } from '../../../../../misc/regex'
import { Input } from '../../../inputs'
import { Loader } from '../../../loading'
import { ValidationModal } from '../../ValidationModal/ValidationModal.component'
import { CpsPincodeModalProps } from './CpsPincodeModal.model'
import styles from './CpsPincodeModal.module.scss'

const incompletePincodeRegex = /^\d{0,4}$/

export const CpsPincodeModal: FC<CpsPincodeModalProps> = ({ isUnmounting, onSubmit }) => {
  const [pincode, setPincode] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const isPincodeValid = useMemo(() => {
    if (pincode.length > 3) {
      return cpsPincodeRegex.test(pincode)
    }
    return undefined
  }, [pincode])

  const handleSubmit = useCallback(() => {
    if (isPincodeValid) {
      setIsLoading(true)
      onSubmit?.(pincode)
    }
  }, [isPincodeValid, onSubmit, pincode])

  const handleClose = useCallback(
    () => onSubmit?.(null),

    [onSubmit],
  )

  const handleInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    if (incompletePincodeRegex.test(value)) {
      setPincode(value)
    }
  }, [])

  return (
    <Formik initialValues={{ cpsPincode: '' }} onSubmit={handleSubmit}>
      {(props) => (
        <ValidationModal
          isUnmounting={isUnmounting}
          title="Carte CPS : Authentification requise"
          onSubmit={handleSubmit}
          disableSubmit={!isPincodeValid || isLoading}
          onClose={handleClose}
          confirmTheme="primary"
        >
          <form onSubmit={props.handleSubmit}>
            <div className={styles.container}>
              {isLoading ? (
                <Loader className={styles['loader']} height={100} />
              ) : (
                <Input
                  autofocus
                  autocomplete="one-time-code"
                  name="cpsPincode"
                  colorPreset="light"
                  value={pincode}
                  onChange={handleInput}
                  label="Code PIN (4 chiffres)"
                  valid={isPincodeValid}
                  error={isPincodeValid === false ? 'Format incorrect' : undefined}
                  type="password"
                />
              )}
            </div>
          </form>
        </ValidationModal>
      )}
    </Formik>
  )
}
