import { EditorTool } from '@follow/elfe'

export const surveyConfigurationEditorTools: Array<EditorTool> = ['marks', 'align', 'list', 'undo']

interface BaseSurvey {
  content: SurveyBranchConfig[]
  active: boolean
  finish: string
  name: string
  tagIds: string[]
}

export interface Survey extends BaseSurvey {
  id: number
  pathologyId: number
}

export interface SurveyApi extends BaseSurvey {
  pathologyId: number
}

export interface SurveyBranchConfig {
  uuid: string
  level: number
  questions: SurveyBranchItem[]
}

export type SurveyBranchItem = SurveyScore | SurveyQuestionConfig

export interface SurveyScore {
  type: SurveyBranchItemType.Score
  uuid: string
  id: number
  excludedFields: number[]
  fields: SurveyFieldCustomLabel[]
  beforeText: string
}

export interface SurveyQuestionConfig {
  type: SurveyBranchItemType.Question
  uuid: string
  label: string
  answers: SurveyQuestionAnswer[]
}

export interface SurveyFieldCustomLabel {
  id: number
  value: string
}

export interface SurveyQuestionAnswer {
  label: string
  branchUuid: string
}

export enum SurveyBranchItemType {
  Score = 'score',
  Question = 'question',
}

export interface SurveyDuplicationPayload {
  /**
   * survey id to duplicate
   */
  surveyId: number

  /**
   * user id to duplicate to
   */
  userId: string
}
