/**
 * Converti une chaine de caractère au format PascalCase
 * @param value valeur à convertir
 * @param delimiters liste des délimiteurs apres lesquels le caractère doit etre mis en majuscule
 */
export function pascalize(value: string, delimiters = [' ', '-', '_']) {
  return [...value.toLowerCase()]
    .map((char, index, chars) =>
      index === 0 || (index > 0 && delimiters.includes(chars[index - 1]))
        ? char.toLocaleUpperCase()
        : char,
    )
    .join('')
}

export const camelToSeparator = (text: string, separator: string) =>
  text
    .split(/(?=[A-Z])/)
    .join(separator)
    .toLowerCase()
