import { AnswerValue } from '../../../model/AnswerValue'

import { DocumentAnswerValuesDomainActionsTypes } from './documentAnswerValues.model'
import { IdentityIdentifier } from '../../restux/restux.model'

export const documentAnswerValuesDomainActions = {
  createDocumentAnswerValue: () => ({
    type: DocumentAnswerValuesDomainActionsTypes.CREATE,
  }),
  updateDocumentAnswerValue: (documentAnswerValueId: number, updates: Partial<AnswerValue>) => ({
    type: DocumentAnswerValuesDomainActionsTypes.UDPATE,
    documentAnswerValueId,
    updates,
  }),
  deleteDocumentAnswerValue: (documentAnswerValueId: number) => ({
    type: DocumentAnswerValuesDomainActionsTypes.DELETE,
    documentAnswerValueId,
  }),
  clearDocumentAnswerValues: () => ({
    type: DocumentAnswerValuesDomainActionsTypes.CLEAR,
  }),
  fetchDocumentAnswerValues: (documentId: IdentityIdentifier) => ({
    type: DocumentAnswerValuesDomainActionsTypes.FETCH,
    documentId,
  }),
  fetchDocumentQuestionnaireScore: (documentId: IdentityIdentifier) => ({
    type: DocumentAnswerValuesDomainActionsTypes.FETCH_SCORE,
    documentId,
  }),
  onFetchSuccessDocumentAnswerValues: (
    documentId: IdentityIdentifier,
    documentAnswerValues: ReadonlyArray<AnswerValue>,
  ) => ({
    type: DocumentAnswerValuesDomainActionsTypes.FETCH_SUCCESS,
    documentId,
    documentAnswerValues,
  }),
  setAnswerValueError: (isError: boolean) => ({
    type: DocumentAnswerValuesDomainActionsTypes.SET_ERROR,
    isError,
  }),
}
