import { FunctionComponent, useCallback, useState } from 'react'
import { AccordionItem, SelectInput } from '../../../../shared'

import { DocumentLayoutProps } from './DocumentLayoutForm.model'
import styles from './DocumentLayoutForm.module.scss'
import { useGetAllDocumentLayouts } from '../../../../../hooks/queries/documentLayouts'

const NO_LAYOUT_ID = -1

export const DocumentLayoutForm: FunctionComponent<DocumentLayoutProps> = ({
  documentInstance,
  isEditable = true,
  updateDocumentInstance,
}) => {
  const { documentLayoutList } = useGetAllDocumentLayouts()

  const [showLayoutList, setShowLayoutList] = useState<boolean>(false)

  const toggleListAccordion = useCallback(() => {
    setShowLayoutList(!showLayoutList)
  }, [showLayoutList])

  const [selectedLayoutId, setSelectedLayoutId] = useState(
    documentInstance.documentLayoutId || NO_LAYOUT_ID,
  )

  const handleLayoutChange = (selectedValue) => {
    updateDocumentInstance(documentInstance.id, {
      documentLayoutId: selectedValue === NO_LAYOUT_ID ? null : selectedValue,
      type: documentInstance.type,
    })
    setSelectedLayoutId(selectedValue)
  }

  const documentLayoutSelectValues = [
    { value: NO_LAYOUT_ID, label: 'Aucun' },
    ...documentLayoutList.map(({ id, title }) => ({ value: id, label: title })),
  ]

  const selectedLayout = documentLayoutSelectValues.find(
    (documentLayoutSelectValue) => documentLayoutSelectValue.value === selectedLayoutId,
  )
  return (
    <AccordionItem
      open={showLayoutList}
      onOpenClose={toggleListAccordion}
      overflow={true}
      renderLabel={() => (
        <>
          <span className={styles.accordionTitle}>En-tête/Pied de page</span>
          <span className={styles.accordionSubtitle}>
            {selectedLayout ? selectedLayout.label : 'Aucun'}
          </span>
        </>
      )}
      testId="list-layout-accordion-item"
    >
      <div className={styles.dropdown}>
        <SelectInput
          options={documentLayoutSelectValues}
          value={selectedLayout}
          onSelect={(selectedValue) =>
            handleLayoutChange(selectedValue ? selectedValue.value : NO_LAYOUT_ID)
          }
          disabled={!isEditable}
        />
      </div>
    </AccordionItem>
  )
}
