import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { ServerSentNotification } from '../../../../model/Notification'
import { putNotification } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { ServerSentNotificationMutationPayload } from '../../../../data/admin/notifications/notification.model'
import { hideBottomPanel } from '../../../../store/ui/bottomPanel'
import { adminNotifKeys } from './adminNotifications.keys'
import { checkIfPagesContainItem } from '../../../../misc/query.utilities'

interface NotificationMutateVariables {
  notificationId: string
  variables: ServerSentNotificationMutationPayload
}

export const usePutNotification = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()
  const closeBottomPanel = useActionDispatch(hideBottomPanel)

  const queryReturn = useMutation({
    mutationFn: async ({ notificationId, variables }: NotificationMutateVariables) => {
      const { ok, data } = await putNotification(notificationId, variables)
      if (!ok || !data) {
        throw new UserFriendlyError('error', 'La mise à jour de la notification a échoué')
      }
      return data
    },

    onSuccess: (notification) => {
      valid('Notification mise à jour')
      closeBottomPanel()
      queryClient.refetchQueries({
        queryKey: adminNotifKeys.all,
        predicate: (query) =>
          checkIfPagesContainItem<ServerSentNotification>(query.state.data, notification.id),
      })
    },
  })

  return queryReturn
}
