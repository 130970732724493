import React, { FunctionComponent } from 'react'
import styles from './FullLoader.module.scss'
import { InfiniteLoader } from '../InfiniteLoader'
import { FullLoaderProps } from './FullLoader.model'

export const FullLoader: FunctionComponent<FullLoaderProps> = ({ message }) => (
  <div className={styles.container}>
    <div className={styles.loaderContainer}>
      <InfiniteLoader loading />
    </div>
    {message && <span className="text-base text-shades-3 mt-3">{message}</span>}
  </div>
)
