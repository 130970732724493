import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getAtcClass } from '../../../data/drugs'
import { UserFriendlyError } from '../../../model/Error'
import { drugsKeys } from './drugs.keys'

interface UseGetAtcClassParams {
  id: number
  enabled?: boolean
}

export const useGetAtcClass = ({ id, enabled }: UseGetAtcClassParams) => {
  const queryKey = useMemo(() => drugsKeys.atcClassDetail(id), [id])

  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { data, ok } = await getAtcClass(id, signal)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la récupération des informations de la classe ATC',
        )
      }

      return data
    },
    enabled,
  })

  return { query }
}
