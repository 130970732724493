import { DocumentCategoryKeys } from '@follow/cdk'
import { HealthcareSetting } from '../../../model/Efficience'

/* 
  loading => L'iframe a seulement été montée
  need_auth => Il faudra fournir un CPS pincode avant d'envoyer une requête
  auth_in_progress => L'iframe de CPS pincode a été ouverte et le processus d'auth de l'iframe est en cours
  ready => L'iframe est prête à recevoir des requêtes
*/
export type DmpIframeStatus = 'loading' | 'need_auth' | 'auth_in_progress' | 'ready'

export interface DmpRequest {
  uuid: string
  documentId?: number
  payload: string
}

export interface DmpState {
  healthcareSettings: HealthcareSetting[]
  isSending: boolean
  requests?: DmpRequest[]
  iframeStatus: DmpIframeStatus
}

export interface FileForDMP {
  id: number
  name: string
  base64: string
  format: string
  dmpDocumentId?: string | null
  version?: string | null
  createdAt?: string
  loincCode?: string
}

export const DmpDomainActionsTypes = {
  SET_REQUESTS: '@DMP/SET_REQUESTS',
  REMOVE_REQUEST: '@DMP/REMOVE_REQUEST',
  SEND_DOCUMENT: '@DMP/SEND_DOCUMENT',
  DELETE_DOCUMENT: '@DMP/DELETE_DOCUMENT',
  UPLOAD_DOCUMENT: '@DMP/UPLOAD_DOCUMENT',
  OPEN_DMP: '@DMP/OPEN_DMP',
  CLOSE_DMP_SESSION: '@DMP/CLOSE_DMP_SESSION',
  FETCH_HEALTHCARE_SETTINGS: '@DMP/FETCH_HEALTHCARE_SETTINGS',
  STORE_HEALTHCARE_SETTINGS: '@DMP/STORE_HEALTHCARE_SETTINGS',
  SEND_PDFS_TO_DMP: '@DMP/SEND_PDFS',
  SET_IFRAME_STATE: '@DMP/SET_IFRAME_STATE',
}

export enum DmpRequestTypes {
  SEND_DOCUMENT = 'SEND_DOCUMENT',
  SEND_DOCUMENT_PDF_FORM = 'SEND_DOCUMENT_PDF_FORM',
  SEND_FILES = 'SEND_FILES',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  DELETE_FILE = 'DELETE_FILE',
  PIN_CODE = 'PIN_CODE',
  OPEN_DMP = 'OPEN_DMP',
  CLOSE_DMP_SESSION = 'CLOSE_DMP_SESSION',
}

export const DocumentTypeCode: { [property in DocumentCategoryKeys]: string } = {
  [DocumentCategoryKeys.ConsultationNote]: '11369-6',
  [DocumentCategoryKeys.DischargeSummaryNote]: '11490-0',
  [DocumentCategoryKeys.PrivacyPolicyAcknowledgment]: '59284-0',
  [DocumentCategoryKeys.SurgicalOperationNote]: '34874-8',
  [DocumentCategoryKeys.ReferralNote]: '96349-6',
  [DocumentCategoryKeys.PrescriptionForMedication]: '57833-6',
  [DocumentCategoryKeys.BizonePrescriptionForMedication]: '57833-6',
  [DocumentCategoryKeys.Certificate]: 'CERT_DECL',
  [DocumentCategoryKeys.Quotation]: '11488-4',
  [DocumentCategoryKeys.Checkup]: '11488-4',
  [DocumentCategoryKeys.Other]: '11488-4',
  [DocumentCategoryKeys.PdfForm]: '11488-4',
  [DocumentCategoryKeys.ReceivedDocument]: '11488-4',
  [DocumentCategoryKeys.MedicalSummary]: 'SYNTH',
  [DocumentCategoryKeys.RequestForTelehealth]: '75496-0',
  [DocumentCategoryKeys.FacilityDischargeSummaryNote]: '11490-0',
  [DocumentCategoryKeys.PrescriptionForFootCare]: 'PRESC-PEDI',
  [DocumentCategoryKeys.PrescriptionForInfirmaryAct]: 'PRESC-INF',
  [DocumentCategoryKeys.PrescriptionForMedicalBiology]: 'PRESC-BIO',
  [DocumentCategoryKeys.PrescriptionForOrthoptics]: 'PRESC-ORTHOPTIE',
  [DocumentCategoryKeys.PrescriptionForPhysicalTherapy]: 'PRESC-KINE',
  [DocumentCategoryKeys.PrescriptionForSpeechTherapy]: 'PRESC-ORTHOPHO',
  [DocumentCategoryKeys.Survey]: '11488-4',
}
