import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getUser } from '../../../../data/admin'
import { isDefined } from '../../../../misc/functions.utilities'
import { userKeys } from './users.keys'
import { handleApiError } from '../../../../misc/api.utilities'
import { UserFriendlyError } from '../../../../model/Error'

export const useGetUser = (userUuid?: string) => {
  const queryKey = useMemo(() => userKeys.detail(userUuid), [userUuid])

  const queryReturn = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      if (!isDefined(userUuid)) return

      const apiResponse = await getUser(userUuid, signal)
      const { data, ok } = apiResponse

      if (!data || !ok) {
        handleApiError(apiResponse)
        throw new UserFriendlyError(
          'error',
          "Impossible de récupérer les données de l'utilisateur",
          `Utilisateur ${userUuid} inaccessible`,
        )
      }
      return data
    },
    enabled: isDefined(userUuid),
  })

  return queryReturn
}
