import { isDefined } from '@follow/cdk'
import { useMutation } from '@tanstack/react-query'
import { checkPatientIdentity } from '../../../../data/insi'
import { UserFriendlyError } from '../../../../model/Error'
import { InsiIdentityCheckPayload, InsiPatient } from '../../../../model/Insi'

interface UseInsiRestIdentityCheckVariables {
  patient?: InsiIdentityCheckPayload
}

export const useInsiRestIdentityCheck = () => {
  const mutation = useMutation<InsiPatient, Error, UseInsiRestIdentityCheckVariables>({
    mutationFn: async ({ patient }) => {
      if (!isDefined(patient)) {
        throw new UserFriendlyError(
          'error',
          `Impossible de vérifier l'identité du patient`,
          'Les informations fournies sont manquantes ou incomplètes',
        )
      }

      const { data, ok } = await checkPatientIdentity(patient)

      if (!ok || !data) {
        throw new UserFriendlyError(
          'error',
          "Échec de la validation de l'identité",
          'Une erreur est survenue',
        )
      }

      const { status, ...patientResponse } = data

      if (status !== 'FOUND') {
        throw new UserFriendlyError(
          'error',
          "Échec de la validation de l'indentité",
          "Les informations d'identité du patient n'ont pas été validées par le service INSi. (Code retour INSI: 01)",
          { id: 'INSI_REST_ERROR_FAIL' },
        )
      }

      return patientResponse
    },
  })

  return mutation
}
