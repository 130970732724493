import { useFormik } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ContactForm from '../../../../containers/Contact/ContactForm'
import {
  getDefaultValues,
  ContactFormFields,
  getInitialValues,
} from '../../../../containers/Contact/ContactForm/ContactForm.model'
import { ContactValidationSchema } from '../../../../containers/Contact/ContactForm/ContactForm.validator'
import {
  useCreateContact,
  useGetContact,
  useUpdateContact,
} from '../../../../hooks/queries/contact'
import { useActionDispatch } from '../../../../hooks/utils'
import { mapContactToRecipientContact } from '../../../../misc/contact.utilities'
import { Contact, RecipientContact } from '../../../../model/Contact'
import { FarteDocumentInstance } from '../../../../model/DocumentInstance'
import { documentInstancesActions } from '../../../../store/cache/documentInstances'
import { inUseMedicalEventDocumentSelector } from '../../../../store/ui/medicalEvents/medicalEventDocumentInstances'
import { Button } from '../../buttons'
import { FullLoader } from '../../loading'
import { BottomPanel } from '../BottomPanel'
import {
  BottomPanelContactsContext,
  ContactConfigureBottomPanelProps,
} from './ContactBottomPanel.model'
import styles from './ContactBottomPanel.module.scss'

export const ContactBottomPanel = ({
  isUnmounting,
  context,
  contactId,
  onRequestClose,
  onContactCreated,
  onContactDuplicated,
  patientId,
  readOnly = false,
}: ContactConfigureBottomPanelProps) => {
  const [isInCreatePrivateCopyMode, setIsInCreatePrivateCopyMode] = useState(false)

  const inUseDocumentInstance = useSelector(inUseMedicalEventDocumentSelector)
  const dispatchUpdateDocument = useActionDispatch(documentInstancesActions.actions.apiUpdateItem)

  const { mutate: createContact } = useCreateContact()
  const { mutate: updatePatientContact } = useUpdateContact()

  const {
    query: { data: contact, isLoading },
  } = useGetContact({ id: contactId, enabled: !!contactId })

  const title = useMemo(() => {
    if (contact) {
      return isInCreatePrivateCopyMode
        ? `${contact.title} ${contact.firstName} ${contact.familyName} (Privé)`
        : `${contact.title} ${contact.firstName} ${contact.familyName} (${
            contact.private ? 'Privé' : 'Public'
          })`
    } else {
      return "Création d'un correspondant"
    }
  }, [contact, isInCreatePrivateCopyMode])

  const disabled = useMemo(
    () => readOnly || (contact ? !contact.isEditable && !isInCreatePrivateCopyMode : false),
    [contact, isInCreatePrivateCopyMode, readOnly],
  )

  const editionMode = useMemo(
    () => contact && !isInCreatePrivateCopyMode,
    [contact, isInCreatePrivateCopyMode],
  )

  const initialValues = useMemo(
    () => (contact ? getInitialValues(contact) : getDefaultValues()),
    [contact],
  )

  const updateDocumentContacts = useCallback(
    (inUseDocumentInstance: FarteDocumentInstance, contact: Contact) => {
      const contacts = [...inUseDocumentInstance.contacts, mapContactToRecipientContact(contact)]
      dispatchUpdateDocument(inUseDocumentInstance.id, {
        contacts,
        type: inUseDocumentInstance.type,
      })
    },
    [dispatchUpdateDocument],
  )

  const handleDocumentUpdatedContact = useCallback(
    (updates: Partial<Contact>) => {
      if (contact) {
        if (inUseDocumentInstance && inUseDocumentInstance.type === 'farte') {
          const oldContact = inUseDocumentInstance.contacts.find(({ id }) => contact.id === id)
          if (!oldContact) {
            updateDocumentContacts(inUseDocumentInstance, contact)
          } else {
            const { assignedDoctor, addressingDoctor, mainRecipient } = oldContact
            const newContact: RecipientContact = {
              ...mapContactToRecipientContact(contact),
              assignedDoctor,
              addressingDoctor,
              mainRecipient,
            }
            const newContactsArray = inUseDocumentInstance.contacts.map((item) => {
              if (item.id === oldContact.id) {
                return { ...oldContact, ...newContact }
              }
              return item
            })
            dispatchUpdateDocument(inUseDocumentInstance.id, {
              contacts: newContactsArray,
              type: inUseDocumentInstance.type,
            })
          }
        }
      }
    },
    [contact, dispatchUpdateDocument, inUseDocumentInstance, updateDocumentContacts],
  )

  const handleCreatedContactInDocument = useCallback(
    (createdContact: Contact) => {
      if (
        inUseDocumentInstance &&
        inUseDocumentInstance.type === 'farte' &&
        !inUseDocumentInstance.contacts.find(({ id }) => createdContact.id === id)
      ) {
        updateDocumentContacts(inUseDocumentInstance, createdContact)
      }
    },
    [inUseDocumentInstance, updateDocumentContacts],
  )

  const updateContact = useCallback(
    (updates: Partial<Contact>) => {
      updatePatientContact(
        {
          id: contact!.id,
          contactPayload: { ...updates, id: contact!.id },
          patientId,
        },
        {
          onSuccess: () => {
            if (contact && context === BottomPanelContactsContext.DOCUMENT_CONTACT) {
              handleDocumentUpdatedContact(updates)
            }
          },
        },
      )
    },
    [contact, patientId, context, handleDocumentUpdatedContact, updatePatientContact],
  )

  const createOrCopyContact = useCallback(
    (updates: Partial<Contact>) => {
      const updatesOrganizations = updates.organizations ? [...updates.organizations] : []
      const organizations = updatesOrganizations.map((organization) => ({
        ...organization,
        phoneNumber: organization.phoneNumber?.replace(/[^0-9+]/g, '') || null,
      }))

      createContact(
        {
          contactPayload: {
            ...updates,
            organizations,
            private: true,
          },
          patientId,
        },
        {
          onSuccess: (createdContact) => {
            if (context === BottomPanelContactsContext.DOCUMENT_CONTACT) {
              handleCreatedContactInDocument(createdContact)
            }

            if (isInCreatePrivateCopyMode) {
              onContactDuplicated && onContactDuplicated(createdContact, contact!.id)
            } else {
              onContactCreated && onContactCreated(createdContact)
            }
          },
        },
      )
    },
    [
      contact,
      context,
      createContact,
      handleCreatedContactInDocument,
      isInCreatePrivateCopyMode,
      onContactCreated,
      onContactDuplicated,
      patientId,
    ],
  )

  const handleClose = useCallback(() => {
    setIsInCreatePrivateCopyMode(false)
    onRequestClose()
  }, [onRequestClose])

  const onConfirm = useCallback(
    (updates: Partial<Contact>) => {
      editionMode ? updateContact(updates) : createOrCopyContact(updates)
    },
    [editionMode, updateContact, createOrCopyContact],
  )

  const handleSubmit = useCallback(
    (updates: ContactFormFields) => {
      onConfirm(updates)
      setIsInCreatePrivateCopyMode(false)
    },
    [onConfirm],
  )

  const onCreatePrivateCopy = useCallback(() => {
    setIsInCreatePrivateCopyMode(true)
  }, [setIsInCreatePrivateCopyMode])

  const formik = useFormik<ContactFormFields>({
    initialValues,
    validationSchema: ContactValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const handleValidForm = useCallback(() => {
    formik.submitForm()
    onRequestClose()
  }, [formik, onRequestClose])

  const topBarActions = (
    <div className={styles.actions}>
      <div className={styles.button}>
        <Button
          type="reset"
          label={disabled ? 'Fermer' : 'Annuler'}
          theme="dark"
          onClick={handleClose}
        />
      </div>
      {!disabled && (
        <div className={styles.button}>
          <Button
            type="button"
            label="Valider"
            theme="primary"
            disabled={!formik.isValid}
            onClick={handleValidForm}
          />
        </div>
      )}
      {!isInCreatePrivateCopyMode &&
        contact &&
        !contact.private &&
        context === BottomPanelContactsContext.PATIENT_CONTACT &&
        disabled && (
          <div className={styles.button}>
            <Button
              type="button"
              label="Créer une copie privée"
              theme="primary"
              disabled={!formik.isValid}
              onClick={onCreatePrivateCopy}
            />
          </div>
        )}
    </div>
  )

  return (
    <BottomPanel
      title={title}
      isUnmounting={isUnmounting}
      onRequestClose={handleClose}
      actions={topBarActions}
    >
      <div className={styles.container}>
        {isLoading && contactId ? (
          <FullLoader />
        ) : (
          <ContactForm disabled={disabled} formik={formik} />
        )}
      </div>
    </BottomPanel>
  )
}
