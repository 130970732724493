import { Task, TaskInformation, TaskMutationPayload } from '../../../model/Task'
import { CdaContext } from '../../../model/CdaContext'
import { TestProps } from '../../../misc/types.utilities'

export enum TaskTableColumn {
  Title = 'title',
  CdaContext = 'cdaContext',
  Category = 'category',
  Patient = 'patient',
  Document = 'document',
  AssignedTo = 'assignedTo',
  DocumentStep = 'documentStep',
  CreatedAt = 'createdAt',
  Actions = 'actions',
}

export type TaskTableSize = 'small' | 'normal'

export interface TaskTableProps extends TestProps {
  tasks: Array<Partial<Task>>
  onCreateOrUpdate: (task: TaskMutationPayload) => void
  onDeleteTask: (taskId?: number) => void
  removeNewTask?: () => void
  openTaskInformation?: (taskInformation: TaskInformation[]) => void
  openCdaInformation?: (cdaContext: CdaContext) => void
  displayColumns?: TaskTableColumn[]
  size?: TaskTableSize
}
