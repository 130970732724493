import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useCallback, useMemo } from 'react'
import { recipientSuggestionsKeys } from './recipientSuggestions.keys'
import { getRecipientSuggestion } from '../../../data/recipientSuggestion/getRecipientSuggestion'
import { StaleTimes } from '../Query.model'
import { queryClient } from '../../../App'

interface GetRecipientSuggestionParams {
  search: string
  patientId?: number
  enabled?: boolean
}

export const useGetRecipientSuggestion = ({
  search,
  patientId,
  enabled = true,
}: GetRecipientSuggestionParams) => {
  const queryKey = useMemo(
    () => recipientSuggestionsKeys.search({ search, patientId }),
    [search, patientId],
  )

  const queryReturn = useQuery({
    queryKey,

    queryFn: async ({ signal }) => {
      const { data, ok } = await getRecipientSuggestion(search, patientId, signal)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer les suggestions de contacts`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
    staleTime: StaleTimes.OneDay,
  })

  const cancelPendingQuery = useCallback(
    () => queryClient.cancelQueries({ queryKey: recipientSuggestionsKeys.all }),
    [],
  )

  return { query: queryReturn, cancelPendingQuery }
}
