import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addValid } from '../../../../store/message'
import { hideBottomPanel } from '../../../../store/ui/bottomPanel'
import { useActionDispatch } from '../../../utils'
import { ServerSentNotificationMutationPayload } from '../../../../data/admin/notifications/notification.model'
import { postNotification } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { adminNotifKeys } from './adminNotifications.keys'

export const usePostNotification = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()
  const closeBottomPanel = useActionDispatch(hideBottomPanel)

  const queryReturn = useMutation({
    mutationFn: async (variables: ServerSentNotificationMutationPayload) => {
      const { data, ok } = await postNotification(variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La création de notification a échoué')
      }
      return data
    },

    onSuccess: () => {
      valid('Notification créée')
      closeBottomPanel()
      queryClient.refetchQueries({ queryKey: adminNotifKeys.all })
    },
  })
  return queryReturn
}
