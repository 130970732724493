import { DrugTypes, isDefined } from '@follow/cdk'
import { DrugFilters } from '../../../model/DrugFilters'
import { Filters } from '../../../model/Filters'

export const serializeDrugFilters = (filters: DrugFilters): Filters => {
  return {
    sort_by: filters.sorting?.sortBy,
    direction: filters.sorting?.direction,
    ...(filters.sameRoutesAsDrugs?.length && {
      sameRoutesAsDrugs: filters.sameRoutesAsDrugs.map(({ type, id }) => `${id}:${type}`).join(','),
    }),
    ...(isDefined(filters.aldCompatiblePatientId) && {
      patientAldCompatible: filters.aldCompatiblePatientId,
    }),
    ...serializeTypeSpecificFilters(filters),
  }
}

const serializeTypeSpecificFilters = (filters: DrugFilters): Filters => {
  switch (filters.type) {
    case DrugTypes.SPECIALITY:
    case DrugTypes.NON_PROPRIETARY: {
      return {
        type: filters.type,
        search: filters.search,
      }
    }
    case DrugTypes.ACTIVE_PRINCIPLES: {
      return {
        type: filters.type,
        id: filters.activePrinciple.externalId,
      }
    }
    case DrugTypes.ATC: {
      return {
        type: filters.type,
        id: filters.atcClass.id,
      }
    }
    case DrugTypes.BIOSIMILAR_GROUP: {
      return {
        type: filters.type,
        id: filters.searchId,
      }
    }
    case DrugTypes.INDICATION: {
      return {
        type: filters.type,
        id: filters.indication.externalId,
      }
    }
  }
}
