import React, { FunctionComponent } from 'react'

import styles from './Chevron.module.scss'
import { ColorsV2, getV2Color } from '../../../design-system/colors_v2'

interface ChevronProps {
  color: ColorsV2
}

export const Chevron: FunctionComponent<ChevronProps> = ({ color }) => (
  <div className={styles.chevron} style={{ borderTopColor: getV2Color(color) }} />
)
