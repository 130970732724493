import React, { ButtonHTMLAttributes, FunctionComponent } from 'react'
import { BaseButtonProps, BorderColors, Colors } from './BaseButton.model'
import { StyledButton } from './BaseButton.styled'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'

const defaultColors: Colors = {
  default: getV3ColorCssVariable('shades', 'white'),
  backgroundColor: 'transparent',
}

const defaultBorderColors: BorderColors = {
  default: getV3ColorCssVariable('shades', 'shade4'),
  hover: getV3ColorCssVariable('shades', 'shade4'),
}

export const BaseButton: FunctionComponent<
  BaseButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  colors = defaultColors,
  borderColors = defaultBorderColors,
  appearance = 'standalone',
  children,
  noBorder,
  sizes,
  ...restProps
}) => {
  const mixedColors = { ...defaultColors, ...colors }
  const mixedBorderColors = { ...defaultBorderColors, ...borderColors }

  return (
    <StyledButton
      fontColor={mixedColors.default}
      backgroundColor={mixedColors.backgroundColor}
      borderColor={mixedBorderColors.default}
      borderColorHover={mixedBorderColors.hover}
      noBorder={noBorder}
      heightSize={sizes ? sizes.height : undefined}
      widthSize={sizes ? sizes.width : undefined}
      paddingSize={sizes ? sizes.padding : undefined}
      fontSize={sizes ? sizes.font : undefined}
      appearance={appearance}
      {...restProps}
    >
      {children}
    </StyledButton>
  )
}
