import { DocumentCategoryKeys } from '../../../../../model/DocumentCategory'
import { TagType } from '../../../../../model/Tag'
import { Team } from '../../../../../model/Team'
import { User } from '../../../../../model/User'

export interface PatientTimelineFiltersProps {
  currentUser: User | null
  teams: Team[]
  tags: ReadonlyArray<TagType>
  filters: PatientTimelineFilters
  setFilters: (filters: PatientTimelineFilters) => void
}

export interface PatientTimelineFilters {
  search: string
  category?: DocumentCategoryKeys
  tagIds: string[]
  doctorId?: string
}

export const INITIAL_TIMELINE_FILTERS: PatientTimelineFilters = {
  search: '',
  category: undefined,
  tagIds: [],
  doctorId: undefined,
}
