import { isDefined } from '@follow/cdk'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { ORDOCLIC_APP_EPRESCRIPTION_URL } from '../../../environment/ordoclic'
import { OrdoclicApiLightBatchedPrescription, OrdoclicApiMessage } from '../../../model/ordoclic'
import { IconButton, Modal } from '../../shared'
import { OrdoclicIframeProps } from './OrdoclicIframe.model'
import styles from './OrdoclicIframe.module.scss'

const getPrescriptionIds = (ordoclicMessage: OrdoclicApiMessage) =>
  (ordoclicMessage.message.batch as OrdoclicApiLightBatchedPrescription[]).reduce(
    (acc: string[], value) => {
      if (value.isPrescription) {
        acc.push(value.id)
      }
      return acc
    },
    [],
  )

export const OrdoclicIframe: FunctionComponent<OrdoclicIframeProps> = ({
  generatePrescriptionFiles,
  onClose,
  isUnmounting,
  url,
}) => {
  const ordoclicMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.origin === ORDOCLIC_APP_EPRESCRIPTION_URL && isDefined(event.data)) {
        const ordoclicMessage = event.data as OrdoclicApiMessage
        switch (ordoclicMessage.type) {
          case 'batch-created':
            const prescriptionIds = getPrescriptionIds(ordoclicMessage)
            generatePrescriptionFiles(prescriptionIds)
            break
          case 'close-iframe':
            onClose()
            break
        }
      }
    },
    [generatePrescriptionFiles, onClose],
  )

  useEffect(() => {
    window.addEventListener('message', ordoclicMessageHandler)
    return () => {
      window.removeEventListener('message', ordoclicMessageHandler)
    }
  }, [ordoclicMessageHandler])

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onClose}>
      <div className={styles.container}>
        <div className={styles.backWrapper}>
          <IconButton
            onClick={onClose}
            icon="back"
            theme="light"
            testId="button-exit-ordoclic-iframe"
          />
          <span className={styles.backReturnText}>Retour à l'évènement médical</span>
        </div>
        <iframe className={styles.iframe} title="ordoclic-iframe" allow="camera *" src={url} />
      </div>
    </Modal>
  )
}
