import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { contactsKeys } from './contacts.keys'
import { getContact } from '../../../data/contact'

interface GetContactParams {
  id: string | null
  enabled?: boolean
}

export const useGetContact = ({ id, enabled = true }: GetContactParams) => {
  const queryKey = useMemo(() => contactsKeys.detail(id), [id])

  const queryReturn = useQuery({
    queryKey,
    queryFn: async () => {
      if (!id) return

      const { data, ok } = await getContact(id)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le contact`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
