import React, { FunctionComponent, ChangeEvent, useMemo } from 'react'
import classNames from 'classnames/bind'
import { Patient } from '../../../model/Patient'
import { Heading, Tag, SelectInput } from '../../shared'
import { ColorsV2TagBackground } from '../../../design-system/colors_v2'

import styles from '../ContactCard/ContactCard.module.scss'
import { getDisplayedName } from '../../../misc/patient.utilities'
import { LabeledCheckbox } from '../../legacy/LabeledCheckbox'
import { Address } from '../../../model/Address'
import { isInvalidAddress } from '../../../misc/address.utilities'

interface PatientCardProps {
  patient: Patient
  disabled?: boolean
  copyRecipient: boolean
  mainRecipient: boolean
  onChangeMainRecipient: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeCopyRecipient: (e: ChangeEvent<HTMLInputElement>) => void
}

const cx = classNames.bind(styles)

const mapAddressToValue = (address: Address) => {
  const { street, street2, city } = address
  if (isInvalidAddress(address)) {
    return {
      label: 'Adresse non renseignée',
      value: null,
    }
  }
  return {
    label: `${street2 ? street2 + ', ' : ''}${street}, ${city}`,
    value: `${street2 ? street2 + ', ' : ''}${street}, ${city}`,
  }
}

export const PatientCard: FunctionComponent<PatientCardProps> = ({
  patient,
  disabled = false,
  copyRecipient,
  mainRecipient,
  onChangeMainRecipient,
  onChangeCopyRecipient,
}) => {
  const inactive = !(copyRecipient || mainRecipient)
  const addresses = useMemo(
    () => (patient.address ? [mapAddressToValue(patient.address)] : []),
    [patient.address],
  )
  const { firstname, lastname } = getDisplayedName(patient)

  return (
    <div className={cx(styles.container, { inactive })}>
      <div className={styles.infos}>
        <div className={styles.infoContent}>
          <Heading size={5}>
            <span className={styles.firstName}>{firstname} </span>
            <span className={styles.lastName}>{lastname}</span>
          </Heading>
          <div className={styles.specialty}>
            Patient
            <Tag color={ColorsV2TagBackground.dsTag00}>Privé</Tag>
          </div>
        </div>
      </div>
      <div className={styles.contactType}>
        <div className={styles.check}>
          <LabeledCheckbox
            label="Destinataire principal"
            checked={mainRecipient}
            onChange={onChangeMainRecipient}
            disabled={disabled}
          />
        </div>
        <div className={styles.check}>
          <LabeledCheckbox
            label="En copie"
            checked={copyRecipient}
            onChange={onChangeCopyRecipient}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.contactOrganization}>
        {patient.address && (
          <SelectInput
            options={addresses}
            value={mapAddressToValue(patient.address)}
            onSelect={() => {}}
            title="Adresse"
            readonly
          />
        )}
      </div>
    </div>
  )
}
