import { FunctionComponent } from 'react'
import { Icon } from '../../shared'
import { ColorsV2 } from '../../../design-system/colors_v2'
import styles from './FileListItem.module.scss'
import classNames from 'classnames'
import { CustomFile, FileKind } from '../../../model/File'
import { MedicalEventContentTypeIcon } from '../../../model/MedicalEvent'
import { isImageExtension } from '../../../misc/files.utilities'

export const FileListItem: FunctionComponent<{
  item: CustomFile
}> = ({ item }) => {
  const type = isImageExtension(item.extension) ? FileKind.IMAGE : FileKind.FILE

  return (
    <div className={classNames(styles.item, 'space-x-2 space-y-1')}>
      <div className={styles.icon}>
        <Icon icon={MedicalEventContentTypeIcon[type]} color={ColorsV2.contentDark1} size="nano" />
      </div>
      <span>{item.visibleName}</span>
    </div>
  )
}
