import { FunctionComponent, useEffect, useMemo } from 'react'
import styles from './PatientDetailsPage.module.scss'
import { PatientDetailsPageHeader, PatientForm } from '../../../../components/patient'
import { NavBar } from '../../../../components/layout'
import { LoadingPage, VitalCardModalGroup } from '../../../../components/shared'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isDefined } from '../../../../misc/functions.utilities'
import { Patient } from '../../../../model/Patient'
import { useActionDispatch, useCurrentPatient } from '../../../../hooks/utils'
import { useAtomValue } from 'jotai'
import { beneficiariesAtom } from '../../../../state/vitalCard'
import { FeaturesType } from '@follow/cdk'
import { useUserEnabledFeature } from '../../../../hooks/utils/user'
import { useToggle } from 'react-use'
import { ResourceHistoryPanel } from '../../../../components/history'
import { clearError } from '../../../../store/message'

export const PatientDetailsPage: FunctionComponent = () => {
  const [showHistory, toggleShowHistory] = useToggle(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const vital = searchParams.get('vital')
  const clearMessage = useActionDispatch(clearError)
  const { selected: vitalCardBeneficiary } = useAtomValue(beneficiariesAtom)
  const { currentPatient, isLoadingCurrentPatient } = useCurrentPatient()

  const vitalCardService = useUserEnabledFeature(FeaturesType.vitalCardService)

  useEffect(() => {
    if (currentPatient && !currentPatient.isEditable) {
      navigate(-1)
    }
  }, [navigate, currentPatient])

  const mergedPatient = useMemo<Partial<Patient> | null | undefined>(() => {
    if (isLoadingCurrentPatient) {
      return undefined
    }

    if (vital && (isDefined(currentPatient) || isDefined(vitalCardBeneficiary))) {
      return {
        ...currentPatient,
        ...vitalCardBeneficiary,
      }
    }

    if (isDefined(currentPatient)) {
      return currentPatient
    }

    return null
  }, [currentPatient, isLoadingCurrentPatient, vital, vitalCardBeneficiary])

  useEffect(() => {
    return () => {
      if (vital && !mergedPatient?.id) {
        clearMessage('vitalCardPatientNotFound')
      }
    }
  }, [clearMessage, mergedPatient?.id, vital])

  return mergedPatient === undefined ? (
    <LoadingPage />
  ) : (
    <>
      <NavBar backButtonPath={null} backButtonTitle="Retour">
        <PatientDetailsPageHeader
          patient={mergedPatient}
          isLoading={false}
          openPatientInformationsHistory={toggleShowHistory}
        />
      </NavBar>
      <div className={styles.content}>
        <PatientForm patient={mergedPatient} />
      </div>
      {vitalCardService && <VitalCardModalGroup isPatientFormActive={true} />}
      {currentPatient && (
        <ResourceHistoryPanel
          display={showHistory}
          entityType="patient"
          entityId={currentPatient.id}
          onClose={toggleShowHistory}
        />
      )}
    </>
  )
}
