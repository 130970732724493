import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import {
  drugSortOptions,
  DrugTypes,
  ActivePrinciple,
  AtcClass,
  Drug,
  Indications,
} from '../../../model/Drug'

import createSearchPanel from '../../../containers/SearchPanel'

import { SearchDrugProps } from './SearchDrug.model'

import DrugItem from './DrugItem'
import { Button, SearchInput, SelectSearch, Switch, Tag } from '../../shared'
import { openMonograph, openSMR } from '../../../misc/drug.utilities'
import { ActivePrinciplesSelect, AtcClassSelect, IndicationsSelect } from '..'
import { SelectOption } from '../../../model/SelectOption'
import { debounce, isEqual } from 'lodash'
import { isDefined } from '../../../misc/functions.utilities'
import { IdentityIdentifier } from '../../../store/restux/restux.model'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'
import { useGetPatientInformations } from '../../../hooks/queries/patientInformations'
import { useGetInfiniteDrugs } from '../../../hooks/queries/drugs'
import { DrugFilters } from '../../../model/DrugFilters'
import { useUserPreference } from '../../../hooks/utils/user'
import { BottomPanel } from '../../shared/bottomPanel/BottomPanel'
import { useActionDispatch } from '../../../hooks/utils'
import { domainEditorActions } from '../../../store/domain/editor/editor.actions'

const SearchPanel = createSearchPanel<Drug>()

const drugSearchTypesOptions: SelectOption<DrugTypes>[] = [
  { value: DrugTypes.SPECIALITY, label: 'Nom (spécialité)' },
  { value: DrugTypes.NON_PROPRIETARY, label: 'Dénomination commune' },
  { value: DrugTypes.ACTIVE_PRINCIPLES, label: 'Principe Actif' },
  { value: DrugTypes.INDICATION, label: 'Indication' },
  { value: DrugTypes.ATC, label: 'Classification ATC' },
]

const biosimilarGroupOption = {
  value: DrugTypes.BIOSIMILAR_GROUP,
  label: 'Groupe Bio-Similaire',
}

export const SearchDrug: FunctionComponent<SearchDrugProps> = ({
  disabled,
  initialSearch,
  isMultiSelect,
  isUnmounting,
  updatePendingVariables,
  label = 'Rechercher un médicament',
  onSelect,
  onClose,
}) => {
  const patientId = getCurrentPatientId()
  const drugSortingPreference = useUserPreference('doctor', 'drugSorting')
  const initialSearchType = [...drugSearchTypesOptions, biosimilarGroupOption].find(
    ({ value }) => value === initialSearch?.type,
  )

  const [activePrincipleSelected, setActivePrincipleSelected] = useState<ActivePrinciple>()
  const [indicationSelected, setIndicationSelected] = useState<Indications>()
  const [searchType, setSearchType] = useState(initialSearchType ?? drugSearchTypesOptions[0])
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [selectedSort, setSelectedSort] = useState(
    drugSortOptions.find((option) => isEqual(option.value, drugSortingPreference)) ??
      drugSortOptions[0],
  )
  const [selectedAtcClass, setSelectedAtcClass] = useState<AtcClass | null>(null)
  const [patientAldCompatible, setPatientAldCompatible] = useState<IdentityIdentifier>()
  const [selectedDrugs, setSelectedDrugs] = useState<Drug[]>([])

  const setPendingVariables = useActionDispatch(domainEditorActions.setPendingVariables)

  const typedFilters = useMemo<DrugFilters | null>(() => {
    if (
      (searchType.value === DrugTypes.SPECIALITY ||
        searchType.value === DrugTypes.NON_PROPRIETARY) &&
      debouncedSearch.length > 2
    ) {
      return {
        type: searchType.value,
        search: debouncedSearch,
      }
    }
    if (searchType.value === DrugTypes.ACTIVE_PRINCIPLES && isDefined(activePrincipleSelected)) {
      return {
        type: DrugTypes.ACTIVE_PRINCIPLES,
        activePrinciple: activePrincipleSelected,
      }
    }
    if (searchType.value === DrugTypes.ATC && isDefined(selectedAtcClass)) {
      return {
        type: DrugTypes.ATC,
        atcClass: selectedAtcClass,
      }
    }
    if (searchType.value === DrugTypes.BIOSIMILAR_GROUP && isDefined(initialSearch)) {
      return {
        type: DrugTypes.BIOSIMILAR_GROUP,
        searchId: initialSearch.searchId,
      }
    }
    if (searchType.value === DrugTypes.INDICATION && isDefined(indicationSelected)) {
      return {
        type: DrugTypes.INDICATION,
        indication: indicationSelected,
      }
    }

    return null
  }, [
    activePrincipleSelected,
    indicationSelected,
    initialSearch,
    searchType.value,
    selectedAtcClass,
    debouncedSearch,
  ])

  const {
    drugList,
    paginationState,
    query: { fetchNextPage, isLoading },
  } = useGetInfiniteDrugs({
    filters: typedFilters
      ? {
          ...typedFilters,
          sorting: selectedSort.value,
          sameRoutesAsDrugs: selectedDrugs,
          aldCompatiblePatientId: patientAldCompatible,
        }
      : null,
  })

  const {
    query: { data: patientAllergies = [] },
  } = useGetPatientInformations(patientId!)

  const displayAldInput = useMemo(
    () => patientAllergies.some((item) => item.isAld),
    [patientAllergies],
  )

  const onPrimaryAction = (selection: Drug) => {
    const prescriptionVariableUuid = uuid()
    const selected = {
      drugs: [{ ...selection }],
      prescriptionVariableUuid,
      isAld: isDefined(patientAldCompatible),
    }

    if (updatePendingVariables) {
      setPendingVariables({
        prescriptions: [selected],
      })
    }
    onSelect(selected)
  }

  const handleMultiSelect = useCallback(
    (selection: Drug[]) => {
      setSelectedDrugs(selection)
    },
    [setSelectedDrugs],
  )

  const submitMultiSelection = useCallback(() => {
    const selected = {
      drugs: selectedDrugs.map(({ id, type }) => ({
        id,
        type,
      })),
      prescriptionVariableUuid: uuid(),
      isAld: isDefined(patientAldCompatible),
    }

    if (updatePendingVariables) {
      setPendingVariables({
        prescriptions: [selected],
      })
    }
    onSelect(selected)
  }, [selectedDrugs, patientAldCompatible, updatePendingVariables, onSelect, setPendingVariables])

  const debouncedSearchUpdate = useMemo(() => debounce(setDebouncedSearch, 500), [])

  const handleSearch = useCallback(
    (search: string) => {
      setSearch(search)

      if (search.length < 3) {
        setDebouncedSearch('')
      } else {
        debouncedSearchUpdate(search)
      }
    },
    [debouncedSearchUpdate],
  )

  return (
    <BottomPanel
      isUnmounting={isUnmounting}
      title={label}
      onRequestClose={onClose}
      actions={
        isMultiSelect ? (
          <Button
            label="Valider"
            theme="primary"
            disabled={selectedDrugs.length < 2}
            onClick={submitMultiSelection}
          />
        ) : null
      }
    >
      <SearchPanel
        testId={`search-drug`}
        items={drugList}
        loading={isLoading && isDefined(typedFilters)}
        pageCount={paginationState?.pageCount ?? null}
        itemCount={paginationState?.itemCount ?? null}
        onPrimaryAction={!isMultiSelect ? onPrimaryAction : undefined}
        getItems={() => fetchNextPage()}
        filterCapabilities={[]}
        onMultiSelectionChange={isMultiSelect ? handleMultiSelect : undefined}
        renderCustomFilters={() => (
          <div className="space-y-6 flex flex-col">
            <SelectSearch
              disabled={disabled}
              value={searchType}
              options={drugSearchTypesOptions}
              onSelect={(option) => {
                if (option) {
                  const { value } = option
                  const drugResult = drugSearchTypesOptions.find(
                    (drugSearchType) => drugSearchType.value === value,
                  )
                  if (drugResult) {
                    setSearchType(drugResult)
                    handleSearch('')
                  }
                }
              }}
              colorPreset="dark"
              clearable={false}
            />
            {[DrugTypes.SPECIALITY, DrugTypes.NON_PROPRIETARY].includes(searchType.value) && (
              <SearchInput
                autofocus
                value={search}
                onChange={({ target: { value } }) => {
                  handleSearch(value)
                }}
                disabled={disabled}
                colorPreset="dark"
                placeholder="3 caractères min"
                testId={`search-drug`}
              />
            )}
            {searchType.value === DrugTypes.ATC && (
              <AtcClassSelect onClassSelected={setSelectedAtcClass} />
            )}
            {searchType.value === DrugTypes.ACTIVE_PRINCIPLES && (
              <ActivePrinciplesSelect setActivePrincipleSelected={setActivePrincipleSelected} />
            )}
            {searchType.value === DrugTypes.INDICATION && (
              <IndicationsSelect setIndicationSelected={setIndicationSelected} />
            )}
            {displayAldInput && patientId && (
              <div className="font-semibold text-white">
                <Switch
                  name="Pris en charge dans le cadre de l'ALD du patient"
                  checked={isDefined(patientAldCompatible)}
                  onChange={(checked) => setPatientAldCompatible(checked ? patientId : undefined)}
                />
              </div>
            )}

            {isMultiSelect && (
              <div className="flex flex-col border-t-2 border-white">
                <span className="mt-4 mb-1 text-white font-medium">
                  Vous ne pouvez sélectionner que des médicaments ayant au moins une voie
                  d'administration en commun
                </span>
                <div className="space-y-2">
                  {selectedDrugs.map((drug) => (
                    <Tag key={drug.signature} title={drug.name} ellipsis={false} multiline>
                      {drug.name}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        renderItem={(drug) => (
          <DrugItem
            drug={drug}
            onMonographClick={() => openMonograph(drug)}
            onSMRClick={() => openSMR(drug)}
          />
        )}
        renderHeaderActions={() => (
          <div className="my-4 mx-2 w-80">
            <SelectSearch
              options={drugSortOptions}
              value={selectedSort}
              onSelect={(selected) => {
                if (selected) {
                  const { direction, sortBy } = selected.value
                  const option = drugSortOptions.find(
                    ({ value }) => value.direction === direction && value.sortBy === sortBy,
                  )
                  if (option) {
                    setSelectedSort(option)
                  }
                }
              }}
              clearable={false}
            />
          </div>
        )}
      />
    </BottomPanel>
  )
}
