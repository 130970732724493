import { formatFr, parseApiFormat, DATE_FORMAT_FRONT_SHORT } from '../../../../misc/date.utilities'
import { inputInseeNumberFormatter } from '../../../../misc/inseeNumber.utils'
import { InsiPatient } from '../../../../model/Insi'
import { PatientFormFields } from '../PatientForm.model'

export const deserializeInsiPatient = (
  insiPatient: InsiPatient,
  formValues: PatientFormFields,
): PatientFormFields => {
  const formatedBirthdate = insiPatient.birthDate
    ? formatFr(parseApiFormat(insiPatient.birthDate), DATE_FORMAT_FRONT_SHORT)
    : null

  return {
    ...formValues,
    birthFirstName: insiPatient.birthFirstName ?? formValues.birthFirstName,
    birthLastName: insiPatient.birthLastName ?? formValues.birthLastName,
    usedFirstName: insiPatient.usedFirstName ?? formValues.usedFirstName,
    otherFirstNames: insiPatient.otherFirstName ?? formValues.otherFirstNames,
    sex: insiPatient.sex ?? formValues.sex,
    birthDate: formatedBirthdate ?? formValues.birthDate,
    birthPlaceCode: insiPatient.birthPlaceCode ?? formValues.birthPlaceCode,
    assigningAuthority: insiPatient.assigningAuthority ?? formValues.assigningAuthority,
    inseeNumber: insiPatient.inseeNumber
      ? inputInseeNumberFormatter(insiPatient.inseeNumber)
      : formValues.inseeNumber,
    identityStatus: {
      ...formValues.identityStatus,
      insiStatus: {
        checked: 'VALIDATED',
        checkedAt: new Date(),
      },
    },
  }
}
