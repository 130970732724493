import React, { FunctionComponent } from 'react'
import { isDefined } from '../../../../../misc/functions.utilities'
import { getV3ColorCssVariable } from '../../../../../design-system/colors_v3'
import { Icon } from '../../../Icon'
import { RoundedButton } from '../../../buttons'
import classNames from 'classnames'
import { AlertBannerItemProps } from './AlertBannerItem.model'
import { TooltipWrapper } from '../../../tooltip'
import { Tag } from '../../../tag'

export const AlertBannerItem: FunctionComponent<AlertBannerItemProps> = ({
  code,
  title,
  text,
  icon,
  color,
  isActive = true,
  actionButton,
  onIgnore,
  parentIsList = false,
  info,
  tags,
  testId,
}) => {
  return isActive ? (
    <div
      className={classNames(
        {
          'pl-12 pr-6': parentIsList,
          'px-6': !parentIsList,
        },
        'py-3 relative text-shades-8 bg-shades-1 w-full',
      )}
      data-test-id={testId}
    >
      <div className="flex items-center">
        <div className="mr-4">
          <Icon icon={icon} size="normal" color={getV3ColorCssVariable('labels', color)} />
        </div>
        <div className="mr-4 grow">
          <div className="text-base font-bold text-shades-white">{title}</div>
          <div className="flex flex-row">
            {tags &&
              tags.map((tag, index) => (
                <div className="mr-2 pt-1" key={index}>
                  <TooltipWrapper
                    content={<div className="break-normal">{tag.content}</div>}
                    display={!!tag.content}
                    size="small"
                    pointerDirection="top"
                  >
                    <Tag color={tag.color}>{tag.label}</Tag>
                  </TooltipWrapper>
                </div>
              ))}
            <div className="text-xs font-semibold text-shades-white flex items-center">{text}</div>
          </div>
        </div>
        <div className="flex items-center justify-end">
          {info && (
            <TooltipWrapper content={info} pointerDirection="right">
              <div className="mr-4">
                <Icon
                  icon="infoCircle"
                  size="micro"
                  color={getV3ColorCssVariable('shades', 'white')}
                />
              </div>
            </TooltipWrapper>
          )}
          {actionButton && <>{actionButton}</>}
          {isDefined(onIgnore) && (
            <div className="flex justify-end">
              <RoundedButton label="ignorer" theme="transparent" onClick={() => onIgnore(code)} />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null
}
