import getApi from '../../api/apisauceConfig'
import { PaginatedList } from '../../model/Pagination'
import { singleItemPageLimit } from '../../constants'
import { serializeQueryFilters } from '../../misc/query.utilities'
import { MessageTemplate } from '../../model/MessageTemplate'

export const getMessageTemplates = (
  page: number,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<MessageTemplate>>(
    '/message_templates',
    {
      page,
      limit,
    },
    { signal, paramsSerializer: serializeQueryFilters },
  )
