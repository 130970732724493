import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mergePatients } from '../../../data/patients'
import { UserFriendlyError } from '../../../model/Error'
import { patientsKeys } from './patients.keys'
import { medicalEventsKeys } from '../medicalEvents/medicalEvents.keys'
import { patientImportantInformationsKeys } from '../patientImportantInformations/patientImportantInformations.keys'
import { resourceHistoryKeys } from '../resourceHistory'

export interface MergeVariable {
  patientId: number
  patientToMergeId: number
}

export const useMergePatient = () => {
  const queryClient = useQueryClient()
  const queryKey = patientsKeys.merge

  return useMutation({
    mutationKey: queryKey,

    mutationFn: async ({ patientId, patientToMergeId }: MergeVariable) => {
      const { data, ok } = await mergePatients(patientId, patientToMergeId)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La fusion des patients a échoué')
      }
      return data
    },
    onSuccess: (_data, { patientId }) => {
      queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
      queryClient.invalidateQueries({ queryKey: patientsKeys.lists })
      queryClient.invalidateQueries({ queryKey: patientsKeys.detail(patientId) })
      queryClient.invalidateQueries({
        queryKey: patientImportantInformationsKeys.listAll(patientId),
      })
      queryClient.invalidateQueries({
        queryKey: resourceHistoryKeys.list({ type: 'patient', id: patientId }),
        type: 'all',
      })
    },
  })
}
