import { useMutation, useQueryClient } from '@tanstack/react-query'
import { readNotifications } from '../../../data/notifications'
import { ServerSentNotification } from '../../../model/Notification'
import { PaginatedList } from '../../../model/Pagination'
import { notificationsKeys } from './notifications.keys'

interface ReadUserNotificationsMutateVariables {
  notificationIds: string[]
}

export const useReadUserNotifications = () => {
  const queryClient = useQueryClient()

  const queryReturn = useMutation({
    mutationFn: async ({ notificationIds }: ReadUserNotificationsMutateVariables) => {
      const { data, ok } = await readNotifications(notificationIds)
      if (!data || !ok) {
        throw new Error('Impossible de marquer les notifcations comme lues')
      }
      return null
    },

    onSuccess: (_data, { notificationIds }) => {
      queryClient.invalidateQueries({
        queryKey: notificationsKeys.all,
        predicate: (query) => {
          if (
            query.state.data &&
            typeof query.state.data === 'object' &&
            'pages' in query.state.data
          ) {
            return (
              (
                query.state.data.pages as unknown as PaginatedList<ServerSentNotification>
              )[0].items.some(({ id }) => notificationIds.includes(id)) ?? false
            )
          }
        },
      })
    },
  })
  return queryReturn
}
