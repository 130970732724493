import { FeaturesType } from '@follow/cdk'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { getAddresses } from '../../../../data/mail'
import { subCodeTokenExpired } from '../../../../misc/mail.utilities'
import { UserFriendlyError } from '../../../../model/Error'
import { selectedEmailAddressAtom } from '../../../../state/mail/selectedEmailAddress.atom'
import { useUserEnabledFeature } from '../../../utils/user'
import { mailWebserviceKeys } from './webservice.keys'

const MAIL_SUBTOKEN_ERROR = 'SUBTOKEN_ERROR'

interface UseGetMailAdressesParams {
  enabled?: boolean
}

export const useGetMailAdresses = ({ enabled = true }: UseGetMailAdressesParams) => {
  const isMssSmtpActive = useUserEnabledFeature(FeaturesType.mssSmtp)
  const [, setSelectedEmailAddress] = useAtom(selectedEmailAddressAtom)

  const query = useQuery({
    queryKey: mailWebserviceKeys.mailAddresses,
    queryFn: async () => {
      const { ok, data, status } = await getAddresses()

      if (!ok && data) {
        const isSubTokenExpired = subCodeTokenExpired(data, status)
        if (isSubTokenExpired) {
          throw new Error(MAIL_SUBTOKEN_ERROR)
        }
      }

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "Impossible de récupérer l'adresse email liée à ce compte",
        )
      }

      const firstAddress = data?.emailAddresses?.at(0)
      if (!firstAddress) {
        throw new UserFriendlyError('error', 'Aucune adresse email associée à ce compte')
      }

      return data
    },
    enabled: enabled && !isMssSmtpActive,
    gcTime: Infinity,
    staleTime: Infinity,
    refetchIntervalInBackground: true,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === MAIL_SUBTOKEN_ERROR) {
        return false
      }
      return failureCount < 2
    },
  })

  useEffect(() => {
    const firstAddress = query.data?.emailAddresses.at(0) ?? null
    setSelectedEmailAddress(firstAddress)
  }, [query.data?.emailAddresses, setSelectedEmailAddress])

  return { query }
}
