import React, { FC, useCallback, useMemo, useState } from 'react'
import { IndicationSelectProps } from './IndicationSelect.model'
import { DropdownItem, SearchInput } from '../../shared'
import { SelectOption } from '../../../model/SelectOption'
import { Indications } from '../../../model/Drug'
import { useDebounce } from 'react-use'
import { useGetIndications } from '../../../hooks/queries/drugs'

const convertActivePrinciplesIntoOption = (item: Indications) => ({
  label: item.name,
  value: item.externalId,
})

export const IndicationsSelect: FC<IndicationSelectProps> = ({ setIndicationSelected }) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')

  const {
    query: { data: indications },
  } = useGetIndications({ search: debouncedSearch, enabled: debouncedSearch.length > 2 })

  const indicationOptions = useMemo(() => {
    if (indications) {
      const options = indications.map(convertActivePrinciplesIntoOption)
      return options
    }
  }, [indications])

  const handleSelect = useCallback(
    (selected: SelectOption<number>) => {
      setIndicationSelected({ externalId: selected.value, name: selected.label })
      setSearch(selected.label)
    },
    [setIndicationSelected],
  )

  useDebounce(
    () => {
      setDebouncedSearch(search)
      if (search.length < 3) {
        setIndicationSelected(undefined)
      }
    },
    500,
    [search],
  )

  return (
    <SearchInput
      placeholder="3 caractères min"
      colorPreset="dark"
      value={search}
      results={indicationOptions}
      onSelect={handleSelect}
      renderResult={(option, isHovered) => (
        <DropdownItem selected={isHovered}>
          <div className="max-w-full flex items-center whitespace-normal">
            <span className="capitalize">{option.label}</span>
          </div>
        </DropdownItem>
      )}
      onChange={({ target: { value } }) => {
        setSearch(value)
      }}
    />
  )
}
