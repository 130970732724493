import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deletePrescriptionTemplate } from '../../../data/prescriptionTemplates'
import { Drug } from '../../../model/Drug'
import { UserFriendlyError } from '../../../model/Error'
import { prescriptionTemplateKeys } from './prescriptionTemplates.keys'

interface UseDeletePrescriptionTemplateVariables {
  id: string
  drugs: Array<Drug>
}

export const useDeletePrescriptionTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, UseDeletePrescriptionTemplateVariables>({
    mutationFn: async ({ id }) => {
      const { ok } = await deletePrescriptionTemplate(id)

      if (!ok) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la suppression du modèle de prescription',
        )
      }
    },
    onSuccess: (_, { drugs }) => {
      const ids = drugs.map(({ id }) => id)
      queryClient.invalidateQueries({ queryKey: prescriptionTemplateKeys.list(ids) })
    },
  })
}
