import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Task, TaskMutationPayload } from '../../../model/Task'
import { UserFriendlyError } from '../../../model/Error'
import { updateTask } from '../../../data/task/updateTask'
import { taskKeys } from './tasks.keys'
import { checkIfPagesContainItem } from '../../../misc/query.utilities'

interface TaskMutateVariable {
  taskId: number
  variables: TaskMutationPayload
}

export const useUpdateTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ taskId, variables }: TaskMutateVariable) => {
      const { data, ok } = await updateTask(taskId, variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour de la tâche a échoué')
      }
      return data
    },

    onSuccess: (data, { taskId }) => {
      queryClient.refetchQueries({
        queryKey: taskKeys.all,
        predicate: (query) => checkIfPagesContainItem<Task>(query.state.data, taskId),
      })
      queryClient.setQueryData(taskKeys.detail(data.id), data)
    },
  })
}
