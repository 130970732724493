import React, { FunctionComponent } from 'react'
import { DocumentTemplateListItem } from '../../../model/DocumentTemplate'
import { DocumentInstanceListItem } from '../../../model/DocumentInstance'
import { Icon, TagCollection } from '../../shared'
import { DocumentCategoryIcon } from '../../../model/DocumentCategory'
import { ColorsV2 } from '../../../design-system/colors_v2'
import styles from './DocumentListItem.module.scss'
import classNames from 'classnames'

export const DocumentListItem: FunctionComponent<{
  item: DocumentTemplateListItem | DocumentInstanceListItem
}> = ({ item }) => (
  <div className={classNames(styles.item, 'space-x-2 space-y-1')}>
    <div className={styles.icon}>
      <Icon
        icon={
          typeof item.category === 'object'
            ? DocumentCategoryIcon[item.category.id]
            : DocumentCategoryIcon[item.category]
        } /* Temporaire, Todo : à tej */
        color={ColorsV2.contentDark1}
        size="nano"
      />
    </div>
    <span>{item.title}</span>
    <TagCollection ids={item.tagIds} />
  </div>
)
