import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useMeasure } from 'react-use'
import { isDefined } from '@follow/cdk'
import { MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { FullLoader } from '../../shared'
import WithQuestionContext from '../../../containers/Question/WithQuestionContext'
import { AddMedicalEventContent } from './AddMedicalEventContent'
import { Observations } from './Observations'
import { DocumentInstanceContent } from './DocumentInstanceContent'
import { MedicalEventContentProps } from './MedicalEventContent.model'
import styles from './MedicalEventContent.module.scss'
import { SelectedContentType } from '../../../store/ui/medicalEvents/medicalEventContent'
import { FileInstanceContent } from './FileInstanceContent'
import { FileDropzone, FileViewer } from '../../file'
import classNames from 'classnames'
import { AddEprescriptionTile, OrdoclicIframe } from '../..//eprescription'
import { FileInstanceContentBanner } from './FileInstanceContent/FileInstanceContentBanner'
import {
  extractOwnerOrCreatorNameFromFwDocumentFile,
  generateOwnerCreateNameTitle,
} from '../../../store/domain/medicalEvents/medicalEvents.utils'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { useActionDispatch, useCurrentPatient } from '../../../hooks/utils'
import { useCreatePrescriptionFiles, useGetOrdoclicUrl } from '../../../hooks/queries/ordoclic'
import { isAssistant, isDoctor } from '../../../misc/user.utilities'
import { medicalEventsActions } from '../../../store/cache/medicalEvents'
import { useQueryClient } from '@tanstack/react-query'
import { ordoclicKeys } from '../../../hooks/queries/ordoclic/ordoclic.keys'
import { EfficienceUploadConfig } from '../../../model/Efficience'
import { dmpDomainActions, DmpRequestTypes, FileForDMP } from '../../../store/domain/dmp'
import { EfficienceDmpUploadModal } from '../../dmp/EfficienceDmpUploadModal'
import { CustomFile } from '../../../model/File'
import { mapFileForDMP } from '../../../misc/dmp.utilities'

export const MedicalEventContent: FunctionComponent<MedicalEventContentProps> = ({
  medicalEvent,
  documentQuestionList,
  loading,
  questionnaire,
  prescription,
  quoteLine,
  selectedMedicalEventDocument,
  selectedContentFilter,
  selectMedicalEventContent,
  selectMedicalEventDocument,
  addFilesToMedicalEvent,
  lockDocument,
  updateMedicalEvent,
  userEnabledFeatures,
}) => {
  const dispatchMedicalEventRefresh = useActionDispatch(
    medicalEventsActions.actions.apiGetItemDetails,
  )
  const sendPdfsToDMP = useActionDispatch(dmpDomainActions.sendDocumentsToDMP)

  const { currentPatient } = useCurrentPatient()
  const { usurpedUser } = useContext(ConnectedUserContext)
  const [ref, measure] = useMeasure()
  const [openOrdoclic, setOpenOrdoclic] = useState(false)
  const [prescriptionFilesCreated, setPrescriptionFilesCreated] = useState<CustomFile[]>()
  const [openEfficienceModal, setOpenEfficienceModal] = useState(false)
  const { currentUser, loggedUser } = useContext(ConnectedUserContext)
  const queryClient = useQueryClient()

  const { data: ordoclicUrl } = useGetOrdoclicUrl({
    patientId: currentPatient?.id,
    enabled: openOrdoclic,
  })

  const { mutate: createPrescriptionFiles } = useCreatePrescriptionFiles()

  const allowedToMakeEPrescription = useMemo(
    () =>
      userEnabledFeatures?.ePrescription &&
      ((isDoctor(currentUser) && !isDefined(usurpedUser)) || isAssistant(loggedUser)),
    [currentUser, loggedUser, userEnabledFeatures?.ePrescription, usurpedUser],
  )

  const creatorAndOwnerNameExtracted = extractOwnerOrCreatorNameFromFwDocumentFile(
    selectedMedicalEventDocument,
    currentUser,
  )

  const ownerAndCreatorName = generateOwnerCreateNameTitle(
    creatorAndOwnerNameExtracted.ownerName,
    creatorAndOwnerNameExtracted.creatorName,
  )

  const closeEfficienceModal = useCallback(() => {
    setOpenEfficienceModal(false)
    setPrescriptionFilesCreated([])
  }, [])

  const handleSendPdf = useCallback(
    (config: EfficienceUploadConfig) => {
      if (currentPatient && medicalEvent && prescriptionFilesCreated) {
        const filesForDmp = prescriptionFilesCreated.reduce((acc: FileForDMP[], curr) => {
          const fileForDmp = mapFileForDMP(curr)
          if (fileForDmp) acc.push(fileForDmp)
          return acc
        }, [])

        sendPdfsToDMP(
          currentPatient,
          medicalEvent.owner,
          config,
          DmpRequestTypes.SEND_FILES,
          filesForDmp,
        )
      }

      closeEfficienceModal()
    },
    [currentPatient, medicalEvent, prescriptionFilesCreated, sendPdfsToDMP, closeEfficienceModal],
  )

  const handlePrescriptionFilesGeneration = useCallback(
    (prescriptionIds: string[]) => {
      isDefined(medicalEvent) &&
        createPrescriptionFiles(
          { medicalEventId: medicalEvent.id, prescriptionIds },
          {
            onSuccess: (files) => {
              dispatchMedicalEventRefresh(medicalEvent.id)
              setPrescriptionFilesCreated(files)
            },
          },
        )
    },
    [createPrescriptionFiles, dispatchMedicalEventRefresh, medicalEvent],
  )

  const closeOrdoclicPrescription = useCallback(() => {
    setOpenOrdoclic(false)
    queryClient.removeQueries({ queryKey: ordoclicKeys.detail(currentPatient?.id) })
    if (prescriptionFilesCreated?.length && userEnabledFeatures?.dmpInApp)
      setOpenEfficienceModal(true)
  }, [
    currentPatient?.id,
    prescriptionFilesCreated?.length,
    queryClient,
    userEnabledFeatures?.dmpInApp,
  ])

  useEffect(() => {
    if (medicalEvent && !medicalEvent.isEditable) {
      selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS,
      })
    }
  }, [medicalEvent, selectMedicalEventDocument])

  if (loading) {
    return <FullLoader />
  }

  if (selectedMedicalEventDocument && medicalEvent) {
    if (selectedMedicalEventDocument.type === MedicalEventDocumentType.FW_DOCUMENT) {
      if (selectedMedicalEventDocument.item.type === 'farte') {
        return (
          <div ref={ref} className={styles.container}>
            <WithQuestionContext
              singleColumnDisplay={measure.width < 1024}
              readOnly={!medicalEvent?.isEditable}
            >
              <DocumentInstanceContent
                key={selectedMedicalEventDocument.item.id}
                medicalEvent={medicalEvent}
                documentInstance={selectedMedicalEventDocument.item}
                documentQuestionList={documentQuestionList}
                questionnaire={questionnaire}
                prescription={prescription}
                quoteLine={quoteLine}
                selectedContent={selectedContentFilter}
                onSelectionChange={() => {
                  selectMedicalEventContent({
                    type: SelectedContentType.ADD_VARIABLE_PANEL,
                  })
                }}
                lockDocument={lockDocument}
              />
            </WithQuestionContext>
          </div>
        )
      } else {
        return (
          <>
            {ownerAndCreatorName && (
              <div className={styles.fileViewerHeaderOwnerCreatorName}>{ownerAndCreatorName}</div>
            )}
            <FileInstanceContent documentInstance={selectedMedicalEventDocument.item} />
          </>
        )
      }
    }

    if (selectedMedicalEventDocument.type === MedicalEventDocumentType.OBSERVATIONS) {
      return (
        <Observations
          content={medicalEvent.observation ?? ''}
          onChange={(value) => updateMedicalEvent(medicalEvent.id, { observation: value })}
          readonly={!medicalEvent.isEditable}
        />
      )
    }

    if (selectedMedicalEventDocument.type === MedicalEventDocumentType.FILE) {
      const file = selectedMedicalEventDocument.item
      return (
        <>
          <FileInstanceContentBanner
            file={file}
            selectMedicalEventDocument={selectMedicalEventDocument}
          />
          {ownerAndCreatorName && (
            <div className={styles.fileViewerHeaderOwnerCreatorName}>{ownerAndCreatorName}</div>
          )}
          <div className={styles.fileViewer}>
            <FileViewer file={file} enableScrollButton />
          </div>
        </>
      )
    }
  }

  return medicalEvent ? (
    <div className={styles.addContentContainer}>
      <AddMedicalEventContent medicalEventId={medicalEvent.id} />
      <div className={classNames(styles.floatingTile, styles.dropZone)}>
        <FileDropzone type="file" onDrop={addFilesToMedicalEvent} />
      </div>
      {allowedToMakeEPrescription && (
        <>
          <div className={classNames(styles.floatingTile, styles.eprescription)}>
            <AddEprescriptionTile onClick={() => setOpenOrdoclic(true)} />
          </div>
          <OrdoclicIframe
            onClose={closeOrdoclicPrescription}
            ordoclicPatientId={currentPatient?.ordoclicPatientId}
            display={openOrdoclic && !!ordoclicUrl}
            url={ordoclicUrl}
            generatePrescriptionFiles={handlePrescriptionFilesGeneration}
          />
          <EfficienceDmpUploadModal
            display={openEfficienceModal}
            onClose={closeEfficienceModal}
            onValid={handleSendPdf}
          />
        </>
      )}
    </div>
  ) : null
}
