import { deserializeIdentityStatus } from '@follow/cdk'
import { ApiResponse } from 'apisauce'
import { CdaFile } from '../../../model/CdaContext'
import {
  Mail,
  MailClassificationAttachment,
  MailClassificationCdaAttachment,
} from '../../../model/Mail'
import {
  ApiMail,
  ApiMailCdaAttachement,
  ApiMailClassifyAttachmentResource,
  ApiMailClassifyCdaAttachmentResource,
} from './mail.model'

export function deserializeMailResponse(response: ApiResponse<ApiMail>): ApiResponse<Mail> {
  const { data, ...responseRest } = response
  // Error case => no deserialization
  if (!response.ok || !data) {
    return { ...responseRest }
  }

  return {
    ...responseRest,
    data: {
      ...data,
      patient:
        data?.patient !== null
          ? {
              ...data?.patient,
              identityStatus: deserializeIdentityStatus(data?.patient?.identityStatus),
            }
          : undefined,
      attachments: data.attachments.map(({ cdaContent, part, ...attachment }) => ({
        ...attachment,
        id: part,
        part,
        cdaContent: cdaContent ? deserializeCdaAttachements(cdaContent) : undefined,
      })),
    },
  }
}

const deserializeCdaAttachements = (cdaAttachements: ApiMailCdaAttachement[]): CdaFile[] =>
  cdaAttachements.map((attachment) => ({
    ...attachment,
    visibleName: attachment.title,
    base64: attachment.base64 || '',
  }))

export const mapSelectedAttachmentsToApiMailClassifyAttachmentResources = (
  attachments: MailClassificationAttachment[],
): ApiMailClassifyAttachmentResource[] =>
  attachments.map(
    (attachment: MailClassificationAttachment): ApiMailClassifyAttachmentResource =>
      mapSelectedAttachmentToApiMailClassifyAttachmentResource(attachment),
  )
const mapSelectedAttachmentToApiMailClassifyAttachmentResource = (
  selectedAttachment: MailClassificationAttachment,
): ApiMailClassifyAttachmentResource => ({
  id: selectedAttachment.id,
  visibleName: `${selectedAttachment.classifyVisibleName}.${selectedAttachment.extension}`,
  cdaContent: selectedAttachment.cdaContent?.map(
    (
      selectedCdaAttachment: MailClassificationCdaAttachment,
    ): ApiMailClassifyCdaAttachmentResource => ({
      id: selectedCdaAttachment.id,
      visibleName: `${selectedCdaAttachment.classifyVisibleName}.${selectedCdaAttachment.extension}`,
    }),
  ),
})
