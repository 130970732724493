import { AuditFields } from './Resource'
import { IconsType } from '../components/shared'
import { User } from './User'
import { ColorsV2TagText } from '../design-system/colors_v2'

export enum MessagesTypes {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
  Valid = 'valid',
}

export type MessageSeverity = 'error' | 'warn' | 'info' | 'valid'

export enum NotificationType {
  feature = 'feature',
  announcement = 'announcement',
  incident = 'incident',
}

export const NotificationTypeLabel: { [property in NotificationType]: string } = {
  [NotificationType.feature]: `Nouveautés`,
  [NotificationType.announcement]: `Annonce`,
  [NotificationType.incident]: `Incident`,
} as const

export const NotificationTypeColor: { [property in NotificationType]: string } = {
  [NotificationType.feature]: ColorsV2TagText.dsTag03,
  [NotificationType.announcement]: ColorsV2TagText.dsTag05,
  [NotificationType.incident]: ColorsV2TagText.dsTag07,
} as const

export const NotificationTypeMessageMapping: { [property in NotificationType]: string } = {
  [NotificationType.feature]: MessagesTypes.Info,
  [NotificationType.announcement]: MessagesTypes.Info,
  [NotificationType.incident]: MessagesTypes.Warn,
} as const

export const NotificationTypeIcon: { [property in NotificationType]: IconsType } = {
  [NotificationType.feature]: 'gift',
  [NotificationType.announcement]: 'bell',
  [NotificationType.incident]: 'warningTriangle',
} as const

export interface ServerSentNotification extends AuditFields {
  id: string
  title: string
  type: string
  content: string
  publishedAt: string
  read: boolean
  recipients: Array<User>
  creator: User
}

export interface ServerSentNotificationTypeOptions {
  label: string
  value: NotificationType
  icon: IconsType
  color: string
}

export const NOTIFICATION_TYPE_OPTIONS = Object.entries(NotificationTypeLabel).map(
  ([label, value]) => {
    return {
      label: value,
      value: label,
      icon: NotificationTypeIcon[label],
      color: NotificationTypeColor[label],
    } as ServerSentNotificationTypeOptions
  },
)
