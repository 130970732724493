import React, { FC, useRef } from 'react'
import { Icon } from '../Icon'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import { HorizontalScrollerProps } from './HorizontalScroller.model'

export const HorizontalScroller: FC<HorizontalScrollerProps> = ({ renderTitle, children }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const scrollHorizontally = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      const scrollPosition = containerRef.current.scrollLeft
      const delta = containerRef.current.clientWidth * 0.5
      const signedDelta = direction === 'right' ? delta : delta * -1
      containerRef.current.scrollTo({
        left: scrollPosition + signedDelta,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className="w-full max-w-full">
      <div className="flex justify-between items-center w-full">
        {renderTitle()}
        <div className="space-x-3 p-1 flex">
          <div className="cursor-pointer" onClick={() => scrollHorizontally('left')}>
            <Icon
              icon="chevron"
              size="nano"
              rotate={90}
              color={getV3ColorCssVariable('shades', 'shade6')}
            />
          </div>
          <div className="cursor-pointer" onClick={() => scrollHorizontally('right')}>
            <Icon
              icon="chevron"
              size="nano"
              rotate={-90}
              color={getV3ColorCssVariable('shades', 'shade6')}
            />
          </div>
        </div>
      </div>
      <div ref={containerRef} className="overflow-x-hidden w-full">
        {children}
      </div>
    </div>
  )
}
