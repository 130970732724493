import getApi from '../../api/apisauceConfig'
import { autocompletionPageLimit } from '../../constants'
import { Contact } from '../../model/Contact'
import { Filters } from '../../model/Filters'
import { PaginatedList } from '../../model/Pagination'

export const getContacts = (
  page: number,
  filters?: Filters,
  signal?: AbortSignal,
  limit = autocompletionPageLimit,
) => getApi().get<PaginatedList<Contact>>(`/contacts`, { page, limit, ...filters }, { signal })
