import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { DatePicker, Input, SelectInput, Switch } from '../../../shared'
import { HealthDataModalInputWrapper } from '../../HealthDataModalInputWrapper'
import {
  computeTypeLabel,
  convertFloatToDisplayFormat,
  findBloodTypeOption,
  findHeartFailureOption,
  findLiverFailureOption,
  findRenalFailureOption,
} from '../../../../misc/healthData.utilities'
import {
  BLOOD_TYPE_OPTIONS,
  HealthDataType,
  LIVER_FAILURE_OPTIONS,
  HEART_FAILURE_OPTIONS,
  RENAL_FAILURE_OPTIONS,
} from '../../../../model/HealthData'
import { DATE_FORMAT_FRONT_SHORT, formatFr } from '../../../../misc/date.utilities'
import styles from './HealthDataMedicalInformations.module.scss'
import { HealthDataMedicalInformationsProps } from './HealthDataMedicalInformations.model'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'
import { isDoctor } from '../../../../misc/user.utilities'
import { useGetInfinitePatientHealthData } from '../../../../hooks/queries/patientHealthData'

const regexValidation = (value: string, regex: RegExp | null) => {
  return regex ? regex.test(value) : true
}

export const HealthDataMedicalInformations: FunctionComponent<
  HealthDataMedicalInformationsProps
> = ({
  patient,
  indexedTypes,
  setInputChanged,
  setSelectedHealthDataType,
  validation,
  setValidation,
  setFields,
}) => {
  const [weight, setWeight] = useState<string>()
  const [size, setSize] = useState<string>()
  const [dfg, setDfg] = useState<string>()
  const [bloodType, setBloodType] = useState<string>()
  const [systolicPressure, setSystolicPressure] = useState<number>()
  const [diastolicPressure, setDiastolicPressure] = useState<number>()
  const [liverFailure, setLiverFailure] = useState<string>()
  const [renalFailure, setRenalFailure] = useState<string>()
  const [heartFailure, setHeartFailure] = useState<string>()
  const [creatinineLevel, setCreatinineLevel] = useState<string>()
  const [clearance, setClearance] = useState<string>()
  const [pregnancy, setPregnancy] = useState<boolean>()
  const [pregnancyDate, setPregnancyDate] = useState<Date | null>(null)
  const [breastFeeding, setBreastFeeding] = useState<boolean>()
  const [breastFeedingDate, setBreastFeedingDate] = useState<Date | null>(null)
  const [lastPeriod, setLastPeriod] = useState<Date | null>(null)

  const { currentUser } = useContext(ConnectedUserContext)
  const { indexedValues } = useGetInfinitePatientHealthData({
    patientId: patient.id,
  })
  useEffect(() => {
    if (
      weight ||
      size ||
      dfg ||
      bloodType ||
      systolicPressure ||
      diastolicPressure ||
      liverFailure ||
      renalFailure ||
      heartFailure ||
      creatinineLevel ||
      clearance ||
      pregnancy !== undefined ||
      breastFeeding !== undefined ||
      lastPeriod ||
      breastFeedingDate ||
      pregnancyDate
    ) {
      setInputChanged(true)
      setFields([
        { value: weight, type: indexedTypes.weight },
        { value: size, type: indexedTypes.size },
        { value: dfg, type: indexedTypes.dfg },
        { value: bloodType, type: indexedTypes.blood_type },
        { value: systolicPressure, type: indexedTypes.systolic_pressure },
        { value: diastolicPressure, type: indexedTypes.diastolic_pressure },
        { value: liverFailure, type: indexedTypes.liver_failure },
        { value: renalFailure, type: indexedTypes.renal_failure },
        { value: heartFailure, type: indexedTypes.heart_failure },
        { value: creatinineLevel, type: indexedTypes.creatinine_level },
        { value: clearance, type: indexedTypes.clearance },
        { value: pregnancy, type: indexedTypes.pregnancy },
        {
          value: pregnancyDate,
          type: indexedTypes.pregnancy_date,
          parentId: indexedTypes.pregnancy_date?.parentId,
        },
        { value: breastFeeding, type: indexedTypes.breastfeeding },
        {
          value: breastFeedingDate,
          type: indexedTypes.breastfeeding_date,
          parentId: indexedTypes.breastfeeding_date?.parentId,
        },
        { value: lastPeriod, type: indexedTypes.last_period_at },
      ])
    } else {
      setInputChanged(false)
    }
  }, [
    weight,
    size,
    dfg,
    bloodType,
    systolicPressure,
    diastolicPressure,
    liverFailure,
    renalFailure,
    heartFailure,
    creatinineLevel,
    clearance,
    pregnancy,
    breastFeeding,
    lastPeriod,
    breastFeedingDate,
    pregnancyDate,
    setInputChanged,
    indexedTypes,
    setFields,
  ])

  const getValidationValue = useCallback(
    (value: string, healthDataType?: HealthDataType) =>
      value === ''
        ? undefined
        : !!healthDataType && regexValidation(value, healthDataType.validationPattern),
    [],
  )

  return (
    <div className={styles.column}>
      <span>Vous pouvez ici mettre à jour les données médicales relatives à votre patient</span>
      {indexedTypes.weight && (
        <HealthDataModalInputWrapper
          value={indexedValues.weight}
          type={indexedTypes.weight}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="weight"
              label={computeTypeLabel(indexedTypes.weight)}
              colorPreset="light"
              valid={validation.weight}
              value={weight ?? ''}
              autocomplete="off"
              placeholder={
                indexedValues.weight
                  ? convertFloatToDisplayFormat(indexedValues.weight.value.value)
                  : undefined
              }
              onChange={(event) => {
                const value = convertFloatToDisplayFormat(event.target.value, false)
                setWeight(value)
                if (value !== undefined) {
                  setValidation((old) => ({
                    ...old,
                    weight:
                      !!indexedTypes.weight &&
                      regexValidation(value, indexedTypes.weight.validationPattern),
                  }))
                }
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.size && (
        <HealthDataModalInputWrapper
          value={indexedValues.size}
          type={indexedTypes.size}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="size"
              label={computeTypeLabel(indexedTypes.size)}
              colorPreset="light"
              value={size ?? ''}
              valid={validation.size}
              autocomplete="off"
              placeholder={
                indexedValues.size
                  ? convertFloatToDisplayFormat(indexedValues.size.value.value)
                  : undefined
              }
              onChange={(event) => {
                const value = convertFloatToDisplayFormat(event.target.value, false)
                setSize(value)
                if (value !== undefined) {
                  setValidation((old) => ({
                    ...old,
                    size:
                      !!indexedTypes.size &&
                      regexValidation(value, indexedTypes.size.validationPattern),
                  }))
                }
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.bmi && indexedValues.bmi && (
        <HealthDataModalInputWrapper
          value={indexedValues.bmi}
          type={indexedTypes.bmi}
          onClickHistory={setSelectedHealthDataType}
          disabled
        >
          <div className={styles.number_input_layout}>
            <Input
              disabled
              name="bmi"
              label={computeTypeLabel(indexedTypes.bmi)}
              colorPreset="light"
              valid={validation.bmi}
              value={convertFloatToDisplayFormat(indexedValues.bmi.value.value)}
              autocomplete="off"
              placeholder={convertFloatToDisplayFormat(indexedValues.bmi.value.value)}
              onChange={() => {}}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.liver_failure && (
        <HealthDataModalInputWrapper
          value={indexedValues.liver_failure}
          type={indexedTypes.liver_failure}
          onClickHistory={setSelectedHealthDataType}
        >
          <SelectInput
            title={indexedTypes.liver_failure.name}
            placeholder={indexedValues.liver_failure?.value.value}
            options={LIVER_FAILURE_OPTIONS}
            value={liverFailure ? findLiverFailureOption(liverFailure) : undefined}
            onSelect={(selected) => setLiverFailure(selected?.value)}
          />
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.renal_failure && (
        <HealthDataModalInputWrapper
          value={indexedValues.renal_failure}
          type={indexedTypes.renal_failure}
          onClickHistory={setSelectedHealthDataType}
        >
          <SelectInput
            title={indexedTypes.renal_failure.name}
            placeholder={indexedValues.renal_failure?.value.value}
            options={RENAL_FAILURE_OPTIONS}
            value={renalFailure ? findRenalFailureOption(renalFailure) : undefined}
            onSelect={(selected) => setRenalFailure(selected?.value)}
          />
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.blood_pressure && (
        <HealthDataModalInputWrapper
          value={indexedValues.blood_pressure}
          type={indexedTypes.blood_pressure}
          onClickHistory={setSelectedHealthDataType}
          disabled
        >
          <div className={styles.number_input_layout}>
            <Input
              disabled
              name="blood_pressure"
              label={computeTypeLabel(indexedTypes.blood_pressure)}
              colorPreset="light"
              valid={validation.blood_pressure}
              value={indexedValues.blood_pressure?.value.value}
              autocomplete="off"
              placeholder={indexedValues.blood_pressure?.value.value}
              onChange={() => {}}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.systolic_pressure && (
        <HealthDataModalInputWrapper
          value={indexedValues.systolic_pressure}
          type={indexedTypes.systolic_pressure}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="systolic_pressure"
              label={computeTypeLabel(indexedTypes.systolic_pressure)}
              colorPreset="light"
              valid={validation.systolic_pressure}
              value={systolicPressure ?? ''}
              autocomplete="off"
              placeholder={
                indexedValues.systolic_pressure
                  ? indexedValues.systolic_pressure.value.value
                  : undefined
              }
              onChange={(event) => {
                const value = event.target.value
                setSystolicPressure((value && parseInt(value)) || undefined)
                setValidation((old) => ({
                  ...old,
                  systolicPressure: getValidationValue(value, indexedTypes.systolic_pressure),
                }))
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}
      {indexedTypes.diastolic_pressure && (
        <HealthDataModalInputWrapper
          value={indexedValues.diastolic_pressure}
          type={indexedTypes.diastolic_pressure}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="diastolic_pressure"
              label={computeTypeLabel(indexedTypes.diastolic_pressure)}
              colorPreset="light"
              valid={validation.diastolic_pressure}
              value={diastolicPressure ?? ''}
              autocomplete="off"
              placeholder={
                indexedValues.diastolic_pressure
                  ? indexedValues.diastolic_pressure.value.value
                  : undefined
              }
              onChange={(event) => {
                const value = event.target.value
                setDiastolicPressure((value && parseInt(value)) || undefined)
                setValidation((old) => ({
                  ...old,
                  diastolicPressure: getValidationValue(value, indexedTypes.diastolic_pressure),
                }))
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}

      {indexedTypes.heart_failure && (
        <HealthDataModalInputWrapper
          value={indexedValues.heart_failure}
          type={indexedTypes.heart_failure}
          onClickHistory={setSelectedHealthDataType}
        >
          <SelectInput
            title={indexedTypes.heart_failure.name}
            placeholder={indexedValues.heart_failure?.value.value}
            options={HEART_FAILURE_OPTIONS}
            value={heartFailure ? findHeartFailureOption(heartFailure) : undefined}
            onSelect={(selected) => setHeartFailure(selected?.value)}
          />
        </HealthDataModalInputWrapper>
      )}

      {indexedTypes.dfg && (
        <HealthDataModalInputWrapper
          value={indexedValues.dfg}
          type={indexedTypes.dfg}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="dfg"
              label={computeTypeLabel(indexedTypes.dfg)}
              colorPreset="light"
              value={dfg ?? ''}
              valid={validation.dfg}
              autocomplete="off"
              placeholder={
                indexedValues.dfg
                  ? convertFloatToDisplayFormat(indexedValues.dfg.value.value)
                  : undefined
              }
              onChange={(event) => {
                const value = convertFloatToDisplayFormat(event.target.value, false)
                setDfg(value)
                if (value !== undefined) {
                  setValidation((old) => ({
                    ...old,
                    dfg:
                      !!indexedTypes.dfg &&
                      regexValidation(value, indexedTypes.dfg.validationPattern),
                  }))
                }
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}

      {indexedTypes.blood_type && (
        <HealthDataModalInputWrapper
          value={indexedValues.blood_type}
          type={indexedTypes.blood_type}
          onClickHistory={setSelectedHealthDataType}
        >
          <SelectInput
            title={indexedTypes.blood_type.name}
            placeholder={indexedValues.blood_type?.value.value}
            options={BLOOD_TYPE_OPTIONS}
            value={bloodType ? findBloodTypeOption(bloodType) : undefined}
            onSelect={(selected) => setBloodType(selected?.value)}
            testId={`health-data-blood-type-select`}
          />
        </HealthDataModalInputWrapper>
      )}

      {indexedTypes.creatinine_level && (
        <HealthDataModalInputWrapper
          value={indexedValues.creatinine_level}
          type={indexedTypes.creatinine_level}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="creatinine_level"
              label={computeTypeLabel(indexedTypes.creatinine_level)}
              colorPreset="light"
              value={creatinineLevel ?? ''}
              valid={validation.creatinine_level}
              autocomplete="off"
              placeholder={
                indexedValues.creatinine_level
                  ? convertFloatToDisplayFormat(indexedValues.creatinine_level.value.value)
                  : undefined
              }
              onChange={(event) => {
                const value = convertFloatToDisplayFormat(event.target.value, false)
                setCreatinineLevel(value)
                setValidation((old) => ({
                  ...old,
                  creatinineLevel:
                    !!indexedTypes.creatinine_level &&
                    regexValidation(value, indexedTypes.creatinine_level.validationPattern),
                }))
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}

      {indexedTypes.clearance && (
        <HealthDataModalInputWrapper
          value={indexedValues.clearance}
          type={indexedTypes.clearance}
          onClickHistory={setSelectedHealthDataType}
        >
          <div className={styles.number_input_layout}>
            <Input
              name="clearance"
              label={computeTypeLabel(indexedTypes.clearance)}
              colorPreset="light"
              value={clearance ?? ''}
              valid={validation.clearance}
              autocomplete="off"
              placeholder={
                indexedValues.clearance
                  ? convertFloatToDisplayFormat(indexedValues.clearance.value.value)
                  : undefined
              }
              onChange={(event) => {
                const value = convertFloatToDisplayFormat(event.target.value, false)
                setClearance(value)
                setValidation((old) => ({
                  ...old,
                  clearance:
                    !!indexedTypes.clearance &&
                    regexValidation(value, indexedTypes.clearance.validationPattern),
                }))
              }}
            />
          </div>
        </HealthDataModalInputWrapper>
      )}

      {isDoctor(currentUser) && !currentUser.preferences.enabledFeatures.gynecology && (
        <>
          {patient.sex !== 'MALE' && (
            <>
              {indexedTypes.breastfeeding && (
                <HealthDataModalInputWrapper
                  value={indexedValues.breastfeeding}
                  type={indexedTypes.breastfeeding}
                  onClickHistory={setSelectedHealthDataType}
                >
                  <div className={styles.input_layout}>
                    <Switch
                      name="Allaitement"
                      checked={breastFeeding ?? indexedValues?.breastfeeding?.value.value}
                      onChange={(value) => setBreastFeeding(value)}
                    />
                  </div>
                </HealthDataModalInputWrapper>
              )}
              {indexedTypes.breastfeeding_date &&
                (breastFeeding ?? indexedValues?.breastfeeding?.value.value) && (
                  <HealthDataModalInputWrapper
                    value={indexedValues.breastfeeding_date}
                    type={indexedTypes.breastfeeding_date}
                    onClickHistory={setSelectedHealthDataType}
                  >
                    <DatePicker
                      label="Date de début d'allaitement"
                      placeholder={
                        indexedValues.breastfeeding_date?.value
                          ? formatFr(
                              indexedValues.breastfeeding_date.value.value,
                              DATE_FORMAT_FRONT_SHORT,
                            )
                          : 'jj/mm/aaaa'
                      }
                      value={breastFeedingDate}
                      onChange={(value) => setBreastFeedingDate(value)}
                      showCalendarButton={false}
                    />
                  </HealthDataModalInputWrapper>
                )}
              {indexedTypes.pregnancy && (
                <HealthDataModalInputWrapper
                  value={indexedValues.pregnancy}
                  type={indexedTypes.pregnancy}
                  onClickHistory={setSelectedHealthDataType}
                >
                  <div className={styles.input_layout}>
                    <Switch
                      name="Grossesse"
                      checked={pregnancy ?? indexedValues.pregnancy?.value?.value}
                      onChange={(value) => setPregnancy(value)}
                    />
                  </div>
                </HealthDataModalInputWrapper>
              )}
              {indexedTypes.pregnancy_date &&
                (pregnancy ?? indexedValues.pregnancy?.value?.value) && (
                  <HealthDataModalInputWrapper
                    value={indexedValues.pregnancy_date}
                    type={indexedTypes.pregnancy_date}
                    onClickHistory={setSelectedHealthDataType}
                  >
                    <DatePicker
                      label="Date de début de Grossesse"
                      placeholder={
                        indexedValues.pregnancy_date?.value
                          ? formatFr(
                              indexedValues.pregnancy_date.value.value,
                              DATE_FORMAT_FRONT_SHORT,
                            )
                          : 'jj/mm/aaaa'
                      }
                      value={pregnancyDate}
                      onChange={(value) => setPregnancyDate(value)}
                      showCalendarButton={false}
                    />
                  </HealthDataModalInputWrapper>
                )}
              {indexedTypes.last_period_at && (
                <HealthDataModalInputWrapper
                  value={indexedValues.last_period_at}
                  type={indexedTypes.last_period_at}
                  onClickHistory={setSelectedHealthDataType}
                >
                  <DatePicker
                    label="Date des dernières règles"
                    placeholder={
                      indexedValues.last_period_at?.value
                        ? formatFr(
                            indexedValues.last_period_at.value.value,
                            DATE_FORMAT_FRONT_SHORT,
                          )
                        : 'jj/mm/aaaa'
                    }
                    value={lastPeriod}
                    onChange={(value) => setLastPeriod(value)}
                    showCalendarButton={false}
                  />
                </HealthDataModalInputWrapper>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
