import React, { memo, FunctionComponent } from 'react'

import { ColorsV2TagBackground } from '../../../../design-system/colors_v2'

import { TagColor } from '../TagColor'

import { TagColorListProps } from './TagColorList.model'
import styles from './TagColorList.module.scss'

export const TagColorList: FunctionComponent<TagColorListProps> = memo(
  ({ color, onChange, disabled = false }) => (
    <div>
      <label>Couleur de fond</label>
      <div className={styles.colors}>
        {Object.values(ColorsV2TagBackground).map((current, key) => (
          <TagColor
            key={key}
            active={color === current}
            color={current}
            onClick={onChange}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  ),
)
