import React, { FunctionComponent } from 'react'
import { MoreButtonProps } from './MoreButton.model'
import styles from './MoreButton.module.scss'
import { Icon } from '../../Icon'
import { ColorsV2 } from '../../../../design-system/colors_v2'

export const MoreButton: FunctionComponent<MoreButtonProps> = ({
  children,
  onChange,
  showingMore,
}) => (
  <button className={styles.container} onClick={onChange} data-test-id="button-more">
    <div className={styles.label}>{children}</div>
    <Icon
      size="femto"
      icon="chevron"
      color={ColorsV2.contentDark1}
      rotate={showingMore ? 180 : 0}
    ></Icon>
  </button>
)
