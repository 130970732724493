import React, { FC, useMemo } from 'react'
import { Icon, Tag } from '../../../shared'
import { ColorsV2 } from '../../../../design-system/colors_v2'
import { Question } from '../../../../model/Questionnaire'
import styles from './QuestionListItem.module.scss'

const labelizer = (list: Array<{ title: string }>, limit = 5) => {
  const more = list.length - limit
  const moreLabel = list.length > limit ? `, +${more} autre${more > 1 ? 's' : ''}` : null
  const labels = list.filter((_, index) => index < limit).map(({ title }) => title)
  return moreLabel ? `${labels.join(', ')} ${moreLabel}` : labels.join(', ')
}

export const QuestionItem: FC<{ item: Question }> = ({ item }) => {
  const tagLabel = item.private ? 'Privé' : 'Public'
  const questionnairesLabel = useMemo(() => labelizer(item.questionnaires), [item.questionnaires])
  const modelsLabel = useMemo(() => labelizer(item.documentTemplates), [item.documentTemplates])
  return (
    <div className={styles.container}>
      <div className={styles.firstLine}>
        <span className={styles.questionTitle}>{item.title}</span>
        <Tag>{tagLabel}</Tag>
      </div>
      {questionnairesLabel && (
        <div className={styles.questionnaires}>
          <Icon icon="questionnaire" size="nano" color={ColorsV2.dsGreyBlue} />
          <span className="ml-2">{questionnairesLabel}</span>
        </div>
      )}
      {modelsLabel && (
        <div className={styles.questionnaires}>
          <Icon icon="checkup" size="nano" color={ColorsV2.dsGreyBlue} />
          <span className="ml-2">{modelsLabel}</span>
        </div>
      )}
    </div>
  )
}
