import { useMutation } from '@tanstack/react-query'
import { initiateAuthenticationMail } from '../../../../data/mail'
import { UserFriendlyError } from '../../../../model/Error'
import { MailWebServiceLoginFormFields, MailWebServiceLoginResponse } from '../../../../model/Mail'
import { mailErrorsMessage, mailErrorsTitle } from '../mail.errors'

export const useInitiateMailAuthentication = () => {
  const mutation = useMutation<MailWebServiceLoginResponse, Error, MailWebServiceLoginFormFields>({
    mutationFn: async (variables) => {
      const { ok, data } = await initiateAuthenticationMail(variables)

      if (!ok || !data) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.connexionError,
          mailErrorsMessage.checkCredentials,
        )
      }

      return data
    },
  })

  return mutation
}
