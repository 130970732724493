import React, { FunctionComponent } from 'react'
import classNames from 'classnames/bind'
import { ScorePercentageProps } from './ScorePercentage.model'
import { getColorByScoreConfigAndValue } from '../../../../../model/Questionnaire'
import { isDefined } from '../../../../../misc/functions.utilities'
import { useGetQuestionnaireInstanceScore } from '../../../../../hooks/queries/questionnaireInstances/getQuestionnaireInstanceScore.query'
import styles from './ScorePercentage.module.scss'

const classNamesCx = classNames.bind(styles)

export const ScorePercentage: FunctionComponent<ScorePercentageProps> = ({
  documentId,
  questionnaireId,
  scoreConfig,
}) => {
  const {
    query: { data: scoreValue },
  } = useGetQuestionnaireInstanceScore({
    documentId,
    questionnaireId,
    enabled: isDefined(documentId) && isDefined(questionnaireId),
  })

  if (!isDefined(scoreValue)) {
    return null
  }

  const label = scoreConfig.percentage
    ? `${Math.round(scoreValue)}%`
    : scoreConfig.noRounding
      ? scoreValue
      : Math.round(scoreValue)

  const className = getColorByScoreConfigAndValue(scoreConfig, scoreValue)

  return <span className={classNamesCx(styles.scoreValue, className)}>{label}</span>
}
