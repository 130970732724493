import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { isDefined } from '../../../misc/functions.utilities'
import { Mail } from '../../../model/Mail'
import { TaskCategory } from '../../../model/Task'
import { useCreateTask } from '../task'
import { insiKeys } from './insi.keys'
import { useInsiIdentityCheck } from './useInsiIdentityCheck.query'

interface UseAutomaticIdentityCheckParams {
  mail: Mail
  enabled?: boolean
}

export const useAutomaticIdentityCheck = ({ mail, enabled }: UseAutomaticIdentityCheckParams) => {
  const queryKey = useMemo(() => insiKeys.patientMail(mail.id), [mail.id])

  const { mutateAsync: insiIdentityCheck } = useInsiIdentityCheck()
  const { mutateAsync: createTask } = useCreateTask()

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (!isDefined(mail.patient)) {
        throw new Error('INSi auto check : no patient')
      }

      try {
        const identityCheck = await insiIdentityCheck({ patient: mail.patient })
        return identityCheck
      } catch (e) {
        await createTask({
          title: mail.patient.id
            ? "Échec de la validation de l'identité via l'INSi"
            : `Échec de la validation de l'identité via l'INSi pour le patient ${mail.patient.birthFirstName} ${mail.patient.birthLastName}`,
          category: TaskCategory.INFORMATION,
          patientId: mail.patient.id ?? undefined,
          autoCreated: true,
          isNotify: true,
        })

        return null
      }
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: enabled && isDefined(mail.patient),
  })

  return { query }
}
