import { ApiResponse } from 'apisauce'

export interface OrdoclicUrlResource {
  url: string
}

export const deserializeOrdoclicUrlResponse = (
  response: ApiResponse<OrdoclicUrlResource>,
): ApiResponse<string> => {
  const { data, ...responseRest } = response

  if (!response.ok || !data) {
    return { ...responseRest }
  }

  return {
    ...responseRest,
    data: data.url,
  }
}
