import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { messageTemplatesKeys } from './messageTemplates.keys'
import { deleteMessageTemplate } from '../../../data/messageTemplates/deleteMessageTemplate'

interface MessageTemplateMutateVariable {
  messageTemplateId: number
}

export const useDeleteMessageTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ messageTemplateId }: MessageTemplateMutateVariable) => {
      const { ok } = await deleteMessageTemplate(messageTemplateId)
      if (!ok) {
        throw new UserFriendlyError('error', 'La suppression du modèle de message a échoué')
      }
      return null
    },

    onSuccess: (_, { messageTemplateId }) => {
      queryClient.invalidateQueries({ queryKey: messageTemplatesKeys.list })
      queryClient.removeQueries({ queryKey: messageTemplatesKeys.detail(messageTemplateId) })
      queryClient.invalidateQueries({ queryKey: messageTemplatesKeys.defaultMessage })
    },
  })
}
