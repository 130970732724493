import { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { MedicalEventHeaderProps } from './MedicalEventHeader.model'

import { IconButton, TooltipWrapper } from '../../../../shared'
import { Space } from './Space'

import styles from './MedicalEventHeader.module.scss'
import { DATE_FORMAT_FRONT_SHORT, formatFr } from '../../../../../misc/date.utilities'
import { getFullName, isDoctor } from '../../../../../misc/user.utilities'
import { ConnectedUserContext } from '../../../../../misc/auth.utilities'
import { hasDoctorRole } from '../../../../../misc/roles.utilities'
import { PatientPageHeader } from '../../PatientPageNavBar/PatientPageHeader'
import { useNavigate } from 'react-router-dom'
import { SearchMedicalEventDocumentsBottomPanel } from '../../../../shared/bottomPanel/SearchMedicalEventDocumentsBottomPanel'
import { useDownloadDocuments, usePrintDocuments } from '../../../../../hooks/queries/document'
import { DocumentInstanceListItem, SendableDocument } from '../../../../../model/DocumentInstance'
import { getRenderedPdfFileName } from '../../../../../misc/files.utilities'
import { BasicRenderMode } from '../../../../../model/Document'
import { CustomFile } from '../../../../../model/File'
import { SendFileModal } from '../../../../medicalEvent'
import { isDefined } from '../../../../../misc/functions.utilities'
import { UrlQueryParams } from '../../../../../store/domain/medicalEvents'
import { useGetMailSubject, useTestMailConnection } from '../../../../../hooks/queries/mail'
import { MailEditor } from '../../../../mail'
import { getQueryParam } from '../../../../../misc/url.utilities'

interface InitialMailState {
  content?: string
  object?: string
  emails?: Array<string>
  documents?: Array<SendableDocument>
  fileIds?: Array<number>
}

export const MedicalEventHeader: FunctionComponent<MedicalEventHeaderProps> = ({
  patient,
  medicalEvent,
  userEnabledFeatures,
  editMedicalEvent,
  isOwner,
  onLock,
}) => {
  const [
    isOpenSearchMedicalEventDocumentsBottomPanel,
    setIsOpenSearchMedicalEventDocumentsBottomPanel,
  ] = useState<boolean | BasicRenderMode>(false)
  const [showSendFileModal, setShowSendFileModal] = useState(
    !!getQueryParam(UrlQueryParams.SENDING),
  )

  const [initialMail, setInitialMail] = useState<InitialMailState>()

  const { loggedUser, currentUser } = useContext(ConnectedUserContext)
  const allowedToLock = loggedUser && isOwner && hasDoctorRole(loggedUser.roles)
  const navigate = useNavigate()
  const print = usePrintDocuments()
  const download = useDownloadDocuments()
  const { mutate: testMailConnection } = useTestMailConnection()
  const { mutateAsync: getMailSubject, isPending: isMailSubjectLoading } = useGetMailSubject()

  const tooltipContent = useMemo(() => {
    if (userEnabledFeatures?.mail) {
      if (userEnabledFeatures.mssSmtp && isDoctor(currentUser) && !currentUser.mailizEmailAddress) {
        return "Vous devez saisir votre adresse MSS Mailiz dans vos informations de compte afin d'accéder à votre boîte de réception dans Follow. Cliquez sur le bouton pour saisir une adresse email"
      }

      return !patient?.inseeNumber
        ? 'Numéro de sécurité sociale du patient manquant'
        : 'Le patient ne souhaite pas être contacté ou que ses informations soient échangées via la messagerie de santé sécurisée'
    }
  }, [currentUser, patient?.inseeNumber, userEnabledFeatures])

  const handlePrint = useCallback(
    ({ documents, files }: { documents: DocumentInstanceListItem[]; files: CustomFile[] }) => {
      print({
        documents: documents,
        files: files,
      })
    },
    [print],
  )

  const handleDownload = useCallback(
    ({ documents, files }: { documents: DocumentInstanceListItem[]; files: CustomFile[] }) => {
      download({
        fileName: getRenderedPdfFileName(patient),
        documents: documents,
        files: files,
      })
    },
    [download, patient],
  )

  const handleSelectItems = useCallback(
    ({
      documents,
      files,
    }: {
      documents: readonly DocumentInstanceListItem[]
      files: readonly CustomFile[]
    }) =>
      isOpenSearchMedicalEventDocumentsBottomPanel === 'download'
        ? handleDownload({ documents: [...documents], files: [...files] })
        : handlePrint({ documents: [...documents], files: [...files] }),
    [handleDownload, handlePrint, isOpenSearchMedicalEventDocumentsBottomPanel],
  )
  const handleSendMail = useCallback(() => {
    testMailConnection(undefined, {
      onSuccess: (data) => {
        if (data) {
          setShowSendFileModal(true)
        }
      },
    })
  }, [testMailConnection])

  if (!patient) {
    return null
  }

  const handleSubmitEvent = async (
    includeObservations: boolean,
    addresses: Array<string>,
    documents?: Array<SendableDocument>,
    fileIds?: Array<number>,
  ) => {
    const object = !!documents?.length
      ? await getMailSubject({ documentIds: documents.map(({ id }) => id) })
      : undefined

    setInitialMail({
      content: includeObservations ? medicalEvent?.observation : undefined,
      emails: addresses,
      documents,
      object,
      fileIds,
    })
  }

  return (
    <>
      <div className={styles.contentWrapper}>
        <PatientPageHeader patient={patient} />
        {medicalEvent && (
          <div className={styles.medicalEventHeader}>
            {!isOwner && (
              <>
                <div className={styles.ownerFullName}>{getFullName(medicalEvent.owner)}</div>
                <Space>{`•`}</Space>
              </>
            )}
            <div className={styles.medicalEventTitle}>
              {`${medicalEvent.title}, `}
              {formatFr(new Date(medicalEvent.date), DATE_FORMAT_FRONT_SHORT)}
            </div>
            <Space />
            {medicalEvent.isEditable && (
              <div className={styles.action}>
                <IconButton onClick={() => editMedicalEvent()} icon="pencil" theme="dark" />
              </div>
            )}
            {allowedToLock && (
              <div className={styles.action}>
                <IconButton
                  onClick={() => onLock(!medicalEvent.locked)}
                  title={
                    medicalEvent.locked ? 'Cliquer pour déverrouiller' : 'Cliquer pour verrouiller'
                  }
                  icon={medicalEvent.locked ? 'lock' : 'unlock'}
                  theme="dark"
                />
              </div>
            )}
            <div className={styles.action}>
              <IconButton
                onClick={() => setIsOpenSearchMedicalEventDocumentsBottomPanel('print')}
                icon="print"
                theme="dark"
              />
            </div>
            {userEnabledFeatures?.mail && (
              <div className={styles.action}>
                <TooltipWrapper
                  content={tooltipContent}
                  display={
                    !patient.inseeNumber ||
                    patient.disallowMSSMessaging ||
                    (userEnabledFeatures.mssSmtp &&
                      isDoctor(currentUser) &&
                      !currentUser.mailizEmailAddress)
                  }
                  pointerDirection="right"
                >
                  {userEnabledFeatures.mssSmtp &&
                  isDoctor(currentUser) &&
                  !currentUser.mailizEmailAddress ? (
                    <div>
                      <IconButton
                        onClick={() => navigate('/account/settings?focus=mailizEmailAddress')}
                        icon="mail"
                        theme="dark"
                      />
                    </div>
                  ) : (
                    <div>
                      <IconButton
                        onClick={handleSendMail}
                        icon="mail"
                        theme="dark"
                        disabled={!patient.inseeNumber || patient.disallowMSSMessaging}
                      />
                    </div>
                  )}
                </TooltipWrapper>
              </div>
            )}
            <div className={styles.action}>
              <IconButton
                onClick={() => setIsOpenSearchMedicalEventDocumentsBottomPanel('download')}
                icon="save"
                theme="primary"
              />
            </div>
            <SearchMedicalEventDocumentsBottomPanel
              display={!!isOpenSearchMedicalEventDocumentsBottomPanel}
              onRequestClose={() => setIsOpenSearchMedicalEventDocumentsBottomPanel(false)}
              medicalEvent={medicalEvent}
              title={
                isOpenSearchMedicalEventDocumentsBottomPanel === 'download'
                  ? 'Sélectionner les documents à télécharger'
                  : 'Sélectionner les documents à imprimer'
              }
              onSelect={handleSelectItems}
            />
          </div>
        )}
      </div>
      <SendFileModal
        display={showSendFileModal || isMailSubjectLoading}
        onClose={() => setShowSendFileModal(false)}
        onSubmit={handleSubmitEvent}
        currentMedicalEvent={medicalEvent}
      />
      <MailEditor
        display={isDefined(initialMail)}
        onClose={() => setInitialMail(undefined)}
        medicalEventId={medicalEvent?.id}
        initialPatient={patient}
        initialContent={initialMail?.content}
        initialRecipients={initialMail?.emails}
        initialDocuments={initialMail?.documents}
        initialSubject={initialMail?.object}
        initialFileIds={initialMail?.fileIds}
      />
    </>
  )
}
