import { FC, useCallback, useMemo } from 'react'
import { doctolibZipperOpenCalendar } from '../../../../../../misc/Doctolib/doctolib.actions'
import { AgendaTabProps } from './AgendaTab.model'
import { useDoctolibStatus } from '../../../../../../hooks/utils'
import { TabStatus } from '../Tab/Tab.model'
import { DOCTOLIB_APP_URL } from '../../../../../../environment/doctolib'
import { NavTab } from '../NavTab'
import { ActionTab } from '../ActionTab'
import { AGENDIZE_APP_URL } from '../../../../../../environment/agendize'
import { useAtomValue } from 'jotai'
import { zipperStatusAtom } from '../../../../../../state/doctolib'
import { DoctolibZipperStatus } from '../../../../../../model/Doctolib'

const DOCTOLIB_ERROR_LABEL =
  "Veuillez vérifier que vous êtes bien connecté(e) à votre agenda Doctolib et que l'extension est installée"

export const AgendaTab: FC<AgendaTabProps> = ({ label, agendaType }) => {
  const zipperStatus = useAtomValue(zipperStatusAtom)
  const doctolibError =
    agendaType === 'doctolib' && zipperStatus === DoctolibZipperStatus.NOT_LOADED

  const { initializeDoctolibZipper } = useDoctolibStatus()

  const status: TabStatus =
    (zipperStatus === DoctolibZipperStatus.NOT_LOADED && 'warning') ||
    (zipperStatus === DoctolibZipperStatus.LOADED && 'valid') ||
    'default'

  const agendaUrl: string = useMemo(() => {
    switch (agendaType) {
      case 'agendize':
        return AGENDIZE_APP_URL ?? ''
      case 'doctolib':
        return DOCTOLIB_APP_URL ?? ''
    }
  }, [agendaType])

  const handleReconnectDoctolib = useCallback(() => {
    initializeDoctolibZipper()
  }, [initializeDoctolibZipper])

  const testId = `nav-link-agenda-${agendaType}`

  return agendaType === 'doctolib' ? (
    <ActionTab
      testId={testId}
      interactiveLabel
      icon={'calendar'}
      status={status}
      onClick={doctolibZipperOpenCalendar}
      label={
        doctolibError ? (
          <div onClick={(event) => event.stopPropagation()}>
            <p className="mb-2">{DOCTOLIB_ERROR_LABEL}</p>
            <span className="underline cursor-pointer" onClick={handleReconnectDoctolib}>
              Se reconnecter
            </span>
          </div>
        ) : (
          label
        )
      }
    />
  ) : (
    <NavTab testId={testId} label={label} icon={'calendar'} newTab link={agendaUrl} />
  )
}
