import { useMutation } from '@tanstack/react-query'
import { DoctolibPatient } from '../../../misc/Doctolib/doctolib.model'
import { doctolibToFollowPatientMapper } from '../../../misc/Doctolib/doctolib.utils'
import { UserFriendlyError } from '../../../model/Error'
import { useUpdatePatient } from '../patients'

interface UpdateFollowPatientWithoutPendingChangesVariables {
  patient: DoctolibPatient
}

// à retirer lors de la suppression du FF doctolibUpdates
export const useUpdateFollowPatientWithoutPendingChanges = () => {
  const { mutate: updatePatient } = useUpdatePatient({ triggerSubscription: false })

  const mutate = useMutation({
    mutationFn: async ({ patient }: UpdateFollowPatientWithoutPendingChangesVariables) => {
      if (patient.pms_id) {
        const fwPatientId = parseInt(patient.pms_id, 10)
        if (Number.isInteger(fwPatientId)) {
          const fwPatient = doctolibToFollowPatientMapper(patient, false)
          updatePatient({ id: fwPatientId, payload: fwPatient })
        } else {
          throw new UserFriendlyError(
            'error',
            `L'identifiant du patient indiqué par doctolib n'a pas le bon format: (${JSON.stringify(
              patient.pms_id,
            )})`,
          )
        }
      }
    },
  })

  return mutate
}
