import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createPrescriptionTemplate } from '../../../data/prescriptionTemplates'
import { Drug } from '../../../model/Drug'
import { UserFriendlyError } from '../../../model/Error'
import { PrescriptionTemplate } from '../../../model/PrescriptionTemplate'
import { prescriptionTemplateKeys } from './prescriptionTemplates.keys'

interface UseCreatePrescriptionTemplateVariables {
  prescriptionUuid: string
  name: string
  drugs: Array<Drug>
}

export const useCreatePrescriptionTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation<PrescriptionTemplate, Error, UseCreatePrescriptionTemplateVariables>({
    mutationFn: async ({ name, prescriptionUuid }) => {
      const { data, ok } = await createPrescriptionTemplate(prescriptionUuid, name)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Erreur lors de la création du modèle de prescription')
      }

      return data
    },
    onSuccess: (_, { drugs }) => {
      const ids = drugs.map(({ id }) => id)
      queryClient.invalidateQueries({ queryKey: prescriptionTemplateKeys.list(ids) })
    },
  })
}
