import { EFFICIENCE_APP_URL } from '../environment/efficience'
import { CustomFile } from '../model/File'
import { FileForDMP } from '../store/domain/dmp'

export const openDMP = () => {
  window.open(EFFICIENCE_APP_URL)
}

export const DMP_SERVICES_ACCEPTED_EXTENSIONS = ['jpeg', 'jpg', 'tiff', 'pdf', 'rtf', 'plain']

export const mapFileForDMP = (pendingFile: CustomFile): FileForDMP | undefined => {
  const { id, visibleName, base64, dmpId, version, createdAt, loincCode, extension } = pendingFile

  if (!base64) return

  return {
    id,
    base64,
    createdAt,
    loincCode,
    format: extension === 'jpg' ? 'jpeg' : extension,
    name: visibleName,
    dmpDocumentId: dmpId,
    version: version.toString(),
  }
}
