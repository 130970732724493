import React, { FunctionComponent, useState } from 'react'
import { DocumentTemplateListItem } from '../../../model/DocumentTemplate'
import { Button, ListItemType } from '../../shared'
import { SearchDocumentTemplatesProps } from './SearchDocumentTemplates.model'
import createSearchPanel, { FiltersType } from '../../../containers/SearchPanel'
import { RestuxLoadingState } from '../../../store/restux/ui'
import { FwResourceType } from '../../../model/Resource'
import { DocumentListItem } from '../../document'
import { useGetUsedTags } from '../../../hooks/queries/tag'
import { useGetUsedDocumentCategories } from '../../../hooks/queries/documentCategories/useGetUsedDocumentCategories.query'
import { BottomPanel } from '../../shared/bottomPanel/BottomPanel'

const SearchPanel = createSearchPanel<DocumentTemplateListItem>()

export const SearchDocumentTemplates: FunctionComponent<SearchDocumentTemplatesProps> = ({
  isUnmounting,
  documentTemplatesPage,
  onClose,
  onSubmit,
  getPaginatedDocumentTemplate,
  clearDocumentTemplateList,
}) => {
  const [selected, setSelected] = useState<ReadonlyArray<DocumentTemplateListItem>>([])
  const { usedTagList: usedTags } = useGetUsedTags({
    usedInResource: FwResourceType.DocumentTemplates,
  })

  const { usedDocumentCategories } = useGetUsedDocumentCategories({
    usedInResource: FwResourceType.DocumentTemplates,
  })

  const applySelection = (selectedItem?: ListItemType) => {
    const selection = selectedItem
      ? documentTemplatesPage.items.filter((item) => item.id === selectedItem.id)
      : documentTemplatesPage.items.filter((item) => selected.some(({ id }) => id === item.id))

    onSubmit(selection)
  }

  return (
    <BottomPanel
      isUnmounting={isUnmounting}
      onRequestClose={onClose}
      title="Rechercher des modèles de document"
      actions={
        selected.length > 0 ? (
          <Button theme="primary" label="Valider" onClick={() => applySelection()} />
        ) : null
      }
    >
      <SearchPanel
        items={documentTemplatesPage.items}
        loading={documentTemplatesPage.loadingState !== RestuxLoadingState.IDLE}
        itemCount={documentTemplatesPage.totalItems}
        pageCount={documentTemplatesPage.totalPages}
        onClearItems={() => clearDocumentTemplateList()}
        onMultiSelectionChange={(selection) => setSelected(selection)}
        onPrimaryAction={applySelection}
        getItems={(currentPage, { search, ...filters }) =>
          getPaginatedDocumentTemplate({
            page: { currentPage, pageSize: 40 },
            filters: {
              title: search,
              categories: filters.documentCategories,
              tagIds: filters.tagIds,
            },
          })
        }
        renderItem={(item) => <DocumentListItem item={item} />}
        filterCapabilities={[FiltersType.Title, FiltersType.DocumentCategory, FiltersType.Tags]}
        documentCategories={usedDocumentCategories}
        usedTags={usedTags}
      />
    </BottomPanel>
  )
}
