import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { EFFICIENCE_APP_URL } from '../../../environment/efficience'
import { dmpDomainActions, DmpRequestTypes } from '../../../store/domain/dmp'
import { IconButton, Modal } from '../../shared'
import { EffecienceIframeProps } from './EfficienceIframe.model'
import { isDefined } from '../../../misc/functions.utilities'
import { useActionDispatch, useCurrentPatient } from '../../../hooks/utils'
import styles from './EfficienceIframe.module.scss'
import { useAtom } from 'jotai'
import { cpsCodeModalAtom } from '../../../state/vitalCard'
import { RESET } from 'jotai/utils'
import { isArray } from 'lodash'
import { developmentMode } from '../../../misc/environment.utilities'

enum EfficienceErrorCode {
  NOT_FOUND = 'DMPNotFound',
  ERROR_CODE = 'DMPError',
  NO_HP = 'NoHPConnected',
  UNAUTHORIZED = 'RefusedAction',
}

export const EfficienceIframe: FunctionComponent<EffecienceIframeProps> = ({
  requests,
  iframeStatus,
  setIframeStatus,
  addValid,
  addError,
  updateDocument,
  updateFile,
  sendDmpMetrics,
  closeDmpSession,
}) => {
  const { currentPatient } = useCurrentPatient()
  const efficienceRef = useRef<HTMLIFrameElement>(null)
  const [openEfficience, setOpenEfficience] = useState(false)
  const [, setCpsModalState] = useAtom(cpsCodeModalAtom)
  const removeRequest = useActionDispatch(dmpDomainActions.removeRequest)

  const sendMessageToEfficience = useCallback(() => {
    if (requests && requests.length > 0) {
      const request = requests[0]
      if (request.uuid && request.payload) {
        efficienceRef.current?.contentWindow?.postMessage(request.payload, '*')
      }
    }
  }, [requests])

  const handleClosePinCodeModal = useCallback(() => setCpsModalState(RESET), [setCpsModalState])

  const resetPinCodeModal = useCallback(() => {
    handleClosePinCodeModal()
    setIframeStatus('need_auth')
  }, [handleClosePinCodeModal, setIframeStatus])

  const handlePinCode = useCallback((pinCode: string | null) => {
    efficienceRef.current?.contentWindow?.postMessage(JSON.stringify({ pinCode }), '*')
  }, [])

  const openPinCodeModal = useCallback(async () => {
    const pinCodePromise = new Promise<string | null>((resolve, reject) => {
      const promiseCallback = (pincode: string | null) => resolve(pincode)
      setCpsModalState({ onPinCodeInput: promiseCallback })
    })

    const pincode = await pinCodePromise

    if (!isDefined(pincode)) {
      resetPinCodeModal()
      return
    }

    handlePinCode(pincode)
  }, [handlePinCode, resetPinCodeModal, setCpsModalState])

  const handleReturn = useCallback(() => {
    closeDmpSession()
    setOpenEfficience(false)
  }, [closeDmpSession])

  const reloadIframe = useCallback(() => {
    if (!efficienceRef.current) return

    efficienceRef.current.src = EFFICIENCE_APP_URL
    setIframeStatus('loading')
  }, [setIframeStatus])

  const handleDMPError = useCallback(
    (errors?: Array<{ code: string }>) => {
      // On n'ajoute pas aux metrics les erreurs d'auth
      if (isDefined(currentPatient)) {
        sendDmpMetrics({
          success: false,
          patientId: currentPatient.id,
        })
      }

      const errorCodes = errors?.map((error) => error.code).filter(isDefined)

      if (
        errorCodes?.includes(EfficienceErrorCode.NO_HP) ||
        errorCodes?.includes(EfficienceErrorCode.UNAUTHORIZED)
      ) {
        reloadIframe()
      }

      let errorDetail = "Une erreur s'est produite"

      if (errorCodes?.includes(EfficienceErrorCode.UNAUTHORIZED)) {
        errorDetail = 'Action non autorisée'
      }
      if (errorCodes?.includes(EfficienceErrorCode.NO_HP)) {
        errorDetail = 'Votre session a expiré'
      }

      addError("Erreur lors de l'envoi au DMP", errorDetail)
    },
    [addError, currentPatient, reloadIframe, sendDmpMetrics],
  )

  const efficienceMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.origin === EFFICIENCE_APP_URL) {
        const response = event.data

        if (response.data && requests && requests.length > 0) {
          const { answer } = JSON.parse(window.atob(response.data))
          if (developmentMode) {
            console.log(answer)
          }

          const hasError = isArray(answer?.errors) && answer.errors.length > 0

          removeRequest(answer.RelatesTo.fileId, requests)

          if (hasError) {
            console.error('Efficience error', answer)
            handleDMPError(answer.errors)
            return
          }

          switch (answer.RelatesTo.type) {
            case DmpRequestTypes.SEND_DOCUMENT:
            case DmpRequestTypes.SEND_DOCUMENT_PDF_FORM:
              addValid('Le document a bien été envoyé au dossier médical partagé')
              updateDocument(answer.RelatesTo.fileId, {
                type: answer.RelatesTo.type === DmpRequestTypes.SEND_DOCUMENT ? 'farte' : 'pdf',
                dmpDocumentId: answer.document.uniqueId,
              })
              break

            case DmpRequestTypes.SEND_FILES:
              addValid('Le document a bien été envoyé au dossier médical partagé')
              updateFile(answer.RelatesTo.fileId, {
                dmpId: answer.document.uniqueId,
              })
              break

            case DmpRequestTypes.DELETE_DOCUMENT:
              updateDocument(answer.RelatesTo.fileId, { dmpDocumentId: null })
              addValid('Le document a été supprimé du dossier médical partagé')
              break

            case DmpRequestTypes.DELETE_FILE:
              updateFile(answer.RelatesTo.fileId, { dmpId: null })
              addValid('Le fichier a été supprimé du dossier médical partagé')
              break

            case DmpRequestTypes.OPEN_DMP:
              answer.errors &&
              answer?.errors.every(({ code }) => code === EfficienceErrorCode.NOT_FOUND)
                ? addError('Ce patient ne possède pas de dossier médical partagé')
                : setOpenEfficience(true)
              break
          }

          // Metrics
        } else {
          if (response.type === 'login') {
            setOpenEfficience(false)
            handleClosePinCodeModal()
            sendMessageToEfficience()
          }

          if (response.type === 'waiting_for_pincode_entry') {
            setIframeStatus('need_auth')
          }

          if (response.type === 'login_error') {
            addError('Code PIN CPS invalide', 'Veuillez réessayer')
            resetPinCodeModal()
          }

          if (response.type === 'login' && isDefined(response?.hpInfos)) {
            setIframeStatus('ready')
          }
        }
      }
    },
    [
      requests,
      removeRequest,
      handleDMPError,
      addValid,
      updateDocument,
      updateFile,
      addError,
      handleClosePinCodeModal,
      sendMessageToEfficience,
      setIframeStatus,
      resetPinCodeModal,
    ],
  )

  useEffect(() => {
    if (iframeStatus === 'auth_in_progress') {
      openPinCodeModal()
    }
  }, [iframeStatus, openPinCodeModal])

  useEffect(() => {
    window.addEventListener('message', efficienceMessageHandler)
    return () => {
      window.removeEventListener('message', efficienceMessageHandler)
    }
  }, [efficienceMessageHandler])

  useEffect(() => {
    sendMessageToEfficience()
  }, [sendMessageToEfficience])

  return (
    <>
      <Modal
        onClickOutside={() => setOpenEfficience(false)}
        isUnmounting={false}
        hidden={!openEfficience}
      >
        <div className={styles.container}>
          <div className={styles.backWrapper}>
            <IconButton
              onClick={handleReturn}
              icon="back"
              theme="light"
              size="normal"
              testId="button-exit-efficience-iframe"
            />
            <span className={styles.backReturnText}>Retour sur Follow</span>
          </div>
          <iframe
            className="w-full h-full"
            ref={efficienceRef}
            title="efficience-iframe"
            src={EFFICIENCE_APP_URL}
          />
        </div>
      </Modal>
    </>
  )
}
