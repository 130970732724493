import React, { FunctionComponent } from 'react'
import { MessageTemplateListProps } from './MessageTemplateList.model'
import styles from './MessageTemplateList.module.scss'
import { MessageTemplateListItem } from './MessageTemplateListItem'
import { MessageTemplateMenuActions } from './MessageTemplateMenuActions'
import { useGetInfiniteMessageTemplates } from '../../../../../hooks/queries/messageTemplates'
import { LabeledButton, List } from '../../../../shared'
import { EmptyPlaceHolder } from '../../../../shared/EmptyPlaceHolder'

export const MessageTemplateList: FunctionComponent<MessageTemplateListProps> = ({
  onAdd,
  onRename,
  onReplace,
  onDelete,
  onUpdateDefault,
  onApply,
}) => {
  const {
    messageTemplateList,
    paginationState,
    query: { isLoading, fetchNextPage },
  } = useGetInfiniteMessageTemplates()

  return (
    <div className={styles.list}>
      <List
        noRef
        pageCount={paginationState?.pageCount ?? null}
        currentPage={paginationState?.currentPage ?? 0}
        testId="message-template"
        loading={isLoading}
        items={messageTemplateList}
        renderItem={(messageTemplate) => (
          <MessageTemplateListItem messageTemplate={messageTemplate} />
        )}
        renderCustomItemActions={(messageTemplate) => (
          <MessageTemplateMenuActions
            messageTemplate={messageTemplate}
            onRename={onRename}
            onReplace={onReplace}
            onDelete={onDelete}
            onUpdateDefault={onUpdateDefault}
            onApply={onApply}
          />
        )}
        renderEmptyPlaceHolder={
          <div className={styles.emptyPlaceHolderWrapper}>
            <EmptyPlaceHolder />
          </div>
        }
        onUpdateList={() => fetchNextPage()}
        fullPageMode={false}
        renderActions={() => (
          <div className={styles.addButtonWrapper}>
            <LabeledButton
              icon="add"
              label="Enregistrer le message courant comme modèle"
              active={true}
              size="small"
              onClick={onAdd}
            />
          </div>
        )}
        addItemLabel="Enregistrer le message courant comme modèle"
        onPrimaryAction={onApply}
      />
    </div>
  )
}
