import { isDefined } from '@follow/cdk'
import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { fetchFile } from '../../../store/cache/files/api'
import { fileKeys } from './files.keys'

interface GetFileParams {
  fileId?: number
  enabled?: boolean
}

export const useGetFile = ({ fileId, enabled = true }: GetFileParams) => {
  const queryKey = fileKeys.detail(fileId)

  const queryResult = useQuery({
    queryKey,

    queryFn: async () => {
      if (!isDefined(fileId)) return

      const { data, ok } = await fetchFile(fileId)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le fichier ${fileId}`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryResult }
}
