import { ApiResponse } from 'apisauce'
import { parseISO } from 'date-fns'
import { ManualPrescriptionItem } from '../../../model/ManualPrescription'
import { PaginatedList } from '../../../model/Pagination'
import { SerializedManualPrescription } from './patientManualPrescriptions.model'

export const deserializePatientManualPrescriptionsData = (
  item: SerializedManualPrescription,
): ManualPrescriptionItem => ({
  ...item,
  id: item.uuid,
  startDate: parseISO(item.startDate),
  endDate: item.endDate ? parseISO(item.endDate) : undefined,
})

export const deserializePatientManualPrescriptionsPaginatedResponse = (
  response: ApiResponse<PaginatedList<SerializedManualPrescription>>,
): ApiResponse<PaginatedList<ManualPrescriptionItem>> => {
  const { data, ...responseRest } = response
  if (!response.ok || !data) {
    return { ...responseRest }
  }
  return {
    ...responseRest,
    data: { ...data, items: data.items.map(deserializePatientManualPrescriptionsData) },
  }
}
