import { UnmountTransitionProps } from '../../../../misc/withUnmountTransition.hoc'
import { Contact } from '../../../../model/Contact'
import { ComponentUsingBottomPanelProps } from '../BottomPanel/BottomPanel.model'

export enum BottomPanelContactsContext {
  PATIENT_CONTACT,
  DOCUMENT_CONTACT,
}

interface ContactBottomPanelOwnProps {
  contactId: string | null
  patientId?: number
  readOnly?: boolean
  context?: BottomPanelContactsContext
  onContactCreated?: (contact: Contact) => void
  onContactDuplicated?: (contact: Contact, contactId: string) => void
}

export interface ContactConfigureBottomPanelProps
  extends ContactBottomPanelOwnProps,
    ComponentUsingBottomPanelProps,
    UnmountTransitionProps {}
