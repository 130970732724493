import { UnmountTransitionProps } from '../../../../../misc/withUnmountTransition.hoc'
import {
  PendingDoctolibPatientData,
  PendingFollowPatientAPIField,
} from '../../../../../model/Doctolib'

export interface DoctolibPatientInfoUpdateModalProps extends UnmountTransitionProps {
  pendingPatientData: PendingDoctolibPatientData[]
  onSubmit: (selectedPendingData: PendingDataFieldsValues[]) => void
  onClose: () => void
}

export const ModalFields: { [key in PendingFollowPatientAPIField]: string } = {
  [PendingFollowPatientAPIField.Email]: 'Email',
  [PendingFollowPatientAPIField.Telephone1]: 'Tél. portable',
  [PendingFollowPatientAPIField.Telephone2]: 'Tél. fixe',
  [PendingFollowPatientAPIField.Street]: 'Adresse',
  [PendingFollowPatientAPIField.Zipcode]: 'Code postal',
  [PendingFollowPatientAPIField.City]: 'Ville',
}

export interface PendingDataFieldsValues {
  id: string
  label: string
  field: PendingFollowPatientAPIField
  previousValue: string | null
  suggestedValue: string | null
}
