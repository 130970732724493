import { FunctionComponent, useCallback, useContext, useMemo } from 'react'
import { IconButton, SelectSearch, TooltipWrapper } from '../../../shared'
import { SelectOption } from '../../../../model/SelectOption'
import { MailNavbarProps } from './MailNavBar.model'
import { useAtom } from 'jotai'
import { useGetMailAdresses } from '../../../../hooks/queries/mail'
import { isDoctor } from '../../../../misc/user.utilities'
import { selectedEmailAddressAtom } from '../../../../state/mail/selectedEmailAddress.atom'
import { FeaturesType } from '@follow/cdk'
import { useUserEnabledFeature } from '../../../../hooks/utils/user'
import { ConnectedUserContext } from '../../../../misc/auth.utilities'

export const MailNavbar: FunctionComponent<MailNavbarProps> = ({ disconnect }) => {
  const { currentUser } = useContext(ConnectedUserContext)
  const isMssSmtpEnabled = useUserEnabledFeature(FeaturesType.mssSmtp)

  const { query: mailAddressList } = useGetMailAdresses({})
  const [selectedAddress, setSelectedAddress] = useAtom(selectedEmailAddressAtom)
  const mailAddressOptions = useMemo(() => {
    if (isMssSmtpEnabled && isDoctor(currentUser) && currentUser.mailizEmailAddress) {
      return [{ value: currentUser.mailizEmailAddress, label: currentUser.mailizEmailAddress }]
    }

    return mailAddressList.data?.emailAddresses.map((value) => ({ value, label: value })) ?? []
  }, [currentUser, isMssSmtpEnabled, mailAddressList])

  const addressValue = useMemo(() => {
    if (isMssSmtpEnabled) {
      return mailAddressOptions.at(0)
    } else {
      return mailAddressOptions.find(({ value }) => value === selectedAddress)
    }
  }, [isMssSmtpEnabled, mailAddressOptions, selectedAddress])

  const disabled = 1 === mailAddressOptions.length

  const handleSelect = useCallback(
    (selected: SelectOption<string> | undefined) => {
      if (selected) {
        setSelectedAddress(selected.value)
      }
    },
    [setSelectedAddress],
  )
  return (
    <div className="flex w-full bg-shades-2 h-16 px-5 items-center">
      <div className="flex w-full items-end h-full">
        <div className="flex flex-col h-3/4 bg-shades-white px-5 rounded-t-md">
          <span className="my-auto font-semibold text-xl">Boîte de réception</span>
        </div>
      </div>
      <div className="my-auto w-150 max-w-full mr-3">
        <SelectSearch
          options={mailAddressOptions}
          value={addressValue}
          placeholder="Sélectionner une adresse mail"
          disabled={disabled}
          onSelect={handleSelect}
          colorPreset="dark"
          clearable={false}
        />
      </div>
      {disconnect && (
        <TooltipWrapper
          pointerDirection="right"
          content="Se déconnecter de la messagerie de santé sécurisée"
        >
          <div>
            <IconButton icon="power" onClick={disconnect} />
          </div>
        </TooltipWrapper>
      )}
    </div>
  )
}
