import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { deleteMedicalEvent } from '../../../data/medicalEvents'
import { medicalEventsKeys } from './medicalEvents.keys'
import { patientStatisticsKeys } from '../patientStatistics/patientStatistics.keys'
import { patientImportantInformationsKeys } from '../patientImportantInformations/patientImportantInformations.keys'
import { patientTreatmentsKeys } from '../patientTreatments/patientTreatments.keys'

export interface DeleteMedicalEventMutateVariable {
  medicalEventId: number
  patientId: number
}

export const useDeleteMedicalEvent = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ medicalEventId }: DeleteMedicalEventMutateVariable) => {
      const { ok } = await deleteMedicalEvent(medicalEventId)
      if (!ok) {
        throw new UserFriendlyError('error', `La suppression de l'évènement médical a échoué`)
      }
      return null
    },

    onSuccess: (_data, { patientId }) => {
      queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
      queryClient.invalidateQueries({ queryKey: patientStatisticsKeys.detail(patientId) })
      queryClient.invalidateQueries({
        queryKey: patientImportantInformationsKeys.listAll(patientId),
      })
      queryClient.invalidateQueries({ queryKey: patientTreatmentsKeys.lists(patientId) })
    },
  })
}
