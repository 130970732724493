import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Patient } from '../../../model/Patient'
import { useInsiIdentityCheck } from '../insi'
import { MailPatient } from '../../../model/Mail'
import { useUserEnabledFeature } from '../../utils/user'
import { FeaturesType } from '@follow/cdk'
import { useCreatePatient } from '../patients'
import { getNewPatientFromMail } from '../../../misc/insi.utilities'
import { useActionDispatch } from '../../utils'
import { addValid } from '../../../store/message'
import { mailKeys } from './mail.keys'
import { useSetAtom } from 'jotai'
import { partialMailPatientModalAtom } from '../../../state/mail'
import { patientsKeys } from '../patients/patients.keys'
import { useCreateTask } from '../task'
import { TaskCategory } from '../../../model/Task'
import { RESET } from 'jotai/utils'

interface UseCreatePatientFromMailVariables {
  patient: MailPatient
  mailId: string
  bypassCheck?: boolean
}

export const useCreatePatientFromMail = () => {
  const queryClient = useQueryClient()
  const dispatchValid = useActionDispatch(addValid)
  const setPartialPatientModalState = useSetAtom(partialMailPatientModalAtom)

  const insiService = useUserEnabledFeature(FeaturesType.insiValidation)
  const mssExtractPatient = useUserEnabledFeature(FeaturesType.mssExtractPatient)

  const { mutateAsync: identityCheck } = useInsiIdentityCheck()
  const { mutateAsync: createPatient } = useCreatePatient()
  const { mutate: createTask } = useCreateTask()

  const mutation = useMutation<Patient | null, Error, UseCreatePatientFromMailVariables>({
    mutationFn: async ({ patient, bypassCheck }) => {
      // Bypass de la vérification si ces FF sont paramétrés
      if (bypassCheck || (!insiService && mssExtractPatient)) {
        const unverifiedPatient = getNewPatientFromMail(patient, false)
        const createdPatient = await createPatient({ patient: unverifiedPatient })
        return createdPatient
      }

      let removeCriticalInformationFromPatient: boolean

      // Vérification de l'identité
      try {
        const insiCheck = await identityCheck({ patient })
        // Annulation du processus (par ex, si l'utilisateur ferme la modale insiConsult)
        if (insiCheck === null) return null

        // La validation a réussi
        removeCriticalInformationFromPatient = false
      } catch (e) {
        // La validation a échoué
        removeCriticalInformationFromPatient = true

        //Via une modale, on propose à l'utilisateur de créer le patient (avec un statut identité "temporaire")
        const inputPromise = new Promise<boolean>((resolve) => {
          setPartialPatientModalState({
            onSubmit: (createPatient) => resolve(createPatient),
          })
        })
        const shouldCreatePatient = await inputPromise

        if (!shouldCreatePatient) {
          return null
        }
      }
      return await createPatient({
        patient: getNewPatientFromMail(patient, removeCriticalInformationFromPatient),
        params: {
          external: true,
          forceInsiCheck: insiService === 'insiValidationRest',
        },
      })
    },
    onSettled: () => {
      setPartialPatientModalState(RESET)
    },
    onSuccess(data, { mailId }) {
      if (data === null) return

      dispatchValid('Patient créé avec succès')
      queryClient.invalidateQueries({ queryKey: mailKeys.detail(mailId) })
      queryClient.invalidateQueries({ queryKey: patientsKeys.lists })

      if (
        insiService === 'insiValidationInApp' &&
        data.identityStatus.insiStatus.checked === 'FAILED'
      ) {
        createTask({
          title: "Échec de la validation de l'identité via l'INSi",
          autoCreated: true,
          category: TaskCategory.INFORMATION,
          patientId: data.id,
          isNotify: true,
        })
      }
    },
  })

  return mutation
}
