import { FC, useMemo } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { beneficiariesAtom, beneficiaryDifferencesAtom } from '../../../../state/vitalCard'
import { useHandleBeneficiary } from '../../../../hooks/queries/vitalCard'
import { VitalCardModalGroupProps } from './VitalCardModalGroup.model'
import { VitalCardBeneficiariesModal } from '../VitalCardBeneficiariesModal'
import { isDefined } from '../../../../misc/functions.utilities'
import { Patient } from '../../../../model/Patient'
import { VitalCardDifferencesModal } from '../VitalCardDifferencesModal'
import { RESET } from 'jotai/utils'

export const VitalCardModalGroup: FC<VitalCardModalGroupProps> = ({ isPatientFormActive }) => {
  const beneficiaryDifferences = useAtomValue(beneficiaryDifferencesAtom)

  const [{ beneficiaries, selected }, setBenefiariesState] = useAtom(beneficiariesAtom)

  const { mutate: handleBeneficiary } = useHandleBeneficiary()

  const displayBeneficiariesModal = useMemo(
    () => isDefined(beneficiaries) && !isDefined(selected),
    [beneficiaries, selected],
  )

  const handleSelectBeneficiary = (selected: Partial<Patient> | null) => {
    if (isDefined(selected)) {
      handleBeneficiary({ patient: selected, isPatientFormActive })
    } else {
      setBenefiariesState(RESET)
    }
  }

  return (
    <>
      <VitalCardBeneficiariesModal
        display={displayBeneficiariesModal}
        beneficiaries={beneficiaries ?? []}
        onSelectBeneficiary={handleSelectBeneficiary}
      />
      <VitalCardDifferencesModal
        display={!!beneficiaryDifferences}
        onSubmit={beneficiaryDifferences?.onSelectDifferenceOptions ?? null}
        vitalCardDifferences={beneficiaryDifferences?.differences ?? null}
      />
    </>
  )
}
