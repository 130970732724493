import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getIndications } from '../../../data/drugs'
import { UserFriendlyError } from '../../../model/Error'
import { drugsKeys } from './drugs.keys'

interface UseGetIndicationsParams {
  search: string
  enabled?: boolean
}

export const useGetIndications = ({ search, enabled = true }: UseGetIndicationsParams) => {
  const queryKey = useMemo(() => drugsKeys.indicationsList(search), [search])

  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { data, ok } = await getIndications(search, signal)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'Impossible de récupérer la liste des indications')
      }

      return data
    },
    enabled,
  })

  return { query }
}
