import getApi from '../../api/apisauceConfig'
import { largePageLimit } from '../../constants'
import { PaginatedList } from '../../model/Pagination'
import {
  deserializePatientManualPrescriptionsPaginatedResponse,
  SerializedManualPrescription,
} from './mapper'

export const getPatientManualPrescriptions = (
  patientId: number,
  page: number,
  signal?: AbortSignal,
  limit = largePageLimit,
) =>
  getApi()
    .get<
      PaginatedList<SerializedManualPrescription>
    >(`patients/${patientId}/manual_prescriptions`, { page, limit }, { signal })
    .then(deserializePatientManualPrescriptionsPaginatedResponse)
