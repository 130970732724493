import React from 'react'
import { IconProps } from './BaseIcon.model'
import { library } from '../Icon.library'
import { getV2Color } from '../../../../design-system/colors_v2'
import { isFwCssVariableColor } from '../../../../design-system/design-system.utils'

interface BaseIconProps extends IconProps {
  sizeInPx: number
}

const validColor = (color: IconProps['color']) =>
  color ? (isFwCssVariableColor(color) ? `var(${color})` : getV2Color(color)) : undefined

export const BaseIcon = React.forwardRef<SVGSVGElement, BaseIconProps>(
  ({ icon, color, sizeInPx = 24, rotate = 0, testId = `icon-${icon}`, title }, ref) => {
    const svg = getSvg(icon)
    const [x1, y1, x2, y2] = svg.view.split(' ')

    return (
      <svg
        ref={ref}
        viewBox={svg.view}
        height={`${sizeInPx}px`}
        width={`${sizeInPx}px`}
        data-test-id={testId}
      >
        {title && <title>{title}</title>}
        <g
          fill={validColor(color)}
          dangerouslySetInnerHTML={{ __html: svg.path }}
          transform={`rotate(${rotate} ${(x2 - x1) / 2} ${(y2 - y1) / 2})`}
        />
      </svg>
    )
  },
)

const getSvg = (icon) => {
  const svg = library[icon]
  return svg ? svg : library.default
}
