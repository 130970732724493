import { useMutation, useQueryClient } from '@tanstack/react-query'
import { duplicateSurvey } from '../../../data/survey'
import { UserFriendlyError } from '../../../model/Error'
import { Survey, SurveyApi } from '../../../model/Survey'
import { surveyKeys } from './survey.keys'

interface UseDuplicateSurveyVariables {
  survey: Survey
}

export const useDuplicateSurvey = () => {
  const queryClient = useQueryClient()

  return useMutation<Survey, Error, UseDuplicateSurveyVariables>({
    mutationFn: async ({ survey: { id, name, ...content } }) => {
      const toBeDuplicated: SurveyApi = {
        ...content,
        name: `Copie de ${name}`,
      }

      const { data, ok } = await duplicateSurvey(toBeDuplicated)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "Impossible de dupliquer le questionnaire en salle d'attente",
        )
      }

      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: surveyKeys.list })
      queryClient.setQueryData(surveyKeys.detail(data.id), data)
    },
  })
}
