import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { ErrorPandaMessage, FullLoader } from '../../../components/shared'
import { MailClassification } from '../../../components/mail'
import { downloadBlob } from '../../../misc/blob.utilities'
import { requestPrintByBlobUrl } from '../../../misc/print.utilities'
import { pdfDataToUrl } from '../../../components/file/FileViewer/PdfViewerLegacy/pdf.utils'
import { MailContent } from '../../../components/mail/MailContent/MailContent.component'
import styles from './MailPage.module.scss'
import { useParams, useSearchParams } from 'react-router-dom'
import { isDefined } from '../../../misc/functions.utilities'
import { useGetMail, useGetMailAttachment } from '../../../hooks/queries/mail'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { MailAttachement, MailListPage } from '../../../model/Mail'
import { mailKeys } from '../../../hooks/queries/mail/mail.keys'

const PART_QUERY_PARAM = 'part'
const CDA_QUERY_PARAM = 'cda'

export const MailPage: FunctionComponent = () => {
  const { mailId } = useParams<'mailId'>()
  const [searchParams, setSearchParams] = useSearchParams()

  const queryClient = useQueryClient()
  const {
    query: { data: mail, status },
  } = useGetMail({ id: mailId ?? '', enabled: isDefined(mailId) })

  const selectionParams = useMemo(() => {
    const partParam = searchParams.get(PART_QUERY_PARAM)
    if (!partParam) return null

    const parsedPart = Number.parseInt(partParam)
    if (!isFinite(parsedPart)) return null

    const cdaParam = searchParams.get(CDA_QUERY_PARAM)

    return { partId: parsedPart, cda: cdaParam }
  }, [searchParams])

  const {
    query: { data: attachment },
  } = useGetMailAttachment({
    mailId: mailId ?? '',
    partId: selectionParams?.partId ?? -1,
    cdaId: selectionParams?.cda ?? undefined,
    enabled: !!mailId && !!selectionParams?.partId,
  })

  const handleDownloadOrPrintFile = (file: MailAttachement, mode: 'download' | 'print') => {
    if (file.base64) {
      fetch(file.base64)
        .then((res) => res.blob())
        .then((blob) => {
          if (mode === 'download') downloadBlob(blob, `${file.visibleName}.${file.extension}`)
          else requestPrintByBlobUrl(pdfDataToUrl(blob))
        })
    }
  }

  const handleSelectAttachment = useCallback(
    (partId: number, cdaId?: string) => {
      setSearchParams(() => {
        const params = new URLSearchParams()
        params.set(PART_QUERY_PARAM, partId.toString())
        if (cdaId) {
          params.set(CDA_QUERY_PARAM, cdaId)
        }
        return params
      })
    },
    [setSearchParams],
  )

  // Sélection automatique de la première pièce jointe si présente
  useEffect(() => {
    const defaultAttachmentId = mail?.attachments.at(0)?.id
    if (!searchParams.get(PART_QUERY_PARAM) && defaultAttachmentId) {
      handleSelectAttachment(defaultAttachmentId)
    }
  }, [handleSelectAttachment, mail?.attachments, searchParams, setSearchParams])

  // Mise à jour des flags du mail dans les query de list
  // Notamment, retrait du flag "Unread" au chargement du mail
  useEffect(() => {
    queryClient.setQueriesData<InfiniteData<MailListPage>>(
      { queryKey: mailKeys.lists },
      (oldData) => {
        if (!oldData) return oldData

        const mutatedPages = oldData.pages.map((page) => ({
          ...page,
          items: page.items.map((cachedMail) => {
            if (!isDefined(mail) || cachedMail.id !== mail.id) {
              return cachedMail
            }

            return {
              ...cachedMail,
              flags: mail.flags,
            }
          }),
        }))

        return { ...oldData, pages: mutatedPages }
      },
    )
  }, [mail, queryClient])

  return (
    <div className="w-full flex bg-white h-full">
      {status === 'pending' && (
        <div className={styles.loaderContainer}>
          <FullLoader message="Chargement de l'email" />
        </div>
      )}

      {status === 'error' && (
        <div className="px-10">
          <ErrorPandaMessage
            title="Une erreur est survenue lors du chargement de l'email"
            subTitle="Veuillez réessayer ultérieurement"
            backToHomePage={false}
          />
        </div>
      )}

      {status === 'success' && (
        <>
          {mail && (
            <div className="grow">
              <MailContent
                inUseMail={mail}
                selectedFile={attachment}
                selectedAttachmentId={selectionParams?.cda ?? selectionParams?.partId ?? null}
                setSelectedAttachmentId={handleSelectAttachment}
                handleDownloadOrPrintFile={handleDownloadOrPrintFile}
              />
            </div>
          )}
          {mail?.attachments && mail.attachments.length > 0 && (
            <div className="p-5 pl-0 max-w-xs min-w-xs">
              <MailClassification inUseMail={mail} key={mail.id} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
