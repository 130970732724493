import { Address } from '../../../../model/Address'
import { SelectOption } from '../../../../model/SelectOption'

export interface PatientContactsFormBlockProps {
  colorPreset?: 'light' | 'dark'
  patientAddress?: Address | null
  patientId?: number
}

export const CONTACT_VISIBILITY_OPTIONS: SelectOption<boolean | null>[] = [
  { value: null, label: 'Tous' },
  { value: false, label: 'Publics' },
  { value: true, label: 'Privés' },
]
