import { useMutation } from '@tanstack/react-query'
import { classifyAttachments } from '../../../data/mail'
import { UserFriendlyError } from '../../../model/Error'
import { MailClassificationAttachment } from '../../../model/Mail'
import { addValid } from '../../../store/message'
import { useActionDispatch } from '../../utils'
import { mailErrorsTitle, mailErrorsMessage } from './mail.errors'

interface UseClassifyAttachmentsVariables {
  messageId: string
  selectedAttachments: MailClassificationAttachment[]
  patientId: number
  documentCategoryValue: string
  date: Date
}

export const useClassifyAttachments = () => {
  const dispatchValid = useActionDispatch(addValid)

  const mutation = useMutation<void, Error, UseClassifyAttachmentsVariables>({
    mutationFn: async ({
      messageId,
      selectedAttachments,
      patientId,
      documentCategoryValue,
      date,
    }) => {
      const { ok } = await classifyAttachments(
        messageId,
        selectedAttachments,
        patientId,
        documentCategoryValue,
        date,
      )

      if (!ok) {
        throw new UserFriendlyError(
          'error',
          mailErrorsTitle.errorMail,
          mailErrorsMessage.classificationError,
        )
      }
    },
    onSuccess: () => {
      dispatchValid(
        'Classification des documents',
        'Les documents ont été ajoutés dans un nouvel événement médical du patient',
      )
    },
  })

  return mutation
}
