import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { deletePatientTreatment } from '../../../data/patientTreatments/deletePatientTreatment'
import { UserFriendlyError } from '../../../model/Error'
import { getVariableData } from '../../../store/renderer'
import { patientTreatmentsKeys } from './patientTreatments.keys'

interface UseDeletePatientTreatmentVariables {
  patientId: number
  treatmentId: string
}

export const useDeletePatientTreatment = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation<void, Error, UseDeletePatientTreatmentVariables>({
    mutationFn: async ({ patientId, treatmentId }) => {
      const { ok } = await deletePatientTreatment(patientId, treatmentId)

      if (!ok) {
        throw new UserFriendlyError('error', 'Erreur lors de la suppression du traitement')
      }
    },
    onSuccess: (_, { patientId }) => {
      queryClient.invalidateQueries({ queryKey: patientTreatmentsKeys.lists(patientId) })
      dispatch(getVariableData())
    },
  })
}
