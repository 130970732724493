export const isHtmlEmptyValue = (content: string | null): boolean => {
  if (content === null || content === '') {
    return true
  }

  const strippedContent = content
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/\u00A0/g, '')
    .replace('&nbsp;', '')
    .trim()

  return strippedContent === ''
}
