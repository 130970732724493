import { FC, useCallback, useMemo, useState } from 'react'
import { usePrintDocuments } from '../../../../../hooks/queries/document'
import { formatFr, DATE_FORMAT_FRONT_SHORT } from '../../../../../misc/date.utilities'
import { getFullName } from '../../../../../misc/user.utilities'
import { DocumentInstanceListItem } from '../../../../../model/DocumentInstance'
import { CustomFile } from '../../../../../model/File'
import { IconButton, TooltipWrapper } from '../../../../shared'
import { SearchMedicalEventDocumentsBottomPanel } from '../../../../shared/bottomPanel/SearchMedicalEventDocumentsBottomPanel'
import { EventDetailsTooltip } from '../../../PatientPageContent/PatientTimeline/MedicalEventItem/Header/EventDetailsTooltip'
import { EventHeaderProps } from './PatientTransversalNavigationEventHeader.model'
import styles from './PatientTransversalNavigationEventHeader.module.scss'

export const PatientTransversalNavigationEventHeader: FC<EventHeaderProps> = ({
  event,
}: EventHeaderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const print = usePrintDocuments()

  const handlePrint = useCallback(
    ({
      documents,
      files,
    }: {
      documents: readonly DocumentInstanceListItem[]
      files: readonly CustomFile[]
    }) => {
      print({ documents: [...documents], files: [...files] })
    },
    [print],
  )
  const ownerName = useMemo(() => getFullName(event.owner), [event.owner])
  const creatorName = useMemo(() => getFullName(event.creator), [event.creator])

  const hasOwnerOrCreatorName = !!ownerName || !!creatorName

  return (
    <div className={styles.eventHeader}>
      <h3 className={styles.eventTitle}>{event.title}</h3>
      <div className={styles.eventDataAndActions}>
        <span className={styles.eventDate}>
          {formatFr(new Date(event.date), DATE_FORMAT_FRONT_SHORT)}
        </span>
        <div className={styles.actionIcons}>
          {hasOwnerOrCreatorName && (
            <TooltipWrapper
              content={<EventDetailsTooltip ownerName={ownerName} creatorName={creatorName} />}
              pointerDirection="top"
              textAlign="left"
            >
              <div>
                <IconButton icon="infoCircle" theme="light" size="micro" />
              </div>
            </TooltipWrapper>
          )}
          <IconButton
            icon="print"
            theme="light"
            onClick={() => setIsOpen(true)}
            title="Imprimer des fichiers"
            size="micro"
          />
        </div>
      </div>
      <SearchMedicalEventDocumentsBottomPanel
        display={isOpen}
        onRequestClose={() => setIsOpen(false)}
        medicalEvent={event}
        title="Sélectionner les documents à imprimer"
        onSelect={handlePrint}
      />
    </div>
  )
}
