import { AnswerValue } from '../../../model/AnswerValue'

export type DocumentAnswerValuesState = Record<number, ReadonlyArray<AnswerValue> | undefined>

export const DocumentAnswerValuesDomainActionsTypes = {
  CREATE: '@DOCUMENT_ANSWER_VALUES/CREATE',
  UDPATE: '@DOCUMENT_ANSWER_VALUES/UDPATE',
  DELETE: '@DOCUMENT_ANSWER_VALUES/DELETE',
  CLEAR: '@DOCUMENT_ANSWER_VALUES/CLEAR',
  FETCH: '@DOCUMENT_ANSWER_VALUES/FETCH',
  FETCH_SCORE: '@DOCUMENT_ANSWER_VALUES/FETCH_SCORE',
  FETCH_SUCCESS: '@DOCUMENT_ANSWER_VALUES/FETCH_SUCCESS',
  SET_ERROR: '@DOCUMENT_ANSWER_VALUES/SET_ERROR',
}
