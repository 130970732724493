import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { isUploadableToServer } from '../../../misc/files.utilities'
import { uploadFile } from '../../../data/files'
import { FileType } from '../../../model/File'
import { questionInstancesKeys } from '../questionInstances/questionInstance.keys'
import { updateAnswerInstance } from '../../../data/answerInstances'

interface AddImageToAnswerInstanceMutateVariable {
  answerInstanceId: number
  file: File
  questionnaireInstanceId?: number
}

export const useAddImageToAnswerInstance = () => {
  const queryClient = useQueryClient()

  const throwUploadError = () => {
    throw new UserFriendlyError('error', "L'ajout d'une image sur la réponse a échoué")
  }

  return useMutation({
    mutationFn: async ({ answerInstanceId, file }: AddImageToAnswerInstanceMutateVariable) => {
      if (!isUploadableToServer(file)) {
        throw new UserFriendlyError(
          'error',
          'Taille de fichier non supportée',
          `Le fichier n'a pas pu être uploadé car sa taille est supérieur à 50Mo`,
        )
      }
      const { data: uploadFileData, ok: uploadFileOk } = await uploadFile(
        file,
        FileType.ANSWER_IMAGE,
      )
      if (!uploadFileData || !uploadFileOk) {
        return throwUploadError()
      }
      const { data: updateAnswerData, ok: updateAnswerOk } = await updateAnswerInstance(
        answerInstanceId,
        {
          imageFile: uploadFileData,
        },
      )
      if (!updateAnswerData || !updateAnswerOk) {
        return throwUploadError()
      }
      return updateAnswerData
    },

    onSuccess: (answer, { questionnaireInstanceId }) => {
      queryClient.invalidateQueries({ queryKey: questionInstancesKeys.detail(answer.questionId) })
      // if (questionnaireId) {
      //   queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
      // }
    },
  })
}
