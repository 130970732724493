import { Formik, FormikProps } from 'formik'
import { capitalize } from 'lodash'
import React, { FunctionComponent, useEffect } from 'react'
import { Button, FormikTextInput } from '../../../shared'
import { isDefined } from '../../../../misc/functions.utilities'
import { MailWebServiceLoginOtpFormFields } from '../../../../model/Mail'
import { MailOTPMethod } from '../MailLoginForm/MailLoginForm.model'
import { MailLoginOtpFormProps } from './MailLoginOtpForm.model'
import { MailLoginOtpValidationSchema } from './MailLoginOtpForm.validator'

export const MailLoginOtpForm: FunctionComponent<MailLoginOtpFormProps> = ({
  onSubmit,
  onRetry,
  firstStepToken,
  otpMethod,
}) => {
  const handleSubmit = (values: MailWebServiceLoginOtpFormFields) => {
    onSubmit({ otpPassword: values.otpPassword })
  }

  useEffect(() => {
    if (!firstStepToken) {
      onRetry()
    } else {
      try {
        const { exp } = JSON.parse(atob(firstStepToken.split('.')[1]))
        if (isDefined(exp) && typeof exp === 'number') {
          const timestamp = Date.now() / 1000
          const leftTime = exp - timestamp
          if (leftTime < 60) {
            onRetry()
          }
        }
      } catch (e) {
        console.warn('Unable to parse MSS tmp auth token', e)
      }
    }
  }, [firstStepToken, onRetry])

  return (
    <div>
      <Formik
        initialValues={{ otpPassword: '' }}
        validationSchema={MailLoginOtpValidationSchema}
        onSubmit={handleSubmit}
        validateOnBlur
        validateOnChange
        validateOnMount={true}
      >
        {(props: FormikProps<MailWebServiceLoginOtpFormFields>) => (
          <div
            onKeyPress={(e) => {
              if (e.key === 'Enter' && props.isValid) {
                props.handleSubmit()
              }
            }}
          >
            <span className="text-xs text-shades-4 mb-3">
              {`Veuillez entrer le code reçu par ${
                otpMethod === MailOTPMethod.EMAIL ? capitalize(otpMethod) : otpMethod
              }`}
            </span>
            <FormikTextInput
              fieldName="otpPassword"
              label="Code"
              autocomplete={false}
              colorPreset="light"
              required
            />
            <div className="flex w-full mt-5">
              <Button
                theme="primary"
                type="submit"
                onClick={() => {
                  props.handleSubmit()
                }}
                label="Valider le code reçu"
                disabled={!props.isValid}
              />
            </div>
          </div>
        )}
      </Formik>
      <div className="w-full mt-5 text-center">
        <span
          onClick={onRetry}
          className="text-xs text-shades-4 mb-3 hover:text-shades-3 hover:cursor-pointer"
        >
          Code non reçu ou expiré, recommencer la connexion
        </span>
      </div>
    </div>
  )
}
