import React, { FunctionComponent, useCallback, useState } from 'react'
import { MessageTemplateManagerProps } from './MessageTemplateManager.model'
import { ManageMessageTemplateModal } from './ManageMessageTemplateModal'
import { FormMessageTemplateModal } from './FormMessageTemplateModal'
import { MessageTemplate } from '../../../model/MessageTemplate'
import { useCreateMessageTemplate } from '../../../hooks/queries/messageTemplates'
import { useUpdateMessageTemplate } from '../../../hooks/queries/messageTemplates/useUpdateMessageTemplate.query'
import { DeleteValidationModal, ValidationModal } from '../../shared'
import { useDeleteMessageTemplate } from '../../../hooks/queries/messageTemplates/useDeleteMessageTemplate.query'
import { useUpdateDefaultMessageTemplate } from '../../../hooks/queries/messageTemplates/useUpdateDefaultMessageTemplate.query'
import { isHtmlEmptyValue } from '../../../misc/html.utils'

export const MessageTemplateManager: FunctionComponent<MessageTemplateManagerProps> = ({
  display,
  onClose,
  editor,
}) => {
  const [openedFormMessageTemplateModal, setOpenedFormMessageTemplateModal] = useState(false)
  const [openedReplaceMessageTemplateModal, setOpenedReplaceMessageTemplateModal] = useState(false)
  const [openedDeleteMessageTemplateModal, setOpenedDeleteMessageTemplateModal] = useState(false)
  const [openedEmptyBodyAlert, setOpenedEmptyBodyAlert] = useState(false)
  const [openedApplyMessageTemplateModal, setOpenedApplyMessageTemplateModal] = useState(false)
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<
    MessageTemplate | undefined
  >(undefined)
  const { mutate: createMessageTemplate } = useCreateMessageTemplate()
  const { mutate: updateMessageTemplate } = useUpdateMessageTemplate()
  const { mutate: deleteMessageTemplate } = useDeleteMessageTemplate()
  const { mutate: updateDefaultMessageTemplate } = useUpdateDefaultMessageTemplate()

  const getEditorHtml = useCallback(() => {
    return editor?.getHTML() ?? ''
  }, [editor])

  const editorHtmlIsEmpty = useCallback(() => {
    return isHtmlEmptyValue(getEditorHtml())
  }, [getEditorHtml])

  const handleAddMessageTemplate = useCallback(() => {
    if (editorHtmlIsEmpty()) {
      setOpenedEmptyBodyAlert(true)
      return
    }

    setSelectedMessageTemplate(undefined)
    setOpenedFormMessageTemplateModal(true)
  }, [editorHtmlIsEmpty])

  const handleRenameMessageTemplate = useCallback((messageTemplate: MessageTemplate) => {
    setSelectedMessageTemplate(messageTemplate)
    setOpenedFormMessageTemplateModal(true)
  }, [])

  const handleReplaceMessageTemplate = useCallback(
    (messageTemplate: MessageTemplate) => {
      if (editorHtmlIsEmpty()) {
        setOpenedEmptyBodyAlert(true)
        return
      }

      setSelectedMessageTemplate(messageTemplate)
      setOpenedReplaceMessageTemplateModal(true)
    },
    [editorHtmlIsEmpty],
  )

  const handleDeleteMessageTemplate = useCallback((messageTemplate: MessageTemplate) => {
    setSelectedMessageTemplate(messageTemplate)
    setOpenedDeleteMessageTemplateModal(true)
  }, [])

  const handleUpdateDefaultMessageTemplate = useCallback(
    (messageTemplate: MessageTemplate) => {
      updateDefaultMessageTemplate({ id: !messageTemplate.isDefault ? messageTemplate.id : null })
    },
    [updateDefaultMessageTemplate],
  )

  const handleSubmitAddMessageTemplate = useCallback(
    (title: string) => {
      createMessageTemplate(
        {
          title,
          body: getEditorHtml(),
        },
        {
          onSuccess: () => {
            setOpenedFormMessageTemplateModal(false)
            onClose()
          },
        },
      )
    },
    [getEditorHtml, createMessageTemplate, onClose],
  )

  const handleSubmitRenameMessageTemplate = useCallback(
    (title: string) => {
      if (!selectedMessageTemplate?.id) {
        return
      }

      updateMessageTemplate(
        { id: selectedMessageTemplate.id, payload: { ...selectedMessageTemplate, title } },
        {
          onSuccess: () => {
            setOpenedFormMessageTemplateModal(false)
            setSelectedMessageTemplate(undefined)
          },
        },
      )
    },
    [selectedMessageTemplate, updateMessageTemplate],
  )

  const handleSubmitFormMessageTemplate = useCallback(
    (title: string) => {
      if (!selectedMessageTemplate?.id) {
        handleSubmitAddMessageTemplate(title)
        return
      }

      handleSubmitRenameMessageTemplate(title)
    },
    [handleSubmitAddMessageTemplate, handleSubmitRenameMessageTemplate, selectedMessageTemplate],
  )

  const handleConfirmReplaceMessageTemplate = useCallback(() => {
    if (!selectedMessageTemplate?.id) {
      return
    }

    updateMessageTemplate(
      {
        id: selectedMessageTemplate.id,
        payload: { ...selectedMessageTemplate, body: getEditorHtml() },
      },
      {
        onSuccess: () => {
          setOpenedReplaceMessageTemplateModal(false)
          setSelectedMessageTemplate(undefined)
        },
      },
    )
  }, [getEditorHtml, selectedMessageTemplate, updateMessageTemplate])

  const handleConfirmDeleteMessageTemplate = useCallback(() => {
    if (!selectedMessageTemplate?.id) {
      return
    }

    deleteMessageTemplate(
      { messageTemplateId: selectedMessageTemplate?.id },
      {
        onSuccess: () => {
          setOpenedDeleteMessageTemplateModal(false)
          setSelectedMessageTemplate(undefined)
        },
      },
    )
  }, [selectedMessageTemplate, deleteMessageTemplate])

  const applyMessageTemplate = useCallback(
    (template: MessageTemplate) => {
      if (!editor) {
        return
      }

      editor.commands.setContent(template.body)
      setOpenedApplyMessageTemplateModal(false)
      onClose()
    },
    [editor, onClose],
  )

  const handleSelectMessageTemplate = useCallback(
    (messageTemplate: MessageTemplate) => {
      if (!editorHtmlIsEmpty()) {
        setSelectedMessageTemplate(messageTemplate)
        setOpenedApplyMessageTemplateModal(true)
        return
      }

      applyMessageTemplate(messageTemplate)
    },
    [editorHtmlIsEmpty, applyMessageTemplate],
  )

  const handleApplySelectedMessageTemplate = useCallback(() => {
    if (!selectedMessageTemplate) return

    applyMessageTemplate(selectedMessageTemplate)
  }, [applyMessageTemplate, selectedMessageTemplate])

  return (
    <>
      <ManageMessageTemplateModal
        display={display}
        onClose={onClose}
        onAdd={handleAddMessageTemplate}
        onRename={handleRenameMessageTemplate}
        onReplace={handleReplaceMessageTemplate}
        onDelete={handleDeleteMessageTemplate}
        onUpdateDefault={handleUpdateDefaultMessageTemplate}
        onApply={handleSelectMessageTemplate}
      />
      <FormMessageTemplateModal
        messageTemplate={selectedMessageTemplate}
        onSubmit={handleSubmitFormMessageTemplate}
        display={openedFormMessageTemplateModal}
        label={selectedMessageTemplate?.id ? 'Renommer le modèle' : 'Enregistrer le modèle'}
        onClose={() => setOpenedFormMessageTemplateModal(false)}
      />
      {selectedMessageTemplate && (
        <ValidationModal
          display={openedReplaceMessageTemplateModal}
          title={`Voulez vous vraiment remplacer le modèle "${selectedMessageTemplate.title}" ?`}
          testId="modal-replace-message-template"
          onClose={() => setOpenedReplaceMessageTemplateModal(false)}
          onSubmit={handleConfirmReplaceMessageTemplate}
        />
      )}
      <ValidationModal
        display={openedEmptyBodyAlert}
        title="Votre message est vide, impossible de l'enregistrer en tant que modèle"
        testId="modal-empty-body-alert"
        onClose={() => setOpenedEmptyBodyAlert(false)}
      />
      {selectedMessageTemplate && (
        <DeleteValidationModal
          display={openedDeleteMessageTemplateModal}
          title={`Voulez vous vraiment supprimer le modèle "${selectedMessageTemplate.title}" ?`}
          testId="modal-delete-message-template"
          onClose={() => setOpenedDeleteMessageTemplateModal(false)}
          onSubmit={handleConfirmDeleteMessageTemplate}
        />
      )}
      {selectedMessageTemplate && (
        <ValidationModal
          display={openedApplyMessageTemplateModal}
          title="Voulez vous vraiment appliquer ce modèle ? Le contenu de votre message sera remplacé."
          testId="modal-apply-message-template"
          onClose={() => setOpenedApplyMessageTemplateModal(false)}
          onSubmit={handleApplySelectedMessageTemplate}
        />
      )}
    </>
  )
}
