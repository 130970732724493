import React from 'react'
import classnames from 'classnames/bind'

import { Icon } from '../../Icon'

import { InputProps } from './Input.model'

import styles from './Input.module.scss'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
const classnamesCx = classnames.bind(styles)

export const Input = React.forwardRef<HTMLDivElement, InputProps>(
  (
    {
      type = 'text',
      colorPreset = 'dark',
      height = 'medium',
      label,
      name,
      placeholder,
      error,
      icon,
      valid,
      disabled = false,
      readonly = false,
      required = false,
      autocomplete = 'on',
      autofocus = false,
      pattern,
      underline = true,
      value,
      centered = false,
      maxLength,
      minLength,
      infos,
      testId,
      minValue,
      maxValue,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      onWheel,
    },
    ref,
  ) => {
    const containerClasses = classnamesCx({
      container: true,
      dark: colorPreset === 'dark',
      light: colorPreset === 'light',
    })

    const labelText = label ? `${label} ${required ? '*' : ''}` : label

    const inputClasses = classnamesCx({
      input: true,
      light: colorPreset === 'light',
      centered,
      readonly,
    })

    return (
      <div className={containerClasses} ref={ref}>
        <div
          className={classnamesCx(
            styles.content,
            disabled && colorPreset === 'light' ? 'bg-shades-8' : '',
            `${height}Height`,
            {
              underline,
              valid: !!valid && !disabled,
              invalid: valid === false && !disabled,
            },
          )}
        >
          {icon && (
            <div className={styles.iconWrapper}>
              <Icon
                size="nano"
                icon={icon}
                color={getV3ColorCssVariable(
                  'shades',
                  colorPreset === 'light' ? 'shade4' : 'shade5',
                )}
              />
            </div>
          )}
          <div className={classnamesCx(styles.wrapper)}>
            {labelText && <label className={styles.label}>{labelText}</label>}
            <input
              data-test-id={testId}
              type={type}
              name={name}
              value={value}
              className={inputClasses}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readonly}
              autoComplete={autocomplete}
              autoFocus={autofocus}
              pattern={pattern}
              maxLength={maxLength}
              minLength={minLength}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              onWheel={onWheel}
              min={minValue}
              max={maxValue}
            />
          </div>
        </div>
        {!valid && error && <div className={styles.error}>{error}</div>}
        {infos && (
          <div className="text-xs mt-2 inline-flex items-center space-x-1">
            <Icon icon="infoCircle" color={getV3ColorCssVariable('shades', 'shade4')} size="pico" />
            <span>{infos}</span>
          </div>
        )}
      </div>
    )
  },
)
