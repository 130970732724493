import classNames from 'classnames'
import React, { useCallback } from 'react'
import { ColorsV2 } from '../../../../../../design-system/colors_v2'
import { getV3ColorCssVariable } from '../../../../../../design-system/colors_v3'
import { Icon } from '../../../../Icon'
import { MedicalEventsDocumentsListItemProps } from './MedicalEventsDocumentsListItem.model'
import styles from './MedicalEventsDocumentsListItem.module.scss'

const cx = classNames.bind(styles)

export const MedicalEventsDocumentsListItem = React.forwardRef<
  HTMLDivElement,
  MedicalEventsDocumentsListItemProps
>(({ id, title, icon, selected, disabled, onDocumentClick, onFileClick }, ref) => {
  const handleSelectDocument = useCallback(() => {
    if (onDocumentClick) onDocumentClick()
    if (onFileClick) onFileClick()
  }, [onDocumentClick, onFileClick])

  const isSelected = !disabled && selected

  return (
    <div
      key={id}
      className={cx(styles.documentItemContainer, {
        [styles.selected]: isSelected,
        [styles.disabled]: disabled,
      })}
      onClick={disabled ? undefined : handleSelectDocument}
      ref={ref}
    >
      <div className={styles.documentItemDescription}>
        <Icon
          icon={icon}
          size="nano"
          color={isSelected ? ColorsV2.dsWhite : getV3ColorCssVariable('shades', 'shade2')}
        />
        <span className={styles.documentItemName}>{title}</span>
      </div>
    </div>
  )
})
