import uuidv4 from 'uuid/v4'
import { createReducer } from 'redux-starter-kit'
import { dmpDomainActions } from './dmp.actions'
import { DmpDomainActionsTypes, DmpState } from './dmp.model'

const DEFAULT_STATE: DmpState = {
  healthcareSettings: [],
  isSending: false,
  iframeStatus: 'loading',
}

const ACTION_HANDLERS = {
  [DmpDomainActionsTypes.SET_REQUESTS]: (
    state: DmpState,
    { requests }: ReturnType<typeof dmpDomainActions.setRequests>,
  ): DmpState => {
    return {
      ...state,
      requests: requests.map((request) => ({
        uuid: uuidv4(),
        documentId: request.documentId,
        payload: JSON.stringify({
          remoteCommand: window.btoa(window.unescape(encodeURIComponent(JSON.stringify(request)))),
        }),
      })),
      isSending: true,
    }
  },
  [DmpDomainActionsTypes.REMOVE_REQUEST]: (
    state: DmpState,
    { fileId, requests }: ReturnType<typeof dmpDomainActions.removeRequest>,
  ): DmpState => {
    return {
      ...state,
      requests: requests.filter((request) => request.documentId !== fileId),
      isSending: requests.length !== 1,
    }
  },
  [DmpDomainActionsTypes.STORE_HEALTHCARE_SETTINGS]: (
    state: DmpState,
    { healthcareSettings }: ReturnType<typeof dmpDomainActions.storeHealthcareSettings>,
  ) => ({
    ...state,
    healthcareSettings,
  }),
  [DmpDomainActionsTypes.SET_IFRAME_STATE]: (
    state: DmpState,
    { iframeStatus }: ReturnType<typeof dmpDomainActions.setIframeStatus>,
  ) => ({
    ...state,
    iframeStatus,
  }),
}

export const dmpReducer = createReducer<DmpState>(DEFAULT_STATE, ACTION_HANDLERS)
