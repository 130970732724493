import { Event, EventSourcePolyfill } from 'event-source-polyfill'
import { MERCURE_HUB_URL } from '../../../environment/mercurehub'
import { User } from '../../../model/User'
import { useCallback, useEffect } from 'react'
import { MercureMessageEvent, NotificationEventType } from '../../../model/NotificationSubscriber'
import { useEphemeralNotificationEvent } from './useEphemeralNotificationEvent.hook'
import { useGetTaskCount } from '../../queries/task'
import { EphemeralNotification } from '../../../model/EphemeralNotification'
import { NotificationType, ServerSentNotification } from '../../../model/Notification'
import { useQueryClient } from '@tanstack/react-query'
import { notificationsKeys } from '../../queries/notifications'

export const useNotificationSubscriber = (loggedUser: User | null) => {
  const { handleEphemeralNotificationEvent } = useEphemeralNotificationEvent()
  const { refetch: refetchUnreadTaskCount } = useGetTaskCount(
    { read: false },
    { infinityStale: true },
  )
  const queryClient = useQueryClient()

  const handleNotificationEvent = useCallback(
    ({ type }: ServerSentNotification) => {
      if (type === NotificationType.feature) {
        queryClient.refetchQueries({
          queryKey: notificationsKeys.types([NotificationType.feature]),
        })
      } else {
        queryClient.refetchQueries({
          queryKey: notificationsKeys.types([
            NotificationType.announcement,
            NotificationType.incident,
          ]),
        })
      }
    },
    [queryClient],
  )

  const handleEventSourceEvent = useCallback(
    (evt: Event) => {
      const event = evt as MercureMessageEvent
      if (event.data) {
        switch (event.type) {
          case NotificationEventType.ephemeralNotification:
            const ephemeralNotification: EphemeralNotification = JSON.parse(event.data)
            handleEphemeralNotificationEvent(ephemeralNotification)
            break
          case NotificationEventType.notification:
            const notification: ServerSentNotification = JSON.parse(event.data)
            handleNotificationEvent(notification)
            break
          case NotificationEventType.task:
            refetchUnreadTaskCount()
            break
        }
      }
    },
    [handleEphemeralNotificationEvent, handleNotificationEvent, refetchUnreadTaskCount],
  )

  useEffect(() => {
    if (!loggedUser) return

    let eventSource: EventSourcePolyfill
    try {
      eventSource = getEventSource(loggedUser)
      eventSource.addEventListener(
        NotificationEventType.ephemeralNotification,
        handleEventSourceEvent,
      )
      eventSource.addEventListener(NotificationEventType.notification, handleEventSourceEvent)
      eventSource.addEventListener(NotificationEventType.task, handleEventSourceEvent)
    } catch (error) {
      console.error(error)
    }
    return () => {
      eventSource?.close()
    }
  }, [handleEventSourceEvent, loggedUser])
}

const getEventSource = (loggedUser: User) => {
  const mercureUrl = new URL(MERCURE_HUB_URL)
  mercureUrl.searchParams.append('topic', `${MERCURE_HUB_URL}/follow_tasks_${loggedUser.id}`)
  mercureUrl.searchParams.append('topic', `${MERCURE_HUB_URL}/follow_global_notification`)
  mercureUrl.searchParams.append('topic', `${MERCURE_HUB_URL}/follow_global_ephemeral_notification`)
  mercureUrl.searchParams.append(
    'topic',
    `${MERCURE_HUB_URL}/follow_private_notification_${loggedUser.id}`,
  )
  mercureUrl.searchParams.append(
    'topic',
    `${MERCURE_HUB_URL}/follow_private_ephemeral_notification_${loggedUser.id}`,
  )

  const eventSource = new EventSourcePolyfill(mercureUrl.href, {
    headers: {
      authorization: `Bearer ${loggedUser.subscriberToken}`,
    },
  })

  return eventSource
}
