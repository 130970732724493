import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { UiQuoteline } from '../../../../components/ccam/QuoteLineForm/QuoteLineForm.model'
import { getQuoteLinePreview } from '../../../../data/ccam/quoteLines/getQuoteLinePreview'
import { UserFriendlyError } from '../../../../model/Error'
import { quoteLinesKeys } from './quoteLines.keys'

interface GetQuoteLinePreviewVariables {
  quoteLineId: string
  quoteLine: UiQuoteline
  enabled?: boolean
}

export const useGetQuoteLinePreview = ({
  quoteLineId,
  quoteLine,
  enabled,
}: GetQuoteLinePreviewVariables) => {
  const quoteLineKey = quoteLinesKeys.preview(quoteLine)

  const queryResult = useQuery({
    queryKey: quoteLineKey,
    queryFn: async ({ signal }) => {
      const { data, ok } = await getQuoteLinePreview(quoteLineId, quoteLine, signal)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Impossible de récupérer la prévisualisation de la configuration du code CCAM.',
        )
      }
      return data
    },
    enabled,
  })
  const queryClient = useQueryClient()

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey: quoteLineKey })
  }, [queryClient, quoteLineKey])

  return { data: queryResult, cancelPendingQuery }
}
