import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getDrug } from '../../../data/drugs'
import { DrugIdentity } from '../../../model/Drug'
import { UserFriendlyError } from '../../../model/Error'
import { drugsKeys } from './drugs.keys'

interface UseGetDrugParams {
  drug: DrugIdentity | null
  enabled?: boolean
}

export const useGetDrug = ({ drug, enabled = true }: UseGetDrugParams) => {
  const queryKey = useMemo(() => drugsKeys.detail(drug), [drug])

  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      if (!drug) return null

      const { data, ok } = await getDrug(drug, signal)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la récupération des informations du médicament',
        )
      }

      return data
    },
    enabled,
  })

  return { query }
}
