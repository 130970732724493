import { ColorsV2TagBackground } from '../../../../design-system/colors_v2'
import { DocumentAlertSeverity, DocumentAlert } from '../../../../model/DocumentAlerts'

export interface DocumentAlertItemProps {
  alert: DocumentAlert
  open: boolean
}

export const mapSeverityToTagColor: { [key in DocumentAlertSeverity]: ColorsV2TagBackground } = {
  [DocumentAlertSeverity.info]: ColorsV2TagBackground.dsTag03,
  [DocumentAlertSeverity.level1]: ColorsV2TagBackground.dsTag05,
  [DocumentAlertSeverity.level2]: ColorsV2TagBackground.dsTag06,
  [DocumentAlertSeverity.level3]: ColorsV2TagBackground.dsTag07,
  [DocumentAlertSeverity.level4]: ColorsV2TagBackground.dsTag08,
}
