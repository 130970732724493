import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteSurvey } from '../../../data/survey'
import { UserFriendlyError } from '../../../model/Error'
import { surveyKeys } from './survey.keys'

interface UseDeleteSurveyVariables {
  id: number
}

export const useDeleteSurvey = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, UseDeleteSurveyVariables>({
    mutationFn: async ({ id }) => {
      const { ok } = await deleteSurvey(id)

      if (!ok) {
        throw new UserFriendlyError(
          'error',
          "La suppression du questionnaire salle d'attente a échoué",
        )
      }
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: surveyKeys.list })
      queryClient.removeQueries({ queryKey: surveyKeys.detail(variables.id) })
    },
  })
}
