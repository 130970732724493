import { useQuery } from '@tanstack/react-query'
import { codedInformationsKeys } from './codedInformations.keys'
import { UserFriendlyError } from '../../../model/Error'
import { searchCodedInformations } from '../../../data/patientCodedInformations'
import { useCallback } from 'react'
import { queryClient } from '../../../App'

interface GetSearchInformationsParams {
  search: string
  enabled?: boolean
}

export const useSearchCodedInformations = ({
  search,
  enabled = true,
}: GetSearchInformationsParams) => {
  const queryKey = codedInformationsKeys.searchByName(search)

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await searchCodedInformations(search)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer les données de recherche sur les allergies et antécédents`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  const cancelPendingQuery = useCallback(() => queryClient.cancelQueries({ queryKey }), [queryKey])

  return { query: queryReturn, cancelPendingQuery }
}
