import { FeaturesType } from '@follow/cdk'
import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getAddresses, getMailList } from '../../../data/mail'
import { UserFriendlyError } from '../../../model/Error'
import { DEFAULT_OFFSET_MAIL_LIST, DEFAULT_LIMIT_MAIL_LIST } from '../../../model/Mail'
import { mailLoginRedirect } from '../../../state/mail'
import { UrlQueryParams } from '../../../store/domain/medicalEvents'
import { useUserEnabledFeature } from '../../utils/user'

export const useTestMailConnection = () => {
  const enabledMail = useUserEnabledFeature(FeaturesType.mail)
  const isSmtpActive = useUserEnabledFeature(FeaturesType.mssSmtp)

  const setMailLoginRedirect = useSetAtom(mailLoginRedirect)
  const [, setSearchParams] = useSearchParams()
  const location = useLocation()

  const mutation = useMutation({
    mutationFn: async () => {
      if (!enabledMail) {
        throw new UserFriendlyError('error', "La messagerie n'est pas disponible") // ne devrait jamais arriver
      }

      if (isSmtpActive) {
        setSearchParams((prev) => {
          const updated = new URLSearchParams(prev)
          updated.set(UrlQueryParams.SENDING, 'true')
          return updated
        })

        const { ok } = await getMailList(DEFAULT_OFFSET_MAIL_LIST, DEFAULT_LIMIT_MAIL_LIST)
        return ok
      } else {
        const searchParams = new URLSearchParams(location.search)
        searchParams.set(UrlQueryParams.SENDING, 'true')
        setMailLoginRedirect(`${location.pathname}?${searchParams.toString()}`)

        const { ok } = await getAddresses()
        return ok
      }
    },
    onSuccess: (data) => {
      if (data) {
        setSearchParams((prev) => {
          const updated = new URLSearchParams(prev)
          updated.delete(UrlQueryParams.SENDING)
          return updated
        })
        setMailLoginRedirect(RESET)
      }
    },
  })

  return mutation
}
