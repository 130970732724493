import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { MessageTemplateMutationPayload } from '../../../data/messageTemplates/mapper/messageTemplates.model'
import { messageTemplatesKeys } from './messageTemplates.keys'
import { updateMessageTemplate } from '../../../data/messageTemplates/updateMessageTemplate'

interface MessageTemplateMutateVariable {
  id: number
  payload: MessageTemplateMutationPayload
}

export const useUpdateMessageTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, payload }: MessageTemplateMutateVariable) => {
      const { data, ok, status } = await updateMessageTemplate(id, payload)
      if (!data || !ok) {
        if (status === 409) {
          throw new UserFriendlyError(
            'error',
            'Ce titre de modèle est déjà utilisé, veuillez en choisir un autre',
          )
        }

        throw new UserFriendlyError('error', 'La modification du modèle de message a échoué')
      }
      return data
    },

    onSuccess: (messageTemplate) => {
      queryClient.invalidateQueries({ queryKey: messageTemplatesKeys.list })
      queryClient.setQueryData(messageTemplatesKeys.detail(messageTemplate.id), messageTemplate)
    },
  })
}
