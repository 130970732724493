import { ApiResponse } from 'apisauce'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'
import { getPdfFromDocuments } from '../../../api/Renderer'
import { blobToBase64 } from '../../../components/file/FileViewer/PdfViewerLegacy/pdf.utils'
import { formatFr } from '../../../misc/date.utilities'
import { Patient, Sex } from '../../../model/Patient'
import { Roles } from '../../../model/Roles'
import { dmpDomainActions } from './dmp.actions'
import {
  DmpDomainActionsTypes,
  DmpIframeStatus,
  DmpRequestTypes,
  DocumentTypeCode,
  FileForDMP,
} from './dmp.model'
import { EfficienceUploadConfig, HealthcareSetting } from '../../../model/Efficience'
import { getHealthcareSettings } from './api'
import { addError, addInfo } from '../../message'
import { inUseMedicalEventSelector } from '../../ui/medicalEvents'
import { MedicalEvent } from '../../../model/MedicalEvent'
import { DocumentInstance } from '../../../model/DocumentInstance'
import { inUseMedicalEventDocumentSelector } from '../../ui/medicalEvents/medicalEventDocumentInstances'
import { extractFileNameFromHeader } from '../../../misc/extractFilename.utilities'
import { getCurrentPatient } from '../../../misc/currentPatient.utilities'
import { RenderableDocument } from '../../../data/documents/mapper/documents.model'
import { ExtendedUserDoctor } from '../../../model/User'
import { dmpIframeStatusSelector } from './dmp.selectors'
import { AnyAction } from 'redux'

const buildRequest = (
  file: FileForDMP,
  patient: Patient,
  medicalEventOwner: ExtendedUserDoctor,
  efficienceConfig: EfficienceUploadConfig,
  requestType: DmpRequestTypes,
) => {
  const requestId = {
    type: requestType,
    fileId: file.id,
  }

  return {
    documentId: file.id,
    request: {
      action: 'submitDocument',
      id: requestId,
      sendInBackground: 1,
      modal: 0,
      patient: {
        Identity: {
          Ins: {
            key: patient.inseeNumber?.slice(-2),
            oid: '1.2.250.1.213.1.4.10',
            value: patient.inseeNumber?.slice(0, -2),
          },
          root: 'T',
          extension: patient.inseeNumber,
          birthName: patient.birthLastName,
          birthGiven: patient.birthFirstName,
          sex: patient.sex === Sex.MALE ? '2' : '3',
          birthDate: formatFr(new Date(patient.birthDate), 'yyyyMMdd'),
          birthPlace: patient.birthPlaceCode,
        },
      },
      document: {
        content: file.base64.split(',')[1],
        format: file.format,
        title: file.name,
        typeCode: file.loincCode ?? '11488-4',
        role: medicalEventOwner.roles.includes(Roles.DOCTOR) ? 'PPRF' : 'SPRF',
        createDate: formatFr(file.createdAt ? new Date(file.createdAt) : new Date(), 'yyyyMMdd'),
        versionNumber: file.version ? file.version + 1 : '1',
        invisiblePatient: efficienceConfig.hideToPatient ? 1 : 0,
        invisibleRepresentantLegal: efficienceConfig.hideToLegalRepresentative ? 1 : 0,
        masquePS: efficienceConfig.hideToHealthProfessional ? 1 : 0,
        practice: efficienceConfig.healthcareSettingsId,
        ...(file.dmpDocumentId && {
          replacedDocumentUniqueId: file.dmpDocumentId,
        }),
      },
    },
  }
}

/* Déclenche si besoin le processus d'auth via CPS et pincode */
function* waitForIframeToLoad() {
  const iframeStatus: DmpIframeStatus = yield select(dmpIframeStatusSelector)
  if (iframeStatus === 'need_auth') {
    yield put(dmpDomainActions.setIframeStatus('auth_in_progress'))

    yield take(
      (action: AnyAction | ReturnType<typeof dmpDomainActions.setIframeStatus>) =>
        action.type === DmpDomainActionsTypes.SET_IFRAME_STATE && action?.iframeStatus === 'ready',
    )
  }
}

function* uploadDocumentWorker({
  documentId,
  documentType,
  config,
  requestType,
}: ReturnType<typeof dmpDomainActions.uploadDocument>) {
  const patient = getCurrentPatient()
  const medicalEvent: MedicalEvent | undefined = yield select(inUseMedicalEventSelector)
  if (!patient || !medicalEvent) return

  const document: DocumentInstance | null = yield select(inUseMedicalEventDocumentSelector)

  const renderable: RenderableDocument = { documentId, type: documentType }

  if (!document) {
    yield addError(
      "Erreur lors de l'envoi au DMP",
      "Les informations nécessaires à l'envoi sont introuvables",
    )
    return
  }

  yield sendDocument([renderable], config, patient, medicalEvent, document, requestType)
}

function* uploadDocumentWatcher() {
  yield takeEvery(DmpDomainActionsTypes.UPLOAD_DOCUMENT, uploadDocumentWorker)
}

function* sendDocument(
  documents: Array<RenderableDocument>,
  efficienceConfig: EfficienceUploadConfig,
  patient: Patient,
  medicalEvent: MedicalEvent,
  documentInstance: DocumentInstance,
  requestType: DmpRequestTypes,
) {
  const response: ApiResponse<Blob> = yield call(getPdfFromDocuments, documents)
  if (response.ok && response.data) {
    const name = extractFileNameFromHeader(response.headers) ?? 'Document'
    const base64 = yield call(blobToBase64, response.data)
    const { id, version, dmpDocumentId, documentCategory, createdAt } = documentInstance

    const fileForDMP: FileForDMP = {
      id,
      name,
      base64,
      format: 'pdf',
      dmpDocumentId,
      version,
      createdAt,
      loincCode: DocumentTypeCode[documentCategory.id],
    }

    yield put(
      dmpDomainActions.sendDocumentsToDMP(
        patient,
        medicalEvent.owner,
        efficienceConfig,
        requestType,
        [fileForDMP],
      ),
    )
  }
}

function* sendDocumentsToDMPWorker({
  patient,
  medicalEventOwner,
  efficienceConfig,
  files,
  requestType,
}: ReturnType<typeof dmpDomainActions.sendDocumentsToDMP>) {
  yield call(waitForIframeToLoad)

  yield put(addInfo("Documents en cours d'envoi au dossier médical partagé du patient"))

  const requests = files.map((file) =>
    buildRequest(file, patient, medicalEventOwner, efficienceConfig, requestType),
  )

  yield put(dmpDomainActions.setRequests(requests))
}

function* sendDocumentsToDMPWatcher() {
  yield takeEvery(DmpDomainActionsTypes.SEND_PDFS_TO_DMP, sendDocumentsToDMPWorker)
}

function* deleteDocumentWorker({
  documentId,
  dmpDocumentId,
  patientInseeNumber,
  requestType,
}: ReturnType<typeof dmpDomainActions.deleteDocument>) {
  yield call(waitForIframeToLoad)

  yield put(
    dmpDomainActions.setRequests([
      {
        documentId,
        request: {
          action: 'deleteDocument',
          id: {
            type: requestType,
            fileId: documentId,
          },
          patient: {
            ins: {
              root: 'T',
              extension: patientInseeNumber,
            },
          },
          document: {
            uniqueId: dmpDocumentId,
            practice: 'SA05',
          },
        },
      },
    ]),
  )
}

function* openDmpWorker({ patientIns }: ReturnType<typeof dmpDomainActions.openDmp>) {
  yield call(waitForIframeToLoad)

  yield put(
    dmpDomainActions.setRequests([
      {
        documentId: undefined,
        request: {
          action: 'openDmp',
          id: {
            type: DmpRequestTypes.OPEN_DMP,
          },
          patient: {
            ins: {
              root: 'T',
              extension: patientIns,
            },
          },
        },
      },
    ]),
  )
}

function* openDmpWatcher() {
  yield takeEvery(DmpDomainActionsTypes.OPEN_DMP, openDmpWorker)
}

function* closeDmpSessionWorker() {
  yield put(
    dmpDomainActions.setRequests([
      {
        documentId: undefined,
        request: {
          action: 'closeDmpSession',
          id: { type: DmpRequestTypes.CLOSE_DMP_SESSION },
        },
      },
    ]),
  )
}

function* closeDmpSessionWatcher() {
  yield takeEvery(DmpDomainActionsTypes.CLOSE_DMP_SESSION, closeDmpSessionWorker)
}

function* deleteDocumentWatcher() {
  yield takeEvery(DmpDomainActionsTypes.DELETE_DOCUMENT, deleteDocumentWorker)
}

function* fetchHealthcareSettingsWorker() {
  const response: ApiResponse<HealthcareSetting[]> = yield call(getHealthcareSettings)
  if (response.ok && response.data) {
    yield put(dmpDomainActions.storeHealthcareSettings(response.data))
  }
}

function* fetchHealthcareSettingsWatcher() {
  yield takeEvery(DmpDomainActionsTypes.FETCH_HEALTHCARE_SETTINGS, fetchHealthcareSettingsWorker)
}

export const dmpSagas = {
  deleteDocumentWatcher,
  uploadDocumentWatcher,
  openDmpWatcher,
  closeDmpSessionWatcher,
  fetchHealthcareSettingsWatcher,
  sendDocumentsToDMPWatcher,
}
