import React, { FC } from 'react'
import { ColorsV2 } from '../../../design-system/colors_v2'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import { formatFr } from '../../../misc/date.utilities'
import { getVigilancePictogram } from '../../../misc/drug.utilities'
import { isDefined } from '../../../misc/functions.utilities'
import { DrugGenericTypes } from '../../../model/Drug'
import { InlineTag, TooltipWrapper, Icon } from '../../shared'
import { DrugThumbnailProps } from './DrugThumbnail.model'
import { computeActivePrinciplesLabel, computePriceInformations } from './DrugThumbnail.utils'

const sizes = {
  medium: 'text-base font-medium',
  big: 'text-lg font-semibold',
}

export const DrugThumbnail: FC<DrugThumbnailProps> = ({
  drug,
  size,
  treatment,
  prescription,
  displayInfo,
  isAdditionalDrug,
}) => {
  const priceInformations = computePriceInformations({
    drug,
    prescriptionPrice: prescription?.price,
  })
  const activesPrinciples = computeActivePrinciplesLabel(drug)
  const vigilancePictogram = getVigilancePictogram(drug.vigilanceLevel)

  return (
    <div className="flex flex-col">
      <span className={`${sizes[size]} text-shades-2 break-all flex flex-row justify-between`}>
        <span>
          {isAdditionalDrug && 'et '}
          {displayInfo ? drug.summary : drug.name}
          <span className="pl-2 space-x-2">
            {drug.genericType === DrugGenericTypes.GENERIC && (
              <InlineTag color="lightGray">Générique</InlineTag>
            )}
            {drug.genericType === DrugGenericTypes.REFERENT && (
              <InlineTag color="lightGray">Référent</InlineTag>
            )}
            {drug.drugInSport && <InlineTag color="lightGray">Dopant</InlineTag>}
            {drug.isNarcotic && <InlineTag color="lightGray">Stupéfiant</InlineTag>}
            {isDefined(drug.bioSimilarGroup) && (
              <InlineTag color="lightGray">
                <span className="mr-1">Groupe Bio-Similaire</span>
                <Icon
                  size="nano"
                  icon="infoCircle"
                  color={ColorsV2.backgroundDark3}
                  title={`${drug.bioSimilarGroup.name} (Groupe ${drug.bioSimilarGroup.id})\n${drug.bioSimilarGroup.comment}`}
                />
              </InlineTag>
            )}
            {drug.restrictedPrescriptions.length > 0 && (
              <InlineTag color="lightGray">
                <span className="mr-1">Prescription Restreinte</span>
                <Icon
                  size="nano"
                  icon="infoCircle"
                  color={ColorsV2.backgroundDark3}
                  title={drug.restrictedPrescriptions.reduce(
                    (title, info) => `${title}\n${info}`,
                    '',
                  )}
                />
              </InlineTag>
            )}
            {isDefined(treatment?.drugConfig.drug.sideEffects) &&
              treatment?.drugConfig.drug.sideEffects.map(({ frequencyDisplayName, title }) => (
                <InlineTag color="lightGray">
                  {title}: {frequencyDisplayName ?? 'Fréquence inconnue'}
                </InlineTag>
              ))}
          </span>
        </span>
        {displayInfo && (
          <div className="ml-2 pt-1">
            <TooltipWrapper
              content={<div className="break-normal">{drug.name}</div>}
              pointerDirection="right"
            >
              <Icon size="micro" icon="infoCircle" color={ColorsV2.backgroundDark1} />
            </TooltipWrapper>
          </div>
        )}
      </span>
      <div className="flex flex-row items-center">
        <div className="flex flex-col">
          {activesPrinciples && (
            <span className="pt-1 text-shades-4 text-xs font-medium">{activesPrinciples}</span>
          )}
          {priceInformations && (
            <span className="pt-1 text-shades-4 text-xs font-medium">{priceInformations}</span>
          )}
          {treatment?.drugConfig.posologySummary && (
            <span className="pt-1 text-shades-4 text-xs font-medium">
              {treatment?.drugConfig.posologySummary}
            </span>
          )}
          <div className="flex items-center pt-2 text-shades-4 text-xs font-medium space-x-2">
            {treatment?.startDate && (
              <>
                <Icon
                  icon="calendar"
                  size="nano"
                  color={getV3ColorCssVariable('shades', 'shade4')}
                />
                <span>{formatFr(treatment.startDate, 'dd/MM/yyyy')}</span>
              </>
            )}
            {treatment?.endDate && (
              <>
                <Icon
                  icon="arrow"
                  rotate={90}
                  size="nano"
                  color={getV3ColorCssVariable('shades', 'shade4')}
                />
                <Icon
                  icon="calendar"
                  size="nano"
                  color={getV3ColorCssVariable('shades', 'shade4')}
                />
                <span>{formatFr(treatment.endDate, 'dd/MM/yyyy')}</span>
              </>
            )}
          </div>
        </div>
        {vigilancePictogram && size === 'big' && (
          <img
            className="w-8 h-8 ml-2"
            src={vigilancePictogram.pictogram}
            alt={vigilancePictogram.tooltip}
            title={vigilancePictogram.tooltip}
          />
        )}
      </div>
    </div>
  )
}
