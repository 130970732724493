import { FC } from 'react'
import { Icon } from '../../../../shared'
import { IdentityStatusConditionProps } from './IdentityStatusCondition.model'
import classNames from 'classnames'
import { getV3ColorCssVariable } from '../../../../../design-system/colors_v3'

export const IdentityStatusCondition: FC<IdentityStatusConditionProps> = ({ label, valid }) => (
  <div className="flex">
    <Icon
      size="nano"
      icon={valid ? 'done' : 'cross'}
      color={
        valid
          ? getV3ColorCssVariable('shades', 'shade3')
          : getV3ColorCssVariable('status', 'invalid')
      }
    />
    <span
      className={classNames('ml-2', {
        'text-status-invalid': !valid,
        'text-shades-3': valid,
      })}
    >
      {label}
    </span>
  </div>
)
