import React, { FunctionComponent } from 'react'

import { parseISO } from 'date-fns'

import { ColorsV2 } from '../../../design-system/colors_v2'
import { Icon } from '../../../components/shared'
import { getDistanceInWordsToNow } from '../../../misc/date.utilities'

import styles from './ToolbarButton.module.scss'

export const HistoryButton: FunctionComponent<{ onClick: () => void; updatedAt: string }> = ({
  onClick,
  updatedAt,
}) => (
  <div role="button" className={styles.button} onClick={onClick}>
    <Icon icon="history" size="nano" color={ColorsV2.backgroundDark3} />
    <div className={styles.label}>il y a {getDistanceInWordsToNow(parseISO(updatedAt))}</div>
  </div>
)
