import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { questionInstancesKeys } from './questionInstance.keys'
import { getQuestionInstance } from '../../../data/questionInstances'

interface GetQuestionInstanceParams {
  id: number
  enabled?: boolean
}

export const useGetQuestionInstance = ({ id, enabled = true }: GetQuestionInstanceParams) => {
  const queryKey = useMemo(() => questionInstancesKeys.detail(id), [id])

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await getQuestionInstance(id)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la question`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
