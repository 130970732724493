import React, { FunctionComponent, useState } from 'react'
import { LabeledButtonProps } from './LabeledButton.model'
import { Heading } from '../../fonts'
import styles from './LabeledButton.module.scss'
import { Icon } from '../../Icon'
import { ColorsV2 } from '../../../../design-system/colors_v2'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const LabeledButton: FunctionComponent<LabeledButtonProps> = ({
  label,
  icon,
  active = true,
  type = 'button',
  size = 'medium',
  testId,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      type={type}
      onClick={onClick}
      className={styles.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-test-id={testId}
    >
      <div
        className={cx(styles.icon, {
          hovered: isHovered,
          inactive: !active,
          [size]: true,
        })}
      >
        <Icon
          icon={icon}
          size={size === 'medium' ? 'nano' : 'femto'}
          color={active ? ColorsV2.dsWhite : ColorsV2.dsLight5}
        />
      </div>
      <div className={cx(styles.label, { small: size === 'small' })}>
        <Heading
          size={size === 'medium' ? 5 : 6}
          theme={active ? (isHovered ? 'medium' : 'primary') : isHovered ? 'primary' : 'medium'}
        >
          {label}
        </Heading>
      </div>
    </button>
  )
}
