import React, { FC, memo, useEffect, useMemo } from 'react'
import { HeadlessEditorProps } from './HeadlessEditor.model'
import { getVariablePlugins } from '@/plugins'
import { EditorContent, EditorContext, useEditor } from '@tiptap/react'
import { EDITOR_ALL_TOOLS } from '@/model/Toolbar'
import { useEditorConfig } from '@/hooks'
import { VariableEditorConfig } from '@/model/Config'
import { EditorConfigContext, VariableEditorContext } from '@/context'
import classNames from 'classnames/bind'
import styles from '../../styles/editor/elfe.module.scss'
import { defaultImageFetch } from '@/utils/image.utils'
import { isDefined } from '@/utils/functions.utils'
import { DEFAULT_FONT_FAMILY } from '@/model/Font'

const cx = classNames.bind(styles)

const defaultSanitizeHtml = (html: string) => html

export const HeadlessEditor: FC<HeadlessEditorProps> = memo(function ({
  content,
  variableMap = {},
  defaultFontFamily,
  themes,
  authenticatedImageRequest = defaultImageFetch,
  sanitizeHtmlVariable = defaultSanitizeHtml,
}) {
  const editorConfig = useEditorConfig({
    tools: Array.from(EDITOR_ALL_TOOLS),
    themes: [],
    authenticatedImageRequest,
  })

  const variableEditorConfig = useMemo<VariableEditorConfig>(
    () => ({ variableMap, sanitizeHtmlVariable, variableMenu: null }),
    [sanitizeHtmlVariable, variableMap],
  )

  const extensions = useMemo(() => getVariablePlugins({ defaultFontFamily }), [defaultFontFamily])

  const editor = useEditor({
    content,
    extensions,
    editable: false,
    editorProps: {
      attributes: {
        style: `--elfe-default-font-family: ${defaultFontFamily ?? DEFAULT_FONT_FAMILY}`,
      },
    },
  })

  useEffect(() => {
    if (isDefined(editor) && editor.getHTML() !== content) {
      editor.commands.setContent(content)
    }
  }, [content, editor])

  return (
    <div className={cx('elfe-base', themes)}>
      <EditorContext.Provider value={{ editor }}>
        <VariableEditorContext.Provider value={variableEditorConfig}>
          <EditorConfigContext.Provider value={editorConfig}>
            <EditorContent editor={editor} content={content} />
          </EditorConfigContext.Provider>
        </VariableEditorContext.Provider>
      </EditorContext.Provider>
    </div>
  )
})
