import { useQuery } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useMemo } from 'react'
import { messageTemplatesKeys } from './messageTemplates.keys'
import { getDefaultMessageTemplate } from '../../../data/messageTemplates/getDefaultMessageTemplate'

interface GetDefaultMessageTemplateParams {
  enabled?: boolean
}

export const useGetDefaultMessageTemplate = ({
  enabled = true,
}: GetDefaultMessageTemplateParams) => {
  const queryKey = useMemo(() => messageTemplatesKeys.defaultMessage, [])

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await getDefaultMessageTemplate()
      if (!ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le modèle de message par défaut`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
