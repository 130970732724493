import { Filters } from '../../../model/Filters'

const all = ['mail']
const lists = [...all, 'list']
const list = (filters: Filters) => [...lists, filters]
const detail = (id: string) => [...all, id]
const attachment = (mailId: string, part: number | string, cdaPartId?: string) => [
  ...detail(mailId),
  'attachment',
  part,
  ...(cdaPartId ? [cdaPartId] : []),
]
const allowPatientEmailUse = (email: string) => [...all, 'patientEmailUse', email]

export const mailKeys = {
  all,
  lists,
  list,
  detail,
  attachment,
  allowPatientEmailUse,
}
