import { useMutation } from '@tanstack/react-query'
import { DoctolibPatient } from '../../../misc/Doctolib/doctolib.model'
import { useCreatePatient } from '../patients'
import {
  doctolibToFollowPatientMapper,
  openFollowPatient,
  followToDoctolibPatientMapper,
} from '../../../misc/Doctolib/doctolib.utils'
import { UserFriendlyError } from '../../../model/Error'
import { doctolibZipperUpdateOrCreatePatient } from '../../../misc/Doctolib/doctolib.actions'

interface PatientMutateVariable {
  patient: DoctolibPatient
}

export const useCreateFollowPatient = () => {
  const { mutate: createPatient } = useCreatePatient({ triggerSubscription: false })

  return useMutation({
    mutationFn: async ({ patient }: PatientMutateVariable) => {
      try {
        const fwPatient = doctolibToFollowPatientMapper(patient, true)

        createPatient(
          { patient: fwPatient },
          {
            onSuccess: (createPatientData) => {
              openFollowPatient(createPatientData.id)
              doctolibZipperUpdateOrCreatePatient({
                ...followToDoctolibPatientMapper(createPatientData),
                doctolib_id: patient.doctolib_id, // On repasse le doctolib id pour éviter la création d'un doublon au retour dans Doctolib
              })
            },
          },
        )
      } catch (e: any) {
        throw new UserFriendlyError(
          'error',
          `L'import des données patient depuis Doctolib a échoué : ${e.message}`,
        )
      }
    },
  })
}
