import React, { FunctionComponent, CSSProperties, ReactNode } from 'react'

import styles from './Tag.module.scss'
import {
  ColorsV2TagBackground,
  ColorsV2TagText,
  getV2Color,
} from '../../../../design-system/colors_v2'
import classNames from 'classnames'
import { Icon } from '../../Icon'
import { TestProps } from '../../../../misc/types.utilities'

interface TagProps extends TestProps {
  children?: ReactNode
  color?: ColorsV2TagBackground
  selected?: boolean
  title?: string
  ellipsis?: boolean
  multiline?: boolean
  onClose?: (event: React.MouseEvent) => void
  onClick?: (event: React.MouseEvent) => void
}

function getStyle(color: ColorsV2TagBackground, selected: boolean, onClick): CSSProperties {
  return {
    backgroundColor:
      onClick && !selected
        ? 'transparent'
        : onClick
          ? getV2Color(ColorsV2TagText[color])
          : getV2Color(color),
    border: onClick && !selected ? `1px solid ${getV2Color(ColorsV2TagText[color])}` : 'none',
    color:
      onClick && !selected
        ? getV2Color(color)
        : onClick
          ? 'white'
          : getV2Color(ColorsV2TagText[color]),
    fill:
      onClick && !selected
        ? getV2Color(color)
        : onClick
          ? 'white'
          : getV2Color(ColorsV2TagText[color]),
    cursor: onClick ? 'pointer' : 'default',
  }
}

export const Tag: FunctionComponent<TagProps> = React.forwardRef<HTMLSpanElement, TagProps>(
  (
    {
      color = ColorsV2TagBackground.dsTag00,
      selected = false,
      children,
      title,
      ellipsis = true,
      multiline = false,
      testId,
      onClose,
      onClick,
    },
    ref,
  ) => {
    const style = getStyle(color, selected, onClick)
    return (
      <span
        ref={ref}
        className={classNames(styles.container, {
          [styles.multiline]: multiline,
        })}
        style={style}
        onClick={onClick}
        title={title}
        data-test-id={`tag-${testId}`}
      >
        <span
          className={classNames({
            [styles.ellipsis]: ellipsis,
          })}
        >
          {children}
        </span>
        {onClose && (
          <div className={styles.cross} onClick={onClose} data-test-id={`button-tag-remove`}>
            <Icon icon="cross" size="pico" />
          </div>
        )}
      </span>
    )
  },
)
