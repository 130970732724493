import { useMutation } from '@tanstack/react-query'
import { useActionDispatch } from '../../../utils'
import { addValid } from '../../../../store/message'
import { logoutStellair } from '../../../../data/vitalCard'
import { UserFriendlyError } from '../../../../model/Error'
import { externalServicesActions } from '../../../../store/domain/externalServices'

export const useLogoutStellair = () => {
  const valid = useActionDispatch(addValid)
  const fetchExternalServices = useActionDispatch(externalServicesActions.getStatus)

  return useMutation({
    mutationFn: async () => {
      const { status, ok, data } = await logoutStellair()

      if (!ok) {
        console.error(data)
        throw new UserFriendlyError(
          'error',
          'Erreur lors de la déconnexion de Stellair',
          `Une erreur est survenue (${status})`,
        )
      }
    },

    onSuccess: () => {
      fetchExternalServices()
      valid('Déconnecté·e de Stellair')
    },
  })
}
