import React from 'react'
import { Icon } from '../../shared'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import { getDistanceToNowInDays } from '../../../misc/date.utilities'
import { HealthDataModalInputWrapperProps } from './HealthDataModalInputWrapper.model'
import styles from './HealthDataModalInputWrapper.module.scss'
import classNames from 'classnames'

export function HealthDataModalInputWrapper({
  value,
  type,
  disabled,
  onClickHistory,
  children,
}: HealthDataModalInputWrapperProps) {
  return (
    <div>
      {children && (
        <div className={classNames(styles.wrapper, disabled ? styles.disabled : undefined)}>
          {children}
        </div>
      )}
      {type.canBeHistorized && value?.createdAt && (
        <div className={styles.history_container}>
          <span className={styles.update_label}>{`Modifié ${getDistanceToNowInDays(
            new Date(value.createdAt),
          )}`}</span>
          <div className={styles.history_button_container} onClick={() => onClickHistory(type)}>
            <Icon icon="history" size="nano" color={getV3ColorCssVariable('primary', 'default')} />
            <span className={styles.history_label}>Historique</span>
          </div>
        </div>
      )}
    </div>
  )
}
