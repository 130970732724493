export type VariableType = 'inline' | 'inline-block' | 'block'
export type VariableState = 'fallback' | 'value'
export type VariableTheme = 'stale'

export interface RawVariableData {
  id: string
  value: string
  type: VariableType
  state: VariableState
}

export interface VariableData extends RawVariableData {
  themes?: Array<VariableTheme>
}

export type RawVariableMap = Record<string, RawVariableData>
export type VariableMap = Record<string, VariableData>

export interface VariableProperties {
  id: string
  displayConfig: string | null
}

export enum VariableAttrbs {
  variableId = 'variableid',
  displayConfig = 'displayconfig',
  flatId = 'flat-variableid',
}

export enum VariableDataAttrbs {
  variableId = `data-variableid`,
  displayConfig = `data-displayconfig`,
  flatId = 'data-flat-variableid',
}
