import { IDrugVariableConfigurationDispatchProps } from './DrugVariableConfiguration.model'
import { DrugVariableConfiguration } from './DrugVariableConfiguration.component'
import { connect } from 'react-redux'
import { medicalEventContentUiActions } from '../../../store/ui/medicalEvents/medicalEventContent'

const mapDispatchToProps: IDrugVariableConfigurationDispatchProps = {
  selectMedicalEventContent: medicalEventContentUiActions.selectMedicalEventContent,
}

export default connect(undefined, mapDispatchToProps)(DrugVariableConfiguration)
