import { FC, useCallback, useMemo, useState } from 'react'
import { Button } from '../../shared'
import { ManualPrescriptionBottomPanelProps } from './ManualPrescriptionBottomPanel.model'
import { v4 as uuid } from 'uuid'
import { ManualPrescriptionForm } from '../ManualPrescriptionForm'
import { ManualPrescriptionFormValue } from '../ManualPrescriptionForm/ManualPrescriptionForm.model'
import { formatISO } from 'date-fns'
import { BottomPanel } from '../../shared/bottomPanel/BottomPanel'
import { domainEditorActions } from '../../../store/domain/editor/editor.actions'
import { useActionDispatch } from '../../../hooks/utils'
import { ManualPrescription } from '../../../model/ManualPrescription'

export const ManualPrescriptionBottomPanel: FC<ManualPrescriptionBottomPanelProps> = ({
  isUnmounting,
  onClose,
  onSubmit,
}) => {
  const [manualPrescriptionForm, setCurrentManualPrescription] =
    useState<ManualPrescriptionFormValue>({
      content: '',
      startDate: new Date(),
      endDate: null,
    })

  const updatePendingVariables = useActionDispatch(domainEditorActions.setPendingVariables)

  const handleSubmit = useCallback(() => {
    if (!manualPrescriptionForm.startDate) return

    const manualPrescription: ManualPrescription = {
      variableUuid: uuid(),
      content: manualPrescriptionForm.content,
      startDate: formatISO(manualPrescriptionForm.startDate),
      endDate: manualPrescriptionForm.endDate ? formatISO(manualPrescriptionForm.endDate) : null,
    }

    onSubmit(manualPrescription)
    updatePendingVariables({
      manualPrescriptions: [manualPrescription],
    })
  }, [
    manualPrescriptionForm.content,
    manualPrescriptionForm.endDate,
    manualPrescriptionForm.startDate,
    onSubmit,
    updatePendingVariables,
  ])

  const isFormValid = useMemo(
    () =>
      manualPrescriptionForm.content &&
      manualPrescriptionForm.startDate &&
      (manualPrescriptionForm.endDate
        ? manualPrescriptionForm.endDate.getTime() > manualPrescriptionForm.startDate.getTime()
        : true,
      []),
    [
      manualPrescriptionForm.content,
      manualPrescriptionForm.endDate,
      manualPrescriptionForm.startDate,
    ],
  )

  return (
    <BottomPanel
      isUnmounting={isUnmounting}
      onRequestClose={onClose}
      title="Ajouter une prescription libre"
      actions={
        <>
          <Button label="Annuler" theme="dark" onClick={onClose} />
          <Button
            type="submit"
            label="Enregistrer"
            theme="primary"
            disabled={!isFormValid}
            onClick={handleSubmit}
          />
        </>
      }
    >
      <div className="flex h-full bg-shades-white">
        <div className="w-full mt-5 ml-10">
          <ManualPrescriptionForm
            currentManualPrescription={manualPrescriptionForm}
            onChange={(manualPrescription) =>
              setCurrentManualPrescription((currentManualPrescription) => ({
                ...currentManualPrescription,
                ...manualPrescription,
              }))
            }
          />
        </div>
      </div>
    </BottomPanel>
  )
}
