import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteDoctolibPendingPatientData } from '../../../data/patients/doctolib'
import { doctolibZipperUpdateOrCreatePatient } from '../../../misc/Doctolib/doctolib.actions'
import { followToDoctolibPatientMapper } from '../../../misc/Doctolib/doctolib.utils'
import { UserFriendlyError } from '../../../model/Error'
import { Patient } from '../../../model/Patient'
import { doctolibPendingChangesKeys } from './doctolibPendingChanges.keys'

interface DeletePendingFollowPatientDataVariables {
  patient: Patient
}

export const useDeletePendingFollowPatientData = () => {
  const queryClient = useQueryClient()
  const mutate = useMutation({
    mutationFn: async ({ patient }: DeletePendingFollowPatientDataVariables) => {
      const { ok } = await deleteDoctolibPendingPatientData(patient.id)

      if (!ok) {
        throw new UserFriendlyError(
          'error',
          'La supression des informations temporaires provenant de Doctolib a échoué',
        )
      }
    },

    onSuccess: (_, { patient }) => {
      doctolibZipperUpdateOrCreatePatient(followToDoctolibPatientMapper(patient))
      queryClient.refetchQueries({
        queryKey: doctolibPendingChangesKeys.detail(patient.id.toString()),
      })
    },
  })

  return mutate
}
