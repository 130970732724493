import { basename } from '../history'
import { QueryFilter } from '../model/Filters'

export function normalizeSearchParams(filters: QueryFilter): Record<string, string> {
  return Object.entries(filters).reduce((params, [property, value]) => {
    if (value !== undefined && value !== null) {
      params[property] = Array.isArray(value) ? value.join(',') : value
    }
    return params
  }, {})
}
export function serializeToUrlSearchParam(searchParams: QueryFilter) {
  const normalized = normalizeSearchParams(searchParams)
  const urlSearch = new URLSearchParams(normalized)

  return {
    pathname: '',
    search: urlSearch.toString(),
  }
}

interface GenericSchema {
  type: 'string' | 'boolean' | 'custom' | 'arrayOfNumbers' | 'arrayOfStrings'
  defaultValue?: any
  customName?: string
}
interface CustomDeserializerSchema extends GenericSchema {
  type: 'custom'
  customDeserializer: (filterParam: string) => any
}
interface SimpleSchema extends GenericSchema {
  type: 'string' | 'boolean'
}

interface ArrayOfNumbersSchema extends GenericSchema {
  type: 'arrayOfNumbers'
}

interface ArrayOfStringsSchema extends GenericSchema {
  type: 'arrayOfStrings'
}

export interface SearchSchema {
  [name: string]:
    | ArrayOfNumbersSchema
    | ArrayOfStringsSchema
    | CustomDeserializerSchema
    | SimpleSchema
}
export function deserializeFromUrlSearchParams<T>(search: string, searchSchema: SearchSchema): T {
  const urlQuery = new URLSearchParams(search)
  const res = Object.entries(searchSchema).map(([name, searchParam]) => {
    const filterParam = urlQuery.get(name)
    const returnName = searchParam.customName || name
    if (!filterParam) {
      return [returnName, searchParam.defaultValue]
    }
    switch (searchParam.type) {
      case 'arrayOfNumbers':
        return [returnName, filterParam.split(',').map((idStr) => parseInt(idStr, 10))]
      case 'arrayOfStrings':
        return [returnName, filterParam.split(',')]
      case 'boolean':
        return [name, filterParam === 'true']
      case 'custom':
        return [returnName, searchParam.customDeserializer(filterParam)]

      default:
        return [returnName, filterParam]
    }
  })
  return res.reduce((acc, [name, value]) => {
    return { ...acc, [name]: value }
  }, {} as T)
}

const join = (...fragments) => fragments.filter((fragment) => fragment !== '/').join('')

export const assignLocation = (url: string) => {
  window.location.assign(join(basename, url))
}

export const replaceLocation = (url: string) => {
  window.location.replace(join(basename, url))
}

export function redirectToErrorPage(mode: 'maintenance' | 'error' = 'error') {
  const pathname = document.location.pathname.replace(process.env.PUBLIC_URL, '')
  const searchParams = new URLSearchParams({ redirect: pathname, mode })
  const maintenanceUrl = `/error.html?${searchParams}`
  assignLocation(maintenanceUrl)
}

export const getQueryParam = (queryParam: string) => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(queryParam)
}
