import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deletePatientInformations } from '../../../data/patientInformations/deletePatientInformations'
import { UserFriendlyError } from '../../../model/Error'
import { patientInformationsKeys } from './patientInformations.keys'

interface DeletePatientInformationsVariables {
  patientId: number
  patientInformationsId: string
}

export const useDeletePatientInformations = (triggerSubscription: boolean = true) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: triggerSubscription ? patientInformationsKeys.mutation : [],

    mutationFn: async ({
      patientId,
      patientInformationsId,
    }: DeletePatientInformationsVariables) => {
      const { ok } = await deletePatientInformations(patientId.toString(), patientInformationsId)
      if (!ok) {
        throw new UserFriendlyError('error', `La suppression de l'allergie a échoué`)
      }
      return null
    },
    onSuccess: (_data, { patientId }) =>
      queryClient.invalidateQueries({ queryKey: patientInformationsKeys.list(patientId) }),
  })
}
