import { Filters } from '../../../model/Filters'

const all = ['countries']
const lists = [...all, 'list']
const list = (filters: Filters) => [...lists, filters]

export const countriesKeys = {
  all,
  lists,
  list,
}
