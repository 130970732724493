import getApi from '../../api'
import { MessageTemplateMutationPayload } from './mapper/messageTemplates.model'
import { MessageTemplate } from '../../model/MessageTemplate'

export const createMessageTemplate = (
  messageTemplate: MessageTemplateMutationPayload,
  params?: Record<string, any>,
) =>
  getApi().post<MessageTemplate>('/message_templates', messageTemplate, {
    params,
  })
