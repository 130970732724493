import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { updateQuestionnaireTemplate } from '../../../data/questionnaireTemplates'
import { questionnaireTemplatesKeys } from './questionnaireTemplates.keys'
import { QuestionnaireTemplateMutationPayload } from '../../../data/questionnaireTemplates/mapper/questionnaireTemplate.model'

interface QuestionnaireTemplateMutateVariable {
  id: number
  payload: QuestionnaireTemplateMutationPayload
}

export const useUpdateQuestionnaireTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, payload }: QuestionnaireTemplateMutateVariable) => {
      const { data, ok } = await updateQuestionnaireTemplate(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour du questionnaire a échoué')
      }
      return data
    },

    onSuccess: (questionnaireTemplate) => {
      queryClient.invalidateQueries({ queryKey: questionnaireTemplatesKeys.lists })
      queryClient.setQueryData(
        questionnaireTemplatesKeys.detail(questionnaireTemplate.id),
        questionnaireTemplate,
      )
    },
  })
}
