import { FeaturesType } from '@follow/cdk'
import { useMutation } from '@tanstack/react-query'
import { insiPatientSanityCheck } from '../../../misc/insi.utilities'
import { UserFriendlyError } from '../../../model/Error'
import { InsiIdentityCheckPayload, InsiPatient } from '../../../model/Insi'
import { addError, addValid } from '../../../store/message'
import { useActionDispatch } from '../../utils'
import { useUserEnabledFeature } from '../../utils/user'
import { useInsiRestIdentityCheck } from './insiRest'
import { useInsiConsultIdentityCheck } from './insiConsult'
import { isDefined } from '../../../misc/functions.utilities'

interface UseInsiIdentityCheckVariables {
  patient: InsiIdentityCheckPayload | null
}

export const useInsiIdentityCheck = () => {
  const insiService = useUserEnabledFeature(FeaturesType.insiValidation)

  const dispatchValid = useActionDispatch(addValid)
  const dispatchError = useActionDispatch(addError)

  const { mutateAsync: insiRestCheck } = useInsiRestIdentityCheck()
  const { mutateAsync: insiConsultCheck } = useInsiConsultIdentityCheck()

  const mutation = useMutation<InsiPatient | null, Error, UseInsiIdentityCheckVariables>({
    mutationFn: async ({ patient }) => {
      if (!insiService) {
        return null
      }

      // Vérification d'identité selon le service INSi
      let patientResponse: InsiPatient | undefined = undefined

      if (insiService === 'insiValidationRest') {
        if (!isDefined(patient)) {
          throw new UserFriendlyError(
            'error',
            "Impossible de vérifier l'identité du patient",
            'Informations incomplètes',
          )
        }
        patientResponse = await insiRestCheck({ patient })
      }
      if (insiService === 'insiValidationInApp') {
        const response = await insiConsultCheck({ patient })
        // Cas où l'utilisateur annule la vérif en fermant la modale
        if (response === null) {
          return null
        }
        patientResponse = response
      }
      if (!patientResponse) {
        throw new Error('Empty insi response, it should never happen')
      }

      // Traitement de la réponse
      const sanityCheckErrors = insiPatientSanityCheck(patientResponse)
      if (sanityCheckErrors.length > 0) {
        const firstError = sanityCheckErrors.at(0)
        const fieldName =
          (firstError === 'birthDate' && 'La date de naissance') ||
          (firstError === 'birthFirstName' && 'Le prénom de naissance') ||
          (firstError === 'birthLastName' && 'Le nom de naissance') ||
          (firstError === 'sex' && 'Le sexe') ||
          'Un champ'

        throw new UserFriendlyError(
          'error',
          sanityCheckErrors.length === 1
            ? `${fieldName} est invalide`
            : 'Plusieurs informations sont invalides',
          "Le service INSi a retourné une identité contenant des erreurs. Elle n'a pas été rapatriée dans le dossier patient. (Code retour INSI: 01)",
        )
      }

      return patientResponse
    },
    onSuccess: (data) => {
      if (data === null) return

      dispatchValid(
        'Succès',
        "Les informations d'identité du patient ont été validées et récupérées depuis le service INSi. (Code retour INSI: 00)",
      )
    },
    onError: (error) => {
      if (!(error instanceof UserFriendlyError)) {
        dispatchError("La validation de l'identité a échoué", 'Vérifiez votre saisie')
      }
    },
  })

  return mutation
}
