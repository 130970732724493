import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { useCallback, useMemo } from 'react'
import { singleItemPageLimit, startingPage } from '../../../constants'
import { getNextPageParam } from '../../../misc/query.utilities'
import { usePaginationState, useFlatPaginatedData } from '../../utils/query'
import { messageTemplatesKeys } from './messageTemplates.keys'
import { getMessageTemplates } from '../../../data/messageTemplates'

interface GetInfiniteMessageTemplatesParams {
  enabled?: boolean
  limit?: number
}

export const useGetInfiniteMessageTemplates = ({
  enabled = true,
  limit = singleItemPageLimit,
}: GetInfiniteMessageTemplatesParams = {}) => {
  const queryKey = useMemo(() => messageTemplatesKeys.list, [])

  const queryReturn = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam, signal }) => {
      const { data, ok } = await getMessageTemplates(pageParam, signal, limit)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer la liste des modèles de message`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
    initialPageParam: startingPage,
    getNextPageParam,
  })

  const queryClient = useQueryClient()
  const messageTemplateList = useFlatPaginatedData(queryReturn.data)
  const paginationState = usePaginationState(queryReturn.data)

  const cancelPendingQuery = useCallback(() => {
    queryClient.cancelQueries({ queryKey })
  }, [queryClient, queryKey])

  return { query: queryReturn, messageTemplateList, paginationState, cancelPendingQuery }
}
