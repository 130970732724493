import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getV3ColorCssVariable } from '../../../../design-system/colors_v3'
import { useSelectArrowNavigation } from '../../../../hooks/utils'
import { SelectOption } from '../../../../model/SelectOption'
import { DropdownItem, DropdownOverlay } from '../../dropdown'
import { Icon } from '../../Icon'
import { SelectInputProps } from './SelectInput.model'
import styles from './SelectInput.module.scss'
import { addTestIdSuffix } from '../../../../misc/testId.utilities'

export const getSelectInputTheme = (disabled: boolean) => ({
  light: {
    background: disabled ? 'bg-shades-8' : 'bg-transparent',
    border: 'border-shades-5',
    color: disabled ? 'text-shades-5' : 'text-shades-2',
    placeholder: 'text-shades-5',
    wrappedLabel: 'placeholder:text-shades-2',
    placeholderClass: 'placeholder:text-shades-5',
    title: 'text-shades-4',
    arrow: getV3ColorCssVariable('shades', 'shade5'),
  },
  dark: {
    background: disabled ? 'bg-shades-3' : 'bg-shades-2',
    border: 'border-shades-4',
    color: disabled ? 'text-shades-5' : 'text-shades-white',
    wrappedLabel: 'placeholder:text-shades-white',
    placeholder: 'text-shades-5',
    placeholderClass: 'placeholder:text-shades-5',
    title: 'text-shades-5',
    arrow: getV3ColorCssVariable('shades', 'shade4'),
  },
})

export const defaultRenderOption = (
  option: SelectOption<any>,
  currentValue: SelectOption<any> | undefined,
  isHovered: boolean,
) => (
  <DropdownItem icon={option.icon} selected={isHovered || currentValue?.value === option.value}>
    <div className="max-w-full whitespace-normal">{option.label}</div>
  </DropdownItem>
)

export const defaultFilterOptions = (textInput: string, allOptions: SelectOption<any>[]) => {
  return allOptions.filter(({ label }) => label.toLowerCase().includes(textInput.toLowerCase()))
}

export function SelectInput<T>({
  value,
  options,
  icon,
  title,
  disabled = false,
  required = false,
  colorPreset = 'light',
  height = 'medium',
  placeholder,
  readonly,
  valid,
  error,
  onSelect,
  onToggle,
  renderOption = defaultRenderOption,
  filterOptions = defaultFilterOptions,
  testId,
}: SelectInputProps<T>) {
  const [open, setOpen] = useState(false)
  const [textValue, setTextValue] = useState(value?.label ?? '')
  const inputRef = useRef<HTMLInputElement>(null)

  const colorTheme = getSelectInputTheme(disabled)[colorPreset]
  const disableInteraction = disabled || readonly

  const titleText = title && `${title}${required ? '*' : ''}`

  const handleSelect = (option: SelectOption<T>) => {
    onSelect(option)
    handleOpen(false)
  }

  const handleOpen = useCallback(
    (open: boolean) => {
      setOpen(open)
      onToggle && onToggle(open)
    },
    [onToggle],
  )

  useEffect(() => {
    setTextValue(value?.label ?? '')
  }, [value])

  const filteredOptions = useMemo(
    () => filterOptions(textValue, options),
    [filterOptions, options, textValue],
  )

  const [scrollRef, hoveredRef, hoveredIndex, handleKeyDown] = useSelectArrowNavigation(
    filteredOptions,
    onSelect,
  )

  return (
    <div data-test-id={addTestIdSuffix('root', testId)}>
      <div
        className={classNames(
          colorTheme.border,
          'relative border-b w-full rounded-t group focus-within:border-primary-default',
          {
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disableInteraction,
            'h-13': height === 'medium',
            'h-8': height === 'small',
            [styles.invalid]: valid === false,
            [styles.valid]: valid === true,
          },
        )}
        onBlur={() => handleOpen(false)}
      >
        <div
          className="pl-2 pr-3 w-full h-full flex items-center"
          data-test-id={addTestIdSuffix('input-wrapper', testId)}
          onClick={() => {
            if (!disableInteraction) {
              handleOpen(!open)
              inputRef.current?.focus()
            }
          }}
        >
          {icon && (
            <div className="mr-2">
              <Icon icon={icon} size="nano" color={getV3ColorCssVariable('shades', 'shade4')} />
            </div>
          )}
          <div className="w-full text-left">
            {titleText && (
              <span className={classNames('text-xs font-medium', colorTheme.title)}>
                {titleText}
              </span>
            )}
            <input
              value={textValue}
              ref={inputRef}
              onChange={(event) => setTextValue(event.currentTarget.value)}
              placeholder={placeholder ?? value?.label}
              className={classNames(
                `w-full appearance-none bg-transparent cursor-inherit text-base font-medium placeholder:font-medium`,
                colorTheme.placeholderClass,
                colorTheme.color,
              )}
              data-test-id={addTestIdSuffix('input', testId)}
              readOnly={disableInteraction}
              onFocus={() => {
                if (!disableInteraction) {
                  setTextValue('')
                }
              }}
              onBlur={() => setTextValue(value?.label ?? '')}
              onKeyDown={handleKeyDown}
            />
          </div>
          <Icon icon="chevron" size="femto" color={colorTheme.arrow} rotate={open ? 180 : 0} />
        </div>
        <DropdownOverlay display={open} scrollRef={scrollRef}>
          {filteredOptions.map((item, index) => (
            <div
              ref={hoveredIndex === index ? hoveredRef : undefined}
              key={index}
              onMouseDown={() => handleSelect(item)}
              data-test-id={addTestIdSuffix('option', testId)}
            >
              {renderOption(item, value, hoveredIndex === index)}
            </div>
          ))}
        </DropdownOverlay>
      </div>
      {error && <span className={styles.errorLabel}>{error}</span>}
    </div>
  )
}
