import React, { FC, useMemo } from 'react'
import { DEFAULT_FONT_FAMILY, FontFamily, FONT_FAMILIES } from '@/model/Font'
import { ToolbarElementProps } from '@/model/Toolbar'
import { computeFontFamilyOption } from './FontFamilySelect.utils'
import { SelectOption } from '@/model/SelectOption'
import { ToolbarSelect } from '@/components/toolbar/atoms'
import { PluginNames } from '@/model/Plugins'

const fontFamiliesOptions = FONT_FAMILIES.map(computeFontFamilyOption)

const renderOption = (option: SelectOption<FontFamily>) => {
  return <span style={{ fontFamily: option.value }}>{option.label}</span>
}

export const FontFamilySelect: FC<ToolbarElementProps> = ({ editor }) => {
  const activeFontFamily = editor.getAttributes('textStyle')['fontFamily']

  const activeFontFamilyOption = useMemo(() => {
    if (activeFontFamily) {
      return computeFontFamilyOption(activeFontFamily)
    }
    // Affichage de la police par défaut
    const fontFamilyPlugin = editor.extensionManager.extensions.find(
      ({ name }) => name === PluginNames.FontFamily,
    )
    const defaultFontFamily = fontFamilyPlugin?.options['defaultFontFamily'] ?? DEFAULT_FONT_FAMILY

    return computeFontFamilyOption(defaultFontFamily)
  }, [activeFontFamily, editor.extensionManager.extensions])

  const onFontFamilySelect = (option: SelectOption<FontFamily>) => {
    editor.chain().focus().setFontFamily(option.value).run()
  }

  return (
    <ToolbarSelect
      title="Police de caractères"
      placeholder="Police"
      value={activeFontFamilyOption}
      options={fontFamiliesOptions}
      onChange={onFontFamilySelect}
      renderItem={renderOption}
    />
  )
}
