import React, { FC, useMemo } from 'react'
import { ToolbarSelect } from '@/components/toolbar/atoms'
import { SelectOption } from '@/model/SelectOption'
import { findAttributesAmongTypes } from '@/utils/plugins.utils'
import { PluginNames } from '@/model/Plugins'
import { ToolbarElementProps } from '@/model/Toolbar'
import { CustomStorage } from '@tiptap/react'

export const BlockSpacingSelect: FC<ToolbarElementProps> = ({ editor }) => {
  const blockSpacingOptions = (editor.storage as CustomStorage).blockSpacing.getOptions()
  const spacingOptions = blockSpacingOptions.spacings

  const activeSpacing: string | null = findAttributesAmongTypes(
    editor,
    blockSpacingOptions.types,
    PluginNames.BlockSpacing,
  )

  const activeSpacingOption = useMemo(
    () => spacingOptions.find(({ value }) => value === activeSpacing),
    [activeSpacing, spacingOptions],
  )

  const handleSpacingSelect = (option: SelectOption<string | null>) => {
    editor.chain().focus().setBlockSpacing(option.value).run()
  }

  return (
    <ToolbarSelect
      title="Espacement inter-paragraphe"
      icon="ParagraphSpacing"
      value={activeSpacingOption}
      options={spacingOptions}
      onChange={handleSpacingSelect}
      onlyIcon
    />
  )
}
