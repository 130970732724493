export const EMAIL_REGEXP_PATTERN = `^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$`
// eslint-disable-next-line no-useless-escape
export const FOLLOW_EMAIL_REGEXP_PATTERN = `^[a-zA-Z0-9_.+-]+(@follow\.fr)$`
export const NAME_REGEXP_PATTERN = `^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$`
export const PHONE_REGEXP_PATTERN = `^\\+?\\s*(\\d+\\s?){8,}$`

export const nameRegex = new RegExp(NAME_REGEXP_PATTERN)
export const phoneRegex = new RegExp(PHONE_REGEXP_PATTERN)
export const followAdminEmailRegex = new RegExp(FOLLOW_EMAIL_REGEXP_PATTERN)
export const internationalPhoneRegex =
  /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/

export const postalCodeRegex = /\d{5}$/
export const emailRegex = new RegExp(EMAIL_REGEXP_PATTERN)
export const imagesRegex = /gif|jpg|jpeg|svg|png/
export const floatRegex = /([0-9]+([.,][0-9]*)?|[,.][0-9]+)/
export const integerRegex = /^\d+$/
export const departmentRegex = /\b\d([AB]|\d{1,2})\b/
export const versionRegex = /(\d{1,2}.\d{1,2}.\d{1,2})/
export const uuidRegex =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
export const phoneCountryCodeRegex = /^\+?(?<code>\d{1,3})$/
export const cpsPincodeRegex = /^\d{4}$/
export const lineBreakRegex = /(\r\n|\n|\r)/gm
