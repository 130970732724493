import React, { FunctionComponent } from 'react'
import { TabProps } from './Tab.model'
import { Icon } from '../../../../../shared'
import { getV3ColorCssVariable } from '../../../../../../design-system/colors_v3'
import classNames from 'classnames'
import styles from './Tab.module.scss'

export const Tab: FunctionComponent<TabProps> = ({
  notification,
  icon,
  iconSize = 'normal',
  status = 'default',
  testId,
  active,
}) => {
  return (
    <div
      key={icon}
      data-test-id={testId}
      className={classNames(styles.container, {
        [styles.active]: active,
      })}
    >
      <div className={classNames(styles.spacer)}>
        <Icon color={getV3ColorCssVariable('shades', 'white')} icon={icon} size={iconSize} />
        {status !== 'default' && <div className={classNames(styles.status, styles[status])} />}
        {!!notification && notification > 0 && (
          <div className={classNames(styles.notification, 'bg-red-600')}>{notification}</div>
        )}
      </div>
    </div>
  )
}
