import { connect } from 'react-redux'
import { branch, compose, renderNothing } from 'recompose'

import { RootState } from '../../../../store'
import { currentUserSelector, userMeTeamsSelector } from '../../../../store/domain/me'
import { PatientTimeline } from './PatientTimeline.component'
import {
  PatientTimelineDispatchProps,
  PatientTimelineProps,
  PatientTimelineStoreProps,
} from './PatientTimeline.model'

import { medicalEventDomainActions } from '../../../../store/domain/medicalEvents/index'
import { medicalEventsPageSelector } from '../../../../store/ui/medicalEvents'
import { currentUserMeTeamsSelector } from '../../../../store/domain/me/me.selectors'
import { Patient } from '../../../../model/Patient'

const mapStateToProps = (state: RootState): PatientTimelineStoreProps => ({
  currentUser: currentUserSelector(state),
  timeline: medicalEventsPageSelector(state),
  teams: currentUserMeTeamsSelector(state),
  userTeams: userMeTeamsSelector(state),
})

const mapDispatchToProps: PatientTimelineDispatchProps = {
  onPinMedicalEvent: medicalEventDomainActions.pinMedicalEvent,
  duplicateMedicalEventDocuments: medicalEventDomainActions.duplicateMedicalEventDocuments,
  changeOwner: medicalEventDomainActions.changeOwner,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withBranch = branch<PatientTimelineStoreProps>(
  ({ currentUser }) => !currentUser,
  renderNothing,
)

const withCompose = compose<PatientTimelineProps, { patient: Patient }>(
  withConnect,
  withBranch,
)(PatientTimeline)
export { withCompose as PatientTimeline }
