import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionInstancesKeys } from './questionInstance.keys'
import { updateQuestionInstance } from '../../../data/questionInstances'
import { QuestionInstanceMutationPayload } from '../../../data/questionInstances/mapper/questionInstances.model'

interface QuestionInstanceMutateVariable {
  id: number
  payload: QuestionInstanceMutationPayload
}

export const useUpdateQuestionInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, payload }: QuestionInstanceMutateVariable) => {
      const { data, ok } = await updateQuestionInstance(id, payload)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour de la template de question a échoué')
      }
      return data
    },

    onSuccess: (question) => {
      queryClient.invalidateQueries({ queryKey: questionInstancesKeys.lists })
      queryClient.invalidateQueries({ queryKey: questionInstancesKeys.detail(question.id) })
      // question.questionnaires.map(({ id }) =>
      //   queryClient.invalidateQueries(questionnaireTemplatesKeys.detail(id)),
      // )
    },
  })
}
