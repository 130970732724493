import { useMutation } from '@tanstack/react-query'
import { createPrescriptionFiles } from '../../../data/ordoclic/createPrescriptionFiles'
import { UserFriendlyError } from '../../../model/Error'

interface CreatePrescriptionFilesMutateVariables {
  medicalEventId: number
  prescriptionIds: string[]
}

export const useCreatePrescriptionFiles = () => {
  return useMutation({
    mutationFn: async ({
      medicalEventId,
      prescriptionIds,
    }: CreatePrescriptionFilesMutateVariables) => {
      const { ok, data } = await createPrescriptionFiles(medicalEventId, prescriptionIds)

      if (!ok) {
        throw new UserFriendlyError(
          'error',
          "Une erreur s'est produite",
          'Impossible de générer les fichiers de la prescription',
        )
      }

      return data
    },
  })
}
