import { useMemo } from 'react'
import { professionsKeys } from './professions.keys'
import { useQuery } from '@tanstack/react-query'
import { Profession } from '../../../model/Professions'
import { getProfessions } from '../../../data/professions'

export const useGetProfessions = () => {
  const queryKey = useMemo(() => professionsKeys.list(), [])

  const query = useQuery<Array<Profession>>({
    queryKey,
    queryFn: async () => {
      const { data, ok } = await getProfessions()
      if (!data || !ok) {
        throw new Error('Impossible de récupérer la liste des professions')
      }
      return data
    },
    gcTime: Infinity,
    staleTime: Infinity,
  })

  return { query }
}
