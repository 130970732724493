import React, { FunctionComponent, useMemo } from 'react'

import { ObservationsProps } from './Observations.model'
import styles from './Observations.module.scss'
import { EditorEvents, EditorTool, SimpleEditor, useElfeEditor } from '@follow/elfe'
import { debounce } from 'lodash'

const editorTools: Array<EditorTool> = ['marks', 'align', 'list', 'undo']

export const Observations: FunctionComponent<ObservationsProps> = ({
  content,
  onChange,
  readonly,
}) => {
  const handleChange = useMemo(
    () =>
      debounce((event: EditorEvents['update']) => {
        onChange(event.editor.getHTML())
      }, 500),
    [onChange],
  )

  const { editor, config } = useElfeEditor(
    {
      editorType: 'simple',
      tools: editorTools,
      themes: ['rounded', 'no-paragraph-space'],
    },
    {
      content,
      onUpdate: handleChange,
    },
  )

  return (
    <div className={styles.observations} data-test-id="editor-observations">
      <SimpleEditor
        editor={editor}
        config={config}
        disabled={readonly}
        toolbarStyle={{ top: 'var(--navbar-height)' }}
      />
    </div>
  )
}
