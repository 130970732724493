import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserFriendlyError } from '../../../model/Error'
import { questionInstancesKeys } from '../questionInstances/questionInstance.keys'
import { updateAnswerInstance } from '../../../data/answerInstances'

interface RemoveImageFromAnswerInstanceMutateVariable {
  answerInstanceId: number
  questionnaireInstanceId?: number
}

export const useRemoveImageFromAnswerInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ answerInstanceId }: RemoveImageFromAnswerInstanceMutateVariable) => {
      const { data, ok } = await updateAnswerInstance(answerInstanceId, {
        imageFile: null,
      })
      if (!data || !ok) {
        throw new UserFriendlyError('error', `La suppression de l'image sur la réponse a échoué`)
      }
      return data
    },

    onSuccess: (answer, { questionnaireInstanceId }) => {
      queryClient.invalidateQueries({ queryKey: questionInstancesKeys.detail(answer.questionId) })
      // if (questionnaireId) {
      //   queryClient.invalidateQueries(questionnairesKeys.detail(questionnaireId))
      // }
    },
  })
}
