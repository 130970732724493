import { FunctionComponent, useContext, useMemo } from 'react'
import { TaskAssignedSelectProps } from './TaskAssignedSelect.model'
import { Icon, SelectSearch, useSelectSearchFiltering } from '../../../../../shared'
import { getFullName, isDoctor } from '../../../../../../misc/user.utilities'
import { ConnectedUserContext } from '../../../../../../misc/auth.utilities'
import { hasAssistantRole } from '../../../../../../misc/roles.utilities'
import { LightAssistantWithPermissions, LightUser } from '../../../../../../model/User'
import styles from './TaskAssignedSelect.module.scss'

export const TaskAssignedSelect: FunctionComponent<TaskAssignedSelectProps> = ({
  selectedAssigned,
  setSelectedAssigned,
  isEditModeEnabled,
}) => {
  const displayedName = selectedAssigned ? getFullName(selectedAssigned) : undefined
  const assignedDefaultName = displayedName === undefined ? 'Aucun collaborateur' : displayedName

  const { loggedUser, currentUser } = useContext(ConnectedUserContext)

  const assignedList: Array<LightUser | LightAssistantWithPermissions> = useMemo(() => {
    if (loggedUser && currentUser) {
      if (hasAssistantRole(loggedUser!!.roles)) {
        return [currentUser, loggedUser]
      } else if (isDoctor(loggedUser)) {
        return [...loggedUser.assistants, currentUser]
      }
    }

    return []
  }, [currentUser, loggedUser])

  const selectedAssignedOption = useMemo(
    () =>
      selectedAssigned
        ? {
            value: selectedAssigned,
            label: `${selectedAssigned.firstName} ${selectedAssigned.familyName}`,
          }
        : undefined,
    [selectedAssigned],
  )

  const assignedOptions = useMemo(() => {
    return assignedList.map((user) => ({
      value: user,
      label: `${user?.firstName} ${user?.familyName}`,
    }))
  }, [assignedList])

  const [assignedText, setAssignedText, filteredAssignedOptions] = useSelectSearchFiltering(
    selectedAssignedOption,
    assignedOptions,
  )

  return (
    <div className={styles.container}>
      {isEditModeEnabled ? (
        <SelectSearch
          placeholder="Rechercher un collaborateur"
          textValue={assignedText}
          onTextChange={setAssignedText}
          onFocus={() => setAssignedText('')}
          value={selectedAssignedOption}
          options={filteredAssignedOptions}
          onSelect={(newValue) => setSelectedAssigned(newValue?.value)}
          clearable
        />
      ) : (
        <div className={styles.displayValueWrapper}>
          <span className={styles.assignedValue}>{assignedDefaultName}</span>
          <div className={styles.iconWrapper}>
            <Icon size="femto" icon="chevron" />
          </div>
        </div>
      )}
    </div>
  )
}
