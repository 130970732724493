import { EditorConfigContext } from '@/context'
import React, { FC, useContext } from 'react'
import classNames from 'classnames/bind'
import styles from './ToolbarSeparator.module.scss'

const cx = classNames.bind(styles)

export const ToolbarSeparator: FC = () => {
  const { themes } = useContext(EditorConfigContext)

  return <div className={cx('separator', themes)} data-separator />
}
