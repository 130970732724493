import React, { FunctionComponent } from 'react'
import classNames from 'classnames/bind'

import { getV2Color, ColorsV2TagText } from '../../../../design-system/colors_v2'

import { ITagColorProps } from './TagColor.model'
import styles from './TagColor.module.scss'

const cx = classNames.bind(styles)

export const TagColor: FunctionComponent<ITagColorProps> = ({
  active = false,
  color,
  onClick,
  disabled = false,
}) => (
  <div
    className={cx(styles.color, { active, disabled })}
    style={{ backgroundColor: getV2Color(ColorsV2TagText[color]) }}
    onClick={!disabled ? () => onClick && onClick(color) : undefined}
  />
)
