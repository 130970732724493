import React, { FunctionComponent } from 'react'
import classNames from 'classnames/bind'
import Ink from 'react-ink'
import { Link } from 'react-router-dom'

import { ColorsV2 } from '../../../../design-system/colors_v2'
import {
  createMatchDevice,
  MatchDeviceProps,
  onlyDesktopConfig,
} from '../../../../misc/MatchDevice'

import { Icon } from '../../Icon'

import styles from './Button.module.scss'
import { ButtonProps } from './Button.model'
import { IconsType } from '../../Icon/Icon.library'

const cx = classNames.bind(styles)

const Button: FunctionComponent<ButtonProps & MatchDeviceProps> = (props) => {
  const { type, icon, label, theme, disabled, onClick, link } = props
  const { testId = `button-${theme}` } = props

  return link ? (
    <Link
      className={cx(styles.button, {
        disabled: disabled === true,
        [theme]: true,
      })}
      to={link}
      data-test-id={testId}
    >
      {buttonContent(label, theme, props.matchDevice, disabled, icon)}
    </Link>
  ) : (
    <button
      type={type}
      className={cx(styles.button, {
        disabled: disabled === true,
        [theme]: true,
      })}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      data-test-id={testId}
    >
      {buttonContent(label, theme, props.matchDevice, disabled, icon)}
    </button>
  )
}

const themeToIconColor = (
  color: string,
): ColorsV2.brandPrimary | ColorsV2.contentDark1 | ColorsV2.contentLight1 => {
  switch (color) {
    case 'dark':
      return ColorsV2.contentLight1
    case 'transparent':
      return ColorsV2.contentDark1
    case 'primary':
      return ColorsV2.contentLight1
    default:
      return ColorsV2.brandPrimary
  }
}

export const buttonContent = (
  label: string,
  theme: string,
  ink = false,
  disabled?: boolean,
  icon?: IconsType,
) => {
  return (
    <>
      {!disabled && ink && <Ink />}
      <div className={styles.label}>
        {icon && <Icon icon={icon} color={themeToIconColor(theme)} size={'micro'} />}
        {label}
      </div>
    </>
  )
}

const withHoc = createMatchDevice(onlyDesktopConfig)(Button)
export { withHoc as Button }
