import getApi from '../../api'
import { singleItemPageLimit } from '../../constants'
import { AtcClass } from '../../model/Drug'
import { PaginatedList } from '../../model/Pagination'

export const getAtcClassChildren = (
  id: number,
  page: number,
  signal?: AbortSignal,
  limit = singleItemPageLimit,
) =>
  getApi().get<PaginatedList<AtcClass>>(
    `/atc_classifications/${id}/children`,
    { page, limit },
    {
      signal,
    },
  )
