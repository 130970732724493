import { FC, useCallback, useState } from 'react'
import { useUpdatePrescription } from '../../../hooks/queries/prescriptions'
import { useCreatePrescriptionTemplate } from '../../../hooks/queries/prescriptionTemplates'
import { Posology, PosologyFormResource } from '../../../model/Posology'
import { PosologyForm } from '../../drug'
import { DocumentPosologyProps } from './DocumentPosology.model'

export const DocumentPosology: FC<DocumentPosologyProps> = ({
  prescription,
  activeIntervalId,
  disabled = false,
  setActiveIntervalId,
  onSuccessfullUpdate,
}) => {
  const [intervalForms, setIntervalForms] = useState<PosologyFormResource[]>([])
  const { mutate: createPrescriptionTemplate } = useCreatePrescriptionTemplate()
  const { mutate: updatePrescription } = useUpdatePrescription()

  const handlePosologyChange = useCallback(
    (posologies: Partial<Posology>[]) => {
      if (!prescription) return
      updatePrescription(
        {
          id: prescription.uuid,
          prescription: {
            posologyIntervals: posologies,
          },
        },
        {
          onSuccess: onSuccessfullUpdate,
        },
      )
    },
    [onSuccessfullUpdate, prescription, updatePrescription],
  )

  const handleUpdatePrescriptionAndCreateTemplate = useCallback(
    (posologies: Partial<Posology>[], name: string) => {
      if (!prescription) return

      updatePrescription(
        {
          id: prescription.uuid,
          prescription: {
            posologyIntervals: posologies,
          },
        },
        {
          onSuccess: () => {
            createPrescriptionTemplate({
              drugs: prescription.drugs,
              prescriptionUuid: prescription.uuid,
              name,
            })
            onSuccessfullUpdate?.()
          },
        },
      )
    },
    [prescription, updatePrescription, createPrescriptionTemplate, onSuccessfullUpdate],
  )

  return (
    <PosologyForm
      prescriptionId={prescription.uuid}
      drugs={prescription.drugs}
      intervals={prescription.posologyIntervals}
      activeIntervalId={activeIntervalId}
      intervalForms={intervalForms}
      setIntervalForms={setIntervalForms}
      setActiveIntervalId={setActiveIntervalId}
      disabled={disabled}
      onSubmit={handlePosologyChange}
      onSubmitAndCreateTemplate={handleUpdatePrescriptionAndCreateTemplate}
      availableAdministrationRoutes={prescription.availableAdministrationRoutes}
    />
  )
}
