import { getFullName } from '../../../misc/user.utilities'
import { MultiSelectOption } from '../../../model/SelectOption'
import { Team } from '../../../model/Team'
import { LightUser, User } from '../../../model/User'
import {
  defaultSharingConfig,
  AccessLevelOptionValues,
} from './AccessLevelSelect/AccessLevelSelect.model'

export const userToOption = (user: LightUser) => ({
  label: getFullName(user),
  value: user,
})

export const optionsToIds = (options: ReadonlyArray<MultiSelectOption<LightUser>>) =>
  options.map(({ value }) => value.id)

export const areUsersEquals = (a: LightUser, b: LightUser) => a.id === b.id

export const rehydrateSharingConfig = (team: Team | undefined): typeof defaultSharingConfig => {
  if (!team) {
    return defaultSharingConfig
  }

  return team.sharingConfig.reduce((acc, config) => {
    return {
      ...acc,
      [config.resourceName]: AccessLevelOptionValues[config.accessLevel],
    }
  }, defaultSharingConfig)
}

export const rehydrateMembers = (selectedTeam: Team | undefined, selectedUser: User | null) => {
  if (selectedTeam) {
    return {
      members: selectedTeam.memberUsers.map(userToOption),
      contacts: selectedTeam.contactUsers.map(userToOption),
    }
  } else if (selectedUser) {
    return {
      members: [userToOption(selectedUser)],
      contacts: [],
    }
  } else {
    return {
      members: [],
      contacts: [],
    }
  }
}
