import { camelToSeparator } from '../misc/string.utilities'
import colorsv3 from '../styles/variables/_colors_v3.scss'
import { FwCssVariableColor } from './design-system.model'

const shades = {
  black: colorsv3.shadeBlack,
  half: colorsv3.shadeHalf,
  shade1: colorsv3.shade1,
  shade2: colorsv3.shade2,
  shade3: colorsv3.shade3,
  shade4: colorsv3.shade4,
  shade5: colorsv3.shade5,
  shade6: colorsv3.shade6,
  shade7: colorsv3.shade7,
  shade8: colorsv3.shade8,
  white: colorsv3.shadeWhite,
} as const

const primary = {
  dark: colorsv3.primaryDark,
  default: colorsv3.primaryDefault,
  light: colorsv3.primaryLight,
  washed: colorsv3.primaryWashed,
  transparent: colorsv3.primaryTransparent,
} as const

const secondary = {
  dark: colorsv3.secondaryDark,
  default: colorsv3.secondaryDefault,
  light: colorsv3.secondaryLight,
  washed: colorsv3.secondaryWashed,
  transparent: colorsv3.secondaryTransparent,
} as const

const status = {
  valid: colorsv3.statusValid,
  warning: colorsv3.statusWarning,
  invalid: colorsv3.statusInvalid,
  normal: colorsv3.statusNormal,
  info: colorsv3.statusInfo,
  danger: colorsv3.statusDanger,
} as const

const transparentStatus = {
  valid: colorsv3.statusTransparentValid,
  warning: colorsv3.statusTransparentWarning,
  invalid: colorsv3.statusTransparentInvalid,
} as const

const labels = {
  lightGray: colorsv3.labelLightGray,
  gray: colorsv3.labelGray,
  darkGray: colorsv3.labelDarkGray,
  brown: colorsv3.labelBrown,
  garnetRed: colorsv3.labelGarnetRed,
  red: colorsv3.labelRed,
  orange: colorsv3.labelOrange,
  yellow: colorsv3.labelYellow,
  green: colorsv3.labelGreen,
  blue: colorsv3.labelBlue,
  navyBlue: colorsv3.labelNavyBlue,
  purple: colorsv3.labelPurple,
  magenta: colorsv3.labelMagenta,
} as const

const transparentLabels = {
  gray: colorsv3.labelTransparentGray,
  darkGray: colorsv3.labelTransparentDarkGray,
  brown: colorsv3.labelTransparentBrown,
  garnetRed: colorsv3.labelTransparentGarnetRed,
  red: colorsv3.labelTransparentRed,
  orange: colorsv3.labelTransparentOrange,
  yellow: colorsv3.labelTransparentYellow,
  green: colorsv3.labelTransparentGreen,
  blue: colorsv3.labelTransparentBlue,
  navyBlue: colorsv3.labelTransparentNavyBlue,
  purple: colorsv3.labelTransparentPurple,
  magenta: colorsv3.labelTransparentMagenta,
} as const

const dimmer = {
  light: colorsv3.dimmerLight,
  medium: colorsv3.dimmerMedium,
  dark: colorsv3.dimmerDark,
} as const

export const COLORS_V3 = {
  shades,
  primary,
  secondary,
  status,
  transparentStatus,
  labels,
  transparentLabels,
  dimmer,
} as const

export type ColorsV3ShadesName = keyof typeof shades
export type ColorsV3PrimaryName = keyof typeof primary
export type ColorsV3SecondaryName = keyof typeof secondary
export type ColorsV3StatusName = keyof typeof status
export type ColorsV3TransparentStatusName = keyof typeof transparentStatus
export type ColorsV3LabelsName = keyof typeof labels
export type ColorsV3TransparentLabelsName = keyof typeof transparentLabels
export type ColorsV3DimmerName = keyof typeof dimmer

type ColorsV3 = typeof COLORS_V3
export type ColorV3Names = keyof ColorsV3

export function getV3Color<ColorName extends ColorV3Names>(
  name: ColorName,
  color: keyof ColorsV3[ColorName],
) {
  return COLORS_V3[name][color]
}

export function getV3ColorCssVariable<ColorName extends ColorV3Names>(
  name: ColorName,
  color: keyof ColorsV3[ColorName],
): FwCssVariableColor {
  return `--fw-color-${camelToSeparator(name, '-')}-${camelToSeparator(color.toString(), '-')}`
}
