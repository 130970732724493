import getApi from '../../api'
import { MailClassificationAttachment } from '../../model/Mail'
import { mapSelectedAttachmentsToApiMailClassifyAttachmentResources } from './mapper'

export const classifyAttachments = (
  messageId: string,
  selectedAttachments: MailClassificationAttachment[],
  patientId: number,
  documentCategoryValue: string,
  date: Date,
) =>
  getApi().post(`/mss/messages/${messageId}/classify`, {
    attachments: mapSelectedAttachmentsToApiMailClassifyAttachmentResources(selectedAttachments),
    patientId,
    documentCategoryId: documentCategoryValue,
    medicalEventDate: date.toISOString(),
  })
