import { useCallback } from 'react'
import { downloadBlob } from '../../../misc/blob.utilities'
import { CustomFile } from '../../../model/File'
import { UserFriendlyError } from '../../../model/Error'
import { fetchFile } from '../../../store/cache/files/api'
import { base64StringToBlob } from 'blob-util'
import { queryClient } from '../../../App'
import { fileKeys } from './files.keys'
import { addExtensionIfMissing } from '../../../misc/files.utilities'

export type DirectDownloadableFileVariable = {
  fileId: number
}

export const useDirectDownloadFile = () => {
  const download = useCallback((file: CustomFile) => {
    if (!file.base64) {
      throw new Error(`Base64 content missing on file ${file.id}`)
    }
    const splitted = file.base64.split(',')
    const blob = base64StringToBlob(splitted[1])
    const filename = addExtensionIfMissing(file.visibleName, file.extension)

    downloadBlob(blob, filename)
  }, [])

  return async ({ fileId }: DirectDownloadableFileVariable) => {
    const data = await queryClient.fetchQuery<CustomFile>({
      queryKey: fileKeys.detail(fileId),
      queryFn: async () => {
        const { data, ok } = await fetchFile(fileId)

        if (!data || !ok) {
          throw new UserFriendlyError(
            'error',
            `Impossible de récupérer le fichier ${fileId}`,
            `Une erreur technique s'est produite`,
          )
        }
        return data
      },
    })

    return download(data)
  }
}
