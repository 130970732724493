import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { MailNavbar } from '../../components/layout'
import { MailEditor, MailList } from '../../components/mail'
import { FullLoader, ErrorPandaMessage } from '../../components/shared'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import styles from './MailLayout.module.scss'
import { useUserEnabledFeature } from '../../hooks/utils/user'
import { FeaturesType, isDefined } from '@follow/cdk'
import { useGetMailList } from '../../hooks/queries/mail/useGetMailList.query'
import { isLoading } from '../../store/http'
import { useMailConnectionStatus } from '../../hooks/utils/mail'
import { ConnectedUserContext } from '../../misc/auth.utilities'
import { useSetAtom } from 'jotai'
import {
  mailWebserviceAuthToken,
  mailWebserviceAuthFirstStepToken,
  selectedEmailAddressAtom,
} from '../../state/mail'
import { useQueryClient } from '@tanstack/react-query'
import { mailWebserviceKeys } from '../../hooks/queries/mail/webservice/webservice.keys'

export const MailLayout: FunctionComponent = () => {
  const { mailId } = useParams<'mailId'>()

  const { currentUser } = useContext(ConnectedUserContext)
  const isMailConnectionOk = useMailConnectionStatus(currentUser)

  const [search, setSearch] = useState('')
  const [isMailEditorOpen, setIsMailEditorOpen] = useState(false)

  const setWebserviceToken = useSetAtom(mailWebserviceAuthToken)
  const setWebserviceFirstStepToken = useSetAtom(mailWebserviceAuthFirstStepToken)
  const setSelectedEmailAddress = useSetAtom(selectedEmailAddressAtom)

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    mailList,
    query: {
      hasNextPage,
      fetchNextPage,
      isLoading: isMailListLoading,
      error: mailListError,
      refetch,
    },
  } = useGetMailList({ search, enabled: isMailConnectionOk })

  const isMssSmtpEnabled = useUserEnabledFeature(FeaturesType.mssSmtp)

  // Redirection automatique vers le premier mail si aucun mail sélectionné
  useEffect(() => {
    const firstMail = mailList.at(0)
    if (!isDefined(mailId) && isDefined(firstMail)) {
      navigate(firstMail.id)
    }
  }, [mailId, mailList, navigate])

  const fetchStatus = useMemo(() => {
    if (isMailListLoading) return 'loading'
    if (mailListError) return 'error'
    return 'success'
  }, [isMailListLoading, mailListError])

  const handleOpenMailEditor = () => {
    setIsMailEditorOpen(true)
  }

  const handleCloseMailEditor = () => {
    setIsMailEditorOpen(false)
  }

  const logout = () => {
    setWebserviceToken(null)
    setWebserviceFirstStepToken(null)

    // On délaie légèrement les opérations qui ne touchent pas au local storage
    // car les modifs du local storage ne sont pas instantanées et risqueraient de ne pas être exécutées
    setTimeout(() => {
      queryClient.removeQueries({ queryKey: mailWebserviceKeys.mailAddresses })
      setSelectedEmailAddress(null)
      navigate('/authenticate/mail')
    }, 0)
  }

  return (
    <div className={styles.mailLayout}>
      <div className={styles.navbarContainer}>
        <MailNavbar disconnect={isMssSmtpEnabled ? undefined : logout} />
      </div>

      <div className={styles.mailContainer}>
        <div className={styles.mailList}>
          <MailList
            mailList={mailList}
            refetch={() => refetch()}
            fetchNextPage={hasNextPage && !isLoading ? fetchNextPage : undefined}
            search={search}
            setSearch={setSearch}
            openMailEditor={handleOpenMailEditor}
            isLoading={isMailListLoading}
            hasError={!!mailListError}
          />
        </div>
        <div className={styles.mailDetail}>
          {fetchStatus === 'loading' && !isDefined(mailId) ? (
            <div className={styles.loading}>
              <FullLoader message="Chargement de votre messagerie sécurisée..." />
            </div>
          ) : fetchStatus === 'error' ? (
            <div className={styles.mailError}>
              <ErrorPandaMessage
                title="Une erreur est survenue"
                subTitle="Nous n'avons pas pu charger votre messagerie sécurisée. Veuillez réessayer ultérieurement."
                contactSupport
                backToHomePage
                className={styles.errorContainer}
              />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
      <MailEditor display={isMailEditorOpen} onClose={handleCloseMailEditor} />
    </div>
  )
}
