import React, { FunctionComponent } from 'react'
import styles from './TaskEmptyState.module.scss'
import { TaskEmptyStateProps } from './TaskEmptyState.model'

export const TaskEmptyState: FunctionComponent<TaskEmptyStateProps> = ({ content }) => {
  return (
    <div className={styles.row}>
      <div>
        <h2 className={styles.title}>Aucune tâche</h2>
        <div className={styles.description}>
          <p>{content}</p>
        </div>
      </div>
    </div>
  )
}
